@import '../../base';

@include b('MessageContextMenu') {
  @include c('cancelEscalation') {
    color: var(--primary-300);
  }

  @include c('removeEscalation') {
    color: var(--primary-300);
  }

  @include c('delete') {
    color: var(--primary-300);
  }

  @include c('recall') {
    color: var(--primary-300);
  }
}
