@import '../../base';

@include b('DeliveryMethodContextMenu') {
  @include c('menu-list') {
    width: 699px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.14);

    @include c('menu-list-item') {
      margin-top: 5px;
    }

    @include c('list-item') {
      margin-left: 40px;
      margin-top: -20px;
    }
  }
}
