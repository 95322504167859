@import '../../base';

@include b('EscalationButton') {
  display: flex;
  margin: auto 0;

  @include c('image') {
    @include svg-background-and-icon(var(--neutral-700), var(--neutral-100));

    .icon {
      stroke: var(--neutral-100);
    }

    width: 25px;
    height: 25px;
    display: flex;
    margin: auto;

    &,
    * {
      cursor: pointer;
      user-select: none;
    }

    &:hover {
      @include svg-background-and-icon(var(--escalation-100), var(--neutral-100));

      .icon {
        fill: var(--neutral-150);
      }
    }

    &--isEscalated {
      @include svg-background-and-icon(var(--escalation-100), var(--neutral-100));

      .icon {
        fill: var(--neutral-150);
      }

      // @include themify {
      //   .icon {
      //     fill: themed('send-message-bg');
      //   }
      // }
    }

    &--alwaysEscalate {
      @include svg-background-and-icon(var(--neutral-700), var(--neutral-100));

      path.icon {
        stroke: var(--neutral-100);
      }

      &:hover {
        @include svg-background-and-icon(var(--neutral-700), var(--neutral-100));
      }
    }
  }
}
