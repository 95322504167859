@import '../../base';

@mixin button {
  background-color: var(--neutral-100);
  border-radius: 6px;
  cursor: pointer;
  font-size: 11px;
  font-weight: 500;
  line-height: 32px;
  margin-left: 8px;
  text-align: center;
  text-transform: uppercase;
  width: 74px;

  &:hover {
    background-color: var(--secondary-500);
    color: var(--neutral-100);
  }
}

@include b('CollaborationEscalationModal') {
  background-color: var(--neutral-150) !important;
  overflow: scroll;

  .tc-CollaborationSearch {
    padding: 5px 0;

    .tc-CollaborationSearch__clear-button {
      right: 5px;
    }
  }

  .tc-CollaborationSearch__clear-button {
    right: 5px;
  }

  @include c('header') {
    display: none;
  }

  @include c('escalation-content') {
    margin: 0 auto;
    width: 500px;
  }

  @include c('modal') {
    box-shadow: none;
    background-color: transparent;
    border: none;
    height: 100vh;
    width: 100% !important;
  }

  @include c('sub-container') {
    background-color: var(--neutral-100);
    border-radius: 3px;

    // box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
    // margin: 3px;
    // width: 360px;
    min-height: auto;

    p {
      font-size: 13px;
      line-height: 16px;
      padding: 15px 20px 0;
    }

    @include c('title') {
      border-bottom: solid 1px var(--neutral-300);
      font-size: 12px;
      font-weight: bold;
      padding: 8px 7px;
      text-transform: uppercase;
    }

    border: solid 1px var(--neutral-300);
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.15);
    margin: 10px 0;
    position: relative;
    width: inherit;
  }

  @include c('close-escalation-button') {
    background-color: var(--primary-300);
    border-radius: 100%;
    color: var(--neutral-100);
    cursor: pointer;
    font-size: 18px;
    height: 34px;
    position: fixed;
    right: 20px;
    text-align: center;
    top: 15px;
    width: 34px;
  }

  @include c('close-icon') {
    margin-left: 5px;
    margin-top: 6px;

    path {
      fill: var(--neutral-100);
    }
  }

  @include c('cancel-circle') {
    background-color: var(--neutral-500);
    border-radius: 100%;
    height: 22px;
    position: absolute;
    right: 6px;
    top: 26px;
    width: 22px;

    path {
      fill: var(--neutral-100);
    }
  }

  @include c('cancel-icon') {
    color: var(--neutral-100);
  }

  @include c('clear-form-button') {
    color: var(--secondary-500);
    cursor: pointer;
    font-size: 10px;
    font-weight: bold;
    position: absolute;
    right: 10px;
    top: 10px;
  }

  @include c('optional-backup-button') {
    color: var(--secondary-500);
    cursor: pointer;
    font-size: 12px;
    margin-bottom: 30px;
  }

  @include c('switch-item') {
    align-items: center;
    display: flex;
  }

  @include c('switch-text') {
    font-size: 14px;
    line-height: 18px;
    padding-right: 10px;
  }

  @include c('curr-role-container') {
    align-items: center;
    display: flex;
    margin-bottom: 15px;
    margin-top: 70px;

    &--closed {
      margin-top: 100px;
    }
  }

  @include c('curr-role-icon') {
    height: 44px;
    margin-right: 10px;
    text-align: center;
    width: 44px;
  }

  @include c('role-icon') {
    margin-top: 12px;
  }

  @include c('role-icon-container') {
    background: #4570a9;
    border-radius: 22px;
    height: 22px;
    text-align: center;
    width: 22px;

    @include c('role-icon') {
      margin: 0;

      circle {
        fill: none;
      }
    }
  }

  @include c('down-arrow') {
    position: absolute;
    right: 13px;
    top: 35px;
  }

  @include c('curr-role-names') {
    display: flex;
    flex-direction: column;
  }

  @include c('curr-role-display-name') {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  @include c('curr-role-tag-name') {
    color: #9ca7a8;
    font-size: 12px;
  }

  @include c('sub-container-content') {
    padding: 25px 30px;
  }

  @include c('esc-pol-row') {
    display: flex;

    label {
      display: block;
      font-size: 11px;
      font-weight: bold;
      text-transform: uppercase;
      white-space: nowrap;
      width: 100%;
    }
  }

  @include c('esc-pol-backup') {
    cursor: pointer;
  }

  @include c('progress-col') {
    border-left: 2px dotted var(--neutral-400);
    margin-right: 20px;
    position: relative;
  }

  @include c('form-progress-circle') {
    border-radius: 5px;
    height: 10px;
    width: 10px;
  }

  @include c('start-symbol') {
    background-color: #00918a;
    border: solid 2px #00918a;
    border-radius: 5px;
    height: 10px;
    width: 10px;
    left: -6px;
    position: absolute;
  }

  @include c('mid-symbol') {
    background: var(--neutral-100);

    // border: solid 2px #000;
    // background-color: #00918a;
    // border: solid 2px #00918a;
    border: solid 2px #000;
    border-radius: 5px;
    height: 10px;
    width: 10px;
    left: -6px;
    position: absolute;

    &--inactive {
      border: solid 2px #c3c7cb;
    }
  }

  @include c('end-symbol') {
    border-bottom: 3px solid var(--neutral-1000);
    bottom: 0;
    left: -6px;
    position: absolute;
    width: 11px;
  }

  @include c('name-col') {
    position: relative;
    width: 330px;
  }

  @include c('og-recipient-name') {
    font-weight: 500;
    line-height: 34px;
    padding: 5px 0;
  }

  @include c('time-col') {
    margin-bottom: 15px;
    margin-left: 6px;
    position: relative;
  }

  @include c('esc-pol-input') {
    appearance: none;
    background: none;
    border: 1px solid var(--neutral-500);
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    height: 34px;
    margin: 5px 0;
    outline: none;
    padding: 0 10px;
    width: 100%;
  }

  @include c('select-time-dropdown') {
    &::placeholder {
      color: var(--neutral-500);
    }

    &::-ms-expand {
      display: none;
    }
  }

  @include c('drop-down-container') {
    border: solid 1px var(--neutral-300);
    border-radius: 6px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.09);
    max-height: 200px;
    min-height: 34px;
    overflow: auto;
    overflow-x: hidden;
    position: absolute;
    top: 16px;
    width: 100%;
  }

  @include c('policy-option') {
    p {
      color: var(--neutral-700);
      font-size: 12px;
      line-height: 16px;
      padding: 5px 0 0 20px;
    }

    input {
      margin-right: 5px;
    }
  }

  @include c('actions') {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 70px;
    margin-top: 36px;
    padding-bottom: 70px;
  }

  @include c('search-container') {
    padding: 10px;
  }

  @include c('avatar') {
    flex: 0 0 22px;
    margin-right: 10px;
  }

  @include c('display-name') {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
  }

  @include c('target-item') {
    align-items: center;
    cursor: pointer;
    display: flex;
    padding: 5px 10px;

    &:hover {
      background: var(--cool-neutral-300);
    }

    &--divider {
      position: relative;

      &::before {
        content: '';
        border-bottom: 1px solid var(--neutral-300);
        position: absolute;
        width: 85%;
        left: 15%;
        bottom: 0;
      }
    }
  }

  @include c('escalation-ends-text') {
    margin-bottom: -5px;
  }

  @include c('green-checkbox-container') {
    position: relative;

    @include c('green-label') {
      &::before {
        border: 1px solid;
        color: var(--neutral-700);
        content: '';
        display: inline-block;
        height: 12px;
        left: 0;
        position: absolute;
        width: 12px;
      }

      &--checked {
        &::before {
          content: none;
        }
      }
    }
  }

  @include c('green-checkbox') {
    left: -4px;
    position: absolute;
    top: -4px;
  }

  @include c('custom-checkbox') {
    opacity: 0;
  }

  @include c('button') {
    background-color: var(--neutral-100);
    border-radius: 6px;
    cursor: pointer;
    font-size: 11px;
    font-weight: 500;
    line-height: 32px;
    margin-left: 8px;
    text-align: center;
    text-transform: uppercase;
    width: 74px;

    &:hover {
      background-color: var(--secondary-500);
      color: var(--neutral-100);
    }
  }

  @include c('primary-button') {
    @include button;

    border: solid 1px var(--secondary-500);
    color: var(--secondary-500);
  }

  @include c('secondary-button') {
    @include button;

    border: solid 1px #9eb2bd;
    color: #9eb2bd;

    &:hover {
      background-color: #9eb2bd;
      border-color: #9eb2bd;
    }
  }
}
