@import '../base';

@include b('DeleteBroadcastListModal') {
  font-size: 18px;
  width: 485px;

  @include c('header') {
    flex: 0 0 auto;
    position: relative;
    margin: 34px 32px 0;
    color: var(--primary-700);
    font-size: 16px;
    text-align: center;
    font-weight: 600;
  }

  @include c('body') {
    display: flex;
    color: var(--neutral-950);
    padding: 20px 50px 50px;
    justify-content: center;
  }

  @include c('close-button') {
    @include common-modal-close-button;

    color: var(--cool-neutral-800);
  }

  @include c('cancel-btn') {
    justify-content: center;
    width: 104px;
    height: 39px;
    border-radius: 6px;
    border: solid 1px var(--cool-neutral-700);
    color: var(--cool-neutral-700);
    font-size: 11px;
    background-color: var(--neutral-100);
    cursor: pointer;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.21);
    margin-right: 8px;
    display: flex;
    align-items: center;

    &:hover {
      background-color: var(--cool-neutral-700);
      color: var(--neutral-300);
    }
  }

  @include c('info') {
    width: 340px;
    height: 63px;
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
    color: var(--neutral-1000);
  }

  @include c('delete-btn') {
    width: 104px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: solid 1px var(--primary-700);
    color: var(--primary-700);
    background-color: var(--neutral-100);
    font-size: 11px;
    cursor: pointer;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.21);

    &:hover {
      background-color: var(--primary-700);
      color: var(--neutral-300);
    }

    &:disabled {
      border-color: var(--neutral-300);
      color: var(--neutral-300);
      cursor: auto;

      &:hover {
        background-color: var(--neutral-100);
        color: var(--neutral-300);
      }
    }
  }

  @include c('title') {
    font-weight: bold;
    margin-bottom: 2vh;
  }

  @include c('footer') {
    @include common-modal-footer;

    position: relative;
    padding: 15px 32px;
    display: flex;
    justify-content: flex-end;
    font-weight: 500;
  }
}
