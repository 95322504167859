@import '../../base';

@include b('CollaborationTeamIcon') {
  background-color: #9baab6;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  @include c('team-icon') {
    height: 55%;

    g {
      fill: #fff !important;
    }
  }
}
