@import '../base';

@include b('SignInContainer') {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: #f9f9f9;
}
