@import '../../base';

@include b('ScheduleMessageReviewModal') {
  height: 640px;

  @include c('header') {
    @include common-modal-header;

    color: var(--neutral-1000);
    font-size: 16px;
    font-weight: normal;
  }

  @include c('header-content') {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;

    p {
      margin-left: 9px;
    }
  }

  @include c('sms-consent') {
    @include c('message-unsecured-note') {
      background-color: var(--neutral-200);
      border-radius: 6px;
      border: solid 1px rgba(55, 76, 92, 0.4);
      display: flex;
      font-size: 11px;
      height: 37px;
      margin: 0 0 24px -17px;
      padding: 5px 3px 4px 6px;
      width: 585px;

      @include c('message-unsecured-note-checkbox') {
        border-radius: 2px;
        height: 14px;
        margin: 7px;
        width: 14px;
      }

      @include c('message-unsecured-note-message') {
        align-items: center;
        display: flex;
        margin-left: 5px;
      }
    }
  }

  @include c('body') {
    @include common-modal-body;

    height: 600px;
    padding: 30px 60px 0;
  }

  @include c('primary-btn') {
    @include button-with-disable(var(--patient-network-700), 74px, 32px, 11px);

    &--isDisabled {
      opacity: 0.7;
      pointer-events: none;
      cursor: not-allowed;
    }

    user-select: none;
  }

  @include c('secondary-btn') {
    @include common-button(var(--neutral-800), 74px, 32px, 11px);

    margin-right: 8px;
    user-select: none;
  }

  @include c('info-container') {
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow-y: auto;
    position: relative;
    flex: 1 1 0;
    word-break: break-word;

    @include c('section') {
      display: flex;
      margin-bottom: 32px;

      & > svg {
        flex: 0 0 31px;
      }

      @include c('section-container') {
        display: flex;
        align-items: center;
        width: 100%;
        margin-left: 20px;
        color: var(--neutral-1000);
        font-size: 13px;

        &--isDeliverySection {
          color: var(--neutral-1000);
          font-size: 13px;
          font-weight: 600;
          text-align: left;

          > div {
            align-items: flex-start;
            flex-direction: column;

            span {
              margin: 0;
            }
          }
        }

        @include c('preferences-header') {
          &--scheduledMessageView {
            width: 77px;
            height: 19px;
            font-size: 14px;
            font-weight: 600;
            color: var(--patient-network-700);
          }
        }

        @include c('no-reply-header') {
          &--scheduledMessageView {
            width: 55px;
            height: 16px;
            font-size: 12px;
            color: var(--neutral-950);
          }
        }

        @include c('no-reply-container') {
          &--scheduledMessageView {
            width: 22px;
            height: 13px;
            font-size: 12px;
            font-weight: bold;
            color: var(--patient-network-700);
          }
        }
      }
    }

    @include c('arrows') {
      margin: 2px 4px -2px 8px;
    }

    @include c('recipient-container') {
      display: inline-block;
      border-radius: 6px;
      padding: 10px;
      background-color: var(--cool-neutral-300);

      &--scheduledMessageView {
        background-color: rgba(228, 236, 239, 0.6);
        color: rgba(53, 51, 60, 0.6);

        svg {
          opacity: 0.6;
        }
      }
    }

    @include c('schedule-section') {
      &--scheduledMessageView {
        display: flex;
        flex-direction: column;
      }

      @include c('send-now') {
        font-weight: 600;

        @include c('send-now-from') {
          color: var(--patient-network-700);
          font-size: 14px;
          font-weight: 600;
          height: 19px;
          width: 66px;
        }

        @include c('send-now-sender') {
          color: var(--neutral-1000);
          font-size: 13px;
          font-weight: 500;
          height: 18px;
          width: 117px;
        }
      }

      @include c('start-and-time') {
        display: flex;

        @include c('start-schedule') {
          font-weight: 600;

          @include c('schedule-header') {
            width: 98px;
            height: 19px;
            font-size: 14px;
            font-weight: 600;
            color: var(--patient-network-700);
          }

          @include c('start-schedule-header') {
            font-weight: 600;

            &--scheduledMessageView {
              color: var(--patient-network-700);
              font-size: 14px;
              font-weight: 600;
              height: 19px;
              width: 66px;
            }
          }
        }
      }

      @include c('timezone-abbr') {
        color: var(--patient-network-700);
        margin-left: 5px;
        font-size: 14px;
        font-weight: 600;
      }

      @include c('repeat-schedule') {
        margin-top: 5px;
        display: flex;

        @include c('repeat-schedule-svg') {
          margin-top: 1px;
        }

        @include c('repeat-schedule-details') {
          margin-left: 5px;
        }
      }
    }

    @include c('message-section') {
      @include c('message-header') {
        width: 98px;
        height: 19px;
        font-size: 14px;
        font-weight: 600;
        color: var(--patient-network-700);
      }

      @include c('template-name') {
        font-weight: 600;
      }

      @include c('message-body') {
        margin-bottom: 10px;
        white-space: pre-wrap;
        font-weight: 500;
      }

      @include c('attachment-container') {
        display: inline-flex;
        align-items: center;
        height: 28px;
        border: solid 0.5px var(--neutral-400);
        border-radius: 6px;
        padding: 0 10px;
        font-size: 12px;
        color: var(--neutral-1000);

        @include c('file-icon') {
          width: 14px;
          margin-right: 10px;
        }
      }
    }
  }

  @include c('footer') {
    position: relative;
    padding: 30px 32px 28px;
    display: flex;
    justify-content: flex-end;
    font-weight: 500;
    background-color: var(--neutral-150);

    &--isSmsMessage {
      flex-direction: column;
    }
  }
}

@include b('ScheduleMessageSuccessModal') {
  font-size: 13px;
  font-weight: normal;
  line-height: 23px;

  @include c('success-header') {
    margin-bottom: 14px;
    font-weight: 600;
    font-size: 22px;
  }

  @include c('success-recipient') {
    font-weight: 600;
  }

  @include c('success-start-date') {
    font-weight: 600;
  }

  @include c('success-time') {
    font-weight: 600;
  }

  @include c('success-timezone') {
    font-weight: normal;
  }

  @include c('no-reply-option') {
    position: relative;

    @include c('preferences-icon') {
      position: absolute;
      top: 2px;
      left: 35px;
      width: 18px;
      height: 18px;
    }
  }
}
