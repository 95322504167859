@import '../../base';
@import './PatientSettingMixins';

@include b('BroadcastListsView') {
  @include patient-settings-view;

  position: relative;

  @include c('header') {
    margin-bottom: 32px;

    @include flex-align-center;
    @include flex-justify-between;

    @include c('title') {
      @include header-text;
    }
  }

  @include c('actions') {
    margin-bottom: 20px;

    @include flex-justify-between;

    @include c('actions-left') {
      @include flex-align-center;

      @include c('create') {
        display: flex;
        align-items: center;
        margin-right: 15px;

        @include pa-primary-button;

        @include c('create-list-arrow') {
          display: inline-block;

          &--isExpanded {
            transform: scaleY(-1);
          }
        }

        &--currentAppSelected--BroadcastLists {
          background-color: var(--secondary-700);

          &--allowProviderBroadcastListAdSync {
            padding-right: 10px;
          }

          &:hover {
            background-color: var(--secondary-900);
          }

          @include c('create-list-arrow') {
            g {
              stroke: var(--neutral-100);
            }
          }
        }

        &:disabled {
          cursor: not-allowed;
          background-color: var(--neutral-400);
        }

        &:hover(:disabled) {
          background-color: var(--neutral-400);
        }
      }

      @include c('filters') {
        display: inline-block;
      }
    }

    @include c('actions-right') {
      @include c('actions-right-button') {
        @include bulk-delete;
      }
    }
  }

  @include c('loading-more') {
    @include loading-more;

    bottom: -10px;
  }

  @include c('create-list-dropdown') {
    position: absolute;
    top: 115px;
    left: 30px;
    width: 160px;
    padding: 6px 6px 8px;
    border-radius: 6px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.09);
    border: solid 1px var(--neutral-300);
    background-color: var(--neutral-100);
    text-align: left;

    @include c('create-list-dropdown-item') {
      width: auto;
      padding: 10px 8px;
      border-radius: 6px;
      font-size: 13px;

      // font-weight: 500;
      color: var(--neutral-950);
      cursor: pointer;

      &:hover {
        background-color: var(--cool-neutral-300);
      }
    }
  }
}
