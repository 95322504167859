@import '../../base';

@include b('PatientSearchBar') {
  width: 100%;
  height: 50px;
  margin-bottom: 1px;
  border-bottom: solid 1px #d6d9da;
  background-color: white;
  display: flex;
  flex-direction: row;

  @include if-ie {
    margin-bottom: 4px;
  }

  @include c('search-back-button') {
    position: relative;
    align-content: center;
    margin: 8px 15px 8px 10px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background-color: $focus-signifier;
    }

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
    }

    @include c('selection-arrow') {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 14px;
      bottom: 12px;
      width: 7px;
      height: 7px;
      border: 1px solid;
      border-color: #4d4d4d transparent transparent #4d4d4d;
      transform: rotate(-45deg);
    }
  }

  @include c('avatar-container') {
    width: 24px;
    height: 24px;

    @include b('AvatarImage') {
      @include c('image-container-circle') {
        @include if-ie {
          &--entityType--patientMessaging {
            width: 24px;
            height: 24px;
            background-color: $patient-color;
          }
        }
      }
    }
  }

  @include c('patient-container') {
    display: flex;
    align-items: center;

    @include c('patient-information') {
      display: flex;
      flex-direction: column;
      margin-left: 10px;

      @include c('patient-name') {
        color: $display-text-patient-network;
        font-size: 15px;
        font-weight: bold;
        max-width: 220px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      @include b('PatientInfo') {
        font-size: 10px;
        color: #b6b6b6;
      }
    }
  }

  @include c('contact-information') {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include c('contact-name') {
      font-size: 15px;
      font-weight: bold;
      color: $display-text-patient-network;
    }

    @include c('contact-relation') {
      font-size: 10px;
      color: #b6b6b6;
    }
  }
}
