@import '../../base';

@include b('DragModal') {
  @include c('overlay-white') {
    @include center-items-both;

    position: absolute;
    top: 0;
    left: -1px;
    width: 100%;
    height: 100%;
    z-index: 100;
    flex-direction: column;
    border: 6px solid var(--neutral-100);
  }

  @include c('overlay') {
    @include center-items-both;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    flex-direction: column;
    background-color: rgba(var(--neutral-100-rgb), 0.97);
    border-radius: 6px;

    &--drag-accepted {
      border: 3px solid var(--secondary-500);
    }

    &--drag-roster {
      border: 3px solid $nacho-cheese-orange;
    }
  }

  @include c('modal') {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: inherit;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;

    &::before {
      content: '';
      display: block;
      flex: 0 auto;
      height: 25%;
    }

    &::after {
      content: '';
      display: block;
      flex: 0 auto;
      height: 50%;
    }

    @include c('image') {
      flex: none;
      width: 73px;
      height: 94px;

      use {
        fill: var(--neutral-100);
      }

      path[fill='#FFF'] {
        fill: var(--neutral-100);
      }
    }

    @include c('caption') {
      flex: none;
      margin-top: 25px;
      font-weight: bold;

      &--drag-accepted {
        color: var(--secondary-500);
      }

      &--drag-roster {
        color: $nacho-cheese-orange;
      }
    }
  }
}
