@import '../base';

@include b('AvatarImage') {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;

  @include if-ie {
    @include m('patientMessaging') {
      background: var(--patient-network-700);
      border-radius: 50%;
    }
  }

  @include m('size', 'small') {
    width: 28px;
    height: 28px;
  }

  @include m('size', 'medium') {
    width: 50px;
    height: 50px;
  }

  @include m('size', 'large') {
    width: 80px;
    height: 80px;
  }

  @include m('size', 'fit') {
    display: block;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }

  @include c('image-container-circle') {
    @include circle;

    // for IE11 min-height bug
    // @include zoomed-image > * {
    //   height: 10px;
    // }
    height: inherit;
    width: inherit;

    &--entityType--activatedTeam {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 55%;
        margin-left: 6%;
      }
    }
  }

  @include c('image-container-square') {
    // for IE11 min-height bug
    // @include zoomed-image > * {
    //   height: 10px;
    // }
    height: inherit;
    width: inherit;
    border-radius: 8px;

    &--entityType--forum {
      border-radius: 7px;
      overflow: hidden;
    }

    @include c('mute') {
      border-radius: 5px;
    }
  }

  @include c('container') {
    @include center-items-both;

    height: inherit;
    width: inherit;
  }

  @include c('largeIcon') {
    width: 35px;
    height: 35px;
  }

  @include c('icon') {
    width: 23px;
    height: 23px;
  }

  @include c('smallIcon') {
    width: 19px;
    height: 19px;
  }

  @include c('image') {
    height: inherit;
    width: inherit;
    border-radius: inherit;
    fill: #fcb040;
    background: $white;

    &--entityType--user {
      fill: $white;
      background: #cad6db;
    }

    &--entityType--forum {
      background: #1a82d3;
    }

    &--entityType--patientCare {
      background: #994f6d;
    }

    &--entityType--patientMessaging {
      background: var(--patient-network-700);

      path {
        fill: var(--neutral-100);
      }
    }

    &--entityType--singleProvider,
    &--entityType--multipleProviders {
      background: var(--patient-network-700);
      padding: 6px;

      path {
        fill: var(--neutral-100);
      }
    }

    &--entityType--singleProvider {
      padding-top: 3px;
    }

    &--entityType--singleProviderSmsOptedOut,
    &--entityType--multipleProvidersSmsOptedOut {
      background: none;
    }

    &--entityType--distributionListPregen {
      background: #979797;
    }

    &--entityType--activatedTeam {
      background: none;
    }

    &--shouldPad {
      height: 100%;
      width: 100%;
      padding: 15%;
    }

    &--inverse {
      border: 1px solid #a3a3a3;
      fill: #474747;
      background: $white;
    }
  }

  @include m('initials') {
    @include c('image-container-circle') {
      border: 1px solid var(--neutral-600);
      font-size: 16px;

      &--isDisabled {
        opacity: 0.5;
      }

      &--entityType--team {
        border: 1px solid transparent;
        font-size: 12px;
      }
    }
  }

  @include c('initials') {
    @include center-items-both;
    @include circle;

    width: 100%;
    height: 100%;
    background-color: var(--neutral-100);
    color: var(--neutral-1000);
    padding-top: 1px;

    &--entityType--team {
      background-color: transparent;
      color: var(--neutral-100);
    }

    &--teamNoTag {
      background-color: #b2dfc9;
      color: #5f786b;
    }
  }

  @include c('mute') {
    @include mute;
    @include circle;

    svg {
      width: 19px;
      height: 19px;
    }
  }
}
