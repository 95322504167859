@import '../../base';

@include b('RemoveChangesButton') {
  border-radius: 16px;
  margin-right: 5px;
  line-height: 0;

  svg {
    width: 32px;
    height: 32px;
    color: var(--cool-neutral-700);
    cursor: pointer;

    use[fill='#fff'],
    path[fill='#FFF'] {
      fill: var(--neutral-100);
    }
  }
}
