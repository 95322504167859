@import '../base';

@include b('NewGroupModal') {
  @include c('body') {
    @include common-modal-body;

    display: flex;
    flex-direction: column;
    padding: 0 31px;

    &::before {
      content: '';
      display: block;
      flex: 1 8 50px;
    }

    &--isForum {
      &::before {
        content: '';
        display: block;
        flex: 1 8 20px;
      }
    }

    &::after {
      content: '';
      display: block;
      flex: 1 8 56px;
    }
  }

  @include c('warning') {
    width: 500px;
    min-height: 48px;
    border-radius: 6px;
    background-color: var(--secondary-500);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--neutral-100);
    font-size: 12px;
    margin-bottom: 30px;
    align-self: center;
  }

  @include c('first-row') {
    font-weight: 600;
  }

  @include c('row-header') {
    font-size: 10px;
    font-weight: 600;
    white-space: nowrap;
    text-transform: uppercase;
    line-height: 14px;
    padding-bottom: 6px;
  }

  @include c('name-row') {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    padding: 0 25px 7px;
  }

  @include c('input') {
    width: 100%;
    padding: 0;
    outline: none;
    font-size: 14px;
    font-weight: 300;
    background-color: var(--neutral-100);
  }

  @include c('description-row') {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    padding: 12px 25px 7px;
    border-top: solid 1px var(--neutral-300);
  }

  @include c('avatar-row') {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    padding: 12px 25px;
    border-top: solid 1px var(--neutral-300);

    @include c('avatar-header') {
      flex: 50px 0 0;
      display: flex;
    }

    @include c('avatar-text-header') {
      margin-bottom: 4px;
      font-size: 10px;
      font-weight: 600;
      white-space: nowrap;
      text-transform: uppercase;
    }

    @include c('avatar-text-body') {
      color: var(--neutral-700);
      font-size: 13px;
      line-height: 19px;
    }
  }

  @include c('members-row') {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    padding: 12px 25px 5px;
    border-top: solid 1px var(--neutral-300);

    @include c('row-header') {
      padding-bottom: 6px;
    }

    @include c('members-picker') {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 2px;
    }
  }

  @include c('New-group-modal') {
    font-weight: 300;
  }

  @include c('create-as-row') {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    padding: 12px 25px 0;
    border-top: solid 1px var(--neutral-300);

    @include c('create-as-header') {
      margin-bottom: 12px;
      font-size: 10px;
      font-weight: 600;
      white-space: nowrap;
      text-transform: uppercase;
    }
  }

  @include c('save-btn') {
    @include button-with-disable(var(--secondary-500), 74px, 32px, 11px);
  }

  @include c('cancel-btn') {
    @include button-with-disable(var(--cool-neutral-700), 74px, 32px, 11px);

    margin-right: 15px;
  }

  @include c('dots-container') {
    @include center-items-both;

    height: 75%;
  }
}
