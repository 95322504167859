@import '../base';

@include b('LeaveGroupModal') {
  @include c('body') {
    @include common-modal-body;

    text-align: center;
    padding: 45px 50px 50px;
  }

  @include c('question') {
    font-weight: bold;
    margin-bottom: 2vh;
  }

  @include c('leave-btn') {
    @include common-button(var(--secondary-500), 74px, 32px, 11px);
  }

  @include c('cancel-btn') {
    @include common-button(var(--neutral-800), 74px, 32px, 11px);

    margin-right: 8px;
  }
}
