@import '../../base';

@include b('ComposePatientConversationItem') {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  position: relative;
  height: 100%;
  width: 100%;
  background-color: var(--cool-neutral-300);
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.29);

  @include c('left-pane') {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    position: relative;
    height: 48px;
    padding-left: 11px;
  }

  @include c('display-name') {
    @include ellipsis;

    flex: 1;
    line-height: 1.4;
    font-size: 14px;
    font-weight: 600;
    color: var(--neutral-950);

    svg {
      margin-right: 6px;
    }

    @include c('patient-name') {
      @include ellipsis;

      display: inline;
      padding: 0 4px;
      font-size: 14px;
      font-weight: normal;
      margin-left: 4px;
    }

    @include c('relation-name') {
      @include ellipsis;

      display: inline;
      padding: 0 4px;
      font-size: 14px;
      font-weight: normal;
    }

    @include c('pill') {
      border-radius: 8px;
      border: solid 1px transparent;
      padding: 0 4px;
      margin-left: 4px;
      background-color: var(--neutral-100);
      font-size: 11px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 19px;
      letter-spacing: normal;
      color: #536676;

      &--isSelected {
        border: solid 1px transparent;
        background-color: var(--neutral-100);
      }
    }
  }

  @include c('avatar') {
    $avatar-size: 42px;

    margin: 11px 7px 0 0;
    position: relative;
    flex: $avatar-size 0 0;

    @include b('AvatarImage') {
      width: $avatar-size;
      height: $avatar-size;
      border-radius: $avatar-size;
    }
  }

  @include c('details') {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding: 16px 9px 5px 0;
  }

  @include c('row') {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 19px;
  }

  @include c('close-button-container') {
    @include center-items-both;

    align-self: flex-end;
    width: 19px;
    height: 19px;
    background-color: #8bd9f3;
    border-radius: 50%;
    margin-bottom: 16px;
  }

  @include c('close-button') {
    width: 10px;
    fill: var(--cool-neutral-300);
    cursor: pointer;
  }

  @include c('patient-info') {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 12px;
    border-radius: 0 0 6px 6px;
    padding: 0 14px;
    padding-bottom: 10px;
  }
}
