@import '../base';

.ReactModalPortal > div {
  opacity: 0;
}

.ReactModalPortal .ReactModal__Overlay {
  &--after-open {
    opacity: 1;
    transition: opacity 0.3s cubic-bezier(0.22, 1, 0.36, 1);
  }

  &--before-close {
    opacity: 0;
    transition: opacity 0.2s cubic-bezier(0.22, 1, 0.36, 1);
  }
}

.ReactModal__Content {
  &:not(&--after-open) {
    transform: translateY(-35%);
  }

  &--after-open {
    transform: translateY(0);
    transition: transform 0.3s cubic-bezier(0.22, 1, 0.36, 1);
  }

  &--before-close {
    transform: translateY(-35%);
    transition: transform 0.2s cubic-bezier(0.22, 1, 0.36, 1);
  }
}

@include b('ModalOverlay') {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(var(--neutral-100-rgb), 0.9);

  &::before {
    content: '';
    display: block;
    flex: 2 4 0;
  }

  &::after {
    content: '';
    display: block;
    flex: 4 4 0;
  }

  &.image {
    &::before {
      flex: 0;
    }

    &::after {
      flex: 0;
    }
  }
}

@include b('Modal') {
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  position: relative;
  outline: none;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.09);
  border-radius: 5px;
  background-color: var(--neutral-100);
  border: 1px solid var(--neutral-300);

  @include m('size', 'image') {
    flex: 1;
    width: 100%;
    height: 100%;
    background-color: transparent;
    box-shadow: none;
    transform: none;
    pointer-events: none;
  }

  @include m('size', 'large') {
    width: 750px;
  }

  @include m('size', 'medium-large') {
    width: 615px;
  }

  @include m('size', 'medium') {
    width: 500px;
  }

  @include m('size', 'medium-small') {
    width: 400px;
  }

  @include m('size', 'small-medium') {
    width: 320px;
  }

  @include m('size', 'small') {
    width: 250px;
  }

  @include c('header') {
    @include common-modal-header;
  }

  @include c('header-contents') {
    @include center-items-both;

    flex-direction: column;
    flex: 1;
    margin: 1px;
  }

  @include c('header-text') {
    display: flex;
    justify-content: center;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 90%;
  }

  @include c('body') {
    @include common-modal-body;

    .word-break {
      word-break: break-word;
      word-wrap: break-word;
      width: 100%;
    }

    padding: 15px;
  }

  @include c('close') {
    @include common-modal-close-button;

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
    }
  }

  @include c('footer') {
    @include common-modal-footer;

    position: relative;
    padding: 15px 32px;
    display: flex;
    justify-content: flex-end;
    font-weight: 500;
  }

  @include c('footer-container') {
    display: flex;
    justify-content: flex-end;
  }

  @include c('footer-secondary-actions-split') {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
  }
}
