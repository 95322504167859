@import '../../base';

@include b('CollaborationSearch') {
  font-size: 15px;
  padding: 5px 15px;
  position: relative;
  width: 100%;

  @include c('clear-button') {
    background-color: var(--cool-neutral-200);
    border-radius: 100%;
    color: var(--cool-neutral-700);
    cursor: pointer;
    height: 22px;
    position: absolute;
    right: 20px;
    text-align: center;
    top: 9px;
    width: 22px;

    circle {
      stroke: var(--secondary-500);
    }

    path {
      fill: var(--secondary-500);
    }
  }

  input {
    background-color: var(--neutral-150);
    border: 1px solid var(--neutral-400);
    border-radius: 18px;
    color: var(--neutral-900);
    height: 30px;
    outline: none;
    padding: 0 13px;
    width: 100%;

    &::-ms-clear {
      display: none;
    }

    &::placeholder {
      color: var(--neutral-400);
    }
  }
}
