@import '../../base';

@include b('ConversationItemDetails') {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 11px 25px 6px 0;
  border-bottom: 1px solid var(--neutral-300);

  @include m('isPatientCare') {
    border-bottom: none;
    padding: 5px 0 0 8px;
  }

  @include m('isForum') {
    padding: 21px 25px 6px 0;
  }

  @include c('row') {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 19px;

    &--isForum {
      height: auto;
    }
  }

  @include c('banner-container') {
    display: flex;
    flex-direction: row;
  }

  @include c('banner') {
    padding-right: 3px;
  }

  @include c('header') {
    flex: 1;
  }

  @include c('counter-party-name') {
    @include ellipsis;

    flex: 1;
    line-height: 1.6;
    font-size: 14px;
    font-weight: 500;
    color: var(--neutral-900);

    svg {
      margin-right: 6px;
    }
  }

  @include c('tag') {
    @include ellipsis;

    flex: 1;
    margin-top: 1px;
    line-height: 1.4;
    font-size: 13px;
    font-weight: 400;
    color: var(--neutral-800);

    &--isSelected {
      color: var(--neutral-900); /* #5d687a => #565559 */
    }
  }

  @include c('last-message-body') {
    @include ellipsis;
    @include markdown;

    flex: 1;
    line-height: 1.4;
    font-size: 14px;
    color: var(--neutral-800);
  }

  @include c('patient-details') {
    @include ellipsis;

    flex: 1;
    line-height: 2;
    font-size: 11px;
    color: var(--neutral-1000);
  }

  /* Dark Mode TODO: */
  @include c('last-message-status') {
    flex-shrink: 0;
    margin-top: 1px;
    margin-right: 6px;
    font-size: 12px;
    white-space: nowrap;
    color: $x-dimmed-text-color;
  }

  @include c('last-message-timestamp') {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    margin-left: 6px;
    font-weight: 300;
    font-size: 10px;
    white-space: nowrap;
    color: var(--neutral-800);
  }

  @include c('patient-reference-pill') {
    width: 100%;
  }

  @include c('role-status') {
    margin-top: 1px;
  }

  /* Dark Mode TODO: */
  @include c('team-member-count') {
    color: #7c8181;
    font-size: 14px;
  }

  @include c('typing-dots') {
    flex: 1;
    line-height: 1.4;
    font-size: 14px;
  }

  @include c('typers') {
    @include ellipsis;

    flex: 1;
    line-height: 1.4;
    font-size: 14px;
    color: var(--neutral-800);
    max-width: 100%;
  }
}
