@import '../../base';

@include b('EmojiPickerButton') {
  width: 27px;
  height: 27px;

  @include c('image') {
    width: 23px;
    height: 23px;
    margin: auto;

    path {
      stroke: var(--neutral-200);
    }

    circle:nth-child(1) {
      fill: var(--neutral-700);
      stroke: var(--neutral-700);
    }

    circle:nth-child(2) {
      fill: var(--neutral-200);
    }

    circle:nth-child(3) {
      fill: var(--neutral-200);
    }
  }

  @include c('image-hover') {
    width: 27px;
    height: 27px;
    margin: auto;
    transition: 0.3s cubic-bezier(0.22, 1, 0.36, 1);
    display: none;
  }

  @include c('emoji-button-container') {
    height: 100%;
    width: 100%;
    cursor: pointer;
    display: flex;

    &:hover {
      @include c('image') {
        display: none;
      }

      @include c('image-hover') {
        display: flex;
      }
    }

    &:focus-visible {
      outline: auto;
      outline-width: 3px !important;
      outline-color: var(--secondary-500) !important;
      border-radius: 13px;
    }
  }
}
