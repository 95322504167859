@import '../../base';

@include b('MessageAttachmentModal') {
  @include c('body') {
    @include common-modal-body;

    flex: 1;
    display: flex;
  }

  @include c('container') {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: transparent;
  }

  @include c('download-header') {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 71px;
    margin: auto;
    padding-right: 30px;
    border-bottom: solid 1px var(—-neutral-300);
    background-color: var(--neutral-200);
    color: var(--secondary-500);
    font-weight: 400;
    pointer-events: auto;

    @include c('close-button') {
      width: 25px;
      height: 25px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }

    @include c('download-action') {
      padding-right: 20px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  @include c('image-wrapper-column') {
    flex: 1;
    display: flex;
    flex-direction: column;

    &::before {
      content: '';
      display: block;
      flex: 1 3 0;
      min-height: 10px;
    }

    &::after {
      content: '';
      display: block;
      flex: 4 2 0;
      min-height: 10px;
    }
  }

  @include c('image-wrapper-row') {
    flex: 24 1 auto;
    display: flex;
    flex-direction: row;

    &::before,
    &::after {
      content: '';
      display: block;
      flex: 1 1 10%;
    }
  }

  @include c('image-wrapper') {
    @include scaled-down-image;

    flex: 8 1 80%;

    img {
      border-radius: 6px;
      box-shadow: 0 2px 6px 0 var(--neutral-700);
    }

    video {
      height: 100%;
      width: 100%;
      object-fit: contain;
      border-radius: 6px;
      outline: none;
      background: #000;
      box-shadow: 0 2px 6px 0 var(--neutral-700);
    }

    iframe {
      border-radius: 6px;
      outline: none;
      border: none;
      box-shadow: 0 2px 6px 0 var(--neutral-700);
      width: 100%;
      height: 100%;
    }
  }
}
