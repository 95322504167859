@include b('CallLogViewMemberInfo') {
  border-radius: 12px;
  border: 1px solid var(--neutral-300);
  text-align: center;
  background-color: var(--neutral-100);
  margin: 30px 0;

  @include c('opt-out') {
    color: $sms-opted-out-primary-color;
    font-size: 12px;
    margin-top: 30px;
  }

  @include c('patient-actions') {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    @include c('patient-action') {
      margin: 0 10px;
    }
  }

  @include c('content') {
    padding: 28px;

    @include c('avatar-holder') {
      height: 64px;
      width: 64px;
      margin: auto;
    }

    @include c('patient-info') {
      display: inline-block;
    }

    @include c('member-title') {
      margin: 8px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &--allOptedOut {
        opacity: 0.5;
      }
    }
  }

  @include c('profile-link') {
    cursor: pointer;
    background-color: var(--patient-network-300);
    padding: 13px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    font-size: 14px;
    font-weight: 600;
    color: var(--patient-network-700);
    width: 100%;
  }
}
