@import '../../../base';

@include b('CustomDndTimeModal') {
  padding: 40px 20px 30px;

  @include c('input-container') {
    color: var(--neutral-800);
    font-size: 12px;
    font-weight: 500;
    padding: 10px 0;
    height: 100px;
  }

  @include c('error-text') {
    color: var(--errors-500);
    font-size: 12px;
  }

  @include c('timer-message') {
    color: var(--secondary-500);
    margin-top: 10px;
  }

  @include c('timeInput-container') {
    display: inline-block;
    margin-top: 5px;
    color: var(--neutral-800);
    font-size: 12px;
    font-weight: 500;

    @include c('timeInput-label') {
      text-align: left;
      padding-left: 5px;
    }

    @include c('timeInput-input-container') {
      cursor: pointer;
      position: relative;
      font-size: 12px;

      @include c('timeInput-input') {
        border: 1px solid var(--neutral-500);
        border-radius: 6px;
        padding: 10px;
        margin: 0 2px;
        text-align: left;
        display: flex;

        svg path {
          fill: var(--neutral-700);
        }
      }

      @include c('timeInput-dropdown-container') {
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;

        @include c('timeInput-dropdown') {
          background: var(--neutral-100);
          border-radius: 5px;
          text-align: left;

          @include c('timeInput-dropdown-item') {
            padding: 11px;
            cursor: pointer;

            &:hover {
              background-color: var(--cool-neutral-300);
            }
          }
        }
      }
    }
  }
}
