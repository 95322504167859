@import '../../base';

@include b('DummyConversationItem') {
  background-color: var(--cool-neutral-300);
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  position: relative;
  height: 100%;
  width: 100%;

  @include c('left-pane') {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    position: relative;
    height: 75px;
    padding-left: 36px;
    margin-right: 14px;
  }

  @include c('details') {
    display: flex;
    height: 100%;
    width: 100%;
    padding: 25px 25px 6px 0;
  }

  @include c('row') {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 19px;

    &:nth-child(1) {
      flex-grow: 2;
    }

    &:nth-child(2) {
      flex-grow: 1;
      flex-shrink: 0;
      margin-left: 10px;
    }
  }

  @include c('text') {
    @include ellipsis;

    flex: 1;
    line-height: 1.4;
    font-size: 14px;
    font-weight: 600;
    color: var(--neutral-900);
  }

  /* Dark Mode TODO: */
  @include c('close-button-container') {
    @include center-items-both;

    width: 20px;
    height: 20px;
    background-color: #8ac2d5;
    border-radius: 50%;
  }

  @include c('close-button') {
    width: 10px;
    fill: var(--cool-neutral-300);
    cursor: pointer;
  }
}
