@import '../../base';

@include b('AttachmentFileHeader') {
  @include c('icon-container') {
    display: inline-block;
    width: 40px;
    height: 40px;
    position: relative;
    vertical-align: middle;
    text-decoration: none;
  }

  @include c('document-icon') {
    display: inline-block;
    height: 41px;
    width: auto;
  }

  @include c('downloading-indicator') {
    display: inline-block;
    width: inherit;
    height: inherit;
  }

  @include c('document-name') {
    vertical-align: middle;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    margin-right: 2px;
    display: inline-block;
    max-width: 260px;
  }
}
