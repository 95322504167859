@import '../../base';

@include b('ExpertSettings') {
  @include profile-detail-container;

  @include c('header') {
    @include profile-header;
  }

  @include c('lead') {
    color: #9b9b9b;
    font-size: 10px;
    font-weight: 400;
    margin-left: 5px;
  }

  @include c('info') {
    @include profile-detail-info;
  }

  @include c('info-header-edit') {
    padding: 20px 25px 0;
    width: 100%;
  }

  @include c('info-header') {
    @include profile-sub-header;

    flex: 1;
    padding: 20px 0 15px;
  }
}
