@import '../../base';

@include b('TemplateVariables') {
  background-color: var(--neutral-150);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  color: var(--neutral-1000);
  padding: 4px 0 3px;

  @include c('header') {
    align-items: center;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    height: 28px;
    justify-content: space-between;
    padding: 0 8px;

    @include c('rightHeader') {
      display: flex;
      justify-content: space-around;

      @include c('dropdown') {
        cursor: pointer;

        &--isExpanded svg {
          transform: rotate(180deg);
        }

        svg {
          margin-top: 3px;
          transition: transform 0.5s;
        }
      }
    }
  }

  @include c('list') {
    height: 0;
    padding: 0;
    overflow-y: auto;
    transition: all 0.5s ease-in-out;

    &--isExpanded {
      height: 130px;
      padding: 5px 3px;
    }

    @include c('noResults') {
      padding: 20px;
      text-align: center;
    }

    @include c('listItem') {
      border: solid 1px rgba(0, 0, 0, 0);
      border-radius: 6px;
      color: var(--secondary-1000);
      cursor: pointer;
      display: flex;
      font-size: 12px;
      padding: 7px 8px;

      &:hover {
        background-color: var(--cool-neutral-300);
        border-color: var(--secondary-500);
      }

      @include c('title') {
        flex: 30px;
        font-weight: 600;
      }

      @include c('description') {
        flex: 220px;
      }

      @include c('example') {
        flex: 1;
        text-align: right;
      }

      &:focus-visible {
        outline: 3px solid var(--secondary-500);
      }
    }
  }
}
