@import '../base';

@include b('TextAreaStyled') {
  height: 100%;
  width: 100%;

  @include c('textArea') {
    border: none;
    font-size: 14px;
    font-weight: 400;
    height: 240px;
    overflow: auto;
    overflow-wrap: break-word;
    padding: 10px;
    position: absolute;
    white-space: pre-wrap;
    width: 100%;

    &--focusVisible {
      &:focus-visible {
        outline: 3px solid var(--secondary-500);
        border-radius: 5px;
      }
    }

    &--dummy {
      color: var(--neutral-1000);
    }

    &--input {
      background: transparent;
      caret-color: var(--neutral-1000);
      color: transparent;
      resize: none;
      z-index: 1;
    }

    .highlightedError {
      color: var(--primary-300);
    }

    .highlighted {
      color: var(--secondary-500);
    }
  }
}
