@import '../base';

@include b('PatientDetailCard') {
  font-weight: 500;
  display: flex;
  flex-direction: column;
  margin-left: 5px;

  @include c('tooltip-container') {
    &--isHovered {
      background-color: var(--patient-network-700);
      border-radius: 6px;
    }

    &--isPatientContact {
      padding: 0;
    }
  }

  @include m('isHovered') {
    background-color: var(--patient-network-700);
    border-radius: 6px;
  }

  @include m('isPatientContact') {
    margin-top: 3px;
    padding: 0;
  }

  @include c('group-name-container') {
    color: var(--neutral-900);
    display: flex;
    margin: 0 5px;
    margin-bottom: 2px;
    margin-top: 5px;

    &--isCenterName {
      justify-content: center;
    }

    @include m('isHovered') {
      background-color: transparent;
    }
  }

  @include c('patient-network-tooltip') {
    background-color: var(--patient-network-700) !important;
  }

  @include c('patient-network-tooltip-arrow') {
    background-color: var(--patient-network-700) !important;
  }

  @include c('group-name') {
    align-self: center;
    color: var(--neutral-950);
    display: block;
    font-size: 15px;
    font-weight: 600;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &--isHovered {
      color: var(--neutral-100);
    }

    &--smsOptedOut {
      color: var(--neutral-600);
    }
  }

  @include c('pill') {
    align-self: center;
    color: var(--neutral-950);
    font-size: 15px;
    font-weight: 600;
    height: 20px;
    padding: 0 4px;

    &--isHovered {
      color: var(--neutral-100);
    }

    @include if-ie {
      flex: 0 0 auto;
      width: 44px;
    }
  }

  @include c('relation') {
    @include ellipsis;

    align-self: center;
    color: var(--neutral-950);
    font-size: 15px;
    font-weight: 600;
    max-width: 86px;
    padding-left: 5px;

    &--isHovered {
      color: var(--neutral-100);
    }

    &--isCenterName {
      text-align: center;
    }

    &--smsOptedOut {
      color: var(--neutral-600);
    }
  }

  @include c('sms-opted-out-info') {
    align-self: flex-start;
    margin-top: 5px;
    border-radius: 6px;
    background-color: $sms-opted-out-primary-color;
    padding: 2px 9px;
    color: var(--neutral-100);
    font-size: 12px;
    font-weight: 400;
  }
}
