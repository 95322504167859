@import '../base';

@include b('UnreadBadgeCount-Text') {
  font-size: 11px;
  color: $white;
  border-radius: 20px;
  background-color: $primary-brand;
  height: 18px;
  width: 18px;
  justify-content: center;
  align-items: center;
  display: flex;

  @include m('double') {
    width: 26px;
  }

  @include m('triple') {
    width: 32px;
  }

  @include m('priority') {
    color: $primary-brand;
    background-color: $white;
  }

  @include c('count') {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@include b('UnreadBadgeCount-Button') {
  @include center-items-both;

  flex-shrink: 0;
  position: relative;
  float: right;
  line-height: 1.2;
  height: 18px;

  @include c('priority-button') {
    @include svg-background-and-icon($priority-message-bg-color, $white);

    width: 18px;
    height: 18px;
  }
}
