@import '../base';

@include b('MessengerLoadingOverlay') {
  @include center-items-both;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 250px;
  height: 22px;
  margin: 0 auto;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 0.5px 1.5px -0.5px rgba(0, 0, 0, 0.44);
  background-color: $accent-color-1;
  color: rgb(250, 236, 236);

  @include c('message') {
    text-align: center;
    font-size: 13px;
    font-weight: 500;

    @include if-ie {
      margin-bottom: 2px;
    }
  }
}
