@import '../../base';

@include b('AlertBoldTextView') {
  margin-bottom: 8px;
  font-weight: bold;

  @include m('isV2') {
    margin-bottom: 6px;
  }
}
