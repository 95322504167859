@import '../../base';

@include b('PatientConversationItemDetails') {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 11px 6px 6px 0;

  @include c('row') {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 19px;

    &:not(:first-child) {
      margin-top: 2px;
    }
  }

  @include c('banner-container') {
    display: flex;
    flex-direction: row;
  }

  @include c('banner') {
    padding-right: 3px;
  }

  @include c('header') {
    flex: 1;
  }

  @include c('counter-party-name') {
    @include ellipsis;

    display: flex;
    flex: 1;
    line-height: 1.4;
    font-size: 15px;
    font-weight: 600;
    color: var(--neutral-900);

    &--smsOptedOut {
      color: var(--neutral-600);
    }

    svg {
      margin-right: 6px;
    }

    @include c('content-width') {
      @include ellipsis;

      margin-right: 4px;
      max-width: 252px;
    }

    @include c('relation-name') {
      @include ellipsis;

      display: inline;
      font-size: 15px;
      font-weight: 600;
      max-width: 100px;
    }

    @include c('pill') {
      display: flex;
      border-radius: 8px;
      border: solid 1px var(--patient-network-400);
      padding: 0 4px;
      margin-left: 4px;
      background-color: var(--patient-network-200);
      font-size: 11px;
      line-height: 19px;
      color: var(--patient-network-700);
      font-weight: 400;
      height: 18px;
      align-items: center;

      &--isSelected {
        border: solid 1px transparent;
        background-color: var(--neutral-100);
      }

      @include if-ie {
        padding-bottom: 2px;
      }
    }
  }

  @include c('tag') {
    @include ellipsis;

    flex: 1;
    margin-top: 1px;
    line-height: 1.4;
    font-size: 13px;
    font-weight: 400;
    color: var(--neutral-800);

    &--isSelected {
      color: var(--patient-network-700);
    }
  }

  @include c('sms-opted-out-text') {
    @include ellipsis;

    flex: 1;
    line-height: 1.4;
    font-size: 14px;
    color: var(--neutral-800);
  }

  @include c('last-message-body') {
    @include ellipsis;

    flex: 1;
    line-height: 1.4;
    font-size: 14px;
    color: var(--neutral-800);
  }

  @include c('group-details') {
    font-size: 11px;
    margin-top: 2px;
  }

  @include c('member-details') {
    color: var(--neutral-950);
  }

  @include c('last-message-status') {
    flex-shrink: 0;
    margin-top: 1px;
    margin-right: 6px;
    white-space: nowrap;
    color: var(--neutral-700);
    font-weight: 500;
  }

  @include c('last-message-timestamp') {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    margin: 0 2px;
    font-weight: 300;
    font-size: 10px;
    white-space: nowrap;
    color: var(--neutral-800);
  }

  @include c('role-status') {
    margin-top: 1px;
  }

  @include c('typing-dots') {
    flex: 1;
    line-height: 1.4;
    font-size: 14px;
  }

  @include c('typers') {
    @include ellipsis;

    flex: 1;
    line-height: 1.4;
    font-size: 14px;
    color: var(--neutral-800);
    max-width: 100%;
  }

  @include c('patient-info') {
    position: absolute;
    bottom: 1px;
    left: 1px;
    right: 1px;
    font-size: 12px;
    color: var(--patient-network-700);
    background-color: var(--patient-network-100);
    border-top: 2px solid var(--patient-network-300);
    border-radius: 0 0 6px 6px;
    padding: 0 14px;
    padding-top: 7.5px;
    padding-bottom: 10px;

    &--selected {
      background: none;
      border-color: var(--cool-neutral-300);
    }

    &--hovered {
      background: var(--neutral-300);
      border-color: var(--neutral-300);
    }

    @include c('patient-name') {
      font-weight: bold;
    }
  }
}
