@import '../../base';

@include b('CollaborationEntityEditorName') {
  display: flex;
  flex-direction: column;
  min-height: auto;
  padding: 10px;
  position: relative;
  width: 100%;

  @include b('AvatarImage') {
    @include c('image-container-square') {
      background-color: var(--cool-neutral-700) !important;
      border-width: 0 !important;
    }

    path {
      fill: var(--neutral-100);
    }
  }

  @include c('input-name-form') {
    border: 1px solid var(--neutral-100);
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    overflow: hidden;
    width: 100%;
    vertical-align: top;
    display: flex;
    cursor: default;

    &--editable {
      border: 1px solid rgba(158, 170, 181, 0.74);
      box-shadow: inset 0 3px 2px 0 #e1e6e8;

      &:hover {
        background-color: var(--neutral-100);
        border-radius: unset;

        @include c('input-name') {
          background-color: var(--neutral-100);
        }
      }
    }

    &--current {
      margin: 0 5px;
    }

    &--allowedToEdit {
      cursor: text;

      &:hover {
        background-color: var(--cool-neutral-200);

        @include c('input-name') {
          background-color: var(--cool-neutral-200);
        }
      }
    }

    &--entityNameError {
      border: solid 1px var(--errors-500);

      @include c('input-name') {
        background-color: var(--errors-50);
      }

      @include c('submit-input') {
        background-color: var(--errors-50);
      }

      &:hover {
        background-color: var(--errors-50);

        @include c('input-name') {
          background-color: var(--errors-50);
        }
      }
    }

    @include c('input-name') {
      padding: 4px 10px;
      flex: 1;

      &::placeholder {
        color: var(--cool-neutral-700);
        font-weight: normal;
      }
    }

    @include c('submit-input') {
      padding-right: 5px;
      font-size: 0.7em;
      font-style: italic;
    }
  }

  @include c('header-container') {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    font-size: 10px;
    color: var(--cool-neutral-700);
    font-weight: 600;

    @include c('title') {
      flex: 1;
      text-align: center;
    }

    &--isNewEntity {
      font-size: 18px;
      margin-bottom: 22px;
      color: var(--neutral-900);
    }
  }

  @include c('name-input-container') {
    display: flex;

    .tc-ToolTip__toolTipContainer--bottom {
      margin-top: 35px;
    }

    .tc-ToolTip {
      display: flex;
      flex: 1;
    }
  }

  @include c('name-actions') {
    width: 60px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;

    button {
      background-color: var(--cool-neutral-200);
      border-radius: 50%;
      width: 22px;
      height: 22px;

      &:hover {
        path {
          fill: var(--secondary-500);
        }
      }
    }

    @include c('gold-icon') {
      cursor: pointer;

      path {
        fill: var(--cool-neutral-700);
      }

      circle {
        fill: var(--neutral-150);
      }

      &:hover {
        path {
          fill: #f0c908;
        }
      }

      &--active {
        circle {
          fill: #f0c908;
        }

        path {
          fill: var(--neutral-100);
        }

        &:hover {
          path {
            fill: var(--neutral-100);
          }
        }
      }
    }

    @include c('icon') {
      cursor: pointer;

      path {
        fill: var(--cool-neutral-700);
      }

      &:hover {
        path {
          fill: var(--secondary-500);
        }
      }

      &--active {
        circle {
          fill: var(--secondary-500);
        }

        path {
          fill: var(--neutral-100);
        }

        &:hover {
          path {
            fill: var(--neutral-100);
          }
        }
      }
    }
  }

  @include c('error-message') {
    background-color: #fe7d78;
    border: 1px solid #c17f7e;
    margin: 5px 0;
    padding: 5px;
    font-size: 11px;
    color: var(--neutral-100);
    text-align: center;
  }
}
