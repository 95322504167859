@import '../base';

@include b('UndoAction') {
  @include center-items-both;

  height: inherit;
  background-color: $interaction-signifier;

  @include c('button') {
    @include center-items-both;

    width: 80px;
    height: 35px;
    margin: 0 20px;
    background-color: $white;
    color: $interaction-signifier;
    border-radius: 6px;
    cursor: pointer;

    @include c('undo') {
      width: 14px;
      height: 13px;
      fill: $interaction-signifier;
      margin-right: 7px;
    }
  }

  @include c('text') {
    flex: 1;
    color: $white;
  }

  @include c('cancel-btn') {
    @include center-items-both;

    border-left: solid 1px $white;
    width: 63px;
    height: 100%;
    cursor: pointer;
    transition: height 200ms;

    @include c('cancel-icon') {
      width: 10px;
      height: 10px;

      path {
        fill: $white;
      }
    }
  }
}
