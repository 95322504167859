@import '../../base';

@include b('CreateAppointmentModal') {
  @include c('visit-number') {
    color: var(--neutral-500);
    text-align: center;
    font-size: 12px;
  }

  @include c('workflow-btn') {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    border: solid 1px var(--neutral-400);
    border-radius: 6px;
    padding: 6px 10px;
    cursor: pointer;
    color: var(--neutral-1000);
    font-size: 13px;
    font-weight: 500;

    &--placeholder {
      color: var(--cool-neutral-800);
      font-weight: normal;
    }
  }

  @include c('menu-item') {
    align-items: center;
    color: var(--neutral-800);
    display: flex;
    font-size: 12px;
    height: 28px;
    padding: 0 3em 0 1em;
    text-align: left;
    user-select: none;
    white-space: nowrap;
    width: 540px;
    margin: 0 3px;

    @include c('workflow-item-details') {
      display: flex;
      max-width: 480px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    @include c('workflow-label') {
      height: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    @include c('workflow-label-dropdown') {
      max-width: 500px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    > span {
      display: flex;

      svg {
        margin-left: 10px;
      }
    }

    &--isSelected {
      color: var(--errors-500);
    }
  }

  @include c('header') {
    @include common-modal-header;

    margin-bottom: 10px;
    color: var(--patient-network-700);
  }

  @include c('workflow-label') {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @include c('appointment-duplicate') {
    color: var(--errors-500);
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    margin-top: 20px;
  }

  @include c('close-button') {
    @include common-modal-close-button;

    color: var(--cool-neutral-800);
  }

  @include c('row') {
    display: flex;
    flex-direction: row;
  }

  @include c('section') {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 6px;

    &--isProviderInput {
      margin: 6px 6px 0 10px;
    }

    &--isLocationInput {
      flex: 275px 0 0;
      margin: 6px;
    }

    @include c('input') {
      width: 100%;
      border: solid 1px var(--neutral-400);
      height: 30px;
      border-radius: 6px;
      padding: 6px 10px;
      font-size: 13px;

      &--isProviderInput {
        width: 250px;
      }
    }
  }

  @include c('label') {
    color: var(--secondary-1000);
    display: flex;
    font-size: 12px;
    font-weight: 600;
    margin: 10px 0 5px 3px;
    text-align: left;
  }

  @include c('body') {
    &--isDisabled {
      opacity: 0.4;
      pointer-events: none;
    }

    @include common-modal-body;

    padding: 35px 30px 40px;
    text-align: center;
    line-height: 1.4;
    padding-top: 0;
  }

  @include c('info') {
    span {
      font-weight: bold;
    }
  }

  @include c('save-btn') {
    @include common-button(var(--patient-network-700), 74px, 32px, 11px);

    &--isLoading {
      cursor: default;
      pointer-events: none;
    }

    &--disabled {
      border-color: var(--neutral-300);
      color: var(--neutral-300);
      cursor: auto;

      &:hover {
        border-color: var(--neutral-300);
        background-color: var(--neutral-100);
        color: var(--neutral-300);
      }
    }
  }

  @include c('cancel-btn') {
    @include common-button(var(--patient-network-700), 74px, 32px, 11px);

    margin-right: 10px;
  }

  @include c('delete-btn') {
    @include common-button(var(--primary-300), 74px, 32px, 11px);

    margin-right: 30px;

    &--isEditing {
      margin-right: 384px;
    }
  }

  @include c('cancel-appointment') {
    &--visitNumberAbsent {
      margin-left: 356px;
      margin-top: -31px;
    }

    &--visitNumberPresent {
      margin-left: 356px;
      margin-top: 0;
    }
  }

  @include m('menu') {
    max-height: 200px;
    overflow: auto;
  }
}
