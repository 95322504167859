@import '../../../base';

@include b('CallLog') {
  display: block;
  height: 100%;

  @include c('call-log-entry') {
    border-radius: 12px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
    border: solid 0.5px var(--neutral-300);
    background-color: var(--neutral-100);
    font-size: 12px;
    margin: 7px 4px;
    color: var(--neutral-800);
    width: 341px;
    cursor: pointer;

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
    }

    &--isActive {
      background-color: var(--patient-network-200);

      @include c('patient-details') {
        border-top: solid 1px var(--patient-network-200);
      }
    }

    @include c('call-members') {
      padding: 2px 13px;
    }

    @include c('qc-patient') {
      padding: 6px 13px;
      font-size: 12px;
      color: var(--patient-network-700);
    }

    @include c('patient-details') {
      padding: 6px 13px;
      background-color: var(--patient-network-200);
      border-radius: 0 0 12px 12px;
      border-top: solid 1px var(--patient-network-300);

      @include c('name') {
        font-size: 13px;
        font-weight: 500;
        color: var(--patient-network-700);
      }
    }

    @include c('opt-out') {
      color: var(--opt-out-100);
      display: flex;
      align-items: center;
      width: 75px;
      text-align: center;
      justify-content: center;
      padding-right: 15px;
    }

    @include c('main') {
      display: flex;

      @include c('info') {
        flex: 1;
        padding: 13px;

        @include c('target') {
          font-size: 15px;
          display: flex;

          @include c('name') {
            font-weight: bold;
            color: var(--neutral-1000);
            margin-right: 4px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &--isOptedOut {
              opacity: 0.5;
            }
          }
        }

        @include c('info-line') {
          display: flex;
          align-items: center;
          color: var(--cool-neutral-900);

          &--isMissedCall {
            color: var(--errors-500);
          }

          svg {
            margin-right: 4px;
          }
        }
      }

      @include c('action') {
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
