@import '../common/styles/_vars.scss';
@import '../widgets/messenger/styles/_vars.scss';
@import url('https://fonts.googleapis.com/css?family=Inter:300,400,500,600');

@font-face {
  font-family: 'Emoji Font';
  font-weight: 300;

  /**
    * TwemojiMozilla-colr.woff2: Generated from https://github.com/matrix-org/twemoji-colr
    * TwemojiMozilla-monochrome.woff: Generated from https://github.com/eosrei/twemoji-color-font/issues/57#issuecomment-482867466
    */
  src: url('./fonts/TwemojiMozilla-colr.woff2'), url('./fonts/TwemojiMozilla-monochrome.woff');
}

@font-face {
  font-family: 'Emoji Font';
  font-weight: normal;

  /**
    * TwemojiMozilla-colr.woff2: Generated from https://github.com/matrix-org/twemoji-colr
    * TwemojiMozilla-monochrome.woff: Generated from https://github.com/eosrei/twemoji-color-font/issues/57#issuecomment-482867466
    */
  src: url('./fonts/TwemojiMozilla-colr.woff2'), url('./fonts/TwemojiMozilla-monochrome.woff');
}

@font-face {
  font-family: 'Emoji Font';
  font-weight: 500;

  /**
    * TwemojiMozilla-colr.woff2: Generated from https://github.com/matrix-org/twemoji-colr
    * TwemojiMozilla-monochrome.woff: Generated from https://github.com/eosrei/twemoji-color-font/issues/57#issuecomment-482867466
    */
  src: url('./fonts/TwemojiMozilla-colr.woff2'), url('./fonts/TwemojiMozilla-monochrome.woff');
}

@font-face {
  font-family: 'Emoji Font';
  font-weight: bold;

  /**
    * TwemojiMozilla-colr.woff2: Generated from https://github.com/matrix-org/twemoji-colr
    * TwemojiMozilla-monochrome.woff: Generated from https://github.com/eosrei/twemoji-color-font/issues/57#issuecomment-482867466
    */
  src: url('./fonts/TwemojiMozilla-colr.woff2'), url('./fonts/TwemojiMozilla-monochrome.woff');
}

@font-face {
  font-family: 'Emoji Dark Font';
  font-weight: 300;
  src: url('./fonts/TwemojiMozilla-colr-dark.woff2');
}

@font-face {
  font-family: 'Emoji Dark Font';
  font-weight: normal;
  src: url('./fonts/TwemojiMozilla-colr-dark.woff2');
}

@font-face {
  font-family: 'Emoji Dark Font';
  font-weight: 500;
  src: url('./fonts/TwemojiMozilla-colr-dark.woff2');
}

@font-face {
  font-family: 'Emoji Dark Font';
  font-weight: bold;
  src: url('./fonts/TwemojiMozilla-colr-dark.woff2');
}

@font-face {
  font-family: system;
  font-weight: 300;
  font-style: normal;
  src:
    local('Inter'),
    local('Avenir-Light'),
    local('.SFNSText-Light'),
    local('.HelveticaNeueDeskInterface-Light'),
    local('.LucidaGrandeUI'),
    local('Segoe UI Light'),
    local('Ubuntu Light'),
    local('Roboto-Light'),
    local('DroidSans'),
    local('Tahoma');
}

@font-face {
  font-family: system;
  font-weight: normal;
  font-style: normal;
  src:
    local('Inter'),
    local('AvenirNext-Regular'),
    local('.SFNSText-Regular'),
    local('.HelveticaNeueDeskInterface-Regular'),
    local('.LucidaGrandeUI'),
    local('Segoe UI'),
    local('Ubuntu'),
    local('Roboto-Regular'),
    local('DroidSans'),
    local('Tahoma');
}

@font-face {
  font-family: system;
  font-weight: 500;
  font-style: normal;
  src:
    local('Inter'),
    local('AvenirNext-Medium'),
    local('.SFNSText-Medium'),
    local('.HelveticaNeueDeskInterface-MediumP4'),
    local('.LucidaGrandeUI'),
    local('Segoe UI Semibold'),
    local('Ubuntu Medium'),
    local('Roboto-Medium'),
    local('DroidSans-Bold'),
    local('Tahoma Bold');
}

@font-face {
  font-family: system;
  font-weight: bold;
  font-style: normal;
  src:
    local('Inter'),
    local('AvenirNext-DemiBold'),
    local('.SFNSText-Bold'),
    local('.HelveticaNeueDeskInterface-Bold'),
    local('.LucidaGrandeUI'),
    local('Segoe UI Bold'),
    local('Ubuntu Bold'),
    local('Roboto-Bold'),
    local('DroidSans-Bold'),
    local('Tahoma Bold');
}

body,
html {
  color: var(--neutral-1000);
  overflow: hidden;
  margin: 0;
  padding: 0;
  font-family: Inter, Arial, sans-serif, 'Emoji Font';
  font-weight: normal;

  * {
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}

a {
  color: $link-color;

  &:hover {
    color: darken($link-color, 10%);
  }
}

img {
  -ms-interpolation-mode: bicubic;
}

#app {
  position: fixed;
  width: 100vw;
  height: 100vh;
}

body.theme-dark {
  color-scheme: dark;
  font-family: Inter, Arial, sans-serif, 'Emoji Dark Font';

  input {
    background-color: var(--neutral-100);
  }
}

body.a11y {
  button:focus-visible {
    outline: 3px solid var(--secondary-500);
  }

  input:focus-visible {
    outline: 3px solid var(--secondary-500) !important;
  }

  .tc-RecipientPicker .Select--multi .Select-input input,
  .tc-RecipientPicker .Select--single .Select-input input:focus-visible {
    outline: none !important;
  }
}

.screen-reader-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

@media print {
  #app {
    position: static;
    width: 100%;
    height: auto;
  }
}
