@import '../base';

@include b('PreviewMessages') {
  width: inherit;
  height: inherit;
  overflow: scroll;

  @include c('message-container') {
    margin: 24px;
  }
}
