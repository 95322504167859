body .orgSettingsContainer {
  display: flex;
  flex-direction: column;
}

.orgSettingsContainer .header {
  display: flex;
  justify-content: space-between;
  padding: 10px 25px 10px 0;
  top: 0;
  position: sticky;
  background-color: var(--neutral-100);
  z-index: 2;
}

.orgSettingsContainer .fileFont {
  font-size: 14px;
}

.orgSettingsContainer .flexListWithDirectionAndMargin {
  display: flex;
  margin-left: 80px;
  flex-direction: column;
}

.orgSettingsContainer .orgSettingsActionContainer {
  align-items: center;
  margin-bottom: 30px;
  margin-top: 30px;
}

.orgSettingsContainer .customDirectoryRowContainer {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.orgSettingsContainer .customDirectoryAddContainer {
  margin-top: 30px;
}

.orgSettingsContainer .customDirectoryEnableText {
  font-weight: bold;
  margin-right: 10px;
}

.orgSettingsContainer .listHeader {
  display: flex;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
}

.orgSettingsContainer .listContent {
  border-radius: 4px;
  color: var(--neutral-900);
  font-size: 14px;
  height: 20px;
  line-height: 20px;
  margin-bottom: 10px;
  padding: 4px 6px;
  vertical-align: middle;
  width: 206px;
}

.orgSettingsContainer .listContentBackground {
  background-color: var(--neutral-100);
  border: 0;
  color: var(--neutral-1000);
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  height: 35px;
  line-height: 17px;
  width: 220px;
}

.orgSettingsContainer .colorBar {
  width: 208px;
}

.orgSettingsContainer .linkText {
  width: 470px;
}

.orgSettingsContainer .inputEntry {
  width: 180px;
  margin-right: 10px;
}

.orgSettingsContainer .checkboxEntry {
  margin: 0 15px;
}

.orgSettingsContainer .orgNameDetails {
  font-size: 22px;
}

.orgSettingsContainer .premiumOrgFeatureEntry {
  margin-left: 5px;
}

.orgSettingsContainer .marginsContainer {
  margin-top: 15px;
  margin-bottom: 15px;
}

.orgSettingsContainer .subHeaderMissing {
  margin-bottom: 15px;
}

.orgSettingsContainer .orgTypeLabel {
  margin-left: 10px;
}

.orgSettingsContainer .pbxOuterContainer {
  display: flex;
  margin-top: 15px;
  padding-bottom: 10px;
}

.orgSettingsContainer .pbxLinesContainer {
  background-color: var(--neutral-100);
  border-bottom: 1px solid var(--neutral-500);
  margin: 4px 0 10px;
  width: 100%;
}

.orgSettingsContainer .refreshButton {
  display: flex;
  margin-left: 86px;
  margin-top: -24px;
}

.orgSettingsContainer .refreshButton:hover {
  cursor: pointer;
}

.orgSettingsContainer .mainHeader {
  display: flex;
  justify-content: space-between;
}

.orgSettingsContainer .templateDownload {
  display: flex;
  border: solid 2px var(--neutral-300);
  align-items: center;
  font-size: 13px;
  padding: 8px;
  width: 120px;
  border-radius: 10px;
}

.orgSettingsContainer .templateDownload:hover {
  border: solid 2px var(--neutral-300);
  background-color: var(--neutral-300);
}

.orgSettingsContainer .uploadButton {
  display: flex;
  border: solid 2px var(--neutral-300);
  align-items: center;
  font-size: 13px;
  padding: 8px;
  justify-content: space-between;
  width: 127px;
  border-radius: 10px;
}

.orgSettingsContainer .uploadButton:hover {
  border: solid 2px var(--neutral-300);
  background-color: var(--neutral-300);
}

.orgSettingsContainer .downloadButton {
  display: flex;
  border: solid 2px var(--neutral-300);
  align-items: center;
  font-size: 13px;
  padding: 6px;
  justify-content: center;
  width: 100px;
  border-radius: 10px;
  margin-left: 20px;
}

.orgSettingsContainer .downloadButton:hover {
  border: solid 2px var(--neutral-300);
  background-color: var(--neutral-300);
}

.orgSettingsContainer .bulkUpload {
  display: flex;
}

.orgSettingsContainer .templateText {
  margin-left: 7px;
  text-decoration: none;
}

.orgSettingsContainer .titleButton {
  color: var(--neutral-1000);
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  margin-right: 10px;
  order: 0;
  padding: 8px 10px;
}

.orgSettingsContainer .titleButtonActive,
.orgSettingsContainer .titleButton:hover {
  border-bottom: 1px solid var(--primary-700);
}

.orgSettingsContainer .numbersHeader {
  color: var(--neutral-1000);
  display: flex;
  font-size: 13px;
  font-weight: 700;
  height: 16px;
  line-height: 16px;
}

.orgSettingsContainer .tableCenterItem {
  padding-left: 360px;
  padding-right: 360px;
}

.orgSettingsContainer .unassignUsersButton {
  background: var(--neutral-100);
  border-radius: 3.5px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 18%);
  cursor: pointer;
  display: flex;
  height: 27px;
  margin-left: 30px;
  margin-top: -4px;
  padding: 6px 28px;
  width: auto;
}

.orgSettingsContainer .usersOrRolesUnassigned {
  align-items: flex-start;
  color: var(--primary-300);
  display: flex;
  flex-direction: row;
  height: 27px;
  margin-right: 65px;
  padding: 6px 28px;
  width: 130px;
}

.orgSettingsContainer .row:hover .usersOrRolesUnassigned {
  align-items: flex-start;
  background: var(--neutral-100);
  border-radius: 3.5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.175866);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 27px;
  padding: 6px 28px;
  width: 130px;
}

.orgSettingsContainer .row:hover .usersOrRolesUnassigned:hover {
  background: var(--neutral-300);
}

.orgSettingsContainer .searchAssignees {
  align-items: center;
  align-self: stretch;
  background: var(--neutral-100);
  border-radius: 110px;
  border: 1px solid var(--neutral-300);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  gap: 10px;
  height: 32px;
  justify-content: space-between;
  order: 0;
  padding: 5px 5px 5px 10px;
  width: 388px;
}

.orgSettingsContainer .row {
  align-items: center;
  align-self: stretch;
  background: var(--neutral-100);
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex: none;
  gap: 10px;
  height: 57px;
  justify-content: space-between;
  order: 1;
  padding: 15px 10px;
  position: relative;
  width: auto;
}

.orgSettingsContainer .selectedRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
  gap: 10px;
  width: auto;
  height: 57px;
  background: var(--neutral-200);
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.orgSettingsContainer .row:hover {
  background: var(--neutral-200);
}

.orgSettingsContainer .checkbox {
  align-items: center;
  display: flex;
  width: 65px;
}

.orgSettingsContainer .assigneeImg {
  border-radius: 50%;
}

.orgSettingsContainer .assigneeDetails {
  display: flex;
  align-items: center;
}

.orgSettingsContainer .ncAssigneeDetails {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.orgSettingsContainer .assigneeDisplayName {
  margin-left: 10px;
}

.orgSettingsContainer .closeButtonInput {
  align-items: flex-start;
  background: var(--cool-neutral-100);
  border-radius: 140px;
  display: flex;
  flex-direction: row;
  height: 21px;
  padding: 5px;
  width: 21px;
}

.orgSettingsContainer .outerContainer {
  display: inline-block;
  border-radius: 6px;
  background-color: var(--neutral-100);
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 14%);
  padding: 6px 0 8px;
  width: 300px;
}

.orgSettingsContainer .menuList {
  width: 300px;
}

.orgSettingsContainer .unassignUsersButton:hover {
  background: var(--neutral-200);
}

.orgSettingsContainer .assignedUserOrRole {
  color: var(--neutral-1000);
  flex-grow: 0;
  flex: none;
  font-family: 'Inter';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  height: 25px;
  line-height: 16px;
  order: 1;
  text-align: right;
  width: 200px;
}

.orgSettingsContainer .removeUserOrRole {
  align-items: flex-start;
  background: var(--neutral-100);
  border-radius: 3.5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.175866);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 27px;
  justify-content: center;
  margin-left: 10px;
  padding: 6px 28px;
  width: 150px;
}

.orgSettingsContainer .searchPlaceholder {
  color: var(--cool-neutral-800);
  flex-grow: 0;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  height: 18px;
  line-height: 18px;
  order: 0;
  width: 84px;
}

.orgSettingsContainer .outerFlexContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;
}

.orgSettingsContainer .outerContainerButton {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.orgSettingsContainer .labelSize {
  margin-top: 5px;
  display: flex;
  font-size: 19px;
}

.orgSettingsContainer .flexListStyle {
  display: flex;
}

.orgSettingsContainer .flexListStyleWithPadding {
  display: flex;
  padding-bottom: 30px;
}

.orgSettingsContainer .labelStyle {
  display: flex;
  flex-direction: column;
}

.orgSettingsContainer .mainLabelStyle {
  display: flex;
  float: left;
  width: 150px;
  font-weight: bold;
  text-align: left;
  margin-right: 30px;
}

.orgSettingsContainer .helpText {
  display: flex;
  margin-left: 30px;
  margin-top: 5px;
}

.orgSettingsContainer .sideLabelStyle {
  display: flex;
  font-size: 11px;
  font-weight: normal;
  line-height: 15px;
  margin: 0;
  padding-top: 2px;
  max-width: 110px;
}

.orgSettingsContainer .inputBoxStyle {
  display: flex;
}

.orgSettingsContainer .inputBoxStyleWithMargin {
  display: flex;
  margin-right: 20px;
}

.orgSettingsContainer .premiumSaveButton {
  margin-top: 10px;
}

.orgSettingsContainer .editIcon {
  background: url('../../images/sprite_icons.png') no-repeat left center;
  background-position: 7px -990px;
  width: 30px;
  height: 35px;
  cursor: pointer;
  display: flex;
}

body:global(.theme-dark) .orgSettingsContainer .editIcon {
  background: url('../../images/sprite_icons_dark.png') no-repeat left center;
  background-position: 7px -990px;
}

.orgSettingsContainer .header[sticky] {
  border-bottom: solid 1px var(--neutral-400);
  box-shadow: 0 6px 4px -4.6px rgb(0, 0, 0, 40%);
}

.orgSettingsContainer .actionButtons {
  display: flex;
  padding: 12px 0 5px;
}

.orgSettingsContainer .navigationBar {
  padding-bottom: 40px;
}

.orgSettingsContainer .settingsList {
  display: flex;
  flex-direction: column;
}

.orgSettingsContainer .individualSettingContainer {
  padding-left: 20px;
}

.orgSettingsContainer .sectionDividingText {
  font-size: 32px;
  padding-bottom: 30px;
}

.orgSettingsContainer .closeButton {
  background: url('../../images/sprite_icons.png') no-repeat left center;
  background-position: -40px -980px;
  width: 10px;
  height: 10px;
  cursor: pointer;
}

body:global(.theme-dark) .orgSettingsContainer .closeButton {
  background: url('../../images/sprite_icons_dark.png') no-repeat left center;
  background-position: -40px -980px;
}

.orgSettingsContainer .addDomainContainer {
  display: flex;
  flex-direction: row;
  padding: 25px 0 20px;
}
