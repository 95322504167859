@import '../../base';

@include b('QuickReplyPortal') {
  position: absolute;
  right: 12px;
  bottom: 70px;

  @include c('dropdown-container') {
    width: 302px;
    max-height: 426px;
    padding: 6px 8px;
    overflow-y: scroll;
    background: var(--neutral-100);
    border-radius: 8px;

    @include c('title') {
      font-size: 10px;
      color: var(--neutral-700);
      margin-bottom: 2px;
    }
  }
}
