@import '../base';

@include b('DuplicateContactModal') {
  @include c('header') {
    @include common-modal-header;

    color: var(--neutral-900);
  }

  @include c('close-button') {
    @include common-modal-close-button;
  }

  @include c('text') {
    color: var(--neutral-900);
    margin-bottom: 30px;
    margin-top: 30px;
    padding: 10px 20px 20px;
    text-align: center;

    @include c('contact-name') {
      font-weight: 600;
    }
  }

  @include c('ok-btn') {
    border-radius: 300px;
    border: solid 1.2px var(--secondary-500);
    color: var(--secondary-500);
    cursor: pointer;
    float: right;
    font-size: 14px;
    font-weight: 500;
    height: 35px;
    padding: 5px;
    width: 94px;
  }
}
