@import '../../base';

@include b('PatientAndContactFormItem') {
  display: flex;
  padding-top: 9px;
  padding-bottom: 15px;
  width: 100%;
  border-bottom: 1px solid var(--neutral-300);

  &:last-child {
    border-bottom: none;
  }

  @include c('entity-input') {
    margin-right: 5px;
  }

  @include c('icon-col') {
    flex: 0 0 61px;
    margin-top: 14px;
  }

  @include c('icon-container') {
    border-radius: 100%;
  }

  @include c('contact-icon-container') {
    background-color: var(--neutral-300);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin-left: 17px;
    margin-top: 4px;

    &:hover {
      background-color: var(--patient-network-700);

      @include c('remove-icon') {
        fill: var(--neutral-300);
      }
    }
  }

  @include c('patient-icon-container') {
    background-color: var(--patient-network-700);
    width: 23px;
    height: 23px;
    margin-left: 14px;

    path {
      fill: var(--neutral-100);
    }
  }

  @include c('patient-icon') {
    width: 23px;
    height: 23px;
  }

  @include c('remove-icon') {
    width: 8px;
    height: 8px;
    fill: var(--patient-network-700);
  }

  @include c('entity-row-left') {
    display: flex;
    flex: 1;
  }

  @include c('entity-row-right') {
    display: flex;
    text-align: right;
  }

  @include c('title') {
    color: var(--neutral-700);
    font-size: 10px;
    padding: 0 6px;
  }

  @include c('input') {
    @include ellipsis;
    @include patient-admin-detail;

    background-color: var(--neutral-300);
    border-radius: 3px;
    max-width: 140px;
    padding: 3px 7px;
    font-weight: bold;
    border: solid 1px transparent;
    font-size: 14px;
    text-align: left;

    &::placeholder {
      color: var(--neutral-600);
    }

    &:focus {
      background-color: var(--neutral-100);
      outline: none;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.21);
    }

    &--hasError {
      border: solid 1px var(--errors-500);
      background-color: var(--errors-100);
    }

    &--contactName {
      margin-top: 14px;
    }

    &--isNumber {
      padding: 6px 7px 5px;
      text-align: right;
      font-weight: normal;
      font-size: 12px;
    }

    &[disabled] {
      background-color: var(--neutral-100);
    }
  }

  @include c('input-dob') {
    width: 110px;
  }

  @include c('input-country-code') {
    width: 58px;
    margin-top: 14px;
  }

  @include c('input-mrn') {
    width: 90px;
  }

  @include c('input-gender') {
    min-width: 65px;
    height: 29px;
  }

  @include c('input-relation') {
    min-width: 97px;
    height: 29px;
  }

  @include c('input-mrn') {
    width: 80px;
  }
}
