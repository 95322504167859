@import '../../base';

@include b('ConversationItem') {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  position: relative;
  height: 100%;
  width: 100%;

  @include m('isMainRoster') {
    &:hover {
      background: var(--neutral-300);

      @include avatar-indicator('PresenceIndicatorAvailable', var(--neutral-300));
      @include avatar-indicator('PresenceIndicatorDnd', var(--neutral-300));
      @include avatar-indicator('RoleIndicator', var(--neutral-300));

      @include b('ConversationOptionMenu') {
        @include c('option-button') {
          display: block;
        }
      }
    }
  }

  @include m('isMainRosterSelected') {
    background: var(--cool-neutral-300);

    @include avatar-indicator('PresenceIndicatorAvailable', var(--cool-neutral-300));
    @include avatar-indicator('PresenceIndicatorDnd', var(--cool-neutral-300));
    @include avatar-indicator('RoleIndicator', var(--cool-neutral-300));

    &:hover {
      background: var(--cool-neutral-300);

      @include avatar-indicator('PresenceIndicatorAvailable', var(--cool-neutral-300));
      @include avatar-indicator('PresenceIndicatorDnd', var(--cool-neutral-300));
      @include avatar-indicator('RoleIndicator', var(--cool-neutral-300));
    }
  }

  @include m('isPatientCare') {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 4px 5px;
  }

  @include c('contents') {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    position: relative;
    height: 100%;
    width: 100%;

    &--isPatientCare {
      background-color: var(--neutral-100);
      border-radius: 6px;
      box-shadow: 0 2px 4px 0 rgba(53, 51, 60, 0.32);
      padding-right: 8px;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: var(--neutral-300);
      }
    }

    &--isPatientCareSelected {
      background-color: var(--cool-neutral-300);
      border: 1px solid #d0f3ff;

      &:hover {
        background-color: var(--cool-neutral-300);
      }
    }
  }

  @include c('left-pane') {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    position: relative;
    height: 100%;
  }

  @include c('avatar') {
    $avatar-size: 52px;

    margin-right: 14px;
    position: relative;
    flex: $avatar-size 0 0;

    @include b('AvatarImage') {
      width: $avatar-size;
      height: $avatar-size;
      border-radius: $avatar-size;
    }
  }

  /* Dark Mode TODO: */
  &--group {
    @include c('avatar') {
      @include b('AvatarImage') {
        color: $avatar-foreground-group;
      }
    }
  }
}
