@import '../base';

@include b('AddMemberToVideoCall') {
  @include c('header') {
    text-align: center;
    color: var(--secondary-500);
    padding-top: 35px;
    font-size: 16px;
    font-weight: 700;
    width: 100%;
  }

  @include c('close-button') {
    @include common-modal-close-button;

    margin: 35px 40px 0 0;
  }

  @include c('body') {
    vertical-align: middle;
    font-weight: 500;
    padding: 20px 20px 55px;
    display: flex;
    flex-direction: column;
    height: 410px;
    overflow-y: auto;
  }

  @include c('patient-contacts') {
    margin-bottom: 50px;
    position: relative;
  }

  @include c('providers') {
    margin-bottom: 50px;
  }

  @include c('provider-picker-container') {
    position: absolute;
    z-index: 1;
  }

  @include c('patient-picker-container') {
    position: absolute;
    background-color: var(--neutral-100);
    z-index: 1;
    width: 318px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
    padding: 2px;

    @include c('patient-picker-option') {
      display: flex;
      cursor: pointer;
      border-radius: 3px;
      font-size: 10px;
      height: 26px;
      align-items: center;

      &:hover {
        background-color: var(--secondary-500);
        color: var(--neutral-100);
      }

      .tc-AvatarImage {
        margin-left: 4px;
      }

      @include c('patient-picker-option-title') {
        flex: 1;
        padding: 4px 7px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      @include c('patient-picker-option-invite') {
        font-size: 13px;
        padding: 7px;
        color: #fff;
      }
    }
  }

  @include c('Edit-providers') {
    width: 318px;
    height: 30px;
    border-radius: 2px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.41);
    background-color: #fff;
    margin: 2px 0;

    .Select-menu-outer {
      position: absolute;
      width: 318px;
    }

    .Select-control {
      position: relative;
      flex: 1;
      height: 30px !important;
    }

    .Select-input {
      height: 30px !important;
      line-height: 30px !important;
    }

    .Select-input input {
      line-height: 30px !important;
      height: 30px !important;
      margin-left: 5px;
      padding: 0 !important;
    }

    .Select-placeholder {
      font-size: 13px;
      color: var(--neutral-600); /* a2a2a2 => A5A5A5 */
      height: 30px !important;
      line-height: 30px !important;
      margin-left: 5px;
    }

    .Select-loading-zone {
      padding: 2px;
    }
  }

  @include c('title-container') {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;

    @include c('title-text') {
      font-size: 12px;
      align-self: flex-end;
      flex: 1;
    }

    @include c('title-text-members') {
      font-size: 11px;
      align-self: flex-end;
      flex: 1;
      color: var(--neutral-1000); /* 000 => 35333C  */
    }

    @include c('invite-button') {
      font-size: 12px;
      width: 175px;
      height: 20px;
      border-radius: 3px;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.19);
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--patient-network-700);

      &[disabled] {
        background-color: var(--neutral-300); /* e2e2e2 => e6e6e6 */
        color: var(--patient-network-700);
        cursor: default;
      }

      &--active {
        color: var(--neutral-100);
        background-color: var(--patient-network-700);

        path {
          fill: var(--neutral-100);
        }
      }

      svg {
        margin-right: 10px;
      }
    }
  }
}
