@import '../../base';

@include b('TemplateForm') {
  height: 100%;
  width: 100%;

  @include c('form') {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    @include c('breadcrumbs') {
      border-bottom: 1px solid var(--neutral-400);
      font-size: 12px;
      padding: 12px 30px;
      text-transform: uppercase;

      @include c('parent') {
        cursor: pointer;
        margin-right: 10px;

        &:hover {
          background-color: var(--cool-neutral-300);
        }
      }

      @include c('child') {
        font-weight: 600;
        margin: 10px;
      }
    }

    @include c('template-error') {
      color: var(--primary-300);
      font-size: 12px;
      margin: -20px 0 4px;
    }

    @include c('wrapper') {
      flex: 1;
      overflow-y: auto;
      padding: 30px 30px 0;

      @include c('header') {
        color: var(--neutral-950);
        font-size: 20px;
        margin-bottom: 21px;
      }

      @include c('formGroup') {
        color: var(--neutral-1000);
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 18px;
        display: flex;
        flex-direction: column;

        @include c('deliveryMethod') {
          background-color: var(--neutral-100);
          border-radius: 6px;
          border: solid 1px var(--neutral-400);
          height: 45px;
          width: 699px;
        }

        @include c('messageUnsecuredNote') {
          align-items: center;
          background-color: var(--neutral-200);
          border-radius: 6px;
          border: solid 1px rgba(55, 76, 92, 0.4);
          cursor: pointer;
          display: flex;
          font-size: 12px;
          font-weight: 600;
          height: 29px;
          margin-top: 5px;
          width: 699px;

          @include c('messageUnsecuredNoteCheckbox') {
            border-radius: 2px;
            height: 14px;
            margin: 7px 12px 7px 10px;
            width: 14px;
          }

          &--isDisabled {
            opacity: 0.4;
            pointer-events: none;
          }
        }

        @include c('messageLengthSubtext') {
          bottom: 8px;
          color: var(--success-500);
          display: flex;
          flex-direction: column;
          font-size: 10px;
          font-weight: 500;
          margin-top: 4px;
          max-width: 697px;
          position: absolute;
          right: 11px;
          text-align: right;

          &--emptyMessageBody {
            color: var(--neutral-700);
          }

          &--lengthExceeded {
            color: var(--primary-300);
          }
        }

        @include c('titleSubtext') {
          color: var(--neutral-700);
          font-size: 10px;
          font-weight: 500;
          margin-top: 4px;
          max-width: 697px;
          text-align: right;
        }

        @include c('templateTypeDropdown') {
          background-color: var(--neutral-100);
          border-radius: 6px;
          border: solid 1px var(--neutral-400);
          cursor: pointer;
          height: 44px;
          margin-top: 5px;
          width: 151px;

          &:focus-visible {
            outline: 3px solid var(--secondary-500);
          }

          &--disabled {
            background-color: var(--neutral-200);
            color: var(--neutral-700);
            cursor: not-allowed;
          }
        }

        @include c('repositoryTypeDropdown') {
          display: flex;
          justify-content: space-between;
          margin: 11px;
          text-transform: capitalize;

          &:focus-visible {
            outline: 3px solid var(--secondary-500);
          }
        }

        @include c('repositoryTypeDropdown-repo') {
          margin-top: 3px;
        }

        @include c('messageTypeDropdown') {
          background-color: var(--neutral-100);
          border-radius: 6px;
          border: solid 1px var(--neutral-400);
          cursor: pointer;
          height: 44px;
          margin-top: 5px;
          width: 699px;

          &--disabled {
            background-color: var(--neutral-100);
            cursor: not-allowed;
          }
        }

        @include c('messageKindDropdown') {
          display: flex;
          justify-content: space-between;
          margin: 9px;

          @include c('dropDownSvg') {
            display: flex;

            @include c('dropDownText') {
              align-items: center;
              display: flex;
              font-size: 14px;
              font-weight: 400;
              margin-left: 10px;
            }

            [fill='#4A657B'] {
              fill: var(--patient-network-700);
            }
          }
        }

        @include c('textArea') {
          border: solid 1px var(--neutral-400);
          border-radius: 6px;
          color: var(--neutral-1000);
          display: flex;
          flex-direction: column;
          font-size: 14px;
          font-weight: 500;
          margin-top: 10px;
          max-width: 697px;
          position: relative;
          width: 100%;

          @include c('inputGroup') {
            position: relative;
            height: 240px;
            width: 100%;
          }
        }

        @include c('nav-item-dropdown') {
          height: 22px;
          width: 22px;
          border-radius: 100%;
          margin-left: auto;
          margin-right: 0;

          [stroke='#4A657B'] {
            stroke: var(--patient-network-700);
          }
        }

        &:hover {
          @include c('nav-item-dropdown') {
            background: var(--neutral-100);

            svg {
              path {
                fill: var(--neutral-100);
              }
            }
          }
        }

        @include c('messageBody') {
          width: 615px;
          height: 500px;
          text-overflow: ellipsis;
          overflow: auto;
          white-space: pre-wrap;
          word-wrap: break-word;

          &:empty::before {
            content: attr(data-placeholder);
            color: var(--neutral-800);
          }
        }

        @include c('attachment-pin') {
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            [stroke='#4A657B'] {
              stroke: var(--patient-network-700);
            }

            [fill='#4A657B'] {
              fill: var(--patient-network-700);
            }
          }

          &:focus-visible {
            outline: 3px solid var(--secondary-500);
          }
        }

        @include c('attachment-label') {
          margin-left: 5px;
        }

        @include c('attachment-tooltip') {
          width: 45%;
        }

        @include c('attachment-container') {
          display: flex;
          align-items: center;

          @include c('attachment-file-name') {
            @include ellipsis;

            margin: 0 11px;
          }

          @include c('attachment-symbol') {
            flex-shrink: 0;
            width: 14px;
            height: 18px;
          }
        }

        @include c('formInput') {
          border: solid 1px var(--neutral-400);
          border-radius: 6px;
          color: var(--neutral-1000);
          display: block;
          font-size: 14px;
          font-weight: 500;
          margin-top: 10px;
          max-width: 697px;
          padding: 12px 9px;
          width: 100%;
        }

        input[type='file'] {
          display: none;
        }

        &--attachment {
          background-image: linear-gradient(to bottom, var(--neutral-100), var(--neutral-200));
          border: solid 0.5px var(--neutral-400);
          border-radius: 6px;
          color: var(--neutral-950);
          cursor: pointer;
          font-size: 12px;
          line-height: 28px;
          margin-top: -10px;
          text-align: center;
          width: 130px;

          label {
            cursor: pointer;
          }
        }

        &--attachmentPreview {
          border-radius: 6px;
          border: solid 0.5px var(--neutral-400);
          color: var(--neutral-1000);
          display: flex;
          flex-direction: row;
          font-size: 12px;
          justify-content: space-between;
          line-height: 28px;
          max-width: 329px;
          padding: 0 10px;

          @include c('removeAttachment') {
            cursor: pointer;
          }
        }

        &--shouldHide {
          display: none;
        }
      }

      @include c('viewTemplate') {
        @include c('title') {
          color: var(--neutral-950);
          font-size: 20px;
          margin-bottom: 28px;
        }

        @include c('body') {
          color: var(--neutral-1000);
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 40px;
          max-width: 650px;
          overflow-x: auto;
          white-space: pre-wrap;
          word-wrap: break-word;
        }
      }
    }

    @include c('footer') {
      background-color: var(--neutral-150);
      border-top: solid 1px var(--neutral-300);
      flex: 0 0 42px;
      line-height: 68px;
      padding: 0 69px;
      text-align: right;
      display: table;

      button {
        background-color: var(--neutral-100);
        border: solid 1px var(--cool-neutral-700);
        border-radius: 6px;
        color: var(--cool-neutral-700);
        font-size: 12px;
        font-weight: 600;
        line-height: initial;
        margin-left: 10px;
        padding: 11px 28px;
        text-transform: uppercase;

        &:disabled {
          background-color: var(--neutral-100);
          border: solid 1px var(--neutral-400);
          color: var(--neutral-400);
          cursor: not-allowed;
        }
      }

      @include c('cancel') {
        background-color: var(--neutral-100);
        border: solid 1px var(--cool-neutral-700);
        color: var(--cool-neutral-700);

        &:hover {
          background-color: var(--cool-neutral-700);
          color: var(--neutral-100);
        }
      }

      @include c('delete') {
        border: solid 1px var(--patient-network-700);
        color: var(--patient-network-700);

        &:hover {
          background-color: var(--primary-700);
          border: solid 1px var(--primary-700);
          color: var(--neutral-100);
        }
      }

      @include c('submit') {
        background-color: var(--patient-network-700);
        color: var(--neutral-100);
      }
    }
  }
}

@include b('TemplateDeliveryMethod') {
  background-color: var(--neutral-100);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 3px;
  width: 699px;

  @include c('menu-item') {
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    padding: 4px;

    &:hover {
      background-color: var(--cool-neutral-300);
    }

    svg {
      margin-right: 5px;

      [fill='#4A657B'] {
        fill: var(--patient-network-700);
      }
    }
  }
}
