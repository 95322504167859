@import '../../styles/base';

@include b('UserName') {
  @include c('tag-name') {
    font-size: 10px;
    color: var(--neutral-900);
  }

  @include c('owner-name') {
    color: var(--neutral-900);

    &--empty {
      color: var(--primary-300);
    }
  }
}
