@import '../base';

@include b('LongPressButton') {
  position: relative;

  @include c('button') {
    background-color: var(--neutral-100);
    border-radius: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
    color: var(--neutral-900);
    font-size: 12px;
    font-weight: 700;
    height: 61px;
    margin-left: 10px;
    margin-right: 5px;
    width: 61px;
    cursor: pointer;

    &:active {
      background-color: var(--secondary-500);
      color: var(--neutral-100);

      &:hover {
        background-color: var(--secondary-500);
      }
    }

    &--disabled {
      cursor: not-allowed;
      background-color: var(--neutral-300);
      color: #a2b0bc;
    }

    &--isLoading {
      background-color: #04a74d;

      &:hover {
        background-color: #04a74d;
      }
    }

    &:hover {
      background-color: var(--cool-neutral-200);
    }
  }
}
