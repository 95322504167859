@import '../base';

@include b('MuteModal') {
  width: 300px;
  background-color: var(--neutral-200);

  @include c('header') {
    @include common-modal-header;

    background-color: var(--neutral-200);
    margin: 12px 15px;
    color: var(--neutral-900);

    &:first-child {
      font-size: 12px;
      font-weight: bold;
    }
  }

  @include c('close-button') {
    @include common-modal-close-button;

    color: var(--neutral-900);
  }

  @include b('MenuItemList') {
    width: 100%;
    padding: 0;
    box-shadow: none;

    @include c('list-item') {
      text-align: center;
      padding: 1em 0;
      border-top: 1px solid var(--neutral-300);

      &:hover {
        cursor: pointer;
      }
    }

    @include c('menu-item') {
      text-align: center;
      color: var(--secondary-500);
      font-size: 14px;
      padding: 0 5em;
    }
  }
}
