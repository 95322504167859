@import '../../base';
@import './PatientSettingMixins';

@include b('CustomLabeling') {
  @include container;

  &::after {
    content: '';
    display: block;
    padding-bottom: 90px;
  }

  h3 {
    @include tab-heading;
  }

  fieldset {
    @include fieldset;
  }

  legend {
    @include input-legend;
  }

  label {
    @include input-label;
  }

  input {
    @include input;
  }

  input::placeholder {
    @include input-placeholder;
  }

  input[type='file'] {
    border: none;
  }

  @include c('error-text') {
    @include input-error-text;
  }

  @include c('logo-upload-strip') {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 6px;

    @include c('logo-upload-preview-empty') {
      width: 50px;
      height: 50px;
      border-radius: 2px;
      border: dashed 1px var(--cool-neutral-700);
      background-color: var(--neutral-150);
      margin-right: 10px;
      cursor: pointer;

      svg {
        margin-left: -25px;
        margin-top: 4px;
      }
    }

    @include c('logo-upload-preview') {
      width: 50px;
      height: 50px;
      margin-right: 10px;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
      border-radius: 2px;
      cursor: pointer;
    }

    @include c('logo-upload-button') {
      @include button;

      background-color: var(--patient-network-700);
      color: var(--neutral-100);
      line-height: 32px;
      padding: 0 11px;
      font-size: 13px;
      margin-right: 6px;
    }

    @include c('logo-delete-button') {
      @include button;

      border: solid 0.5px var(--neutral-400);
      background-color: var(--neutral-100);
      color: var(--neutral-1000);
      line-height: 32px;
      padding: 0 11px;
      font-size: 13px;
    }
  }

  @include c('logo-upload-input') {
    display: none;
  }

  @include c('save-container') {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 311px;
    height: 68px;

    @include c('save-content') {
      width: 100%;
      height: 100%;
      background: var(--neutral-150);
      border-top: 1px solid var(--neutral-300);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      button {
        @include button;

        border: 1px solid var(--neutral-400);
        background: var(--neutral-100);
        line-height: 39px;
        margin-right: 20px;
        color: var(--neutral-700);
        font-size: 14px;
        padding: 0 36px;
        font-weight: bold;
      }

      &--valid {
        button {
          background: var(--patient-network-700);
          color: var(--neutral-100);
          border: var(--patient-network-700);
        }
      }
    }
  }
}
