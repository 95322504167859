@include b('CallLogView') {
  background-color: var(--neutral-150);
  width: 100%;
  overflow-y: scroll;

  @include c('container') {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;

    &--isEmpty {
      height: 100%;
    }
  }

  @include c('empty-message') {
    font-size: 19px;
    color: var(--neutral-700);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
