@import '../../base';

@include b('VirtualWaitingRoomSideBar') {
  width: 450px;
  height: 100%;
  border-right: solid 1px #c7c7c7;
  background-color: #f6f6f8;
  display: flex;
  flex-direction: column;

  @include c('status-dropdown-container') {
    margin-top: 10px;
    padding: 8px 20px;
    height: 46px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @include c('selected-status') {
    font-size: 12px;
    color: #4d4d4d;
  }

  @include c('clear-all') {
    width: 96px;
    height: 30px;
    border-radius: 6px;
    background-color: $patient-color;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;

    &[disabled] {
      color: #c7c7c7;
      background-color: rgba(74, 101, 123, 0.1);
      cursor: default;
    }
  }
}
