@mixin schedule-message-context-menu-btn($width) {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: $width;
  height: 30px;
  border: solid 1px var(--neutral-400);
  border-radius: 6px;
  padding: 6px 10px;
  cursor: pointer;
  color: var(--neutral-1000);
  font-size: 13px;
  font-weight: 500;

  &--placeholder {
    color: var(--cool-neutral-800);
    font-weight: normal;
  }

  &:disabled {
    border: solid 1px var(--neutral-300);
    cursor: not-allowed;
  }

  &.tc-ContextMenu--active svg {
    transform: rotate(180deg);
  }
}

@mixin schedule-message-context-menu-dropdown($width) {
  max-height: 240px;
  width: $width;
  overflow: auto;

  li {
    margin: 2px 8px;
    border-radius: 6px;
  }

  @include c('menu-item') {
    padding: 0 6px;
    color: var(--neutral-1000);

    &--selected {
      font-weight: bold;
    }

    &--focussed {
      outline: 3px solid var(--secondary-500);
    }

    & > span {
      display: flex;
      justify-content: space-between;
      padding: 0 !important;

      p {
        font-size: 14px;
      }

      @include c('check') {
        margin: 0;
      }
    }
  }
}
