@import '../../base';

@include b('RoleIndicator') {
  position: absolute;
  top: calc(100% - 22px);
  right: -4px;

  @include c('container') {
    position: relative;
  }

  @include c('border-circle') {
    width: 21px;
    height: 21px;

    use {
      stroke: $white;
      stroke-width: 2;
    }
  }

  @include c('circle') {
    @include circle(17px);
    @include center-items-both;

    position: absolute;
    top: 2px;
    left: 2px;
  }

  @include c('icon') {
    width: 11px;
    height: 9px;
  }
}
