@import '../base';

@include b('RejectedFileFormatModal') {
  @include c('header') {
    @include common-modal-header;

    color: var(--primary-300);
  }

  @include c('close-button') {
    @include common-modal-close-button;

    color: var(--primary-300);
  }

  @include c('body') {
    @include rejected-attachments-modal-body;

    padding: 15px 35px 20px;
  }

  @include c('img') {
    height: 88px;
    width: 68px;
    margin: 20px 0;
  }

  @include c('title') {
    color: var(--primary-300);
    font-size: 18px;
    font-weight: bold;
    margin-top: 1.5vh;
    margin-bottom: 1.5vh;
  }

  @include c('info') {
    color: var(--neutral-950);

    a {
      color: var(--secondary-500);
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @include c('footer') {
    @include rejected-attachments-footer;
  }
}
