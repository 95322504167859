@mixin primary-button {
  @include center-items-both;

  padding: 15px 13px;
  border: 1px solid #ccc;
  border-radius: 6px;
  background-color: $interaction-signifier;
  color: $white;
  font-size: 16px;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
}

@mixin unread-count-badge($width) {
  background-color: #ef3249;
  color: #fff;
  border: 1px solid darken(#ef3249, 10%);
  border-radius: 4px;
  font-size: 70%;
  line-height: 1;
  padding: 2px 0;
  width: $width;
  text-align: center;
}
