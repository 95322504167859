@import '../../base';

@include b('AlertCardsView') {
  display: block;
  width: 100%;
  background: var(--neutral-100);

  @include if-ie {
    & > div > div > div {
      max-width: 100%;
    }
  }

  @include c('empty-history-text') {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: var(--neutral-800);
  }

  @include c('content-container') {
    display: flex;
    flex-direction: column;
    margin: 25px 15%;

    &--isGroupAlertsAllowed {
      max-width: calc(100vw - 422px);
      margin: 32px 15%;
    }

    @include c('content-title') {
      margin-bottom: 20px;
      font-size: 18px;
      color: var(--neutral-900);
    }

    @include c('card-container') {
      margin-bottom: 10px;
      min-width: 320px;

      &--isGroupAlertsAllowed {
        margin-bottom: 15px;
      }
    }

    @include c('view-history-btn') {
      margin-top: 17px;
      line-height: normal;
      font-weight: 500;
      color: var(--secondary-500);
      cursor: pointer;

      &:hover {
        color: var(--secondary-600);
      }
    }
  }

  @include c('loading-overlay') {
    position: relative;
    width: 100%;
    height: 100%;
    background: var(--neutral-100);
  }

  @include c('dots-container') {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @include c('dots-text') {
    padding-top: 10px;
    color: var(--neutral-700);
  }
}
