@import '../../base';
@import './PatientSettingMixins';

@include b('PatientBroadcastTable') {
  flex: 1;

  @include c('list') {
    min-width: 1025px;

    @include table-wrap;

    @include c('list-header') {
      @include table-header;

      @include c('list-item') {
        @include list-item-header;

        color: var(--secondary-900);
        display: flex;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        flex: 1;
        white-space: nowrap;
      }

      &--currentAppSelected--BroadcastLists {
        @include c('list-item') {
          color: var(--neutral-950);
          font-size: 12px;
        }
      }
    }

    @include c('list-body') {
      @include table-body;
    }

    @include c('empty') {
      @include empty;
    }
  }
}
