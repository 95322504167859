@import '../../../base';

@include b('GeneralRoleModal') {
  color: var(--neutral-900);

  @include c('header') {
    @include common-modal-header;

    color: var(--neutral-900);
  }

  @include c('img') {
    display: block;
    margin: 30px auto;
    border-radius: 0;
    box-shadow: none;
    height: 36px;
    width: 44px;
  }

  @include c('body') {
    @include common-modal-body;

    padding: 0 35px 45px;
    font-size: 14px;
  }

  @include c('body-container') {
    text-align: center;
    margin-top: 10px;
  }

  @include c('row') {
    display: inline;
    text-align: center;
    font-size: 16px;
  }

  @include c('display-name') {
    display: inline;
    color: var(--cool-neutral-900);
    font-weight: 600;
  }

  @include c('submit-btn') {
    @include common-button(var(--secondary-500), 74px, 32px, 11px);
  }
}
