@import '../../base';

@include b('AlertTextView') {
  margin-bottom: 6px;
  white-space: pre-line;
  word-break: break-word;
  word-wrap: break-word;
  line-height: 1.4;
  font-weight: 300;
}
