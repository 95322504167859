@import '../base';

@keyframes tc-spinner-spin {
  0% { opacity: 1; }
  100% { opacity: 0.15; }
}

@mixin spinner-bar {
  animation: tc-spinner-spin 1.2s linear infinite;
  border-radius: 5px;
  background-color: currentcolor;
  position: absolute;
  width: 20%;
  height: 7.8%;
  top: -3.9%;
  left: -10%;
}

@include b('Spinner') {
  position: relative;
  width: 20px;
  height: 20px;
  top: 50%;
  left: 50%;

  @include c('bar-0') {
    @include spinner-bar;

    animation-delay: -1.2s;
    transform: rotate(0deg) translate(146%);
  }

  @include c('bar-1') {
    @include spinner-bar;

    animation-delay: -1.1s;
    transform: rotate(30deg) translate(146%);
  }

  @include c('bar-2') {
    @include spinner-bar;

    animation-delay: -1s;
    transform: rotate(60deg) translate(146%);
  }

  @include c('bar-3') {
    @include spinner-bar;

    animation-delay: -0.9s;
    transform: rotate(90deg) translate(146%);
  }

  @include c('bar-4') {
    @include spinner-bar;

    animation-delay: -0.8s;
    transform: rotate(120deg) translate(146%);
  }

  @include c('bar-5') {
    @include spinner-bar;

    animation-delay: -0.7s;
    transform: rotate(150deg) translate(146%);
  }

  @include c('bar-6') {
    @include spinner-bar;

    animation-delay: -0.6s;
    transform: rotate(180deg) translate(146%);
  }

  @include c('bar-7') {
    @include spinner-bar;

    animation-delay: -0.5s;
    transform: rotate(210deg) translate(146%);
  }

  @include c('bar-8') {
    @include spinner-bar;

    animation-delay: -0.4s;
    transform: rotate(240deg) translate(146%);
  }

  @include c('bar-9') {
    @include spinner-bar;

    animation-delay: -0.3s;
    transform: rotate(270deg) translate(146%);
  }

  @include c('bar-10') {
    @include spinner-bar;

    animation-delay: -0.2s;
    transform: rotate(300deg) translate(146%);
  }

  @include c('bar-11') {
    @include spinner-bar;

    animation-delay: -0.1s;
    transform: rotate(330deg) translate(146%);
  }
}
