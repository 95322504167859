@import '../../base';

@include b('MessageForm') {
  background-color: var(--neutral-150);
  border-top: solid 1px var(--neutral-300);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;

  @include c('role-selector-row') {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    width: 100%;
    padding: 8px 0 0 14px;
  }

  @include c('body-row') {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    align-items: center;
    width: 100%;
    padding: 10px 13px 12px 14px;

    &--role-selector-visible {
      padding-top: 4px;
    }
  }

  @include c('button-container') {
    display: flex;
    align-self: flex-end;
    align-items: center;
    flex: 0 0 25px;
    height: 39px;
    margin-right: 10px;
  }

  @include c('attachment-button-container') {
    display: flex;
    align-self: flex-end;
    align-items: center;
    flex: 0 0 25px;
    height: 39px;
    margin-right: 10px;
  }

  @include c('body-container') {
    flex: 1 1 0%;
    min-height: 100%;
    position: relative;
  }

  @include c('emoji-button-container') {
    @include center-items-both;

    flex: 0 0 25px;
    align-self: flex-end;
    height: 39px;
    margin-left: 11px;
  }

  @include c('mention-button-container') {
    @include center-items-both;

    flex: 0 0 25px;
    align-self: flex-end;
    height: 39px;
    margin-left: 11px;
  }

  @include c('send-button-container') {
    @include center-items-both;

    flex: 0 0 60px;
    align-self: flex-end;
    height: 39px;
    margin-left: 11px;
  }

  @include c('send-button') {
    @include center-items-both;

    flex: 0 0 60px;
    height: 39px;
    margin: auto 0;
    border-radius: 4px;
    background-color: var(--neutral-300);
    color: var(--neutral-100);

    &:focus-visible {
      outline: auto;
      outline-width: 3px !important;
      outline-color: var(--secondary-500) !important;
    }

    &--isSendable {
      background-color: var(--primary-300);
      cursor: pointer;

      &:hover {
        background-color: var(--primary-700);
      }
    }

    &--quickReply {
      background-color: var(--quick-reply-700);
      cursor: pointer;

      &:hover {
        background-color: var(--quick-reply-900);
      }
    }

    &--progress {
      background-color: var(--primary-300);
      border-color: var(--primary-300);
    }
  }

  @include c('dots-container') {
    @include center-items-both;

    height: 75%;
  }

  @include c('multi-select-menu') {
    position: absolute;
    bottom: -62px;
    left: 0;
    right: 0;
    height: 62px;
    background: var(--neutral-200);
    transition:
      opacity 200ms cubic-bezier(0.22, 1, 0.36, 1),
      bottom 200ms cubic-bezier(0.22, 1, 0.36, 1);
    display: flex;
    align-items: center;
    padding: 5px 15px;
    font-weight: 500;
    font-size: 18px;
    opacity: 0;
    border-top: solid 1px var(--neutral-300);

    &--show-multi-select {
      opacity: 1;
      bottom: 0;
      transition:
        opacity 200ms cubic-bezier(0.22, 1, 0.36, 1),
        bottom 160ms cubic-bezier(0.22, 1, 0.36, 1);
    }

    @include c('left-container') {
      flex: 1;
    }

    @include c('right-container') {
      flex: 1;
      text-align: right;
    }

    @include c('cancel-button') {
      padding: 3px;
      display: inline-block;
      color: var(--neutral-600);
      cursor: pointer;

      &:hover {
        color: var(--neutral-800);
      }
    }

    @include c('continue-button') {
      padding: 3px;
      display: inline-block;
      text-transform: capitalize;
      color: var(--secondary-500);
      cursor: pointer;

      &:hover {
        // color: #1f5391;
        color: var(--secondary-900);
      }

      &--option--delete,
      &--option--recall {
        color: var(--primary-300);
      }

      &--option--delete:hover,
      &--option--recall:hover {
        color: var(--primary-900);
      }
    }
  }
}
