@import '../../../base';

@include b('ScheduleMessageRecipient') {
  @include c('name') {
    color: var(--neutral-1000);
    font-size: 13px;
  }

  @include c('secondary') {
    color: var(--neutral-700);
    font-size: 11px;
  }
}
