@import '../../base';

@include b('EmptyRoomCard') {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px dashed #c7c7c7;
  height: 68px;
  width: 410px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #979797;
}
