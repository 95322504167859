$line-color: #e0e0e0 !default;
$dimmed-line-color: lighten($line-color, 3%) !default;
$x-dimmed-line-color: lighten($line-color, 5%) !default;
$xx-dimmed-line-color: lighten($line-color, 8%) !default;
$strong-line-color: darken($line-color, 3%) !default;
$x-strong-line-color: darken($line-color, 5%) !default;
$xx-strong-line-color: darken($line-color, 8%) !default;
$accent-color-1: #2d2b64 !default;
$dimmed-accent-color-1: lighten($accent-color-1, 0%) !default;
$x-dimmed-accent-color-1: lighten($accent-color-1, 25%) !default;
$xx-dimmed-accent-color-1: lighten($accent-color-1, 45%) !default;
$strong-accent-color-1: darken($accent-color-1, 5%) !default;
$x-strong-accent-color-1: darken($accent-color-1, 8%) !default;
$xx-strong-accent-color-1: darken($accent-color-1, 11%) !default;
$link-color: $accent-color-1 !default;
$link-color-active: darken($link-color, 20%) !default;
$text-color: #333 !default;
$dimmed-text-color: lighten($text-color, 25%) !default;
$x-dimmed-text-color: lighten($text-color, 40%) !default;
$xx-dimmed-text-color: lighten($text-color, 50%) !default;
$strong-text-color: darken($text-color, 25%) !default;
$x-strong-text-color: darken($text-color, 40%) !default;
$xx-strong-text-color: darken($text-color, 50%) !default;
$drag-accepted-color: #3080df;
$attachment-gray: #fafafa;
$copy-text: #35333c;
$disabled-hover: #f6f6f6;
$display-text: #4d4d4d;
$display-text-patient-network: #45434d;
$focus-signifier-complement: #f0fcff;
$focus-signifier: #dff7ff;
$interaction-signifier: #3080df;
$label-text: #c8c8c8;
$light-grey: #9b9b9b;
$mid-dark-grey: #656565;
$mid-grey: #7f7f7f;
$nacho-cheese-orange: #ffa92a;
$primary-brand-hover: #c74841;
$primary-brand: #db524b;
$patient-icon-hover: #4078a5;
$patient-icon: #4b667c;
$read-indicator: #148d3c;
$delivered-indicator: #257398;
$sent-indicator: #257398;
$strong-interaction-signifier: darken($interaction-signifier, 10%);
$structure-grey-lines: #e7e7e7;
$warning: #fcb040;
$light-text-grey: #afafaf;
$medium-text-grey: #666;

/* Messenger layout */
$info-pane-transition-ms: 250ms;

/* Roster */
$roster-tile: #fbfcfc;
$roster-tile-selected: #dff7ff;
$roster-tile-hover: #e1e5e6;
$roster-bg: #f2f6f7;
$avatar-foreground: #4a4a4a;
$avatar-foreground-group: #fcb040;
$counter-party-name: #3c3c3c;
$message-timestamp: #7f7f7f;

/* Breakpoints */
$break-small-height: 450px;
$break-medium: 1240px;
$break-medium-large: 1395px;
$break-large: 1525px;

/* Message dimensions */
$break-medium-messages: 1365px;
$message-body-max-width: 500px;
$message-image-max-height: 400px;
$message-video-max-height: 250px;
$message-pdf-max-height: 250px;
$message-pdf-max-width: 300px;
$message-body-width-medium: 375px;
$message-code-max-width: calc(100vw - 520px);
