@import '../base';

@include b('BasicModal') {
  @include c('header-normal') {
    @include common-modal-header;
  }

  @include c('header-danger') {
    @include common-modal-header;

    color: var(--primary-300);
  }

  @include c('close-button-normal') {
    @include common-modal-close-button;
  }

  @include c('close-button-danger') {
    @include common-modal-close-button;

    color: var(--primary-700);
  }

  @include c('body') {
    @include common-modal-body;

    line-height: 1.4;
    padding: 35px 30px 90px;
    text-align: center;

    .word-break {
      word-break: break-word;
      word-wrap: break-word;
      width: 100%;
    }

    &.delete {
      padding: 20px 30px;
    }
  }

  @include c('question') {
    font-weight: bold;
    margin-bottom: 2vh;
  }

  @include c('info') {
    span {
      font-weight: bold;
    }
  }

  @include c('submit-btn-normal') {
    @include common-button(var(--patient-network-700), 74px, 32px, 11px);

    background-color: var(--patient-network-700);
    color: var(--neutral-100);

    &:hover {
      background-color: var(--patient-network-800);
      border-color: var(--patient-network-800);
    }

    &[disabled] {
      background-color: var(--neutral-100);
      border-color: var(--neutral-300);
      color: var(--neutral-300);
      cursor: not-allowed;

      &:hover {
        background-color: var(--neutral-100);
        color: var(--neutral-300);
      }
    }

    &--isSubmitting {
      cursor: default;
      pointer-events: none;
      color: var(--patient-network-700);

      &[disabled] {
        background-color: var(--patient-network-700);
        border-color: var(--patient-network-700);
        color: var(--neutral-100);
      }
    }
  }

  @include c('submit-btn-danger') {
    @include common-button(var(--primary-700), 74px, 32px, 11px);

    &--isSubmitting {
      cursor: default;
      pointer-events: none;
      color: var(--primary-700);
    }
  }

  @include c('cancel-btn') {
    @include common-button(var(--neutral-800), 74px, 32px, 11px);

    margin-right: 10px;
  }

  @include c('danger-btn') {
    @include common-button(var(--primary-700), 74px, 32px, 11px);

    margin-right: 10px;
  }

  @include c('small-text') {
    font-size: 14px;
    line-height: 25px;
  }

  @include c('normal-header-text') {
    font-weight: 400;
  }
}
