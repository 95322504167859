@import '../base';

@include b('SearchBar') {
  width: 350px;
  padding-left: 28px;

  @include c('search-container') {
    line-height: 39px;
    width: 288px;
    display: flex;
    border-radius: 20px;
    border: 1px solid var(--neutral-400);
    background-color: var(--neutral-150);
    overflow: hidden;
    align-items: center;
  }

  @include c('btn') {
    cursor: pointer;
    display: flex;
    user-select: none;
  }

  @include c('icon') {
    margin: auto;

    &--search {
      width: 16px;
      height: 16px;
      fill: var(--primary-300);
    }

    &--cancel {
      width: 10px;
      height: 11px;
      fill: var(--neutral-500);
    }
  }

  @include c('cancel-icon-container') {
    display: flex;
    user-select: none;
    padding: 7px;
    margin: 5px;
    border-radius: 50%;

    &--cancel-hovered {
      padding: 7px;
      margin: 5px;
      background-color: var(--neutral-500);
      border-radius: 50%;

      path {
        fill: var(--neutral-100);
      }

      use {
        fill: var(--neutral-100);
      }
    }
  }

  @include c('search-icon-container') {
    display: flex;
    user-select: none;
    margin: auto 4px auto 15px;
  }

  @include c('input') {
    cursor: text;
    flex: 1;
    margin-left: 5px;
    outline: none;
    background-color: var(--neutral-150);
    color: var(--neutral-900);
    font-size: 16px;
    text-rendering: optimizelegibility;
    padding-right: 5px;

    &::-ms-clear {
      display: none;
    }

    &::placeholder {
      color: var(--neutral-600);
    }
  }
}
