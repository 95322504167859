@import '../../base';

@include b('SidebarHeader') {
  display: flex;
  flex-direction: column;
  background-color: var(--neutral-100);

  @include c('sidebar-content') {
    display: flex;
    align-content: center;
    align-items: center;
    flex-shrink: 0;
    height: 70px;
    background-color: var(--neutral-100);
  }

  @include c('sidebar-btn') {
    margin-right: 5px;
    padding: 0 11px 0 6px;
    display: flex;
    user-select: none;
  }

  @include c('icon-container') {
    margin: 4px;
    width: 100%;
    height: 100%;
    display: flex;

    svg {
      margin: auto;
      width: 16px;
      height: 16px;
      color: var(--primary-700);
      fill: var(--primary-700);
    }

    &--patient-network-icon {
      svg {
        color: var(--patient-network-700);
        fill: var(--patient-network-700);
      }
    }

    &--search-cancel-icon {
      svg {
        padding: 2px 0 1px;
      }
    }

    &:hover {
      background-color: var(--neutral-200);
      border-radius: 50%;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);

      svg {
        fill: var(--primary-700);
        color: var(--primary-700);
      }
    }

    &--patient-network-icon:hover {
      svg {
        fill: var(--patient-network-800);
        color: var(--patient-network-800);
      }
    }
  }

  @include c('icon-container-roles') {
    cursor: pointer;
    width: 28px;
    height: 28px;
    background-color: var(--neutral-200);
    border-radius: 50%;
    margin: 0 3px;

    svg {
      color: var(--cool-neutral-700);
    }

    &:hover {
      background-color: var(--secondary-500);

      svg {
        color: var(--neutral-100);
      }

      @include c('icon-roles') {
        path[stroke='#EFF3F6'] {
          stroke: var(--secondary-500);
        }
      }
    }

    @include c('icon-roles') {
      margin: auto;
      width: 28px;
      height: 28px;
      fill: var(--cool-neutral-700);

      path[stroke='#EFF3F6'] {
        stroke: var(--neutral-200);
      }
    }
  }
}
