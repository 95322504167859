@import '../../base';

@include b('MessageBodyInput') {
  display: flex;

  @include c('input') {
    flex: 1 1 0%;
    outline: none;
    border-radius: 6px;
    border: 1px solid var(--neutral-400);
    color: var(--neutral-1000);
    padding: 6px 12px;
    resize: none;
    background-color: var(--neutral-100);
    font-size: 17px;
    word-break: break-word;
    white-space: pre-wrap;
    line-height: 25px;
    min-height: 100%;
    max-height: 265px;
    overflow-y: scroll;
    position: relative;

    &::-webkit-scrollbar {
      appearance: none;
    }

    &--isAccessible {
      &:focus-visible {
        outline: auto;
        outline-width: 3px !important;
        outline-color: var(--secondary-500) !important;
        border-radius: 10px;
      }
    }

    @include input-placeholder {
      color: var(--neutral-800);
    }

    &:empty {
      &::before {
        content: attr(placeholder);
        display: block;
        color: var(--neutral-800);
        position: absolute;
      }
    }

    &--body--long {
      overflow: auto;
    }

    &--body--short {
      overflow: hidden;
    }

    a {
      pointer-events: none;
      cursor: text;
    }
  }

  @include c('mention') {
    color: var(--secondary-300);
    pointer-events: none;
    user-select: none;
    text-decoration: underline;
  }
}
