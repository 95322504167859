@import '../../base';

@include b('SignInForm') {
  background-color: #fff;
  padding-top: 70px;
  padding-bottom: 40px;
  height: 100vh;

  @include c('loading-indicator') {
    position: absolute;
    top: 200px;
    left: 0;
    width: 100%;
    text-align: center;

    &--hidden {
      display: none;
    }
  }

  @include c('sign-in-container') {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    margin: auto;
    max-width: 445px;
    position: relative;
    width: 100%;
    overflow: hidden;
    justify-content: center;
    white-space: nowrap;
    text-overflow: ellipsis;

    &--hidden {
      display: none;
    }

    &--a11y {
      button:focus-visible {
        outline: 3px solid var(--secondary-500);
      }

      input:focus-visible {
        outline: 3px solid var(--secondary-500) !important;
      }
    }
  }

  @include c('location-container') {
    position: relative;
    width: 100%;
    max-width: 532px;
    margin: auto;
    padding: 20px 90px;
    background-color: $white;
  }

  @include c('location-container-outer') {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  @include c('bg') {
    position: absolute;
    top: 0;
    left: 0;

    > * {
      border-radius: 6px 6px 0 0;
    }
  }

  @include c('header') {
    @include center-items-both;

    flex-direction: column;
    height: 95px;
    justify-content: space-around;
  }

  @include c('username-title') {
    @include center-items-both;

    flex-direction: column;
    position: absolute;
    width: 100%;
    top: 18px;

    &--singleScreen {
      top: 32px;
    }
  }

  @include c('tc-svg') {
    margin-top: 20px;
  }

  @include c('internet-explorer') {
    margin-right: 10px;
  }

  @include c('password-title') {
    @include center-items-both;

    flex-direction: column;
    position: relative;
    width: 100%;
    top: 19px;
  }

  @include c('title-text') {
    color: #070f18;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
  }

  @include c('sign-in-title-text') {
    color: #070f18;
    font-style: normal;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;

    &--isUserUsingIE {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  @include c('tc-svg') {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  @include c('title-subtext') {
    padding: 5px 0 20px;
    font-size: 11px;
    color: #070f18;
  }

  @include c('username') {
    @include center-items-both;

    position: relative;
    margin-top: 35px;
    padding: 3px 40px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 19px;
    text-align: center;
    color: #1772c7;

    @include if-ie {
      margin-top: 5px;
    }
  }

  @include c('username--clickable') {
    cursor: pointer;
  }

  @include c('back-arrow') {
    align-content: center;
    display: flex;
    justify-content: center;

    > * {
      fill: #1772c7;
      height: 12px;
      margin-right: 10px;
      width: 18px;
    }
  }

  @include c('button-arrow') {
    > * {
      fill: currentcolor;
      width: 18px;
      height: 12px;
      color: $white;
    }
  }

  @include c('form') {
    display: flex;
    flex-direction: column;
    padding: 0 40px 40px;
  }

  @include c('error') {
    color: #db524b;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    height: 50px;
    justify-content: flex-end;
    line-height: 14px;
    margin-top: 10px;
    text-align: center;

    @include c('passwordError') {
      font-size: 14px;
      font-weight: 500;
    }

    @include c('accountError') {
      display: flex;
      justify-content: center;
      font-size: 14px;
      font-weight: 500;
      white-space: break-spaces;
      line-height: 1.4;
    }
  }

  @include c('country') {
    @include center-items-both;

    color: $interaction-signifier;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    height: 50px;
    justify-content: flex-end;
    line-height: 14px;
    margin-top: 25px;
    text-align: center;
  }

  @include c('field-group') {
    background-color: #f7f7f7;
    padding: 15px 11px;
    border: solid 1px #e0e0e0;
    border-radius: 3px;
    margin-top: 10px;

    input {
      background-clip: padding-box;
      background-color: #fff;
      border-radius: 0.25rem;
      border: 1px solid #ced4da;
      color: #495057;
      display: block;
      font-size: 1rem;
      font-weight: 400;
      height: calc(1.5em + 0.75rem + 2px);
      line-height: 1.5;
      padding: 0.375rem 0.75rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      width: 100%;

      &:focus {
        background-color: #fff;
        box-shadow: 0 0 0 0.2rem rgb(140, 188, 249);
        border-color: #80bdff;
        color: #495057;
        outline: 0;
      }
    }
  }

  @include c('field-group-error') {
    input {
      background-color: #fdecec;
      border: solid 1px $primary-brand;

      &:focus {
        border: solid 1px $primary-brand;
        box-shadow: none;
      }
    }
  }

  @include c('submit') {
    background-color: #2b70c4;
    border-radius: 4px;
    border: 1px solid #fff;
    box-sizing: border-box;
    color: #fff;
    display: flex;
    font-size: 12px;
    height: 35px;
    justify-content: center;
    margin: 20px 0;
    text-align: center;
    width: 100%;

    &:hover {
      cursor: pointer;
    }
  }

  @include c('cancel') {
    color: #434343;
    cursor: pointer;
    display: flex;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    text-align: center;
  }

  @include c('forgot-password') {
    align-self: center;
    color: $interaction-signifier;
    cursor: pointer;
    font-size: 12px;
    height: 20px;

    &:hover {
      text-decoration: underline;
    }
  }

  @include c('hidden') {
    display: none;
  }
}
