@import '../../base';

@include b('PatientReferenceSelectModal') {
  @include c('body-wrapper') {
    width: 325px;
    padding-top: 60px;
    margin: 0 auto;
    text-align: left;

    @include c('title') {
      color: var(--neutral-700);
      font-size: 14px;
      margin-bottom: 2px;
    }

    @include c('selector-container') {
      position: relative;

      @include c('selector') {
        width: 100%;
        border-radius: 6px;
        height: 38px;
        line-height: 38px;
        border: 1px solid var(--neutral-300);
        color: var(--cool-neutral-800);
        padding: 0 10px;
        font-size: 15px;
        cursor: pointer;
      }

      @include c('selected') {
        display: flex;
        line-height: initial;
        height: auto;
        padding: 9px 0;
        padding-right: 20px;
        align-items: center;

        @include c('cancel-icon') {
          flex: 1 0 auto;
          text-align: right;

          svg {
            rect {
              fill: var(--patient-network-200);
            }

            path {
              fill: var(--neutral-600);
            }
          }
        }
      }

      @include c('dropdown-container') {
        z-index: 1;
        position: absolute;
        width: 408px;
        left: -45px;
        top: 0;
      }
    }
  }
}
