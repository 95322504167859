@import '../../base';

@include b('ImageAttachment') {
  @include m('type', 'image') {
    @include center-items-both;

    position: relative;

    @include c('loading') {
      @include center-items-both;

      background-color: #fff;
      line-height: 1;
      font-size: 12px;
      width: $message-body-max-width;
      height: $message-image-max-height;
      color: #333;
      border-radius: 8px;
      border: 1px solid #e4e9ed;

      @include c('downloading-indicator') {
        width: 20px;
        height: 20px;
      }
    }

    @include c('image') {
      cursor: pointer;
      max-width: $message-body-max-width;
      max-height: $message-image-max-height;
      user-select: none;
      outline: none;
      border: 1px solid #e4e9ed;
      padding: 3px;
      border-radius: 8px;
      background: #fff;

      @include medium-messages {
        max-width: $message-body-width-medium;
      }

      &--isDownloading {
        display: none;
      }

      &--unselectable {
        cursor: default;
      }
    }
  }
}
