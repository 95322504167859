@import '../../base';

@include b('PrintMessageList') {
  max-width: 844px;
  margin: 88px auto 100px;
  border: solid 1px var(--neutral-400);
  border-radius: 6px;
  padding: 23px;
  background-color: var(--neutral-100);
  color: var(--neutral-800);

  @include c('brand') {
    color: var(--neutral-700);
    font-size: 12px;
  }

  @media print {
    max-width: 100%;
    margin: 0;
    border: none;
    padding: 5px;
  }
}
