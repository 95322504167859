@import '../../base';

@include b('RoleBanner') {
  @include center-items-both;

  background-color: var(--cool-neutral-300);
  padding: 0 20px;
  height: 100%;

  @include c('details') {
    @include center-items-both;

    visibility: hidden;
    width: min-content;
    height: 0;
    opacity: 0;
    transition: opacity 100ms;
    color: var(--cool-neutral-900);
    cursor: pointer;

    &--enable {
      visibility: visible;
      height: 32px;
      opacity: 1;
      transition: opacity 700ms;
    }

    @include c('icon') {
      flex: 0 0 22px;
      height: 18px;
      fill: var(--cool-neutral-900);
      margin-right: 10px;
    }

    @include c('header') {
      flex: 0 0 105px;
      margin-right: 4px;
      font-size: 14px;
      font-weight: bold;
      white-space: nowrap;
    }

    @include c('role-names') {
      @include ellipsis;

      max-width: 100%;
      font-size: 14px;
    }
  }
}
