@import '../../base';

@include b('KeywordInput') {
  width: 100%;

  @include m('container') {
    background-color: var(--neutral-100);
    border-radius: 6px;
    border: 1px solid var(--neutral-400);
    margin-right: 10px;
    max-height: 34px;
    width: 85%;
  }

  @include c('no-editable-container') {
    font-weight: bold;
    display: flex;

    @include c('search-term') {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    @include c('in-inbox') {
      flex-shrink: 0;
    }
  }

  @include c('input-container') {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include c('input') {
      flex: 1 1;
      outline: none;
      color: var(--neutral-1000);
      padding: 7px 5px;
      margin: 0 4px;
      resize: none;
      background-color: var(--neutral-100);
      font-size: 16px;
      word-break: break-word;
      white-space: pre-wrap;
      line-height: 25px;
      max-height: 30px;
      overflow-y: scroll;
      position: relative;
    }

    @include c('btn') {
      cursor: pointer;
      display: flex;
      user-select: none;
    }

    @include c('icon-container') {
      display: flex;
      user-select: none;

      &--cancel {
        padding: 7px;
      }
    }

    @include c('icon') {
      margin: auto;

      &--cancel {
        width: 10px;
        height: 11px;
        fill: var(--neutral-500);
      }
    }
  }
}
