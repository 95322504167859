@import '../base';

@include b('GroupMessageStatusModal') {
  @include c('body') {
    @include common-modal-body;

    display: flex;
    flex-direction: column;
    min-width: 500px;
    padding: 0 20px 20px;
  }

  @include c('message-container') {
    @include center-items-both;

    padding: 20px 10px 20px 20px;

    @include c('message') {
      @include b('MessageAttachment') {
        @include m('type', 'image') {
          img {
            max-height: 130px;
          }
        }
      }
    }

    @include b('MessageItem') {
      margin-top: 0;
    }
  }

  @include c('recipients-outer') {
    flex: 1 1 176px;
    position: relative;
    margin-bottom: 15px;

    @include c('recipients-inner') {
      padding: 5px 20px 10px;
    }
  }

  @include m('direction', 'OUTGOING') {
    @include c('sender') {
      order: 2;
    }

    @include c('sender') {
      margin-right: 5px;
    }

    @include c('message') {
      text-align: right;
    }
  }

  @include m('direction', 'INCOMING') {
    @include c('sender') {
      margin-left: 5px;
    }
  }
}
