@import '../../base';

@include b('ProviderSearchBoxResult') {
  padding: 0 12px 8px;
  cursor: pointer;

  @include m('disabled') {
    cursor: auto;
    opacity: 0.5;

    &:hover {
      background-color: var(--neutral-100);
    }
  }

  @include c('wrapper') {
    display: flex;
    align-items: center;
    width: 100%;
    border-top: 1px solid var(--neutral-300);
    padding-top: 8px;

    &--isSearchParity {
      justify-content: space-between;
    }

    &--isFirstEntry {
      border-top: none;
    }

    @include c('entity-info') {
      margin-left: 10px;
      flex: 1 1 auto;

      @include c('entity-name') {
        color: var(--neutral-1000);
        font-size: 14px;
        font-weight: 600;
        height: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 244px;
      }

      @include c('entity-details') {
        @include ellipsis;

        color: var(--neutral-700);
        display: flex;
        font-size: 10px;
        height: 14px;
        height: auto;
        width: auto;

        @include c('entity-title') {
          @include ellipsis;

          margin-right: 3px;
          max-width: 130px;
        }
      }
    }

    @include c('check-mark') {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 18px;
      width: 18px;
      border-radius: 50%;
      margin-right: 5px;

      &--isSelected {
        background-color: #04a74d;
      }
    }
  }

  &:hover {
    background-color: var(--cool-neutral-300);
  }
}
