@import '../base';

@include b('LoadingSpinner') {
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--neutral-100);

  @include c('container') {
    position: absolute;
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;

    @include c('text') {
      color: var(--patient-network-700);
      font-weight: 500;
      font-size: 20px;
    }

    @include c('icon') {
      margin-bottom: 20px;

      img {
        width: 50px;
        height: 50px;
      }
    }
  }
}
