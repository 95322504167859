@import '../../base';

@include b('DndRoster') {
  width: 100%;
  height: auto;
  box-sizing: border-box;

  @include c('details') {
    background-color: var(--neutral-100);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12);
    border-radius: 15px;
    overflow: hidden;
    margin: 0;
    margin-left: 30px;
    margin-right: 24px;
    height: auto;
    max-height: 0;
    opacity: 0;
    visibility: hidden;

    &--enable {
      visibility: visible;
      margin-top: 15px;
      margin-bottom: 6px;
      max-height: 200px;
      opacity: 1;
    }

    @include c('dnd') {
      @include center-items-both;

      padding: 0;

      @include c('icon') {
        height: 15px;
        width: 15px;
        margin-left: 10px;
      }

      @include c('text') {
        flex: 1;
        color: var(--neutral-950);
        font-size: 14px;
        font-weight: 600;
        padding-left: 10px;
      }

      @include c('switch') {
        margin: 6px 10px 5px 0;
      }
    }

    @include c('auto-forward') {
      height: 0;
      opacity: 0;
      border: 0 solid var(--neutral-200);
      padding: 0 10px;

      &--enable {
        border-top: 1px solid var(--neutral-200);
        padding-top: 10px;
        height: 65px;
        opacity: 1;
      }

      @include c('text') {
        font-size: 12px;
        color: var(--neutral-600);
      }

      @include c('users') {
        display: flex;
        align-items: center;

        @include c('switch') {
          margin: 6px 10px 0 0;
        }

        @include c('user') {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          font-size: 13px;
          font-weight: 700;
        }
      }
    }
  }
}
