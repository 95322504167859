@import '../../base';

@include b('MessageStatusFailedRetry') {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  font-size: 12px;

  @include c('retry-button') {
    border-radius: 12px;
    margin-top: 8px;
    padding: 4px 10px;
    font-weight: 300;

    @include m('senderStatus', 'FAILED') {
      background-color: var(--primary-300);
      color: var(--neutral-100);

      &:hover {
        background-color: #c74842;
      }
    }

    @include m('senderStatus', 'RETRYING') {
      background-color: var(--neutral-150);
      color: var(--neutral-700);
    }
  }

  @include c('delete-button') {
    margin-right: 15px;
    padding: 4px 0;
    color: var(--neutral-700);

    &:hover {
      color: var(--neutral-900);
    }
  }
}
