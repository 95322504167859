@import '../base';

@include b('EscalationEnabledModal') {
  @include c('header') {
    background-color: var(--escalation-100);
    text-align: center;
    color: var(--neutral-100);
    border-radius: 5px 5px 0 0;
    height: 122px;
    font-size: 18px;
    width: 100%;
  }

  @include c('img') {
    display: block;
    margin: 31px auto 17px;
    border-radius: 0;
    box-shadow: none;
    height: 30px;
    width: 35px;
  }

  @include c('body') {
    background-color: var(--neutral-150);
  }

  @include c('body-text') {
    border-bottom: solid 1px $dimmed-line-color;
    line-height: 1.6em;
    vertical-align: middle;
    font-weight: 500;
    padding: 20px 20px 55px;
    color: var(--neutral-950);
  }

  @include c('button') {
    text-align: center;
    background-color: var(--neutral-150);
    color: var(--secondary-500);
    padding: 20px 0;
    cursor: pointer;
    border-radius: 0 0 5px 5px;

    &:hover {
      background-color: var(--neutral-300);
    }
  }
}
