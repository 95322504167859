@mixin small-height {
  @media screen and (max-height: $break-small-height) {
    @content;
  }
}

@mixin medium {
  @media screen and (max-width: $break-medium) {
    @content;
  }
}

@mixin gt-medium {
  @media screen and (min-width: $break-medium + 1) {
    @content;
  }
}

@mixin medium-large {
  @media screen and (max-width: $break-medium-large) {
    @content;
  }
}

@mixin large {
  @media screen and (max-width: $break-large) {
    @content;
  }
}

@mixin if-ie {
  // IE-specific CSS overrides
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

@mixin clearfix {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin interactive-button {
  display: flex;
  align-items: center;
  text-align: center;
  height: 30px;
  border: 1px solid $interaction-signifier;
  color: $interaction-signifier;
  justify-content: center;
  width: 80%;
  border-radius: 6px;
  cursor: pointer;
  transition:
    background-color 0.2s cubic-bezier(0.22, 1, 0.36, 1) t,
    color 0.2s cubic-bezier(0.22, 1, 0.36, 1) t;
  font-size: 13px;
  font-weight: bold;

  &:hover {
    color: white;
    background-color: $interaction-signifier;
    transition:
      background-color 0.3s cubic-bezier(0.22, 1, 0.36, 1),
      color 0.3s cubic-bezier(0.22, 1, 0.36, 1);
  }
}

@mixin circle($size: false, $background-color: false) {
  border-radius: 50%;
  overflow: hidden;

  @if $size {
    @include wh($size);
  }

  @if $background-color {
    background-color: $background-color;
  }
}

@mixin wh($size-or-w, $h: false) {
  @if not $h {
    $h: $size-or-w;
  }

  width: $size-or-w;
  height: $h;
}

@mixin gpu {
  // be careful with this: having too many GPU rendering contexts can slow things down
  transform: rotateZ(360deg);
}

@mixin ellipsis {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@mixin user-select($option) {
  @include vendorize(user-select, $option);
}

@mixin appearance($appearance) {
  @include vendorize(appearance, $appearance);
}

@mixin vendorize($property, $value) {
  -webkit-#{$property}: $value;
  -moz-#{$property}: $value;
  -ms-#{$property}: $value;
  #{$property}: $value;
}

@mixin vendorize-value($property, $function_name, $value) {
  #{$property}: -webkit-#{$function_name}unquote('(') unquote($value) unquote(')');
  #{$property}: -moz-#{$function_name}unquote('(') unquote($value) unquote(')');
  #{$property}: -ms-#{$function_name}unquote('(') unquote($value) unquote(')');
  #{$property}: -o-#{$function_name}unquote('(') unquote($value) unquote(')');
  #{$property}: #{$function_name}unquote('(') unquote($value) unquote(')');
}

@mixin list-unstyled {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

@mixin smooth-text {
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
}

@mixin vertically-centered-text {
  white-space: nowrap;

  &::before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}

@mixin element-states($include-self: false, $extra-selectors: false) {
  $selector: '';

  @if $include-self {
    $selector: $selector + '&, ';
  }

  $selector: $selector + '&:hover, &:focus, &:active, &:focus:active';

  @if $extra-selectors {
    $selector: $selector + ', ' + $extra-selectors;
  }

  #{$selector} {
    @content;
  }
}

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin input-placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}

@mixin keep-ratio($ratio: 1 / 1) {
  position: relative;

  &::before {
    content: '';
    display: block;
    padding-top: 1 / $ratio * 100%;
  }

  > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin reset {
  &,
  * {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;

    // font-size: 100%;
    vertical-align: baseline;
    box-sizing: border-box;
    min-height: 0;
    min-width: 0;
    outline: 0;

    ul {
      list-style: none;
    }

    button {
      width: auto;
      height: auto;
      border: 0;
      background-color: transparent;
      cursor: pointer;
      outline: none;
      line-height: 1;
    }
  }
}

@mixin center-items-both {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin scaled-down-image {
  @include center-items-both;

  position: relative;

  > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    object-fit: scale-down;
    user-select: none;
    pointer-events: auto;
  }
}

@mixin zoomed-image {
  @include center-items-both;

  width: 100%;
  height: 100%;

  // callers should provide backgroundImage as an inline style
  background-position: center center;
  background-size: cover;
  overflow: hidden;

  > * {
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
    user-select: none;
    pointer-events: auto;
  }

  img {
    @include if-ie {
      // hide the image and use the background image of the container instead
      zoom: 1;
      opacity: 0;
    }
  }
}

@mixin common-modal-header {
  @include center-items-both;

  flex: 0 0 auto;
  position: relative;
  margin: 34px 32px 0;
  color: var(--secondary-500);

  &:first-child {
    display: flex;
    font-size: 18px;
    text-align: center;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 35px;
  }
}

@mixin common-modal-body {
  color: var(--neutral-900);
}

@mixin common-modal-footer {
  background-color: var(--neutral-200);
  border: 1px solid var(--neutral-200);
}

@mixin common-modal-close-button {
  color: $interaction-signifier;
  cursor: pointer;
  height: 25px;
  margin: 5px;
  pointer-events: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 25px;

  &:focus-visible {
    outline: 3px solid var(--secondary-500);
  }
}

@mixin rejected-attachments-modal-body {
  padding: 0 35px;
  max-width: 100%;
  box-shadow: none;
  border: none;
  text-align: center;
  line-height: 1.4;
  padding-bottom: 4vh;
  border-bottom: 1px solid $line-color;
}

@mixin rejected-attachments-footer {
  justify-content: center;
  padding: 0;

  > div {
    width: 100%;
  }

  button {
    color: $primary-brand;
    font-weight: bold;
    padding: 30px;
    width: 100%;
    background-color: #fafafa;
    cursor: pointer;

    &:hover {
      background-color: #f6f6f6;
    }
  }
}

@mixin mute {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(#000, 0.5);
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 25px;
    height: 25px;
    filter: drop-shadow(0 0 2px rgba(#000, 0.7));
  }
}

@mixin avatar-indicator($class, $stroke) {
  @include b($class) {
    @include c('border-circle') {
      use {
        stroke: $stroke;
      }
    }
  }
}

@mixin common-button($color, $width, $height, $textsize) {
  @include center-items-both;

  width: $width;
  height: $height;
  border-radius: 6px;
  border: solid 1px $color;
  color: $color;
  font-size: $textsize;
  background-color: var(--neutral-100);
  cursor: pointer;

  &:hover {
    background-color: $color;
    color: var(--neutral-100);
    border-color: $color;
  }
}

@mixin button-with-disable($color, $width, $height, $textsize) {
  @include common-button($color, $width, $height, $textsize);

  &[disabled] {
    border-color: var(--neutral-300);
    color: var(--neutral-300);
    cursor: auto;

    &:hover {
      background-color: var(--neutral-100);
      color: var(--neutral-300);
    }
  }

  &--progress {
    background-color: $color;
    border-color: $color;
  }
}

@mixin svg-background-and-icon($background-color, $icon-color) {
  .background {
    fill: $background-color;
  }

  .icon {
    fill: $icon-color;
  }
}

@mixin medium-messages {
  @media screen and (max-width: $break-medium-messages) {
    @content;
  }
}

@mixin profile-header {
  font-size: 10px;
  padding: 5px 15px;
  font-weight: 600;
  color: var(--neutral-900);
  border-bottom: solid 1px var(--neutral-300);
}

@mixin profile-sub-header {
  font-size: 10px;
  color: var(--neutral-700);
  width: 170px;
}

@mixin profile-detail-container {
  width: 100%;
  margin-top: 15px;
}

@mixin profile-detail-info {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 0 18px;
  height: 55px;

  &:not(:last-child) {
    border-bottom: solid 1px var(--neutral-300);
  }
}

@mixin patient-admin-detail {
  font-size: 12px;
  color: inherit;
}

@mixin icon-default {
  background-color: var(--neutral-150);
  border-radius: 100%;
  height: 28px;
  width: 28px;

  path {
    fill: var(--cool-neutral-700);
  }

  rect {
    fill: var(--cool-neutral-700);
  }

  &--open {
    background-color: var(--secondary-500);

    path {
      fill: var(--neutral-100);
    }

    rect {
      fill: var(--neutral-100);
    }
  }

  &:hover {
    background-color: var(--secondary-500);
    border-radius: 100%;

    path {
      fill: var(--neutral-100);
    }

    rect {
      fill: var(--neutral-100);
    }
  }
}

@mixin close-icon {
  border-radius: 100%;
  cursor: pointer;
  height: 24px;
  width: 24px;

  &:hover {
    background-color: #3080df;

    path {
      fill: $white;
    }
  }
}

@mixin markdown {
  code {
    font-family: Courier, 'New Lucida', Console, Monaco, monospace;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }
}
