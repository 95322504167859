@import '../base';

@include b('DeleteTeamModal') {
  @include c('header') {
    @include common-modal-header;

    color: var(--primary-300);
  }

  @include c('close-button') {
    @include common-modal-close-button;

    color: var(--primary-300);
  }

  @include c('body') {
    @include common-modal-body;

    padding: 35px 30px 40px;
    text-align: center;
    line-height: 1.4;
  }

  @include c('question') {
    font-weight: bold;
    margin-bottom: 2vh;
  }

  @include c('info') {
    span {
      font-weight: bold;
    }
  }

  @include c('delete-btn') {
    @include common-button(var(--primary-300), 74px, 32px, 11px);

    &--isLoading {
      cursor: default;
      pointer-events: none;
    }
  }

  @include c('cancel-btn') {
    @include common-button(var(--neutral-800), 74px, 32px, 11px);

    margin-right: 10px;
  }
}
