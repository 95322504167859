@import '../../../base';

@include b('CustomDndMessageModal') {
  @include c('custom-message-list-container') {
    padding: 40px 32px 30px;
  }

  @include c('quick-custom-message-container') {
    padding: 20px 32px 30px;
    background-color: var(--neutral-200);

    h4 {
      color: var(--neutral-900);
      font-size: 14px;
      text-align: left;
      font-weight: 500;
    }

    textarea {
      border: 1px solid var(--neutral-500);
      width: 100%;
      resize: none;
      border-radius: 2px;
      font-size: 14px;
      padding: 6px 8px;
      margin-top: 6px;
      margin-bottom: 4px;

      &::placeholder {
        color: var(--neutral-600);
      }
    }
  }

  @include c('edit-mode-buttons') {
    display: flex;
    padding: 15px 32px;
    justify-content: flex-end;
    background: var(--neutral-150);
    border-radius: 0 0 5px 5px;
  }

  @include c('character-counter') {
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    color: var(--neutral-700);
    margin-bottom: 5px;
  }
}
