@import '../../base';

@include b('PriorityMessageButton') {
  display: flex;
  margin: auto 0;

  @include c('image') {
    @include svg-background-and-icon(var(--neutral-700), transparent);

    width: 25px;
    height: 25px;
    display: flex;
    margin: auto;

    &,
    * {
      cursor: pointer;
      user-select: none;
    }

    &:hover {
      @include svg-background-and-icon(var(--primary-300), transparent);
    }

    &--isPriority {
      @include svg-background-and-icon(var(--primary-300), transparent);
    }

    &--alwaysEscalate {
      @include svg-background-and-icon(#999, $white);

      &:hover {
        @include svg-background-and-icon(#999, $white);
      }
    }
  }
}
