@import '../../base';

@include b('MessageItem') {
  $avatar-size: 30px;

  margin-top: 22px;
  opacity: 1;
  transition: opacity 280ms cubic-bezier(0.22, 1, 0.36, 1);

  @include m('precededByDateSeparator') {
    margin-top: 35px;
  }

  @include m('precededByOtherMessage') {
    margin-top: 10px;
  }

  @include m('shouldTransition') {
    opacity: 0;
  }

  @include m('standalone') {
    margin-top: 0;
  }

  @include m('multiSelect') {
    margin-top: 0;
    padding-top: 14px;
    padding-bottom: 14px;
    border-top: solid 1px var(--neutral-300);
    border-bottom: solid 1px transparent;

    &:last-child {
      border-bottom: solid 1px var(--neutral-300);
    }

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
    }
  }

  @include m('precededByMessageGroup') {
    border-top: solid 1px transparent;
  }

  @include m('isSelected') {
    background: var(--cool-neutral-450);
    border-top: solid 1px var(--cool-neutral-500);
    border-bottom: solid 1px var(--cool-neutral-500);
  }

  @include m('isKeywordMatch') {
    background: var(--cool-neutral-450);
    border-top: solid 1px var(--cool-neutral-500);
    border-bottom: solid 1px var(--cool-neutral-500);
  }

  @include m('isSelectable') {
    background: var(--cool-neutral-450);
    border-top: solid 1px var(--cool-neutral-500);
    border-bottom: solid 1px var(--cool-neutral-500);
    cursor: pointer;
  }

  @include c('hover-container') {
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 0;
    width: auto;
    align-items: flex-start;
    padding: 0 15px;

    &:hover {
      @include b('MessageDropdown') {
        @include c('show-onhover-actions-button') {
          display: flex;
        }
      }
    }

    @include m('direction', 'OUTGOING') {
      flex-direction: row-reverse;
    }

    @include b('MessageDropdown') {
      @include m('flex-display') {
        display: flex;
      }
    }
  }

  @include c('multi-select-checkbox-container') {
    align-self: center;
    margin: 0 40px;
    display: inherit;

    circle[fill='#FFF'] {
      fill: var(--neutral-100);
      stroke: var(--neutral-500);
    }

    circle[fill='#07F'] {
      fill: var(--secondary-500);
    }
  }

  @include m('direction', 'OUTGOING') {
    @include c('user-avatar-wrapper') {
      margin: 0 0 0 10px;
    }

    @include c('multi-select-checkbox-container') {
      flex: 1 0 auto;
    }
  }

  @include m('direction', 'INCOMING') {
    @include c('user-avatar-wrapper') {
      margin: 0 10px 0 0;
    }
  }

  @include c('user-avatar-wrapper') {
    flex: $avatar-size 0 0;
    overflow-y: visible;

    @include b('AvatarImage') {
      width: $avatar-size;
      height: $avatar-size;
    }
  }

  @include m('avatar-link') {
    @include b('AvatarImage') {
      @include c('image-container-circle') {
        cursor: pointer;
        box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.175);

        &:hover {
          box-shadow: 1px 4px 6px 0 rgba(0, 0, 0, 0.345);
        }

        &--entityType--team {
          &:hover {
            cursor: auto;
            box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.175);
          }
        }
      }
    }
  }
}
