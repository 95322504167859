@import '../../base';

@include b('AlertHeader') {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;

  @include c('info-container') {
    display: flex;
    align-items: center;
    flex: 0 0 auto;

    @include c('alert-severity') {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 22px;
      margin-right: 10px;
      border-radius: 17px;
      padding: 0 8px;
      color: var(--neutral-100);

      @include if-ie {
        padding-bottom: 1px;
      }
    }

    @include c('alert-time') {
      color: var(--neutral-700);
      font-size: 14px;
    }
  }

  @include c('status-container') {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 15px;
    flex: 0 1 auto;

    @include c('status-info') {
      @include ellipsis;

      font-size: 14px;
      font-weight: 500;
      color: var(--secondary-300);

      &--clickable {
        cursor: pointer;
      }
    }
  }
}
