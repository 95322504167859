@import '../../../base';

@include b('ValidateNewPassword') {
  @include c('list') {
    display: none;
    position: absolute;
    border-radius: 6px;
    background-color: #fbfbfb;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    margin-top: 10px;
    padding: 10px 15px;

    &--focus {
      display: flex;
    }
  }

  @include c('list-item') {
    color: $primary-brand;

    &--valid {
      color: #04a74d;
    }
  }
}
