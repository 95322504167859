@import '../../base';

@keyframes tc-attachment-drag-modal-blink {
  0% {
    border-color: $nacho-cheese-orange;
  }

  20% {
    border-color: var(--secondary-500);
  }

  30% {
    border-color: $nacho-cheese-orange;
  }

  35% {
    border-color: var(--secondary-500);
  }
}

@include b('AttachmentDragModal') {
  @include c('overlay-white') {
    @include center-items-both;

    position: absolute;
    top: 0;
    left: -1px;
    width: 100%;
    height: 100%;
    flex-direction: column;
    border: 6px solid #fff;
  }

  @include c('overlay') {
    @include center-items-both;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    flex-direction: column;
    background-color: rgba(#fff, 0.9);
    border-radius: 6px;

    &--drag-accepted {
      background: none;
      border: 3px solid var(--secondary-500);
      animation: tc-attachment-drag-modal-blink 1s 200ms;
      animation-delay: 500ms;
    }

    @include c('image') {
      position: absolute;
      top: -14px;
      right: -4px;
      flex: none;
      width: 28px;
      height: 28px;
    }
  }

  @include c('modal') {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
