@import '../../base';

@include b('PdfAttachment') {
  @include m('type', 'pdf') {
    @include center-items-both;

    @include c('loading') {
      @include center-items-both;

      background-color: #fff;
      line-height: 1;
      font-size: 12px;
      width: $message-pdf-max-width;
      height: $message-pdf-max-height;
      color: #333;
      border-radius: 8px;
      border: 1px solid #e4e9ed;

      @include c('downloading-indicator') {
        width: 20px;
        height: 20px;
      }
    }

    @include c('attachment-container') {
      position: relative;
      border: 1px solid #e4e9ed;
      padding: 3px;
      border-radius: 8px;
      background: #fff;
      cursor: pointer;

      &--unselectable {
        cursor: default;
      }

      @include c('pdf') {
        width: $message-pdf-max-width;
        height: $message-pdf-max-height;
        user-select: none;
        border-radius: 5px;
        outline: none;
        vertical-align: top;

        &--isDownloading {
          display: none;
        }
      }

      @include c('pdf-preview-overlay') {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 5px;
        margin: 3px;
      }

      @include c('pdf-icon') {
        position: absolute;
        bottom: 10px;
        right: 10px;
        height: 40px;
      }
    }
  }

  @include m('type', 'document') {
    border: 1px solid #e4e9ed;
    padding: 8px;
    border-radius: 8px;
    background: #fff;
    cursor: pointer;
  }
}
