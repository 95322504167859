@import '../base';

@include b('EscButton') {
  padding: 20px 20px 0 0;
  align-self: flex-end;

  @include c('button') {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    color: var(--cool-neutral-700);
    cursor: pointer;

    use {
      fill: var(--neutral-100);
    }

    circle {
      stroke: var(--cool-neutral-700);
    }

    div {
      padding-top: 5px;
    }

    &:hover {
      color: var(--neutral-800);
    }

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
    }
  }
}
