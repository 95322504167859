@import '../base';

@include b('RecipientPicker') {
  @import '~react-select-plus/dist/react-select-plus';

  .Select.is-open > .Select-control {
    background-color: var(--neutral-100);
  }

  .Select.is-focused > .Select-control {
    background-color: var(--neutral-100);
  }

  .Select-control .Select-input:focus {
    background-color: var(--neutral-100);
  }

  .Select-control .Select-input input {
    color: var(--neutral-1000);
  }

  @include m('isFlex') {
    flex: 1;
  }

  @include c('item-wrapper') {
    flex: 1;
    padding-right: 10px;
  }

  // Selected option menu
  .Select-focus-state-classic {
    border: none;
    box-shadow: none;
  }

  .Select-control {
    display: flex;
    flex-direction: row;
    border: none;
    height: auto;
    background-color: var(--neutral-100);

    &:hover {
      box-shadow: none;
    }
  }

  .Select-loading-zone {
    flex: 1 0 22px;
    padding: 6px 0 0 6px;
  }

  .Select.is-focused:not(.is-open) > .Select-control {
    box-shadow: none;
    background-color: var(--neutral-100);
  }

  // Selected items
  .New-group-modal,
  .New-team-modal,
  .Message-forward-modal,
  .Patient-Message-forward-modal {
    &.is-focused:not(.is-open) > .Select-control {
      box-shadow: none;
    }

    .Select-multi-value-wrapper {
      flex: 1 1 auto;
      min-height: 36px;
      max-height: 71px;
      overflow-y: auto;
    }

    .Select-placeholder {
      color: var(--secondary-500);
      font-weight: 400;
    }
  }

  .Patient-Message-forward-modal {
    .Select-placeholder {
      color: var(--cool-neutral-700);
      font-weight: 400;
    }
  }

  @include m('hideOverflow') {
    .New-group-modal,
    .New-team-modal,
    .Message-forward-modal,
    .Patient-Message-forward-modal {
      .Select-multi-value-wrapper {
        overflow: hidden;
      }
    }
  }

  @include m('isSearchingText') {
    .Select-menu-outer {
      .Select-option {
        &.is-focused {
          background-color: var(--neutral-100);
        }
      }
    }
  }

  @include m('isSearching') {
    .Select-option-group-label {
      background: var(--patient-network-100);
      padding: 0;

      @include c('label') {
        padding: 8px 7px;
      }

      @include c('loading') {
        text-align: center;
        background-color: var(--neutral-100);
      }
    }
  }

  @include m('searchParityPatientNetwork') {
    .new-message,
    .new-message-patient,
    .new-message-patient-compose {
      .Select-menu-outer {
        width: 400px;
        max-height: 540px;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25);
        border: solid 1px var(--neutral-300);
      }

      .Select-menu {
        max-height: 540px;
      }

      .Select-option {
        &.is-focused {
          background-color: var(--cool-neutral-300);
        }
      }
    }

    .Select-menu-outer {
      border-radius: 6px;
    }

    .Select-option-group-label {
      font-size: 14px;
      font-weight: 500;
      color: var(--patient-network-700);
      border: 1px solid var(--patient-network-100);
      background: var(--patient-network-100);

      @include c('label') {
        padding: 8px 7px;
      }

      @include c('loading') {
        text-align: center;
        background-color: var(--neutral-100);
      }
    }

    .Select-option {
      &.is-focused {
        background-color: var(--cool-neutral-300);
      }
    }
  }

  @include m('searchParityProviderNetwork') {
    .new-message,
    .new-message-patient,
    .new-message-patient-compose {
      .Select-menu-outer {
        width: 400px;
        max-height: 540px;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25);
        border: solid 1px var(--neutral-300);
      }

      .Select-menu {
        max-height: 540px;
      }
    }

    .Select-menu-outer {
      border-radius: 6px;
    }

    .Select-option-group-label {
      font-size: 14px;
      font-weight: 500;
      color: var(--neutral-900);
      background-color: var(--neutral-200);
      padding: 0;

      @include c('label') {
        padding: 8px 7px;
      }

      @include c('loading') {
        text-align: center;
        background-color: var(--neutral-100);
      }
    }
  }

  .new-message {
    .Select-multi-value-wrapper {
      width: 75%;
    }

    .Select-menu-outer {
      max-height: 350px;
      background-color: var(--neutral-100);
    }

    .Select-menu {
      max-height: 350px;
    }
  }

  .Edit-group {
    padding-left: 20px;

    .Select-menu-outer {
      position: absolute;
      width: 280px;
    }

    .Select-control {
      position: relative;
      flex: 1;
    }

    .Select-placeholder {
      font-size: 12px;
    }
  }

  .Select--multi,
  .Select--single {
    .Select-multi-value-wrapper {
      display: block;
      width: 100%;
    }

    .Select-value {
      margin: 5px 3px 0 2px;
      border-radius: 100px;
      border: none;
      color: var(--neutral-1000);
      background-color: var(--neutral-300);
      user-select: none;
    }

    .Select-value-label {
      padding: 0;
      margin-right: 8px;

      @include c('user-details') {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      @include b('AvatarImage') {
        margin-right: 8px;
      }
    }

    .Select-value-icon {
      @include center-items-both;

      float: right;
      padding: 0;
      border: none;
      width: 20px;
      height: 20px;
      font-size: 16px;
      margin: 5px 6px 0 0;

      &:hover {
        background-color: var(--neutral-500);
        border-radius: 50%;
        color: var(--neutral-100);
      }
    }

    .Select-placeholder {
      height: 36px;
      line-height: 36px;
      padding-left: 7px;
    }

    .Select-input {
      display: flex;
      align-items: center;
      height: 36px;
      margin: 0;
      padding: 0;

      input {
        height: 22px;
        padding: 7px;
      }
    }
  }

  .Opt-out-modal,
  .Auto-forward-modal {
    .Select-control {
      border: solid 1px var(--secondary-500);
      border-radius: 7px;
      padding: 4px 10px 8px;
    }

    .Select-focus-state-classic {
      border: solid 1px var(--secondary-500);
    }

    .is-focused:not(.is-open) > .Select-control {
      border: inherit;
    }

    .Select--single {
      border: solid 1px var(--secondary-500);
    }

    .Select-placeholder {
      color: var(--secondary-500);
      padding-top: 7px;
      padding-left: 10px;
    }

    .Select-menu-outer {
      width: 445px;
    }
  }

  // Dropdown
  .Select-menu-outer {
    width: 635px;
    margin-top: 5px;
    border: solid 1px var(--neutral-300);
    background-color: var(--neutral-100);
    border-radius: 6px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.09);
  }

  .Select-noresults {
    color: var(--neutral-700);
    background-color: var(--neutral-100);
  }

  .Select-menu {
    border-radius: 6px;

    @include c('user-details') {
      margin: 3px 0;
    }

    @include b('AvatarImage') {
      margin: 0 10px;
      color: var(--neutral-1000);
    }
  }

  // Dropdown group labels
  .Select-option-group-label {
    font-size: 12px;
    font-weight: 500;
    color: var(--neutral-500);
    background: var(--patient-network-100);
  }

  // Dropdown items
  .Select-option {
    font-size: 14px;
    padding: 3px 0;
    background-color: var(--neutral-100);
    color: var(--neutral-1000);

    &:not(:last-child) {
      position: relative;

      &::before {
        content: '';
        border-bottom: 1px solid var(--neutral-300);
        position: absolute;
        width: 85%;
        left: 15%;
        bottom: 0;
      }
    }

    &.is-focused {
      background-color: var(--cool-neutral-300);
      color: var(--neutral-1000);

      @include c('title-department') {
        color: var(--neutral-700);
      }

      @include avatar-indicator('PresenceIndicatorDnd', var(--cool-neutral-300));
    }

    @include c('ma-details') {
      display: flex;
      flex-direction: column;
      margin: -3px 0;
      background-color: var(--neutral-100);

      @include c('ma-header') {
        padding: 12px 0 0 9px;
        color: var(--neutral-700);
      }

      @include c('ma-recipient') {
        align-self: flex-start;
        margin: 9px;
        border-radius: 6px;
        padding: 2px 8px;

        /* background-color: #ebeff2; */
        background-color: var(--patient-network-200);
        font-weight: bold;
      }

      @include c('ma-enter') {
        display: flex;
        justify-content: flex-end;
        padding: 6px 20px 6px 0;
        background-color: var(--neutral-200);
        color: var(--neutral-800);
        font-style: italic;

        svg {
          margin: 6px 0 0 6px;
        }
      }
    }

    @include c('user-details') {
      display: inline-block;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      &--isDisabled {
        opacity: 0.5;
      }

      &--isFamilyAndContactsMessagingClass {
        border-radius: 6px;
        width: 295px;
        height: 37px;
        margin: 4px 8px 4px 6px;
        padding-left: 4px;
        padding-right: 4px;

        &:hover {
          background-color: var(--cool-neutral-300);
        }
      }
    }

    @include b('AvatarImage') {
      flex-shrink: 0;
    }

    @include c('entity-name') {
      @include ellipsis;

      color: var(--neutral-1000);
      flex: 0 0 auto;
      max-width: 400px;
    }

    @include c('role-tag-details') {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0 8px;
      flex: 1 1 auto;
    }

    @include c('tag-indicator') {
      flex-shrink: 0;
      width: 4px;
      height: 4px;
      margin: 3px 5px;
      border-radius: 6px;

      @include if-ie {
        margin-top: 4px;
      }
    }

    @include c('tag-name') {
      @include ellipsis;

      color: var(--neutral-700);
      font-size: 12px;
      flex: 1 1 auto;

      @include c('highlighted') {
        background-color: transparent;
        font-weight: normal;
        color: var(--neutral-1000);
      }
    }

    @include c('title-department') {
      @include ellipsis;

      color: var(--neutral-500);
      margin: 0 8px;
      font-size: 12px;
      flex: 1 1 auto;
    }

    @include c('patient-details') {
      @include ellipsis;

      color: var(--neutral-500);
      font-size: 11px;
      padding-left: 10px;
      min-width: 150px;
    }
  }

  .Select-option-group-label ~ .Select-option,
  .Select-option-group-label ~ .Select-option-group {
    padding-left: 10px;
  }

  .Select-placeholder {
    user-select: none;
    color: var(--neutral-500);
  }

  .Select-arrow-zone {
    display: none;
  }

  .Select-arrow {
    display: none;
  }

  .Select-clear-zone {
    display: none;
  }

  @include c('search-text') {
    background-color: transparent;
    font-weight: bold;
    color: var(--neutral-1000);
  }

  .Add-care-team-modal {
    border: solid 1px var(--neutral-300);
    border-radius: 6px;

    @include c('entity-name') {
      max-width: 200px;
    }

    .Select-placeholder {
      height: 60px;
      padding: 12px;
      color: var(--neutral-600);
      cursor: pointer;

      &:hover {
        background-color: var(--cool-neutral-100);
      }
    }

    .Select-input {
      height: 60px;
      margin: 0;
      padding: 10px;

      input {
        height: 30px;
        padding: 0;
      }
    }

    .Select-arrow-zone {
      display: block;
      position: absolute;
      right: 46px;
      bottom: 14px;

      @include if-ie {
        bottom: 16px;
      }

      @include c('close-button') {
        height: 22px;
        width: 22px;
        color: var(--cool-neutral-700);

        & circle {
          fill: var(--cool-neutral-100);
          stroke: var(--cool-neutral-100);
        }
      }
    }

    &.is-open {
      border: none;
      border-radius: 0;
      box-shadow: none;

      .Select-control {
        height: 62px;
        border: solid 1px var(--neutral-300);
        border-bottom: none;
        border-radius: 6px 6px 0 0;
        box-shadow: -1px -1px 3px rgba(0, 0, 0, 0.09), 1px -1px 3px rgba(0, 0, 0, 0.09);

        .Select-multi-value-wrapper {
          border: none;
          border-radius: 0;
          box-shadow: none;

          .Select-placeholder {
            height: 30px;
            margin: 15px 45px;
            border-radius: 18px;
            padding: 0 12px;
            font-size: 14px;
            cursor: text;

            &:hover {
              background-color: transparent;
            }
          }

          .Select-input {
            height: 30px;
            width: 258px;
            margin: 15px 45px;
            border: solid 1px var(--neutral-300);
            border-radius: 18px;
            padding: 0 12px;
            background-color: var(--neutral-150);
            font-size: 14px;

            input {
              max-width: 210px;
            }
          }
        }
      }

      .Select-menu-outer {
        margin: 0;
        border: solid 1px var(--neutral-300);
        border-top: none;
        border-radius: 0 0 6px 6px;
        width: 350px;
        box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.09), -1px 3px 3px rgba(0, 0, 0, 0.09);
      }

      .Select-menu {
        border-radius: 0;
      }
    }
  }

  .new-message-patient-compose {
    .Select-value {
      background-color: var(--patient-network-300);
      border-radius: 15px;
      height: 30px;
      max-width: 266px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .Select-option {
      padding: 0;
      color: var(--neutral-950);

      &.is-focused {
        background-color: var(--neutral-100);
      }
    }

    .Select-menu-outer {
      max-width: 100%;
      max-height: 350px;
    }

    .Select-menu {
      @include b('AvatarImage') {
        margin: 0;
      }
    }

    .Select--multi,
    .Select--single {
      .Select-value {
        margin: 5px 3px 0 2px;
        border-radius: 100px;
        border: none;
        color: var(--neutral-950);
        background-color: var(--neutral-100);
        user-select: none;
      }
    }

    .Select-value-icon {
      margin: 5px 6px 0;
    }

    @include c('entity-name') {
      @include ellipsis;

      color: var(--neutral-1000);
      flex: 0 0 auto;
      max-width: 185px;
      width: auto;
    }
  }

  .new-message-patient {
    .Select-value {
      border-radius: 6px;
      padding: 10px 0 10px 10px;
    }

    .Select-option {
      padding: 0;
      color: var(--neutral-950);

      &.is-focused {
        background-color: var(--neutral-100);
      }
    }

    .Select-menu-outer {
      max-width: 100%;
      max-height: 350px;
    }

    .Select-menu {
      @include b('AvatarImage') {
        margin: 0;
      }
    }

    .Select--multi,
    .Select--single {
      .Select-value {
        margin: 1px 3px 0 2px;
        border-radius: 100px;
        border: none;
        color: var(--neutral-950);
        background-color: var(--neutral-100);
        user-select: none;
      }
    }

    &--isBroadcastList {
      .Select-value-icon {
        margin: 8px 6px 0 0;
      }
    }

    &--isUser {
      .Select-value-icon {
        margin: 20px 6px 0 0;
      }
    }

    .Select-value-icon {
      margin: 20px 6px 0 0;
    }
  }

  @include c('new-message-patient') {
    &--scheduleMessageRecipientAdded {
      .Select-menu-outer {
        display: none !important;
        pointer-events: none;
      }

      .Select-control {
        .Select-input {
          display: none !important;
          pointer-events: none;
          cursor: none;
        }
      }
    }

    &--isBroadcastList {
      .Select-value-icon {
        margin: 8px 6px 0 0;
      }
    }

    &--isUser {
      .Select-value-icon {
        margin: 20px 6px 0 0;
      }
    }
  }

  @include c('multiRec') {
    .Select-menu-outer {
      background-color: var(--neutral-100);
      border-radius: 6px;
      border: solid 1px var(--neutral-300);
      height: auto;
      max-height: 222px;
      width: 310px;
    }

    .Select-menu {
      max-height: 222px;
      overflow-x: hidden;

      @include b('AvatarImage') {
        margin-right: 10px;
      }
    }
  }

  .New-team-modal {
    .Select-menu-outer {
      width: 490px;
    }

    .Select-loading-zone {
      display: none;
    }
  }

  .VWR-Provider {
    margin-bottom: 10px;
    width: 500px;

    .Select-menu-outer {
      width: 500px;
    }

    .Select-placeholder {
      color: var(--neutral-700);
      padding-left: 10px;
      height: 30px;
      line-height: 30px;
    }

    .Select-control {
      border-radius: 6px;
      border: solid 0.5px var(--neutral-500);
      background-color: var(--neutral-150);
      font-size: 13px;
      padding: 0 10px;
      height: 30px;
    }

    .Select-input {
      height: 30px;

      input {
        height: 30px;
        padding: 0;
      }
    }

    &.is-focused:not(.is-open),
    &.is-open {
      .Select-control {
        border-radius: 6px;
        border: solid 0.5px rgba(5, 140, 255, 0.25);
        background-color: var(--neutral-150);
        padding: 0 10px;
        color: var(--neutral-1000);
      }
    }
  }

  .inform-provider {
    .Select-control {
      background-color: var(--neutral-200);
    }

    &.is-focused:not(.is-open),
    &.is-open {
      .Select-control {
        background-color: var(--neutral-200);
        border: solid 0.5px rgba(5, 140, 255, 0.25);
        border-radius: 2px;
      }
    }

    .Select-input {
      padding-left: 10px;
      padding-top: 4px;
    }

    .Select-placeholder {
      padding-top: 4px;
      padding-left: 10px;
    }

    .Select-multi-value-wrapper {
      display: flex;
      height: 45px;
    }

    .Select-value {
      margin: 5px;
      cursor: pointer;
      background-color: var(--neutral-200);
    }

    .Select-option {
      padding: 5px;
    }

    .Select-menu-outer {
      max-width: 100%;
      max-height: 250px;
    }

    .Select--multi,
    .Select--single {
      .Select-value {
        margin: 5px 3px 0 2px;
        border-radius: 100px;
        border: none;
        color: var(--neutral-1000);
        background-color: var(--neutral-100);
        user-select: none;
      }
    }
  }
}
