@import '../../base';

@include b('CollaborationTagItem') {
  flex: 1;

  @include c('tag-item') {
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 100%;

    &--isClickable {
      cursor: pointer;
    }

    &--tag-selected-name {
      border-radius: 15px;
      margin: 5px 0;
      padding: 0 4px 0 0;

      span {
        font-size: 20px;
        font-weight: 500;
        margin-top: 3px;
      }
    }

    &--tag-list-item {
      flex: 1;
      width: 216px;
    }

    &--tag-selected-name-with-hover {
      border-radius: 15px;
      margin: 5px 0;
      padding: 0 4px 0 7px;
      cursor: pointer;

      span {
        font-size: 20px;
        font-weight: 500;
        margin-top: 2px;
      }

      &:hover {
        background-color: var(--cool-neutral-200);
      }
    }
  }

  @include c('tag-name') {
    line-height: 1.4em;
    max-width: 300px;
    overflow: hidden;
    padding-right: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;

    &--overflow {
      max-width: 100%;
      white-space: normal;
    }
  }

  @include c('dot-color') {
    border-radius: 50%;
    height: 6px;
    width: 6px;
    margin-right: 5px;
  }

  @include c('tag-icon') {
    margin-right: 10px;
  }
}
