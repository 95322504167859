@import '../../base';

@include b('AttachmentButton') {
  display: flex;
  margin: auto 0;

  @include c('image') {
    width: 25px;
    height: 25px;
    fill: var(--neutral-700);

    &:hover {
      fill: var(--secondary-500);
    }
  }

  @include c('attachment-button-container') {
    display: flex;
    margin: auto;

    &:focus-visible {
      outline: auto;
      outline-width: 5px !important;
      outline-color: var(--secondary-500) !important;
      border-radius: 10px;
    }

    &,
    * {
      cursor: pointer;
      user-select: none;
    }
  }
}
