@import '../../base';

@include b('DocumentAttachment') {
  @include m('type', 'document') {
    border: 1px solid var(--neutral-300);
    padding: 8px;
    border-radius: 8px;
    background: var(--neutral-100);
    cursor: pointer;
  }

  @include m('unselectable') {
    cursor: default;
  }
}
