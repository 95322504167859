@import '../../base';

@include b('PatientConversationList') {
  flex: 1 1 auto;
  position: relative;
  display: flex;

  @include c('conversations') {
    flex: 1 1 auto;
    display: flex;

    &:focus-visible {
      outline: 3px solid #058cff;
    }
  }

  @include c('placeholder-container') {
    @include center-items-both;

    flex: 1;
  }

  @include c('ie11-list') {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }

  @include c('dots-container') {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }

  @include c('list-item') {
    cursor: pointer;
    opacity: 1;
    transition: opacity 40ms;
    padding: 4px;

    @include avatar-indicator('PresenceIndicatorAvailable', var(--cool-neutral-200));
    @include avatar-indicator('PresenceIndicatorDnd', var(--cool-neutral-200));
    @include avatar-indicator('RoleIndicator', var(--cool-neutral-200));

    &--isScrolling {
      pointer-events: none !important;
    }

    &:focus-visible {
      outline: 3px solid #058cff;
    }

    &--sectionSeparator {
      border-bottom: 10px solid var(--neutral-100);

      @include b('ConversationItemDetails') {
        border-bottom: none;
      }
    }

    &--sectionSeparatorPatient {
      border-bottom: 10px solid var(--cool-neutral-200);
    }

    &--preview {
      opacity: 0.9;
    }
  }
}
