@import '../../base';

@include b('CallActionModal') {
  @include c('header') {
    height: 40px;
    font-size: 16px;
    text-align: center;
  }

  @include c('close-button') {
    @include common-modal-close-button;

    right: 20px;
    top: 20px;
  }

  @include c('body') {
    color: var(--neutral-800);
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
  }

  @include c('body-container') {
    color: var(--neutral-800);
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  @include b('AvatarImage') {
    height: 64px;
    width: 64px;
    align-self: center;
    margin: 10px;
  }

  @include c('title') {
    font-weight: 600;
    color: var(--neutral-1000);
    padding-bottom: 17px;
  }

  @include c('call-button-text') {
    align-self: center;
  }

  @include c('call-button') {
    display: flex;
    width: 280px;
    height: 45px;
    color: var(--patient-network-700);
    background-color: var(--neutral-200);
    box-shadow: 0 0 3 0 rgba(0, 0, 0, 0);
    transition: box-shadow 0.25s;
    margin: 3px auto;
    justify-content: center;
    align-items: center;
    border-radius: 22px;
    font-size: 14px;
    font-weight: 600;
    position: relative;

    &:hover {
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.23);
    }

    &[disabled] {
      box-shadow: none !important;
      cursor: not-allowed;
    }
  }

  @include c('spinner') {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
  }

  @include c('call-button-container') {
    display: flex;
    flex-direction: row;

    @include c('phone-icon') {
      margin-right: 16px;
      cursor: pointer;

      path {
        fill: var(--neutral-700);
      }
    }

    @include c('video-icon') {
      margin-right: 16px;
      color: var(--patient-network-700);
      fill: var(--patient-network-700);
      height: 20px;
      width: 20px;

      path {
        fill: var(--neutral-700);
      }
    }
  }

  @include c('footer') {
    @include common-modal-footer;

    height: 85px;
    position: relative;
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    font-weight: 500;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-clip: padding-box;
    margin-top: 20px;
  }
}
