@import '../base';

@include b('AlertReactionBang') {
  margin-top: 23px;
  min-height: 18px;
  text-align: center;
  font-size: 13px;
  color: #797979;

  @include m('isPrimaryAction') {
    color: #5fb5ff;
  }

  @include m('precededByDateSeparator') {
    margin-top: 18px;
  }

  &:focus-visible {
    outline: 3px solid var(--secondary-500);
  }
}
