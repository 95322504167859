@import '../../base';

@include b('AutoForwardItem') {
  display: flex;
  align-items: center;

  @include c('switch-button') {
    margin-top: 6px;
  }

  @include c('organization-name') {
    flex: 1;
    font-size: 12px;
    color: $display-text;
    margin-left: 5px;
  }

  @include c('user-name') {
    padding: 5px 20px;
    border-radius: 100px;
    border: solid 1px $interaction-signifier;
    color: $interaction-signifier;
    font-size: 12px;
    cursor: pointer;

    &:hover {
      background-color: $interaction-signifier;
      color: $white;
    }
  }
}
