@import '../../base';

@include b('CollaborationRoles') {
  background: var(--neutral-100);
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
  position: relative;
  width: calc(100% - 349px);

  @include c('tag-filter-message') {
    color: var(--neutral-700);
    font-size: 12px;
  }

  @include c('roles-container') {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    opacity: 1;
    position: relative;
    transition: opacity 150ms ease-in;
    width: 100%;

    &--empty {
      background: url('../../../images/pattern-clip@2x.png');

      @include themify {
        background-blend-mode: color-burn;
        background-color: var(--neutral-100);
      }
    }

    &--infoPaneOpen {
      display: flex;
      flex: none;
      width: calc(100% - 375px);

      @media only screen and (max-width: $break-medium) {
        opacity: 0;
        transition: width 150ms;
        width: 0;
      }
    }
  }

  @include c('roles-header') {
    padding: 15px 20px 0;
    position: relative;

    &--withFilterBar {
      min-height: 96px;
      padding-bottom: 10px;
    }
  }

  @include c('filters-and-action') {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  @include c('selected-or-checked-tag-container') {
    display: flex;
    flex: 1 1;
    flex-flow: row wrap;
  }

  @include c('selected-tag-container') {
    display: flex;
    flex: 1 1;
    flex-flow: row wrap;
    margin-left: -7px;

    &--single {
      width: 100%;
    }
  }

  @include c('selected-tag') {
    display: block;
    margin-right: 6px;
  }

  @include c('not-tagged-icon') {
    margin-right: 5px;

    &--noPath {
      path {
        display: none;
      }
    }
  }

  @include c('tag-selected-name') {
    border-radius: 15px;
    margin: 5px 0;
    padding: 0 4px 0 7px;

    span {
      font-size: 20px;
      font-weight: 500;
      margin-top: 3px;
    }
  }

  @include c('bookmark-icon') {
    height: 13px;
    margin-right: 5px;
    width: 12px;
  }

  @include c('org-peek-container') {
    left: 0;
    position: absolute;
    right: 0;
    top: 42px;
  }

  @include c('org-peek') {
    background: var(--secondary-300); /* double-check */
    border-radius: 10px;
    color: var(--neutral-100);
    cursor: pointer;
    line-height: 26px;
    margin: 0 auto;
    max-width: 360px;
    overflow: hidden;
    padding: 0 20px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: auto;

    span {
      font-weight: bold;
    }
  }

  @include c('roles-actions') {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    justify-content: flex-end;
  }

  @include c('action') {
    margin-right: 5px;
    position: relative;

    &:last-child {
      margin-right: 7px;
    }
  }

  @include c('actions') {
    display: flex;
  }

  @include c('tag-item') {
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  @include c('left-side-container') {
    align-items: center;
    display: flex;
    justify-content: center;
    flex: 0 0 55px;
    height: 100%;
    width: 100%;
  }

  @include c('batch-tag-icon') {
    @include icon-default;

    display: flex;
    position: relative;
    border-radius: 14px;
    padding-left: 5px;
    width: 42px;

    svg {
      height: 25px;
      width: 16px;
      position: absolute;
      top: 2px;
      left: 6px;
    }

    &:hover {
      border-radius: 14px;

      @include c('caret-down') {
        border-top: 4px solid var(--neutral-100);
      }
    }
  }

  @include c('caret-down') {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid var(--cool-neutral-700);
    height: 0;
    width: 0;
  }

  @include c('batch-dropdown-caret') {
    position: absolute;
    right: 7px;
    top: 13px;
  }

  @include c('batch-actions-dropdown') {
    background-color: var(--neutral-100);
    border-radius: 6px;
    box-shadow: 0 2px 4px 0 var(--cool-neutral-700);
    position: absolute;
    z-index: 1;
    border: 1px solid var(--cool-neutral-700);
    min-width: 200px;
    overflow: hidden;
    right: 0;
    top: 34px;
    font-size: 14px;
  }

  @include c('batch-dropdown-item') {
    cursor: pointer;
    padding: 15px 25px;
    line-height: 1em;

    &:hover {
      background-color: var(--cool-neutral-300);
    }
  }

  @include c('action-icon') {
    @include icon-default;

    display: flex;
    align-items: center;
    justify-content: center;

    &--disabled {
      cursor: not-allowed;
    }

    &--gold {
      &:hover {
        background-color: #f0c908;
      }
    }

    &--goldOpen {
      background-color: #f0c908;

      path {
        fill: var(--neutral-100);
      }

      rect {
        fill: var(--neutral-100);
      }
    }
  }

  @include c('plus-icon') {
    @include icon-default;

    border-radius: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 3px 0 8px;

    &:hover {
      border-radius: 50px;

      @include c('entity-icon') {
        #team-icon-path {
          stroke: var(--secondary-500);
        }
      }
    }

    @include c('entity-icon') {
      width: 22px;
      height: 22px;

      #team-icon-path {
        stroke: var(--cool-neutral-200);
      }
    }
  }

  @include c('search-container') {
    margin: 0 auto;
    margin-bottom: 15px;
    max-width: 450px;
    padding: 0 6px;
  }

  @include c('roles-list-container') {
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;
  }

  @include c('filters-tab-list') {
    border-bottom: 1px solid var(--neutral-300);
    display: flex;
    flex-direction: row;
    margin: 0 28px 0 20px;
    position: relative;

    @include c('collaboration-filters-tab') {
      color: var(--neutral-700);
      font-weight: 500;
      height: 25px;
      text-align: center;
      position: relative;
      cursor: pointer;
      padding: 0 25px;

      &:hover {
        color: var(--secondary-500);
        border-bottom: 2px solid var(--secondary-500);
      }
    }

    @include c('collaboration-filters-tab--selected') {
      color: var(--secondary-500);
      border-bottom: 2px solid var(--secondary-500);
    }
  }

  @include c('batch-actions-container') {
    height: 38px;
  }

  @include c('no-results') {
    color: var(--cool-neutral-700);
    font-size: 12px;
    font-weight: 500;
    line-height: 1.3;
    margin-top: 15px;
    text-align: center;
  }

  @include c('batch-actions') {
    padding-left: 18px;
    padding-top: 8px;

    input {
      cursor: pointer;
      margin: 2px 6px;
      height: 11px;

      &:hover {
        box-shadow: 0 3px 4px rgba(0, 0, 0, 0.2);
      }
    }

    label {
      color: var(--neutral-900);
      cursor: pointer;
      font-weight: 300;
      font-size: 16px;
      padding-left: 4px;
      line-height: 22px;
    }
  }

  @include c('deselect') {
    background-color: var(--neutral-150);
    border: 1px solid var(--neutral-300);
    border-radius: 3px;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    height: 24px;
    width: 104px;
  }

  @include c('list-item') {
    display: flex;
    flex-flow: row nowrap;
    height: 100%;
    padding-right: 28px;
    vertical-align: middle;

    input {
      cursor: pointer;

      &:hover {
        box-shadow: 0 3px 4px rgba(0, 0, 0, 0.2);
      }
    }

    &:hover {
      background-color: var(--cool-neutral-100);
    }

    &--selected {
      background-color: var(--cool-neutral-300);

      &:hover {
        background-color: var(--cool-neutral-300);
      }
    }

    &--removeLeftSideContainer {
      padding-left: 20px;
    }
  }

  @include c('check-container') {
    border-radius: 100%;
    cursor: pointer;
    height: 55px;
    width: 55px;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  @include c('list-item-draggable') {
    border: 1px solid transparent;
    border-bottom: solid 1px var(--neutral-300);
    cursor: pointer;
    display: flex;
    flex: 1;
    flex-flow: row nowrap;
    overflow-x: auto;
  }

  @include c('loading-holder') {
    display: flex;
    justify-content: center;
  }

  @include c('bookmark-icon') {
    height: 13px;
    margin-right: 5px;
    width: 12px;
  }

  @include c('right-container') {
    border: 0;
    display: flex;
    opacity: 0;
    transition: width 150ms, opacity 150ms;
    width: 0;

    &--open {
      background-color: var(--cool-neutral-200);
      border-left: 3px solid var(--neutral-400);
      display: flex;
      flex: 0 0 375px;
      flex-flow: column nowrap;
      opacity: 1;
      overflow: auto;
      overflow-x: hidden;
      transition: width 150ms, opacity 250ms;

      @media only screen and (max-width: $break-medium) {
        flex: 1 0 auto;
        opacity: 1;
        transition: opacity 250ms;
        width: auto;
      }
    }
  }

  @include c('scrolling-list-container') {
    position: relative;
    flex: 1;
  }
}
