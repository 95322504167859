@import '../../base';

@include b('PatientBroadcastForm') {
  height: 100%;
  width: 100%;
  position: relative;

  @include c('breadcrumbs') {
    font-size: 12px;
    padding: 10px;
    background-color: var(--neutral-150);

    @include c('parent') {
      cursor: pointer;
      margin-right: 10px;

      &:hover {
        background-color: var(--cool-neutral-300);
      }
    }

    @include c('child') {
      font-weight: 600;
      margin-left: 5px;
    }
  }

  @include c('form') {
    background: var(--neutral-100);
    border-top: solid 0.5px var(--neutral-400);
    display: flex;
    flex-direction: column;
    height: calc(100% - 35px);
    padding: 30px 70px 20px 30px;
    width: 100%;

    @include c('buttons-and-search') {
      align-items: center;
      display: flex;
      flex-shrink: 0;
      margin-bottom: 30px;

      @include c('add-members-button') {
        background-color: var(--patient-network-700);
        border: solid 1px var(--patient-network-700);
        border-radius: 6px;
        color: var(--neutral-100);
        font-size: 13px;
        font-weight: 600;
        line-height: 28px;
        margin-right: 10px;
        width: 112px;

        &:disabled {
          cursor: not-allowed;
          background-color: var(--patient-network-600);
          border: solid 1px var(--patient-network-600);
          color: var(--neutral-100);
        }

        &:hover:enabled {
          background-color: var(--patient-network-600);
          border: solid 1px var(--patient-network-600);
          color: var(--neutral-100);
        }
      }

      @include c('provider-add-members-button') {
        background-color: var(--secondary-700);
        border-radius: 6px;
        color: var(--neutral-100);
        font-size: 13px;
        font-weight: 600;
        line-height: 30px;
        margin-right: 10px;
        width: 112px;

        &:disabled {
          cursor: not-allowed;
          background-color: var(--neutral-400);
        }

        &:hover:enabled {
          background-color: var(--secondary-900);
        }
      }

      @include c('refresh-button-container') {
        display: inline-flex;
        position: relative;
        left: -2px;
      }

      @include c('share-button-container') {
        display: inline-flex;
        position: relative;
        left: 8px;

        @include c('button-icon-share') {
          border-radius: 50%;
          fill: var(--secondary-700);
          height: 30px;
          width: 30px;
        }

        &:hover {
          background-color: var(--secondary-700);

          @include c('button-icon-share') {
            background-color: var(--secondary-700);
            border-radius: 50%;

            path {
              fill: var(--neutral-100);
              stroke: var(--neutral-100);
            }
          }
        }
      }

      &:hover {
        @include c('share-button-container') {
          border-radius: 50%;
        }
      }

      @include c('search-bar') {
        margin-top: 4px;
      }

      @include c('provider-delete-list-button') {
        border-radius: 6px;
        border: solid 1px var(--secondary-700);
        color: var(--secondary-700);
        font-size: 13px;
        font-weight: 600;
        line-height: 28px;
        margin-right: 20px;
        width: 112px;

        &:hover:enabled {
          background-color: var(--errors-500);
          color: var(--neutral-100);
          border: solid 1px var(--errors-500);
        }

        &:disabled {
          cursor: not-allowed;
          border: solid 1px var(--neutral-400);
          color: var(--neutral-400);
        }
      }

      @include c('delete-list-button') {
        border-radius: 6px;
        border: solid 1px var(--patient-network-700);
        color: var(--patient-network-700);
        font-size: 13px;
        font-weight: 600;
        line-height: 28px;
        width: 112px;

        &:hover {
          background-color: var(--errors-500);
          color: var(--neutral-100);
          border: solid 1px var(--errors-500);
        }
      }
    }

    @include c('actions-right-button') {
      align-items: center;
      display: flex;
      margin-left: auto;
      margin-right: 6px;
      height: 22px;
      width: 75px;
      border-radius: 15px;

      @include c('delete-icon') {
        vertical-align: sub;
        width: 20px;
      }

      @include c('delete-icon-text') {
        color: var(--patient-network-700);
        font-size: 13px;
        font-weight: 600;
        height: 10px;
      }

      &:hover {
        background-color: var(--errors-50);

        @include c('delete-icon') {
          path {
            fill: var(--errors-500);
          }
        }

        @include c('delete-icon-text') {
          color: var(--errors-500);
          fill: var(--errors-500);
        }
      }
    }
  }

  @include c('loading') {
    margin: 10px;
    text-align: center;
    visibility: hidden;

    &--isLoading {
      visibility: visible;
    }
  }
}
