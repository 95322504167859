@import '../../base';

@mixin flex-to-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

@mixin text-area-button {
  @include flex-to-center;

  border-radius: 300px;
  cursor: pointer;
  height: 18px;
  margin-right: 2px;
  width: 47px;
}

@include b('CollaborationEntityEditor') {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 14px;
  background-color: var(--cool-neutral-200);
  min-height: auto;

  @include c('cards-container') {
    display: flex;
    flex-direction: column;
    width: 366px;
  }

  @include c('card-container') {
    width: 100%;

    p {
      font-size: 13px;
      line-height: 16px;
      padding: 7px 13px 8px;
    }
  }

  @include c('editor-header') {
    min-height: auto;
    width: 100%;
    box-shadow: 0 1px 1px 0 var(--neutral-500);
    margin-bottom: 5px;
    background-color: var(--neutral-100);

    &--isNewRole {
      box-shadow: 0 4px 3px 0 rgba(0, 0, 0, 0.08);
      border-radius: 3px;
    }
  }

  @include c('delete-button') {
    @include common-button(#db524b, 111, 32, 11px);

    align-self: center;
    border: none;
    border-radius: 3.5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.18);
    margin: 10px 0;
    height: 32px;
    text-transform: none;
    width: 111px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @include c('cancel-button') {
    @include text-area-button;

    background-color: var(--neutral-300);
    border-radius: 300px;
    color: var(--cool-neutral-700);
  }

  @include c('save-button') {
    @include text-area-button;

    background-color: var(--secondary-500);
    color: var(--neutral-100);
  }

  @include c('loading-overlay-container') {
    background-color: var(--cool-neutral-200);
    bottom: 0;
    left: 0;
    opacity: 0.92;
    position: absolute;
    right: 0;
    top: 0;
  }

  @include c('loading-container') {
    left: 0;
    position: absolute;
    right: 0;
    top: 45%;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  @include c('sub-container') {
    background-color: var(--neutral-100);
    border-radius: 3px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
    margin: 3px;
    width: 360px;
    min-height: auto;

    &--isNewTeam {
      border: 0;
      box-shadow: none;
      flex: 1 1 auto;
      width: 100%;
      margin: 3px 0;
      padding: 8px 7px;
    }

    p {
      font-size: 13px;
      line-height: 16px;
      padding: 15px 20px 0;
    }

    @include c('title') {
      border-bottom: solid 1px var(--neutral-300);
      font-size: 12px;
      font-weight: bold;
      padding: 8px 7px;
      text-transform: uppercase;
    }
  }

  @include c('sub-container-content') {
    padding: 25px 30px;
  }

  @include c('title-text-area-container') {
    @include flex-to-center;

    font-size: 10px;
    padding-right: 3px;
    height: 15px;

    @include c('title-text-area') {
      flex: 1;
      font-size: 12px;
      font-weight: bold;
      padding: 9px 7px;
      text-transform: uppercase;
    }
  }

  @include c('text-area-button-container') {
    @include flex-to-center;
  }

  @include c('text-area-editor-container') {
    margin: 10px;
  }

  @include c('role-response-text') {
    background: none;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font: inherit;
    height: 72px;
    line-height: 1.2em;
    margin: 0;
    margin-top: 2px;
    outline: none;
    padding: 7px 5px;
    resize: vertical;
    width: 100%;
  }

  @include c('role-name-attached-container') {
    background: var(--neutral-150);
    margin: 0;
    padding-bottom: 8px;
    padding-left: 13px;
    padding-right: 15px;
    width: 100%;
    min-height: auto;
    border-top: 1px solid var(--neutral-300);

    @include c('title-text-area-container') {
      height: auto;
      border: 0;
      margin: 0;
      padding: 0;
      padding-top: 5px;
    }

    @include c('title-text-area') {
      margin: 0;
      padding: 0;
      padding-left: 7px;
      text-transform: none;
    }
  }

  @include c('auto-response-editor-container') {
    padding-bottom: 5px;
    padding-left: 1px;
    padding-top: 5px;
  }

  @include c('text-area') {
    border: none;
    border-radius: 6px;
    font: inherit;
    height: 80px;
    line-height: 1.2em;
    outline: none;
    padding: 7px 5px;
    resize: vertical;
    width: 100%;

    &:focus {
      background: var(--neutral-100);
      border: none;
    }

    &--empty {
      font-size: 13px;

      &::placeholder {
        color: var(--neutral-900);
        line-height: 65px;
        text-align: center;
      }
    }

    &--admin {
      cursor: pointer;

      &:hover {
        background-color: var(--cool-neutral-200);
      }
    }
  }

  @include c('switch-block') {
    padding: 0 8px 12px 13px;

    @include c('switch-item') {
      align-content: center;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-top: 8px;

      &--rolesHandoffIconIsDisplayed {
        align-items: center;
        cursor: not-allowed;
      }

      span {
        flex: 1;
        line-height: 18px;
      }
    }
  }

  @include c('esc-on-text') {
    color: #04a74d;
  }

  @include c('esc-on-container') {
    border-top: 1px solid var(--neutral-300);
    padding: 20px 13px;
    padding-bottom: 10px;

    @include c('esc-on-title') {
      color: var(--neutral-900);
      font-size: 12px;
      font-weight: bold;
    }

    @include c('esc-on-row') {
      display: flex;
      margin: 20px 0;

      @include c('esc-on-header') {
        color: var(--neutral-700);
        font-size: 10px;
        height: 10px;
        margin-bottom: 8px;
        text-transform: uppercase;
        white-space: nowrap;
      }

      @include c('esc-on-target') {
        width: 200px;

        @include c('esc-on-body') {
          font-size: 12px;
        }
      }
    }
  }

  @include c('always-escalate-on-container') {
    border-top: 1px solid var(--neutral-300);
    padding: 15px 13px 7px;

    @include c('always-escalate-on') {
      align-items: center;
      color: var(--neutral-900);
      display: flex;
      font-size: 12px;

      @include c('always-escalate-on-icon') {
        margin-right: 10px;
      }

      @include c('always-escalate-text') {
        font-style: oblique;
        font-weight: bold;
      }
    }
  }

  @include c('tag-editor') {
    border-radius: 10px;
    height: 34px;
    padding: 8px 10px;
    text-align: center;

    &--admin {
      cursor: pointer;

      &:hover {
        background: var(--cool-neutral-200);
      }
    }
  }

  @include c('tag-empty') {
    border-radius: 10px;
    height: 34px;
    text-align: center;
    margin: 0;
    padding: 4px 0;
  }

  @include c('tag-editor-container') {
    padding: 10px;
  }

  @include c('pager-number-container') {
    flex: 1;

    span {
      border-radius: 10px;
      font-weight: 600;
    }
  }

  @include c('empty-on-duty') {
    @include flex-to-center;

    color: var(--primary-300);
    display: flex;
    height: 60px;
  }

  @include c('on-duty-options') {
    @include flex-to-center;

    flex-direction: row;
    height: 60px;
    position: relative;
  }

  @include c('on-duty-progress') {
    height: 3px;
    left: 0;
    margin: -8px -6px 0;
    position: absolute;
    right: 0;
    top: -11px;
  }

  @include c('loading-on-duty') {
    color: #04a74d;
    flex: 1 0 auto;
    margin-left: 10px;
  }

  @include c('owner-container') {
    flex: 1 0 auto;

    &--admin {
      cursor: pointer;

      &:hover {
        background: var(--cool-neutral-200);
        border-radius: 6px;
      }
    }
  }

  @include c('owner') {
    display: flex;
    height: 60px;
    margin: 0 15px;
  }

  @include c('progress-bar') {
    appearance: none;
    background: none;
    border: none;
    border-radius: 0 5px 5px 0;
    height: inherit;
    width: 100%;

    &::-webkit-progress-bar {
      background: none;
    }

    &::-webkit-progress-value {
      background: var(--secondary-500);
      transition: width 60ms ease-in-out;
    }

    &::-moz-progress-bar {
      background: var(--secondary-500);
    }

    &::-ms-fill {
      background: var(--secondary-500);
      border: none;
    }
  }

  @include c('leave-button') {
    color: #058cff;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    cursor: pointer;

    &:hover {
      background-color: #f0f3f6;
    }
  }
}
