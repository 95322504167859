@import '../../base';

@include b('CollaborationRolesSchedulerModal') {
  text-align: left;

  label {
    color: var(--neutral-1000);
    font-size: 12px;
    font-weight: 300;
    line-height: 25px;
    opacity: 0.5;
  }

  select {
    background-color: var(--neutral-150);
    border: solid 1px var(--neutral-300);
    border-radius: 6px;
    color: var(--neutral-900);
    font-size: 12px;
    font-weight: 500;
    padding: 10px;
    position: relative;
    width: 100%;
  }

  a {
    color: var(--secondary-500);
    text-decoration: none;
  }

  @include c('error') {
    background-color: var(--errors-300);
    border: solid 1px var(--errors-500);
    border-radius: 6px;
    color: var(--neutral-100);
    font-size: 12px;
    margin: 0 auto;
    padding: 7px;
    text-align: center;
    margin-top: 20px;
  }

  @include c('fail-icon') {
    font-family: VAGRoundedBold, VAGRounded, sans-serif;
    font-size: 26px;
    font-weight: bold;
  }

  @include c('schedule-file') {
    background-color: var(--neutral-150);
    border: solid 1px var(--neutral-300);
    border-radius: 6px;
    color: var(--neutral-900);
    font-size: 12px;
    font-weight: 500;
    margin: 0 0 10px;
    padding: 10px;
    position: relative;
  }

  @include c('schedule-file-change') {
    color: var(--secondary-500);
    cursor: pointer;
    font-size: 12px;
    line-height: 14px;
    position: absolute;
    right: 10px;
    top: calc(50% - 6px);

    &:hover {
      color: var(--secondary-900);
    }
  }

  @include c('schedule-integration') {
    position: relative;
  }

  @include c('schedule-response') {
    text-align: center;
  }

  @include c('schedule-response-details') {
    margin-top: 24px;

    b {
      color: var(--neutral-900);
      font-size: 14px;
      font-weight: 500;
      display: block;
      margin-bottom: 5px;
    }

    code {
      background-color: var(--neutral-150);
      border: solid 1px var(--neutral-300);
      border-radius: 6px;
      display: block;
      font-family: system, Arial, sans-serif;
      font-size: 14px;
      height: 95px;
      line-height: calc(22px);
      overflow-y: scroll;
      padding: 5px 10px 10px;
      width: 100%;

      b {
        display: inline-block;
        margin: 0;
        width: 65px;
      }

      &::-webkit-scrollbar {
        appearance: none;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.5);
        border: 2px solid var(--neutral-150);
        border-radius: 8px;
      }

      &::-webkit-scrollbar-track {
        background-color: var(--neutral-100);
        border-radius: 8px;
      }

      &::-webkit-scrollbar:horizontal {
        height: 11px;
      }

      &::-webkit-scrollbar:vertical {
        width: 11px;
      }
    }
  }

  @include c('schedule-response-download-details') {
    color: var(--secondary-500);
    cursor: pointer;
    font-size: 14px;
    margin-top: -20px;
    position: absolute;
    right: 32px;
  }

  @include c('schedule-response-filename') {
    color: var(--neutral-900);
    font-size: 14px;
    margin-top: 19px;

    b {
      color: var(--neutral-900);
      font-size: 14px;
      font-weight: 500;
    }
  }

  @include c('schedule-response-reason') {
    margin-top: 11px;

    b {
      color: var(--neutral-900);
      font-size: 14px;
      font-weight: 500;
    }
  }

  @include c('schedule-response-stats') {
    font-size: 14px;
    line-height: calc(18px);
    margin-top: 11px;

    b {
      color: var(--neutral-900);
      font-size: 14px;
      font-weight: 500;
    }
  }

  @include c('schedule-response-timestamp-failed') {
    color: var(--primary-300);
    font-size: 14px;
    margin-top: 13px;

    b {
      color: var(--neutral-900);
      font-size: 14px;
      font-weight: 500;
    }
  }

  @include c('schedule-response-timestamp-successful') {
    color: #04a74d;
    font-size: 14px;
    margin-top: 13px;
  }

  @include c('schedule-response-view-details') {
    color: var(--secondary-500);
    cursor: pointer;
    font-size: 14px;
    margin-top: 20px;
  }

  @include c('schedule-timezone') {
    margin: 21px 0;
    position: relative;
  }

  @include c('timezone-label') {
    position: absolute;
    top: 7px;
  }

  @include c('schedule-timezone-select') {
    position: absolute;
    right: 0;
    width: 200px;
  }

  @include c('upload-button-input') {
    display: none;
  }
}
