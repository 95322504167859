@import '../../base';
@import '../../DayPickerGradients';

@include b('ScheduleInputs') {
  .SingleDatePicker {
    width: 200px;
    margin-right: 12px;

    .DateInput_input {
      width: 200px;
      height: 30px;
      border: solid 1px var(--neutral-400);
      border-radius: 6px;
      padding: 1px 10px;
      font-size: 13px;
      color: var(--neutral-1000);
      font-weight: 500;

      &::placeholder {
        color: var(--cool-neutral-800);
        font-weight: normal;
      }

      &__focused {
        box-shadow: 0 0 0 3px rgba(5, 140, 255, 0.25);
      }
    }

    .SingleDatePickerInput_calendarIcon {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      padding: 4px 10px;
    }

    .SingleDatePicker_picker {
      background-color: var(--neutral-100);
      top: 36px !important;
      left: 1px !important;
    }

    .CalendarDay {
      display: table-cell;
      vertical-align: middle;

      &:hover {
        @include gradient-day-hover;
      }
    }

    .CalendarDay__default {
      background: var(--neutral-100);
      border: 1px solid var(--neutral-200);
      color: var(--neutral-950);

      &:hover {
        border: 1px solid var(--neutral-200);
      }
    }

    .CalendarDay__blocked_out_of_range {
      background: var(--neutral-100);
      border: 1px solid var(--neutral-200);
      color: var(--neutral-400);

      &:hover {
        border: 1px solid var(--neutral-100);
      }
    }

    .CalendarDay__selected,
    .CalendarDay__selected:active {
      border: 1px solid var(--cool-neutral-100);
      color: var(--neutral-100);
      display: table-cell;
      vertical-align: middle;

      @include gradient-selected-day-hover;

      &:hover {
        border: 1px solid var(--cool-neutral-100);

        @include gradient-selected-day-hover;
      }
    }

    @include c('chevron-icon') {
      &--active {
        transform: rotate(180deg);
      }
    }
  }

  .tc-ContextMenu--active {
    box-shadow: 0 0 0 3px rgba(5, 140, 255, 0.25);
  }

  @include c('timezone-menu-btn') {
    @include schedule-message-context-menu-btn(76px);

    margin-right: 12px;

    &:disabled {
      @include schedule-message-context-menu-btn(45px);
    }
  }

  @include c('repeat-menu-btn') {
    @include schedule-message-context-menu-btn(200px);

    margin-right: 12px;
  }

  @include c('schedule-row') {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  @include c('schedule-option-btn') {
    height: 30px;
    border: solid 1px var(--cool-neutral-700);
    padding: 6px 10px;
    font-size: 13px;
    font-weight: 600;
    border-radius: 6px;
    color: var(--neutral-1000);

    &:hover {
      background-color: var(--cool-neutral-700);
      color: var(--neutral-100);
    }
  }

  @include c('or-text') {
    display: inline-block;
    margin: 0 12px;
    font-size: 13px;
    color: var(--cool-neutral-800);
  }

  @include c('send-now-text') {
    display: inline-block;
    margin: 8px 10px 0 0;
    font-size: 16px;
    font-weight: 600;
    color: var(--neutral-1000);
  }

  @include c('until-text') {
    margin: 0 6px 0 4px;
    font-size: 12px;
    font-weight: normal;
    color: var(--neutral-950);
  }

  @include c('reset-schedule-btn') {
    display: inline-flex;
    border-radius: 50%;
    border: none;
    width: 22px;
    height: 22px;
    justify-content: center;
    background-color: var(--cool-neutral-100);
    align-items: center;

    &:hover {
      background-color: var(--neutral-400);

      svg {
        fill: var(--neutral-100);
      }
    }

    svg {
      fill: var(--cool-neutral-700);
    }

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
    }
  }
}
