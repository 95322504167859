@include b('InputCounter') {
  @include c('input-container') {
    width: 100%;
    border: solid 1px var(--neutral-400);
    height: 30px;
    border-radius: 6px;
    padding: 6px 10px;
    font-size: 13px;

    &--type {
      &--location {
        width: 275px;
      }

      &--provider {
        width: 250px;
      }
    }
  }

  @include c('character-limit-count') {
    color: var(--neutral-700);
    font-size: 10px;
    font-weight: 500;
    margin-top: 4px;
    max-width: 697px;
    text-align: right;
  }
}
