@import '../base';

@include b('UndoComponent') {
  @include c('undo-container') {
    position: absolute;
    height: 63px;
    left: 0;
    right: 0;
    bottom: -63px;
    width: 100%;
    visibility: hidden;
    transition: visibility 200ms, bottom 200ms;

    &--enable {
      bottom: 0;
      visibility: visible;
    }
  }
}
