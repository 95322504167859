@import '../../base';

@include b('ProfileMenu') {
  flex: 0 0 200px;
  padding: 35px 0 0 35px;

  @include c('item-list') {
    display: flex;
    flex-direction: column;
  }

  @include c('item') {
    font-size: 10px;
    font-weight: 600;
    color: var(--neutral-900);
    height: 27px;
    letter-spacing: 0.6px;

    span {
      cursor: pointer;

      &:hover {
        color: var(--neutral-700);
      }
    }
  }
}
