@import '../base';

@include b('CreateOrEditCustomDirectoriesModal') {
  @include c('header') {
    @include common-modal-header;

    color: var(--secondary-500);
  }

  @include c('close-button') {
    @include common-modal-close-button;

    color: var(--cool-neutral-800);
  }

  @include c('body') {
    @include common-modal-body;

    line-height: 1.4;
    padding: 15px 30px;
    text-align: center;
  }

  @include c('label') {
    margin: 10px;
  }

  @include c('checkbox-container') {
    display: flex;
    flex-direction: column;

    @include c('checkbox') {
      margin-left: 10px;
    }
  }

  @include c('custom-directory-item') {
    align-items: center;
    display: flex;
    margin-top: 7px;
    margin-bottom: 15px;

    @include c('custom-directory-label') {
      margin-left: 10px;
    }
  }

  @include c('delete-icon') {
    background:
      url('../../../admin/images/sprite_icons.png')
      no-repeat left center;
    background-position: -27px -964px;
    cursor: pointer;
    display: flex;
    height: 35px;
    margin-top: -8px;
    padding: 7px 25px;
  }

  @include c('save-btn-container') {
    display: flex;
    float: right;
    font-weight: bold;
    margin: 20px 30px 20px 10px;

    @include c('save-btn') {
      @include common-button(var(--secondary-500), 200px, 32px, 11px);

      font-size: 15px;
      padding: 10px;

      &--isLoading {
        cursor: default;
        pointer-events: none;
      }
    }
  }

  @include c('flex-container') {
    display: flex;
    margin-left: 17px;
  }

  @include c('info') {
    display: flex;
    font-weight: bold;
    margin-left: 20px;
    margin-bottom: 10px;
    height: 500px;
    overflow-y: auto;
  }

  @include c('label') {
    span {
      font-weight: bold;
    }
  }
}
