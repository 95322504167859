@import '../../base';

@include b('PatientSettings') {
  background-color: var(--neutral-100);
  display: flex;
  flex-flow: row;
  height: 100%;
  width: 100%;
  position: relative;

  @include c('nav') {
    background-color: var(--cool-neutral-200);
    border-right: solid 1px var(--neutral-400);
    width: 240px;

    @include c('nav-title') {
      color: var(--neutral-1000);
      font-size: 14px;
      font-weight: bold;
      margin: 15px 15px 28px;
    }

    @include c('nav-item') {
      color: var(--neutral-1000);
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: 500;
      padding: 6px 15px;
      width: 100%;
      text-align: start;

      &:focus-visible {
        outline: 3px solid var(--secondary-500);
      }

      &--vwr {
        margin-top: 11px;
      }

      &:hover,
      &--active {
        background-color: var(--patient-network-600);
        color: var(--neutral-100);
      }

      &:hover {
        @include c('nav-item-dropdown') {
          background: var(--neutral-100);

          svg {
            path {
              fill: var(--neutral-100);
            }
          }
        }
      }

      &--active {
        font-weight: 500;
        background-color: var(--patient-network-700);
      }

      &--menu {
        padding-left: 49px;
        display: none;
      }

      &--scheduledMessagesExpanded {
        display: block;
      }

      &--templatesExpanded {
        display: block;
      }

      @include c('nav-item-icon') {
        border-radius: 5px;
        height: 24px;
        width: 24px;
      }

      @include c('nav-item-dropdown') {
        height: 22px;
        width: 22px;
        border-radius: 100%;
        margin-left: auto;
        margin-right: 0;

        g[stroke='#4A657B'] {
          stroke: var(--patient-network-700);
        }

        &--expanded {
          transform: scaleY(-1);
        }
      }

      svg {
        margin-right: 10px;

        rect[fill='#FFF'],
        rect[fill='#fff'],
        circle[fill='#fff'],
        path[fill='#fff'] {
          fill: var(--neutral-100);
        }

        g[stroke='#35333C'],
        path[stroke='#35333C'],
        rect[stroke='#35333C'],
        circle[stroke='#35333C'] {
          stroke: var(--neutral-1000);
        }

        g[fill='#35333C'],
        path[fill='#35333C'],
        rect[fill='#35333C'],
        circle[fill='#35333C'] {
          fill: var(--neutral-1000);
        }
      }
    }
  }

  @include c('body') {
    flex: 5;
    height: 100%;
    width: 100%;
  }
}
