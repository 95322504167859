@import '../../base';

@include b('PatientAdminCardDetails') {
  color: var(--neutral-1000);

  @include m('smsOptedOut') {
    color: var(--opt-out-100);
  }

  @include c('option-button-container') {
    display: flex;
    justify-content: center;
    margin-left: -15px;
    margin-top: 4px;
    position: absolute;
    width: 100%;

    @include if-ie {
      margin: 0;
      display: flex;
      justify-content: center;
      height: inherit;
      align-items: center;
      width: 100%;
    }

    @include c('button-label') {
      font-size: 14px;
      text-align: left;
    }
  }

  @include c('option-button-about') {
    align-items: center;
    background-color: var(--patient-network-600);
    border: 1px solid var(--scheduling-primary-700);
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    color: var(--neutral-100);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    height: 33px;
    justify-content: space-around;
    padding-right: 10px;
    width: 120px;

    @include c('patient-data-icon') {
      height: 14px;
      width: 12px;
      margin-left: 5px;
      margin-bottom: 2px;

      path {
        fill: var(--neutral-100);
      }
    }

    @include c('about-button-icon') {
      display: flex;
      font-style: normal;
      height: 13px;
      margin-left: 6px;
      width: 20px;

      path {
        stroke: var(--neutral-100);
      }
    }

    &:hover {
      @include c('about-button-icon') {
        path {
          stroke: var(--patient-network-700);
        }
      }

      @include c('patient-data-icon') {
        path {
          fill: var(--patient-network-700);
        }

        cursor: pointer;
      }

      background-color: var(--neutral-100);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      color: var(--patient-network-700);
      cursor: pointer;
      font-style: normal;
    }

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
      z-index: 1;
    }
  }

  @include c('option-button-delete') {
    align-items: center;
    background-color: var(--patient-network-600);
    border-bottom-right-radius: 3px;
    border-bottom: 1px solid var(--scheduling-primary-700);
    border-right: 1px solid var(--scheduling-primary-700);
    border-top-right-radius: 3px;
    border-top: 1px solid var(--scheduling-primary-700);
    color: var(--neutral-100);
    display: flex;
    flex-direction: row;
    font-size: 14px;
    height: 33px;
    justify-content: space-around;
    padding-right: 10px;
    width: 77px;

    &--allowAutomatedAppointmentReminders {
      border-bottom-right-radius: 0;
      border-right: none;
      border-top-right-radius: 0;
    }

    @include c('delete-button-icon') {
      display: flex;
      font-style: normal;
      height: 13px;
      margin-bottom: 2px;
      margin-left: 6px;
      width: 13px;
      margin-right: 5px;

      path {
        stroke: var(--neutral-100);
      }
    }

    &:hover {
      @include c('delete-button-icon') {
        path {
          stroke: var(--patient-network-700);
        }
      }

      background-color: var(--neutral-100);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      font-style: normal;
      color: var(--patient-network-700);
      cursor: pointer;
    }

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
      z-index: 1;
    }
  }

  @include c('option-button') {
    align-items: center;
    background-color: var(--patient-network-600);
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    border: 1px solid var(--scheduling-primary-700);
    color: var(--neutral-100);
    display: flex;
    flex-direction: row;
    font-size: 14px;
    height: 33px;
    justify-content: space-around;
    padding-right: 10px;
    width: 77px;

    @include c('edit-button-icon') {
      display: flex;
      font-style: normal;
      height: 19px;
      margin-left: 2px;
      margin-right: -15px;
      margin-top: 7px;
      width: 18px;

      path {
        fill: var(--neutral-100);
      }
    }

    &:hover {
      @include c('edit-button-icon') {
        path {
          fill: var(--patient-network-700);
        }
      }

      background-color: var(--neutral-100);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      font-style: normal;
      color: var(--patient-network-700);
      cursor: pointer;
    }

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
      z-index: 1;
    }
  }

  @include c('patient-row') {
    display: flex;
    height: 37px;
    margin-top: 9px;
    position: relative;

    &--smsOptedOut {
      height: 47px;
    }
  }

  @include c('patient-row-left') {
    display: flex;
    flex: 1;

    @include c('patient-name') {
      min-width: 132px;

      @media only screen and (max-width: 900px) {
        max-width: 70px;
        min-width: 70px;
      }

      @media only screen and (max-width: 1050px) and (-ms-high-contrast: none),
        screen and (max-width: 1050px) and (-ms-high-contrast: active) {
        max-width: 70px;
        min-width: 70px;
      }
    }
  }

  @include c('patient-row-right') {
    display: flex;
    text-align: right;
    margin-right: 12px;

    @include c('patient-name-width') {
      margin-right: -36px;
    }
  }

  @include c('title') {
    color: var(--neutral-700);
    font-size: 10px;
  }

  @include c('name') {
    @include ellipsis;

    font-size: 16px;
    font-weight: 500;
    max-width: 132px;
  }

  @include c('detail-phone') {
    @include patient-admin-detail;

    min-width: 126px;
  }

  @include c('sms-opted-out-info') {
    color: var(--opt-out-100);
    font-size: 10px;
  }

  @include c('detail-mrn') {
    @include patient-admin-detail;

    display: block;
    max-width: 140px;
    min-width: 140px;
    overflow: hidden;
    padding-left: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @include c('patient-mrn') {
    margin-left: 35px;
  }

  @include c('detail-gender') {
    @include patient-admin-detail;

    min-width: 56px;
  }

  @include c('detail-dob') {
    @include patient-admin-detail;

    min-width: 107px;
  }
}
