@import '../base';

@include b('MessageGroup') {
  display: flex;
  flex-flow: wrap;

  @include c('message-list') {
    flex: 1;

    &--isMultiSelect {
      margin-top: 6px;
    }

    &--precededByMessageGroup {
      margin-top: 0;
    }
  }

  @include c('last-message-status-wrapper') {
    flex: 1 1 100%;
    font-size: 70%;
    display: block;
    margin-top: 3px;
  }

  @include b('MessageStatus') {
    color: $dimmed-text-color;
  }
}
