@import '../base';

@include b('AttachmentPreviews') {
  /* background-color: $attachment-gray; */
  background-color: var(--neutral-200); /* fafafa => F3F6F7 */
  bottom: calc(100%);
  width: 100%;
  padding: 10px;
  padding-bottom: 0;
  border-bottom: solid 1px var(--neutral-300); /* EEEEEE => E6E6E6 */
  display: flex;

  @include c('attachments-container') {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  @include c('attachments-text') {
    color: var(--neutral-700);
    align-self: center;
    font-weight: lighter;
    font-size: 14px;
  }

  @include c('attachment-preview') {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 10px;
    margin-left: 20px;
    min-width: 95px;
  }

  @include c('attachment-preview-container') {
    margin-top: 10px;
    height: 120px;
    position: relative;
  }

  @include c('attachment-preview-overflow-container') {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 120px;
    overflow: scroll hidden;
  }

  @include c('attachments-holder') {
    display: flex;

    @include if-ie {
      overflow: auto;
    }
  }

  @include c('attachment-remove-button-container') {
    align-self: flex-end;
    width: 20px;
    height: 20px;
    background-color: var(--secondary-500); /* 3080df/interaction-signifier => 3C82CD  */
    border-radius: 10px;
    margin-right: -3px;
    margin-top: 2px;

    &:focus-visible {
      outline: auto;
      outline-width: 3px !important;
      outline-color: #fff !important;
      border-radius: 10px;
    }

    @include center-items-both;

    &:hover {
      cursor: pointer;
      transition: 0.3s cubic-bezier(0.22, 1, 0.36, 1);

      @include c('attachment-remove-button') {
        width: 10px;
      }
    }
  }

  @include c('attachment-remove-button') {
    width: 10.5px;
  }

  @include c('attachment-preview-image') {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--secondary-500); /* 3080df/interaction-signifier => 3C82CD  */
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 0 5px 5px;
    border-radius: 5px;
    width: 95px;
    height: 105px;
  }

  @include c('attachment-preview-icon') {
    width: 28px;
    margin-top: 5px;
  }

  @include c('attachment-preview-name') {
    @include ellipsis;

    position: absolute;
    bottom: 5px;
    left: 5px;
    font-size: 10px;
    background-color: var(--neutral-100);
    color: var(--secondary-700); /* 2a6fc2 => 3C82CD */
    border-radius: 3px;
    padding: 2px 5px;
    max-width: 100%;
    align-self: flex-start;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.26);

    &--image {
      margin-top: 63px;
    }
  }
}
