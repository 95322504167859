@import '../../../base';

@include b('WorkflowEventModal') {
  overflow: hidden;

  > div:first-child {
    margin: 0;
  }

  svg {
    [fill='#4A657B'] {
      fill: var(--patient-network-700);
    }
  }

  @include c('body') {
    overflow-y: auto;
    padding-top: 10px;
  }

  @include c('event-form') {
    padding: 0 25px 25px;

    label {
      margin: 10px 0 5px;
    }

    &--activeWorkflow {
      opacity: 0.7;
      pointer-events: none;
    }
  }

  @include c('row') {
    display: flex;
  }

  @include c('attachment-container') {
    display: inline-flex;
    align-items: center;
    height: 28px;
    border: solid 0.5px var(--neutral-400);
    border-radius: 6px;
    padding: 0 10px;
    font-size: 12px;
    color: var(--neutral-1000);

    @include c('file-details-container') {
      display: flex;

      @include c('file-icon') {
        height: 16px;
        margin-right: 10px;
        width: 14px;
      }
    }
  }

  @include c('input') {
    border: 1px solid var(--neutral-400);
    border-radius: 6px;
    font-size: 14px;
    height: 38px;
    text-align: center;
    color: var(--neutral-950);

    &--placeholder {
      color: var(--neutral-700);
    }

    &--offset {
      width: 37px;
    }

    &--offsetUnit {
      width: 70px;
    }

    &--offsetDirection {
      width: 70px;
    }
  }

  @include c('sect') {
    flex: 1;
    margin: 5px;

    label {
      font-size: 14px;
      font-weight: 600;
      color: var(--neutral-1000);
      display: inline-block;
    }
  }

  @include c('schedule-sect') {
    flex: none;
    margin-right: 18px;

    @include c('row') {
      width: 190px;
      display: flex;
      justify-content: space-between;

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        appearance: none;
        margin: 0;
      }

      input[type='number'] {
        appearance: textfield;
      }
    }
  }

  @include c('delivery-option-display') {
    span {
      display: inline;
      color: var(--neutral-400);
    }
  }

  @include c('message-type-dropdown') {
    align-items: center;
    border: solid 1px var(--neutral-400);
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    height: 38px;
    width: 475px;

    @include c('message-kind-dropdown') {
      align-items: center;
      display: flex;
      font-size: 14px;
      justify-content: space-between;
      padding: 0 10px 0 8px;
      width: 100%;
    }

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
    }
  }

  @include c('dropdown-svg') {
    display: flex;
    align-items: center;

    svg {
      margin: 6px 6px 0;
    }
  }

  @include c('complex-select') {
    @include c('message-body') {
      border: solid 1px var(--neutral-400);
      border-radius: 6px;
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      color: var(--neutral-950);
      font-size: 14px;
      font-weight: normal;
      max-height: 200px;
      overflow-y: auto;
      padding: 6px 10px;
      white-space: pre-wrap;
      word-wrap: break-word;
      pointer-events: auto;

      @include c('templateVariable') {
        color: var(--secondary-500);
      }
    }

    button {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 38px;
      border: solid 1px var(--neutral-400);
      border-radius: 6px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      padding: 6px 10px;
      cursor: pointer;
      font-size: 14px;
      font-weight: normal;
      color: var(--neutral-950);

      div {
        display: flex;

        svg {
          margin-right: 5px;
        }
      }
    }

    &--placeholder {
      button {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        color: var(--neutral-700);
      }
    }
  }

  @include c('sender') {
    color: var(--neutral-1000);
    background-color: var(--neutral-100);
    border: 1px solid var(--neutral-400);
    border-radius: 6px;
    cursor: pointer;
    height: 38px;
    padding-right: 10px;

    .Select-control {
      .Select-multi-value-wrapper {
        height: 33px;

        .Select-placeholder {
          padding-left: 10px;
        }

        .Select-value {
          margin-top: 1px;
          background-color: var(--neutral-100);
          border: none;
          border-radius: unset;
          font-size: 14px;
          padding: 0;
        }
      }
    }

    &:focus-within {
      outline: 3px solid var(--secondary-500);
    }
  }

  @include c('footer') {
    @include common-modal-footer;

    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    font-weight: 500;
    justify-content: space-between;
    padding: 15px 32px;
    position: relative;

    @include c('primary-actions') {
      align-items: center;
      display: flex;

      @include c('invalid-message') {
        font-size: 12px;
        color: var(--primary-500);
      }

      @include c('primary-btn') {
        @include button-with-disable(var(--patient-network-700), 100px, 40px, 12px);

        margin-left: 10px;
      }

      @include c('main-btn') {
        @include button-with-disable(var(--patient-network-700), 100px, 40px, 12px);

        background-color: var(--patient-network-700);
        color: var(--neutral-100);
        margin-left: 10px;

        &[disabled] {
          background-color: var(--neutral-100);
        }
      }
    }
  }

  @include c('warning') {
    text-align: center;
    position: relative;
    top: -75px;
    width: 100%;
    background-color: var(--orange-200);
    padding: 5px 10px;
    height: 32px;
    margin-bottom: -32px;
  }

  @include c('delete-btn') {
    @include button-with-disable(var(--errors-500), 100px, 40px, 12px);
  }
}
