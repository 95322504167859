@import '../../base';

@include b('PatientReferenceInfo') {
  @include c('patient-pill-container') {
    min-height: 25px;
    display: flex;
    flex-wrap: wrap;
    border: 0.5px solid var(--neutral-600);
    background: var(--neutral-200);
    border-radius: 4px;
    align-items: center;
    margin-top: 2px;
    margin-bottom: 3px;
    padding: 5px;
    font-size: 12px;
    max-width: 575px;

    @include c('patient-name') {
      @include ellipsis;

      font-weight: 700;
      padding-right: 5px;
    }

    @include c('patient-details') {
      @include ellipsis;

      color: var(--neutral-800);
    }
  }

  @include c('patient-pill-error') {
    border: 1px solid var(--errors-500);
    background: var(--errors-100);
    color: var(--neutral-1000);
    font-weight: 700;
  }

  @include c('patient-discharged') {
    color: var(--primary-300);
  }

  @include c('patient-location') {
    color: var(--neutral-700);
    display: flex;
    margin-top: 7px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }
}
