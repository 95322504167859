@import '../../base';

@include b('Profile') {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--neutral-200);

  @include c('content') {
    display: flex;
    flex-direction: row;
    flex: 1 0 auto;
  }

  @include c('scroll') {
    flex: 1 0 auto;
  }

  @include c('body') {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 400px 250px 0;
  }

  @include c('items') {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
  }

  @include c('force-logout-button') {
    @include common-button(var(--primary-300), 150px, 32px, 14px);

    margin: 50px 0 3px;
  }
}
