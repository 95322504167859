@import '../base';

@include b('LocationForm') {
  @include c('location-name') {
    height: auto;
    padding: 9px 5em 9px 22px;
    color: #35333c;
    font-size: 18px;
  }

  @include c('location-header') {
    @include center-items-both;

    flex-direction: column;

    @include c('location-title-text') {
      color: #070f18;
      display: block;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
    }

    @include c('location-title-subtext') {
      margin: 6px 0 56px;
      max-width: 336px;
      font-size: 12px;
      text-align: center;
      color: $display-text;
    }
  }

  @include c('location-picker-container') {
    display: flex;
    align-items: stretch;
    height: 45px;
    margin-bottom: 13px;
    border: solid 1px $interaction-signifier;
    border-radius: 6px;
    font-size: 18px;

    &:hover {
      background-color: #fbfcfc;
    }

    @include c('location-picker-label') {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      width: 30%;
      border-right: solid 1px $interaction-signifier;
      border-radius: 6px 0 0 6px;
      background-color: #f4f4f4;
    }

    @include c('location-picker') {
      display: flex;
      flex: 2;
      align-items: center;
      cursor: pointer;
      margin: 3px 9px;
      padding: 14px 8px;
    }
  }

  @include c('submit') {
    background-color: #2b70c4;
    border-radius: 4px;
    border: 1px solid #fff;
    box-sizing: border-box;
    color: #fff;
    display: flex;
    font-size: 12px;
    height: 35px;
    justify-content: center;
    margin: 20px 0;
    text-align: center;
    width: 100%;

    &:hover {
      background-color: #2b70c4;
    }
  }

  @include c('button-arrow') {
    > * {
      fill: currentcolor;
      width: 18px;
      height: 12px;
      color: $white;
    }
  }
}
