@import '../../base';

@include b('PatientConversationHeader') {
  width: 100%;
  position: relative;
  flex-wrap: wrap;
  align-items: flex-start;
  display: flex;

  @include m('smsOptedOut') {
    min-height: 105px;
  }

  @include b('AvatarImage') {
    display: block;
    margin-right: 8px;
    width: 52px;
    height: 52px;
  }

  @include c('avatar-container') {
    display: inline-block;
    vertical-align: top;
  }

  @include c('actions-container') {
    align-self: flex-end;
    padding: 2px 5px;
  }

  @include c('patient-detail-card-container') {
    display: flex;
    flex-direction: column;
    vertical-align: top;
    max-width: 340px;
  }

  @include c('conversation-details') {
    position: absolute;
    text-align: right;
    top: 14px;
    right: 0;
    margin-right: 40px;
    transition: margin 100ms;
    margin-left: 10px;

    &--infoPaneOpen {
      margin-right: 0;
    }

    &--hasMemberList {
      top: 8px;
    }

    @include c('expiration-detail') {
      font-size: 12px;
      color: #30a0c4;
    }

    @include c('members-detail') {
      font-size: 10px;
      font-style: italic;
      color: var(--neutral-900);
    }
  }
}
