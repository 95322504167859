@import '../../base';

@include b('ProfileStatus') {
  @include profile-detail-container;

  @include c('header') {
    @include profile-header;
  }

  @include c('dnd') {
    @include profile-detail-info;

    position: relative;
  }

  @include c('dnd-info') {
    display: flex;
    align-items: center;
    width: 100%;

    svg {
      height: 15px;
      width: 15px;
    }

    :focus-visible {
      outline: 3px solid var(--secondary-500);
    }
  }

  @include c('dnd-header') {
    flex: 1;
    font-size: 12px;
    margin-left: 10px;
    font-weight: 600;
    color: var(--neutral-900);
  }

  @include c('dots-container-text') {
    @include center-items-both;

    height: 55px;
    border-bottom: solid 1px var(--neutral-300);
  }

  @include c('status-info') {
    @include profile-detail-info;

    &:hover {
      @include c('input') {
        border-bottom: dotted 1px var(--secondary-500);
      }
    }
  }

  @include c('sub-header') {
    @include profile-sub-header;

    padding-bottom: 3px;
  }

  @include c('form') {
    flex: 1;
    font-size: 14px;
    color: var(--neutral-900);
  }

  @include c('input') {
    @include ellipsis;

    width: 95%;
    padding-bottom: 3px;
    border-bottom: dotted 1px var(--neutral-100);
    color: var(--neutral-900);
    cursor: pointer;

    &:focus {
      background-color: var(--neutral-100);
      border-color: var(--secondary-500);
      outline: none;
      border-radius: 0;
    }

    &--isDirty {
      border-color: var(--secondary-500);
      outline: none;
    }
  }

  @include c('buttons') {
    display: flex;
  }

  @include c('auto-forward-info') {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 25px 20px;
  }

  @include c('auto-forward-header') {
    font-size: 12px;
    font-weight: 600;
    color: var(--neutral-900);
    padding: 10px 0;
  }

  @include c('dots-container') {
    @include center-items-both;

    height: 100%;
  }

  @include c('list') {
    display: flex;
    flex-direction: column;
  }
}
