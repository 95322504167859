@import '../../base';
@import './PatientSettingMixins';

@include b('VirtualWaitingRoom') {
  position: relative;
  height: 100%;
  width: 100%;

  @include c('main-container') {
    display: flex;
    flex-direction: column;
    min-width: 600px;
    width: 100%;
    padding: 20px 48px 0 30px;
  }

  @include c('loading') {
    @include center-items-both;

    height: 100%;
    width: 100%;
    position: absolute;
    background-color: var(--neutral-100);
  }

  @include c('header') {
    margin-bottom: 18px;

    @include flex-align-center;
    @include flex-justify-between;

    @include c('title') {
      @include header-text;
    }

    @include c('create-button') {
      @include flex-align-center;

      background-color: var(--patient-network-700);
      justify-content: center;
      color: var(--neutral-100);
      font-size: 13px;
      width: 183px;
      height: 28px;
      font-weight: 600;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: var(--patient-network-800);
        border-color: var(--patient-network-800);
      }
    }
  }

  @include c('dropdown-container') {
    margin-bottom: 30px;
  }

  @include c('dropdown-selector') {
    width: 217px;
    height: 30px;
    border-radius: 15px;
    border: 0.5px solid var(--neutral-400);
    background-color: var(--neutral-100);
    display: flex;
    align-items: center;
    padding: 3px;

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
      outline-offset: -3px;
    }
  }

  @include c('dropdown-header') {
    height: 23px;
    border-radius: 15px;
    display: flex;
    padding: 0 10px;
    flex: 1;
    cursor: pointer;
    align-items: center;

    &:hover {
      background-color: var(--neutral-300);
    }
  }

  @include c('header-name') {
    @include ellipsis;

    flex: 1;
    font-size: 13px;
    font-weight: 500;
    color: var(--neutral-1000);
    text-align: left;
  }

  @include c('down-arrow-container') {
    margin-top: -2px;
  }

  @include c('down-arrow') {
    fill: $display-text;
    width: 9px;
    height: 5px;
  }

  @include c('dropdown') {
    width: 219px;
    border-radius: 6px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.24);
    background-color: var(--neutral-100);
    padding: 10px 8px;
    position: absolute;
    top: 105px;
    overflow-x: hidden;
  }

  @include c('search') {
    text-align: center;
    margin-bottom: 10px;
  }

  @include c('search-input') {
    border-radius: 18px;
    border: solid 1px var(--neutral-300);
    background-color: var(--neutral-150);
    font-size: 14px;
    padding: 3px 10px;
    outline: none;
    width: 100%;
  }

  @include c('search-no-results') {
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    color: var(--neutral-800);
    margin-top: 10px;
  }

  @include c('list') {
    margin-left: -10px;
    padding-bottom: 10px;
    width: 100%;
  }

  @include c('list-item') {
    line-height: 1.8em;
    font-size: 13px;
    margin: 0.3em 1em 0;
    padding: 0 10px;
    display: flex;
    color: var(--neutral-800);
    cursor: pointer;

    &:hover {
      background-color: var(--neutral-300);
      border-radius: 15px;
      width: 190px;
    }
  }

  @include c('list-item-name') {
    @include ellipsis;

    width: 180px;
  }

  @include c('item-title') {
    font-size: 14px;
    color: var(--neutral-1000);
    font-weight: 600;
    margin-bottom: 8px;
    margin-top: 15px;
  }

  @include c('desc') {
    font-size: 12px;
    color: var(--neutral-700);
    margin-bottom: 10px;
    width: 510px;
  }

  @include c('join-url-container') {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 500px;
    height: 30px;
    border-radius: 6px;
    border: solid 0.5px var(--neutral-400);
    background-color: var(--neutral-100);
    font-size: 13px;
    padding: 4px 2px 4px 10px;
    color: var(--neutral-1000);
    margin-bottom: 21px;
  }

  @include c('join-url') {
    @include ellipsis;

    max-width: 420px;
  }

  @include c('input') {
    width: 500px;
    height: 30px;
    border-radius: 6px;
    border: solid 0.5px var(--neutral-400);
    background-color: var(--neutral-100);
    font-size: 13px;
    padding: 4px 10px;
    color: var(--neutral-1000);
    margin-bottom: 30px;

    &:focus {
      border-radius: 6px;
      border: solid 0.5px rgba(5, 140, 255, 0.25);
    }
  }

  @include c('access-title') {
    font-size: 14px;
    color: var(--neutral-1000);
    font-weight: 600;
    margin-bottom: 8px;
  }

  @include c('access-desc') {
    font-size: 12px;
    color: var(--neutral-700);
    margin-bottom: 10px;
    width: 495px;

    &:focus {
      border: solid 0.5px rgba(5, 140, 255, 0.25);
    }
  }

  @include c('copy-button') {
    @include flex-align-center;

    justify-content: center;
    width: 57px;
    height: 23px;
    border-radius: 4px;
    border: solid 0.5px var(--neutral-700);
    font-size: 12px;
    color: var(--neutral-700);
    font-weight: 500;
    padding-top: 1px;
    cursor: pointer;

    &:hover {
      background-color: var(--neutral-700);
      color: var(--neutral-100);
    }
  }

  @include c('edit-buttons') {
    display: flex;
    font-size: 12px;
    font-weight: 600;
    margin-top: 15px;
  }

  @include c('cancel-button') {
    width: 66px;
    height: 30px;
    border: solid 0.5px var(--neutral-700);
    background-color: var(--neutral-100);
    color: var(--neutral-700);
    border-radius: 6px;
    margin-right: 9px;
    cursor: pointer;

    &:hover {
      background-color: var(--neutral-700);
      color: var(--neutral-100);
    }

    &[disabled] {
      border: solid 0.5px var(--neutral-200);
      background-color: var(--neutral-100);
      color: var(--neutral-400);
      cursor: default;
    }
  }

  @include c('save-button') {
    width: 66px;
    height: 30px;
    background-color: var(--patient-network-700);
    color: var(--neutral-100);
    border-radius: 6px;
    cursor: pointer;

    &:hover {
      background-color: var(--patient-network-800);
      border-color: var(--patient-network-800);
    }

    &[disabled] {
      background-color: var(--neutral-200);
      color: var(--neutral-400);
      cursor: default;
    }
  }

  @include c('delete-section') {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
  }

  @include c('delete-button') {
    width: 66px;
    height: 30px;
    border-radius: 6px;
    border: solid 0.5px var(--errors-500);
    background-color: var(--neutral-100);
    color: var(--errors-500);
    font-size: 12px;
    font-weight: 600;

    &:hover {
      background-color: var(--errors-500);
      color: var(--neutral-100);
    }
  }

  @include c('check-mark') {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include c('staff-list') {
    width: 500px;

    @include c('staff') {
      display: flex;
      margin-bottom: 10px;

      @include c('name') {
        font-size: 13px;
        font-weight: 500;
        color: var(--neutral-1000);
        flex: 2;
        display: flex;
        padding-left: 10px;

        @include c('name-text') {
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      @include c('remove-icon') {
        cursor: pointer;
        width: 18px;
        height: 18px;
        margin-right: 6px;
      }
    }
  }

  @include c('switch-container') {
    font-size: 12px;
    color: var(--neutral-950);
    display: flex;
    flex: 1;
    justify-content: flex-end;

    @include c('notification') {
      margin-right: 20px;
    }
  }

  @include c('staff-error-message') {
    font-size: 12px;
    color: var(--errors-500);
  }

  @include c('visit-reasons') {
    display: flex;
    width: 500px;
    margin-bottom: 15px;
  }

  @include c('reasons-desc') {
    flex: 2 1;
  }

  @include c('reasons-button') {
    display: flex;
    flex: 1 1;
    justify-content: flex-end;
  }
}
