@import '../base';

@include b('GroupAvatarDropzone') {
  flex: 0 0 40px;
  height: 100%;

  &--active {
    @include c('upload-button') {
      outline: 2px dashed $xx-strong-line-color;
    }
  }

  @include c('upload-button') {
    @include center-items-both;

    height: 40px;
    border: solid 1px $interaction-signifier;
    border-radius: 50%;
    color: $interaction-signifier;

    &:hover {
      background-color: $interaction-signifier;
      color: $white;
      cursor: pointer;

      @include c('upload-button-image') {
        fill: $white;
      }
    }
  }

  @include c('avatar-img') {
    @include center-items-both;

    height: 40px;
    width: 40px;
    border: solid 1px $interaction-signifier;
    border-radius: 50%;

    &:hover {
      cursor: pointer;
    }
  }

  @include c('avatar-hovered') {
    @include center-items-both;

    height: 40px;
    border-radius: 50%;
    background-color: $copy-text;
    color: $white;
    font-size: 12px;
    cursor: pointer;
  }

  @include c('avatar-hovered-text') {
    &--avatar-menu-open {
      padding: 2px 6px;
      border-radius: 3px;
    }
  }

  @include c('upload-button-image') {
    width: 15px;
    height: 15px;
    fill: $interaction-signifier;
    margin-top: 5px;
  }
}
