@import '../../base';

@include b('ProviderSearchBox') {
  background-color: var(--neutral-100);
  border-radius: 6px;
  border: solid 1px var(--neutral-300);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.09);
  height: auto;
  max-height: 350px;
  min-height: 250px;
  overflow-y: auto;
  position: absolute;
  left: 30px;
  top: 182px;
  width: 353px;
  z-index: 2;

  @include c('search-results') {
    overflow-y: auto;
  }

  @include c('no-search-results') {
    align-items: center;
    color: var(--neutral-700);
    display: flex;
    font-style: italic;
    justify-content: center;
    margin-top: 40px;
  }

  @include c('search-container') {
    display: flex;
    align-items: center;
    background-color: var(--neutral-150);
    border-radius: 18px;
    border: solid 1px var(--neutral-300);
    padding: 0 3px 0 12px;
    height: 28px;
    width: 262px;
    margin: 20px auto;

    @include c('search-text') {
      color: var(--cool-neutral-700);
      font-size: 14px;
      height: 19px;
      width: 100%;

      input {
        background: var(--neutral-150);
        width: 100%;
      }

      input::placeholder {
        color: var(--cool-neutral-700);
      }
    }

    @include c('close-container') {
      background-color: var(--cool-neutral-100);
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      height: 22px;
      justify-content: center;
      align-items: center;
      width: 22px;

      svg {
        height: 8px;
        width: 8px;
        fill: var(--cool-neutral-700);
      }

      &--hide {
        visibility: hidden;
      }
    }
  }

  @include c('loading') {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: center;
  }
}
