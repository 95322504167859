@import '../../base';

@include b('MenuItemList') {
  display: inline-block;
  border-radius: 6px;
  background-color: var(--neutral-100);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.14);
  padding: 6px 0 8px;

  @include c('list') {
    width: 100%;
  }

  @include c('list-container') {
    position: relative;
  }

  @include c('list-item') {
    &:hover {
      background-color: var(--cool-neutral-300);
    }

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
    }
  }

  @include c('menu-item') {
    width: 100%;
    height: 28px;
    padding: 0 5em 0 1em;
    font-size: 12px;
    text-align: left;
    user-select: none;
    white-space: nowrap;
    color: var(--neutral-800);

    > span {
      padding: 3px 9px;

      svg {
        margin-right: 10px;
      }
    }

    &--isSelected {
      color: var(--primary-300);
    }

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
    }
  }
}
