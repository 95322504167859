@import '../../../base';

@include b('PatientCsvFailModal') {
  @include c('title-text') {
    color: $display-text;
    text-align: center;
    font-weight: bold;
    font-size: 1.2em;

    &--generalCsvUploadError {
      margin-bottom: 40px;
    }
  }

  @include c('sub-text') {
    color: $x-dimmed-text-color;
    text-align: center;
  }

  @include c('close-button') {
    @include common-modal-close-button;

    color: $primary-brand;
  }

  @include c('error-container') {
    width: 90%;
    margin: 20px 5%;
    border: 1px solid #333;
    border-radius: 3px;
    min-height: 50px;
    max-height: 250px;
    overflow-y: auto;
    font-size: 0.8em;
  }

  @include c('error-header') {
    display: flex;
    width: 100%;
    background-color: #eee;
  }

  @include c('error-row') {
    display: flex;
    width: 100%;
  }

  @include c('record-num') {
    flex: 0 0 40px;
    text-align: center;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 4px;
  }

  @include c('reason') {
    flex: 1 1 auto;
    border-bottom: 1px solid #ddd;
    padding: 4px;
  }

  @include c('img') {
    width: 70px;
    height: 70px;
    margin-bottom: 15px;
  }
}
