@import '../base';

@include b('Messenger') {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;

  @include m('isHidden') {
    display: none;
  }

  @include c('content') {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;

    &--isPrintModeOpen {
      display: none;
    }

    @include c('sidebar-content') {
      flex: 0 0 350px;
      position: relative;
      height: 100%;

      @media print {
        display: none;
      }
    }

    @include c('middle-and-info') {
      display: flex;
      flex: 1 1 auto;
      flex-direction: row;
      position: relative;
      background-color: var(--neutral-200);
    }

    @include c('info-button') {
      position: absolute;
      top: 0;
      right: 0;
    }

    @include c('middle-content') {
      display: flex;
      flex-direction: row;
      position: relative;

      @include medium {
        flex: 1 1 auto;
        opacity: 1;
        transition: opacity 250ms cubic-bezier(0.22, 1, 0.36, 1);

        &--isInfoPaneOpen {
          flex: 0;
          opacity: 0;
          transition: opacity 250ms cubic-bezier(0.22, 1, 0.36, 1);
        }
      }

      @include gt-medium {
        flex: 1 1 auto;
      }

      @media print {
        width: 100%;
        height: 90vh;
      }
    }
  }

  @include c('info-pane') {
    display: flex;

    @include medium {
      flex: 0 0 0;
      width: 0;
      border-left: 1px solid var(--neutral-300);
      opacity: 0;
      transition: opacity 350ms cubic-bezier(0.22, 1, 0.36, 1);

      &--isInfoPaneOpen {
        flex: 1 0 auto;
        opacity: 1;
        transition: opacity 350ms cubic-bezier(0.22, 1, 0.36, 1) 120ms;
      }
    }

    @include gt-medium {
      flex: 0 0 0;
      width: 0;
      border: 0;
      opacity: 0;
      transition: all $info-pane-transition-ms cubic-bezier(0.22, 1, 0.36, 1);

      &--isInfoPaneOpen {
        border-left: 1px solid var(--neutral-300);
        flex: 0 0 350px;
        opacity: 1;
        transition:
          border $info-pane-transition-ms cubic-bezier(0.22, 1, 0.36, 1),
          width $info-pane-transition-ms cubic-bezier(0.22, 1, 0.36, 1),
          opacity 250ms cubic-bezier(0.22, 1, 0.36, 1) 120ms;
      }
    }

    @media print {
      display: none;
    }
  }

  @media print {
    display: block;
  }
}
