$dark-accent-color-1: #f1544c !default;
$dark-active-color: #74ce4a !default;
$dark-alert-text-color: #3e3e3e !default;
$dark-alert-bg-button: #f6f6f6 !default;
$dark-alert-bg-body: #fbfbfb !default;
$dark-border-color: #dadada !default;
$dark-card-hover-color: #e4f6fe !default;
$dark-disabled-text-border-color: #474c50;
$dark-line-color: #e0e0e0 !default;
$dark-text-color: #333 !default;
$dark-main-bg: #2b2b2b;
$dark-secondary-bg: #2f3337;
$dark-accent-bg: #255d74;
$dark-accent-hover-bg: lighten($dark-accent-bg, 10%);
$dark-tab-selector-bg: #2e2e2e;
$dark-incoming-message-bg: #383838;
$dark-incoming-message-hover-bg: lighten($dark-incoming-message-bg, 10%);
$dark-user-avatar-bg: #464646;
$dark-muted-avatar-bg: #333;
$dark-send-message-bg: #242828;
$dark-stroke-color: #3d565c;
$dark-header-text: #d9d9d9;
$dark-main-text: #e6e6e6;
$dark-status-text: #5ecbff;
$dark-on-duty-text: #badcf7;
$dark-roster-date-text: #f2f2f2;
$dark-message-date-text: #bfbfbf;
$dark-send-button-quick: #326c7c;
$dark-outgoing-message-color: #444;
$dark-hover-bg: #404040;
$dark-roster-conversation-item: #363c3f;
$dark-primary-color: #659bdc;
$dark-secondary-color: #888;
$dark-app-selector-bg: #353a4b;
$dark-app-selector-selected-bg: #4d4d4d;
$dark-box-shadow-color: black;
$dark-read-text: #7ae3a0;
$dark-patient-color: #82aed3;
$dark-active-conversation-secondary: #a9bfc8;
$dark-icon-bg: #3e4747;
