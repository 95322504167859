@import '../../../base';

@include b('ManagePatientOrContactModal') {
  width: 860px;
  height: 260px;
  background-color: rgba(255, 255, 255, 0);
  box-shadow: none;
  border: none;

  @include c('header') {
    @include common-modal-header;

    background-color: rgba(255, 255, 255, 0);
    width: 100%;
    margin: 34px 0 0;

    p {
      white-space: normal;
    }
  }

  @include c('errors') {
    height: 22px;
    margin-top: 8px;
    margin-bottom: 0;

    &--hasError {
      align-items: center;
      background-color: var(--errors-500);
      border: solid 1px var(--primary-300);
      border-radius: 6px;
      color: var(--neutral-100);
      display: inline-flex;
      font-size: 12px;
      font-weight: 500;
      padding: 2px 50px;
    }
  }

  @include c('body') {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 80px;
    margin-top: 20px;
    padding-bottom: 20px;
    background-color: var(--neutral-100);
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  }

  @include c('footer') {
    display: flex;
    justify-content: flex-end;
    background-color: var(--neutral-150);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    align-items: center;
    padding: 25px 23px;
    background-clip: padding-box;
  }

  @include c('save-btn') {
    @include button-with-disable(var(--secondary-500), 75px, 32px, 11px);

    &--progress {
      cursor: default;
    }

    padding-top: 2px;
  }

  @include c('cancel-btn') {
    @include common-button(var(--cool-neutral-700), 75px, 32px, 11px);

    &--progress {
      &:hover {
        background-color: var(--neutral-100);
        border-color: var(--cool-neutral-700);
        color: var(--cool-neutral-700);
        cursor: default;
      }
    }

    margin-right: 8px;
    padding-top: 2px;
  }

  @include c('patient-admin-container') {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: var(--neutral-100);
    width: 860px;
    margin: 0 auto;
    padding: 6px 15px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  }

  @include c('dots-container') {
    @include center-items-both;

    height: 75%;
  }
}
