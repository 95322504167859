@import '../../base';

@include b('DownloadApp') {
  margin-top: 30px;
  color: #9b9b9b;

  @include c('text') {
    cursor: pointer;
    padding: 10px 15px;
    border-radius: 100px;
    border: solid 1px #fbfcfc;

    &:hover {
      border-color: $interaction-signifier;
      color: $interaction-signifier;
    }

    &--is-open {
      border-color: $interaction-signifier;
      color: $interaction-signifier;
    }
  }

  @include c('app-list') {
    height: 200px !important;
  }
}
