@import '../../base';

@include b('AlertCardV2') {
  border: 1px solid var(--neutral-500);
  border-radius: 4px;
  background: var(--neutral-150);
  color: var(--neutral-1000);

  @include c('content-container') {
    padding: 10px 14px 12px;

    @include c('horizontal-section-container') {
      display: flex;

      @include c('left-section') {
        flex: 1 1 auto;
        max-width: 50%;
        min-width: 100px;

        @include if-ie {
          flex: 1 1 50%;
        }

        &--fullWidth {
          max-width: 100%;
        }
      }

      @include c('right-section') {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;

        @include if-ie {
          max-width: 50%;
        }
      }
    }

    @include c('expand-btn') {
      color: var(--secondary-500);
      font-weight: 500;
      cursor: pointer;
      white-space: nowrap;

      &:hover {
        color: #175296;
      }
    }

    @include c('alert-buttons') {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      max-width: 100%;
      padding-left: 6px;
    }
  }
}
