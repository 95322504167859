@import '../../../base';

@include b('WorkflowItem') {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  border: solid 1px var(--neutral-400);
  border-radius: 6px;
  padding: 11px 0;
  cursor: pointer;

  &:hover {
    background-color: var(--cool-neutral-300);
  }

  @include c('status-row') {
    display: flex;
    justify-content: center;
    flex: 0 0 80px;

    @include c('status') {
      border-radius: 4px;
      padding: 2px 5px;
      background-color: var(--primary-300);
      font-size: 10px;
      font-weight: 600;
      text-align: center;
      color: var(--neutral-100);

      &--isActive {
        background-color: var(--success-70);
      }
    }
  }

  @include c('content-row') {
    flex: 1;

    @include c('label') {
      color: var(--neutral-1000);
      font-size: 14px;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    @include c('num-events') {
      margin-top: 2px;
      font-size: 14px;
      font-weight: 600;
      color: $xx-dimmed-text-color;
    }
  }
}
