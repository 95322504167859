@import '../../base';

@include b('PatientSidebarTabPicker') {
  width: 100%;
  display: flex;
  padding: 5px 16px;
  height: 38px;
  min-height: 38px;

  @include c('accessibility') {
    display: flex;
    width: 100%;
  }

  @include c('tab') {
    flex: 1;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    color: var(--neutral-600);
    padding: 5px;
    cursor: pointer;

    &--isActive {
      color: var(--patient-network-700);
      border-bottom: 2px solid var(--patient-network-700);
    }

    &:hover {
      color: var(--patient-network-700);
    }
  }
}
