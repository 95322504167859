@import '../base';

@include b('InfoPane') {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1 1 auto;
  position: relative;
  background-color: var(--cool-neutral-200);
  padding: 3px;

  @include c('close') {
    height: 51px;
    right: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 15px 15px 0 0;

    button {
      svg {
        color: var(--secondary-500);

        &:hover {
          color: var(--secondary-900);
        }
      }
    }
  }

  @include c('main') {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    padding: 0;
    transition: padding 250ms cubic-bezier(0.22, 1, 0.36, 1);

    &--isInfoPaneOpen {
      padding: 0 2px;
      transition: padding 250ms cubic-bezier(0.22, 1, 0.36, 1);
    }

    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
    }
  }
}
