body .viewContainer {
  background-color: var(--neutral-200);
  padding: 15px 25px 50px;
  position: relative;
}

.viewContainer .headerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.viewContainer .header {
  flex: 1 1 auto;
}

.viewContainer .closeButton {
  background: url('../../../images/sprite_icons.png') no-repeat left center;
  background-position: -40px -980px;
  width: 10px;
  height: 10px;
  cursor: pointer;
}

body:global(.theme-dark) .viewContainer .closeButton {
  background: url('../../../images/sprite_icons_dark.png') no-repeat left center;
  background-position: -40px -980px;
}

.viewContainer .button {
  margin-top: 20px;
  border: none;
}
