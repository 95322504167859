@import '../../base';

@include b('VirtualWaitingRoomRoom') {
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @include c('header') {
    display: flex;
    height: 97px;
    border-bottom: solid 0.5px var(--neutral-400);
  }

  @include c('left-container') {
    min-width: 300px;
    flex: 1;
  }

  @include c('name-container') {
    display: flex;
    margin-top: 11px;
  }

  @include c('avatar-container') {
    background-color: var(--patient-network-700);
    width: 24px;
    height: 24px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 13px 0 9px;
  }

  @include c('patient-icon') {
    width: 24px;
    height: 24px;
  }

  @include c('name') {
    @include ellipsis;

    max-width: 500px;
    font-weight: 500;
  }

  @include c('video-icon') {
    margin-left: 16px;
    cursor: pointer;

    svg {
      width: 21px;
      height: 16px;
      fill: var(--patient-network-700);
    }

    &--isCompletedStatus {
      svg {
        fill: rgba(74, 101, 123, 0.1);
        cursor: default;
      }
    }

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
    }
  }

  @include c('details-container') {
    display: flex;
    margin: 11px 0 0 46px;
    font-size: 10px;
    font-weight: 500;
  }

  @include c('details-header') {
    color: var(--neutral-700);
  }

  @include c('mobile-container') {
    margin-right: 60px;
    flex: 1;
    min-width: 110px;
  }

  @include c('mobile-phone') {
    font-size: 14px;
    font-weight: 400;
    margin-top: 5px;
  }

  @include c('assignment-container') {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }

  @include c('assignment-button') {
    @include flex-align-center;
  }

  @include c('assignment') {
    @include ellipsis;

    color: var(--secondary-500);
    font-size: 10px;
    margin-right: 4px;
    text-transform: uppercase;
    max-width: 200px;
  }

  @include c('dropdown-svg') {
    width: 10px;
    height: 6px;
  }

  @include c('right-container') {
    width: 170px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    font-size: 14px;
  }

  @include c('status-button') {
    @include flex-align-center;

    width: 152px;
    height: 35px;
    border-radius: 6px;
    background-color: var(--neutral-300);
    color: var(--patient-network-700);
    padding: 0 20px;
    cursor: pointer;

    &:hover {
      background-color: var(--neutral-400);
    }

    &--disabled {
      color: var(--neutral-400);
      background-color: var(--neutral-200);
      cursor: default;

      &:hover {
        background-color: var(--neutral-200);
      }
    }
  }

  @include c('status') {
    font-size: 14px;
    font-weight: 600;
    flex: 1;
    display: flex;
    align-items: flex-start;
    text-transform: capitalize;
  }

  @include c('inform-button') {
    @include flex-align-center;

    width: 152px;
    height: 35px;
    border-radius: 6px;
    background-color: var(--patient-network-700);
    color: var(--neutral-100);
    cursor: pointer;
    font-weight: 500;
    justify-content: center;

    &:hover {
      background-color: var(--patient-network-600);
    }

    &--disabled {
      color: var(--neutral-400);
      background-color: rgba(74, 101, 123, 0.1);
      cursor: default;

      &:hover {
        background-color: rgba(74, 101, 123, 0.1);
      }
    }

    &--cancel {
      color: var(--primary-300);
      background-color: var(--neutral-400);

      &:hover {
        background-color: var(--neutral-400);
      }
    }
  }

  @include c('success-header') {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  @include c('success-name') {
    font-weight: 600;
    font-size: 13px;
  }

  @include c('success-text') {
    font-weight: 400;
    color: var(--neutral-1000);
    font-size: 13px;
  }

  @include c('empty-pane') {
    background-color: var(--neutral-150);
  }
}
