@import '../base';

@include b('AppSelector') {
  background-color: var(--neutral-100);
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;

  @include m('showDebugUI') {
    width: 70%;
  }

  @include c('app-selector-buttons') {
    flex: 0 0 77px;
    height: 100%;
    border-top: solid 1px var(--neutral-300);
    border-right: solid 1px var(--neutral-400);
    border-bottom: solid 1px var(--neutral-300);
    background-color: var(--neutral-200);

    @media print {
      display: none;
    }
  }

  @include c('main-window') {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    width: 100%;

    @media print {
      display: block;
    }
  }
}
