@import '../../base';

@include b('OrganizationSelector') {
  position: absolute;
  z-index: 99;
  top: 40px;
  left: 20px;
  width: 333px;
  height: auto;
  border-radius: 6px;
  background-color: var(--neutral-100);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.14);
  margin: 0.7em 0 0 0.5em;

  path {
    fill: var(--neutral-1000);
  }

  &:focus {
    outline: none;
  }

  @include c('search-container') {
    text-align: center;
    padding: 10px 20px;
    padding-top: 15px;
    position: relative;
    width: 100%;
  }

  @include c('search') {
    border-radius: 18px;
    border: solid 1px var(--neutral-300);
    background-color: var(--neutral-150);
    font-size: 14px;
    padding: 3px 10px;
    outline: none;
    width: 100%;
  }

  @include c('search-icon-container') {
    border-radius: 100%;
    background: var(--neutral-200);
    cursor: pointer;
    height: 23px;
    right: 21px;
    top: 16px;
    position: absolute;
    width: 23px;
    align-items: center;
    justify-content: center;
  }

  @include c('search-icon') {
    width: 8px;
    height: 8px;
    color: var(--cool-neutral-700);
  }

  @include c('search-no-results') {
    width: 100%;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    color: var(--neutral-800);
    margin-top: 10px;
  }

  @include c('scroll-container') {
    position: relative;
  }

  @include c('list') {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
  }

  @include c('list-item') {
    float: left;
    line-height: 2.5em;
    font-size: 12px;
    margin: 0.3em 1em 0;
    display: flex;
    color: var(--neutral-800);
    padding: 0 12px;
    cursor: pointer;
    min-width: 300px;
    align-items: center;
    justify-content: space-between;

    &:hover {
      background-color: var(--cool-neutral-300);
      border-radius: 24px;
    }

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
    }

    &--is-priority {
      background-color: var(--primary-300);
      color: var(--neutral-100);
      border-radius: 24px;

      @include c('list-item-name') {
        color: var(--neutral-100);
      }

      &:hover {
        background-color: var(--primary-700);
      }
    }
  }

  @include c('list-item-name') {
    @include ellipsis;

    float: left;
    max-width: 250px;
    user-select: none;

    &--is-selected {
      color: var(--primary-300);
    }
  }

  @include c('image') {
    height: 1.8em;
    width: 1.8em;
    float: right;
    padding: 0.6em 0.6em 0 0;
  }
}
