@import '../../base';

@include b('DebugUI') {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 30%;
  border-left: 1px solid #ddd;
  padding: 10px;
  background: #222;
  color: #eee;
  font-size: 12px;
  font-family: ui-monospace, 'Cascadia Code', 'Source Code Pro', Menlo, Consolas, 'DejaVu Sans Mono', monospace;
  overflow: scroll;
  line-height: 1.3;

  @include c('textButton') {
    color: #666;
    cursor: pointer;
    text-decoration: underline;
  }

  @include c('watchlistContainer') {
    border: 1px solid #666;
    border-radius: 8px;
    padding: 10px;
    margin: 5px 0 15px;

    @include c('watchlistStore') {
      color: #666;
    }

    @include c('watchlistObservableContainer') {
      margin: 5px 0 15px 15px;

      @include c('watchlistObservableKeyContainer') {
        display: flex;

        @include c('watchlistObservableKey') {
          flex: 1;
          color: #aaa;
          font-weight: 800;
        }
      }

      @include c('watchlistObservableValue') {
        flex: 1;
        width: 100%;
        white-space: break-spaces;
      }
    }
  }

  @include c('observableListContainer') {
    display: flex;

    @include c('observableListName') {
      flex: 4;
      text-decoration: underline;
      margin-right: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      padding-bottom: 2px;
    }

    @include c('observableListType') {
      flex: 1;
      color: #aaa;
      font-style: italic;
    }
  }
}
