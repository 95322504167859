@import '../../base';

@include b('MessageInputs') {
  .tc-ContextMenu--active {
    box-shadow: 0 0 0 3px rgba(5, 140, 255, 0.25);
  }

  svg {
    [fill='#4A657B'] {
      fill: var(--patient-network-700);
    }

    [stroke='#4A657B'] {
      stroke: var(--patient-network-700);
    }
  }

  @include c('template-menu-btn') {
    @include schedule-message-context-menu-btn(100%);

    font-size: 12px;
    font-weight: normal;
    color: var(--neutral-900);

    @include c('template-menu-label') {
      display: flex;

      & > svg {
        margin-right: 10px;
      }
    }
  }

  @include c('messageLengthSubtext') {
    bottom: 5px;
    color: var(--success-500);
    display: flex;
    flex-direction: column;
    font-size: 10px;
    font-weight: 500;
    position: absolute;
    right: 15px;
    text-align: right;

    &--lengthExceeded {
      color: var(--primary-300);
    }
  }

  @include c('attachment-btn') {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 130px;
    height: 26px;
    border-radius: 6px;
    border: solid 0.5px var(--neutral-400);
    padding: 8px 10px;
    background-image: linear-gradient(to bottom, var(--neutral-150), var(--neutral-200));
    font-size: 12px;
    color: var(--neutral-950);
  }

  @include c('message-section') {
    background-color: var(--neutral-100);
    border: solid 0.5px var(--neutral-400);
    border-radius: 6px;
    color: var(--neutral-1000);
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 6px;
    margin-top: 6px;
    max-width: 574px;
    min-height: 105px;
    position: relative;
    width: 100%;

    @include c('message-input') {
      background-color: transparent;
      border: none;
      margin-top: 6px;
      overflow-y: auto;
      padding: 6px 10px 10px 6px;
      resize: none;
      width: 100%;

      &--placeholder {
        font-weight: normal;
        color: var(--cool-neutral-800);
      }

      &:focus-visible {
        outline: 3px solid var(--secondary-500);
      }
    }
  }

  @include c('attachment-container') {
    display: inline-flex;
    align-items: center;
    height: 28px;
    border: solid 0.5px var(--neutral-400);
    border-radius: 6px;
    padding: 0 10px;
    font-size: 12px;
    color: var(--neutral-1000);

    @include c('file-icon') {
      height: 16px;
      margin-right: 10px;
      width: 14px;
    }

    @include c('reset-attachment-btn') {
      display: inline-flex;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      margin-left: 10px;
      justify-content: center;
      background-color: var(--cool-neutral-100);
      align-items: center;

      &:hover {
        background-color: var(--neutral-400);

        svg {
          fill: var(--neutral-100);
        }
      }

      svg {
        width: 6px;
        fill: var(--cool-neutral-700);
      }
    }
  }

  @include c('attachment-download-btn') {
    cursor: pointer;
    display: flex;
  }

  @include c('attachment-download') {
    display: flex;
  }
}
