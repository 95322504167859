@import '../../base';

@include b('ProviderBroadcastListsView') {
  height: 100%;
  width: 100%;

  @include c('header') {
    margin-bottom: 32px;
    align-items: center;
    display: flex;
    justify-content: space-between;

    @include c('title') {
      font-size: 22px;
      color: var(--neutral-950);
    }
  }

  @include c('actions') {
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include c('actions-left') {
      align-items: center;
      display: flex;

      @include c('create') {
        margin-right: 20px;
        background-color: var(--secondary-700);
        border-radius: 6px;
        color: var(--neutral-100);
        font-size: 13px;
        line-height: 28px;
        padding: 0 15px;
        font-weight: 500;

        &:hover {
          /* background-color: #71a7e6; */
          background-color: var(--secondary-200);
        }
      }

      @include c('filters') {
        display: inline-block;
      }
    }

    @include c('actions-right') {
      @include c('actions-right-button') {
        height: 22px;
        width: 68px;
        border-radius: 15px;
        align-items: center;
        display: flex;
        font-size: 13px;

        @include c('delete-icon') {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
}
