@import '../base';

@include b('ExistingPatientConversations') {
  position: absolute;
  top: -121px;
  left: 55px;
  width: 685px;
  height: 106px;
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.13);
  border: solid 1px var(--neutral-400);
  background-color: var(--neutral-100);

  @include c('patient-conversation-text') {
    margin: 7px 12px 0;
    font-size: 14px;
    color: var(--neutral-700);
    display: flex;
    justify-content: space-between;

    @include c('close-button') {
      cursor: pointer;
      width: 14px;

      &:hover {
        color: var(--neutral-100);

        circle {
          fill: var(--neutral-700);
        }
      }

      &:focus-visible {
        outline: 3px solid var(--secondary-500);
      }
    }
  }

  @include c('patient-conversation-cards') {
    display: flex;
    margin: 0 7px 7px;
  }
}
