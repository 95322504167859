@import '../../base';

@include b('ProfileHeader') {
  display: flex;
  flex-direction: column;
  flex: 0 0 210px;
  background-color: var(--neutral-100);
  border-bottom: solid 1px var(--neutral-300);

  @include c('right-buttons') {
    display: flex;
    position: relative;
    align-self: flex-end;
  }

  @include c('user-details') {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @include c('user-name') {
    font-size: 18px;
    font-weight: 600;
    color: var(--neutral-1000);
    margin-top: 15px;
  }
}
