@import '../../base';

@include b('AlertActionChoice') {
  margin-top: 12px;
  max-width: 100%;
  border: 1px solid #cfcec9;
  border-radius: 6px;
  padding: 6px 10px;
  background-color: var(--neutral-200);
  font-weight: 300;
  font-size: 16px;
  color: var(--neutral-1000);

  @include m('shouldClip') {
    @include ellipsis;
  }
}
