@import '../base';

@include b('AddUsersSearchBox') {
  background-color: var(--neutral-200);
  border-radius: 3px;
  border: solid 1px var(--neutral-300);
  height: auto;
  left: 130px;
  position: absolute;
  width: 353px;
  z-index: 2;

  @include c('search-results') {
    overflow-y: auto;
    max-height: 275px;
  }

  @include c('individuals-tab') {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 3px 10px 4px;
    gap: 10px;
    height: 26px;
    mix-blend-mode: multiply;
    order: 0;
    align-self: stretch;
  }

  @include c('individuals-selection-tab') {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 3px 10px 4px;
    gap: 10px;
    height: 26px;
    mix-blend-mode: multiply;
    order: 0;
    align-self: stretch;
  }

  @include c('limited-results-tab') {
    align-content: center;
    align-items: center;
    align-self: stretch;
    background-color: var(--neutral-100);
    display: flex;
    flex-direction: row;
    font-weight: 550;
    gap: 10px;
    height: 36px;
    justify-content: center;
    padding: 3px 10px 4px;
  }

  @include c('entity-info') {
    border-bottom: 1px solid var(--neutral-300);
    display: flex;
    flex-direction: column;
    padding-bottom: 12px;
    padding-top: 15px;
    width: 100%;
  }

  @include c('clear-selection-button') {
    align-items: center;
    background-color: var(--neutral-200);
    border-radius: 4px;
    border: 1px solid var(--neutral-700);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-size: 11px;
    margin-bottom: 5px;
    margin-left: 13px;
    width: 199px;
  }

  @include c('add-users-button') {
    background-color: var(--secondary-500);
    border: 1px solid  var(--secondary-500);
    border-radius: 6px;
    color: var(--neutral-100);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    margin-left: 40px;
    width: 200px;
    text-align: center;
    font-size: 13px;
    line-height: 23px;
  }

  @include c('check-mark') {
    align-items: center;
    border-radius: 50%;
    display: flex;
    height: 19px;
    justify-content: center;
    margin-right: 25px;
    width: 24px;

    &--isSelected {
      background-color: var(--success-500);
    }
  }

  @include c('entity-details') {
    @include ellipsis;

    color: var(--neutral-700);
    display: flex;
    font-size: 10px;
    height: 14px;
    height: auto;
    width: auto;

    @include c('entity-title') {
      @include ellipsis;

      margin-right: 3px;
      max-width: 130px;
    }
  }

  @include c('no-search-results') {
    align-items: center;
    color: var(--neutral-700);
    display: flex;
    font-style: italic;
    justify-content: center;
    margin: 40px;
  }

  @include c('search-outer') {
    align-items: center;
    align-self: stretch;
    background-color: var(--neutral-100);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: 46px;
    order: 0;
    padding: 8px 0 8px 10px;

    &:hover {
      background-color: var(--cool-neutral-300);
    }
  }

  @include c('search-container') {
    display: flex;
    align-items: center;
    background: var(--neutral-100);
    border-radius: 18px;
    border: solid 1px var(--neutral-300);
    padding: 0 3px 0 12px;
    height: 28px;
    width: 320px;
    margin: 15px auto;

    @include c('search-text') {
      color: var(--cool-neutral-700);
      font-size: 14px;
      height: 19px;
      width: 100%;

      input {
        background: var(--neutral-100);
        width: 100%;
      }

      input::placeholder {
        color: var(--cool-neutral-700);
      }
    }

    @include c('close-container') {
      background-color: var(--cool-neutral-100);
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      height: 22px;
      justify-content: center;
      align-items: center;
      width: 22px;

      svg {
        height: 8px;
        width: 8px;
        fill: var(--cool-neutral-700);
      }

      &--hide {
        visibility: hidden;
      }
    }
  }
}
