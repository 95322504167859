@import '../base';

@include b('RemoveEscalationGroupModal') {
  @include c('header') {
    @include common-modal-header;
  }

  @include c('close-button') {
    @include common-modal-close-button;
  }

  @include c('body') {
    @include common-modal-body;

    padding: 45px 30px 110px;
    text-align: center;
    line-height: 1.4;
  }

  @include c('question') {
    font-weight: bold;
    margin-bottom: 2vh;
  }

  @include c('remove-btn') {
    @include common-button(var(--secondary-500), 74px, 32px, 11px);
  }

  @include c('keep-btn') {
    @include common-button(var(--secondary-500), 74px, 32px, 11px);

    margin-right: 10px;
  }
}
