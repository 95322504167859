@import '../../base';
@import '../PatientSettings/PatientSettingMixins';

@include b('TemplateMenu') {
  @include schedule-message-context-menu-dropdown(574px);

  background: var(--neutral-100);
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.24);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  & > div {
    flex: 1 0;
    flex: 1 0 auto;
  }

  li {
    display: flex;
    align-items: center;
    height: 37px;
    margin: 0 11px;
  }

  @include m('disableCustom') {
    padding-top: 15px;
  }

  @include c('title-sect') {
    display: flex;
    margin-left: 20px;
    height: 31px;

    @include c('title-buttons') {
      color: var(--cool-neutral-800);
      font-size: 14px;
      font-weight: 600;
      height: 19px;
      margin-right: 20px;
      padding-bottom: 16px;

      &--isPersonalRepository {
        color: var(--patient-network-700);
        border-bottom: solid 1px var(--patient-network-700);
      }

      &--isGeneralRepository {
        color: var(--patient-network-700);
        border-bottom: solid 1px var(--patient-network-700);
      }

      &--isDynamicRepository {
        color: var(--patient-network-700);
        border-bottom: solid 1px var(--patient-network-700);
      }

      &:focus-visible {
        outline: 3px solid var(--secondary-500);
      }
    }
  }

  @include c('menu-list-scroller') {
    min-height: 50px;
    max-height: 150px;
    overflow-y: auto;

    @include c('loading-more') {
      @include loading-more;
    }
  }

  @include c('menu-list') {
    width: 100%;
    box-shadow: none;

    @include c('menu-item') {
      font-size: 14px;

      @include c('template-label-container') {
        display: flex;

        @include c('template-title') {
          width: 600px;
        }
      }

      &:focus-visible {
        outline: 3px solid var(--secondary-500);
      }
    }
  }

  @include c('no-template-placeholder') {
    color: var(--neutral-500);
    font-size: 14px;
    line-height: 1.57;
    padding: 0 0 52px 21px;
  }
}
