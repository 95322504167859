@import '../base';

@include b('RequestToJoinTeam') {
  $avatar-size: 38px;

  background-color: #f5f5f5;
  border-radius: 17px;
  color: #515151;
  height: 153px;
  margin: 10px auto;
  padding: 13px 16px;
  width: 428px;
  position: relative;

  @include themify {
    background-color: themed('main-bg');
    border: 1px solid themed('border-color');
  }

  @include c('requests') {
    display: flex;
    flex-direction: row;
    font-weight: 14px;
    align-items: center;
    margin-bottom: 8px;

    @include c('user-avatar-wrapper') {
      flex: $avatar-size 0 0;
      overflow-y: visible;

      @include b('AvatarImage') {
        height: $avatar-size;
        width: $avatar-size;
      }
    }

    @include m('avatar-link') {
      @include b('AvatarImage') {
        @include c('image-container-circle') {
          cursor: pointer;
          box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.175);
        }
      }
    }

    @include c('requester-info') {
      margin-left: 15px;

      @include c('requester') {
        color: #4d4d4d;
        font-weight: 600;

        @include themify {
          color: themed('main-text');
        }
      }

      @include c('requester-title') {
        color: #565656;

        @include themify {
          color: themed('main-text');
        }
      }
    }
  }

  @include c('message-time') {
    position: absolute;
    top: 17px;
    right: 17px;
    color: #919191;
    font-size: 10.2px;
    font-weight: 300;

    @include themify {
      color: themed('main-text');
    }
  }

  @include c('request-text') {
    color: #616161;
    font-size: 16px;
    font-weight: 400;

    @include themify {
      color: themed('main-text');
    }
  }

  @include c('request-buttons') {
    position: absolute;
    bottom: 13px;
    right: 10px;
    display: flex;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: white;
      font-size: 15px;
      font-weight: 500;
      border-radius: 18px;
      width: 104px;
      height: 26px;
    }

    @include c('deny-request') {
      background-color: #cf706c;

      &--isLoading {
        cursor: default;
      }
    }

    @include c('accept-request') {
      background-color: #6dc0aa;
      margin-left: 7px;

      &--isLoading {
        cursor: default;
      }
    }
  }

  @include c('request-final') {
    position: absolute;
    bottom: 18px;
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    right: 17.5px;

    @include c('request-accepted') {
      color: #54af97;
      margin-left: 4px;
    }

    @include c('request-denied') {
      color: #cf706c;
      margin-left: 4px;
    }
  }
}
