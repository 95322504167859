@import '../../base';

@include b('CollaborationUserItem') {
  display: flex;
  font-size: 14px;

  @include m('showDnd') {
    width: 100%;

    @include c('user-item') {
      width: 100%;
    }
  }

  @include c('role-tag-details') {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 8px;
    flex: 1 1 auto;
  }

  @include c('tag-indicator') {
    flex-shrink: 0;
    width: 4px;
    height: 4px;
    margin: 3px 5px;
    border-radius: 6px;

    @include if-ie {
      margin-top: 4px;
    }
  }

  @include c('tag-name') {
    @include ellipsis;

    color: var(--neutral-700);
    font-size: 12px;
    flex: 1 1 auto;

    @include c('highlighted') {
      background-color: transparent;
      font-weight: normal;
      color: var(--neutral-1000);
    }
  }

  @include c('user-item') {
    align-items: center;
    display: flex;
    flex-direction: row;

    &--reverse {
      flex-direction: row-reverse;
    }
  }

  @include c('on-duty') {
    .tc-AvatarImage__image-container-circle {
      border: 1px solid var(--secondary-700);
    }
  }

  @include c('current-user-on-duty') {
    color: var(--secondary-700);
    font-weight: 600;
    margin: 0 10px;
  }

  @include c('current-user-in-team') {
    color: var(--teams-900);
    font-weight: 600;
    margin: 0 10px;
  }

  @include c('user-details') {
    align-items: flex-start;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    margin: 0 10px;
  }

  @include c('user-name-status') {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  @include c('team-check-icon') {
    background-color: var(--teams-100);
    border-radius: 100%;
    height: 23px;
    width: 23px;

    svg {
      height: 10px;
      margin-left: 6px;
      margin-top: 6px;
      width: 10px;

      path {
        fill: var(--teams-900) !important;
      }
    }
  }

  @include c('user-name') {
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @include c('user-status') {
    color: var(--primary-300);
    display: flex;
    flex: 1 0 auto;
    flex-direction: row;
    font-size: 12px;
    justify-content: flex-end;

    @include c('dnd-icon') {
      margin-left: 5px;
    }

    span {
      margin-right: 3px;
    }
  }

  @include c('no-one-on-duty') {
    color: var(--primary-300);
  }

  @include c('user-title') {
    color: var(--cool-neutral-700);
    font-size: 10px;
    font-weight: bold;
    max-width: 180px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
