@import '../../base';
@import '../../DayPickerGradients';

@include b('AppointmentDateTimeForm') {
  @import '~react-dates/lib/css/datepicker';

  display: flex;

  .DayPicker {
    background-color: var(--neutral-100);
  }

  .DayPicker_weekHeader {
    color: var(--neutral-1000);
  }

  .DayPickerNavigation_button__default {
    border: 1px solid var(--patient-network-300);
    background-color: var(--neutral-100);
    color: var(--neutral-800);

    &:active {
      background: var(--neutral-200);
    }

    &:focus {
      border: 1px solid var(--neutral-400);
    }

    &:hover {
      border: 1px solid var(--neutral-400);
    }

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
    }
  }

  .CalendarMonth {
    background-color: var(--neutral-100);
  }

  .CalendarMonth_caption {
    color: var(--neutral-1000);
  }

  .CalendarMonthGrid {
    background-color: var(--neutral-100);
  }

  @include c('date-time-timezone-section') {
    display: flex;
    width: 100%;
  }

  @include c('inner-labels') {
    align-self: flex-start;
    color: var(--secondary-1000);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    margin-left: 3px;
    margin-bottom: 5px;
  }

  @include c('date-section') {
    display: flex;
    flex-direction: column;
    flex: 4;
  }

  @include c('time-section') {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 2;
  }

  @include c('timezone-section') {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .DateInput {
    background-color: var(--neutral-100);
    width: 100%;
    margin-right: 4px;
  }

  .SingleDatePickerInput {
    background-color: var(--neutral-100);
    color: var(--neutral-1000);
    width: 100%;
  }

  .SingleDatePicker {
    background-color: var(--neutral-100);
    flex: 1;
    margin-right: 12px;

    .DateInput_input {
      background-color: var(--neutral-100);
      border-radius: 6px;
      border: solid 1px var(--neutral-400);
      color: var(--neutral-1000);
      font-size: 13px;
      font-weight: 500;
      height: 30px;
      padding: 1px 10px;
      width: 100% !important;

      &::placeholder {
        color: var(--cool-neutral-800);
        font-weight: normal;
      }

      &__focused {
        box-shadow: 0 0 0 3px rgba(5, 140, 255, 0.25);
      }
    }

    .SingleDatePickerInput_calendarIcon {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      padding: 4px 10px;
    }

    .SingleDatePicker_picker {
      top: 36px !important;
      left: 1px !important;
    }

    @include c('chevron-icon') {
      &--active {
        transform: rotate(180deg);
      }
    }
  }

  .CalendarDay {
    background-color: var(--neutral-100);
    border: 1px solid var(--neutral-200);
    display: table-cell;
    vertical-align: middle;

    &:hover {
      @include gradient-day-hover;
    }

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
    }
  }

  .CalendarDay__default {
    background: var(--neutral-100);
    border: 1px solid var(--neutral-200);
    color: var(--neutral-950);

    &:hover {
      border: 1px solid var(--neutral-200);
    }
  }

  .CalendarDay__blocked_out_of_range {
    background: var(--neutral-100);
    border: 1px solid var(--neutral-200);
    color: var(--neutral-400);

    &:hover {
      border: 1px solid var(--neutral-100);
    }
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active {
    display: table-cell;
    color: var(--neutral-100);
    vertical-align: middle;
    border: 1px solid var(--neutral-200);

    @include gradient-selected-day-hover;

    &:hover {
      border: 1px solid var(--neutral-200);

      @include gradient-selected-day-hover;
    }
  }

  @include c('timezone-menu-btn') {
    @include schedule-message-context-menu-btn(auto);

    &:disabled {
      @include schedule-message-context-menu-btn(auto);
    }
  }
}
