@import '../base';

@include b('PatientCareCard') {
  @include c('card') {
    margin: 20px auto 0;
    border: 1.5px solid var(--neutral-500);
    border-radius: 18px;
    max-width: 600px;
    min-width: 340px;
    padding: 10px 10px 16px;
    background-color: var(--neutral-150);

    @include c('header') {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      @include c('icon') {
        border-radius: 50%;

        @include m('subType', 'ADMIT') {
          background-color: #73c56c;
        }

        @include m('subType', 'DISCHARGE') {
          background-color: #000;
        }

        @include m('subType', 'LAB') {
          background-color: #e05c55;
        }

        @include m('subType', 'TRANSFER') {
          background-color: #65c9ca;
        }

        @include m('subType', 'NA') {
          background-color: #a8bbc5;
        }
      }

      @include c('title') {
        margin-left: 11px;
        font-size: 17px;
        font-weight: bold;
        color: var(--neutral-1000);
      }

      @include c('timestamp') {
        margin-left: auto;
        font-size: 13px;
        color: var(--cool-neutral-700);
      }
    }

    @include c('item') {
      display: flex;
      justify-content: space-between;
      padding: 8px 0;
      line-height: 1.6;
      font-size: 13px;

      &:not(:last-child) {
        border-bottom: 0.5px solid var(--neutral-300);
      }

      @include c('title') {
        flex: 1;
        max-width: 50%;
        text-align: left;
        color: var(--cool-neutral-900);
      }

      @include c('value') {
        flex: 1;
        max-width: 50%;
        text-align: right;
        font-weight: 500;
        color: var(--neutral-950);
      }
    }
  }

  @include c('action-container') {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    @include c('action-button') {
      max-width: 600px;
      border-radius: 20px;
      padding: 11px 56px;
      background-color: var(--cool-neutral-200);
      line-height: 1.2;
      font-size: 15px;
      font-weight: 500;
      color: var(--secondary-500);
    }
  }
}
