@import '../../base';

@include b('KeywordSearchSideBar') {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-right: 1px solid var(--neutral-300);
  background-color: var(--cool-neutral-200);
  font-size: 15px;
  z-index: 2;
  overflow: hidden;
  padding: 20px;

  @include c('search-container') {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include c('arrow-back') {
      margin-right: 5px;
    }
  }

  @include c('label') {
    color: var(--primary-900);
    font-size: 12px;
    cursor: pointer;
  }

  @include c('results-container') {
    display: none;
    overflow-y: auto;
    height: inherit;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: top 0.3s;

    &--block {
      display: block;
    }

    &--none {
      display: none;
    }

    @include c('conversation-result') {
      border-bottom: 1px solid var(--neutral-400);
      margin-top: 8px;

      @include c('conversation-header') {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
      }

      @include c('conversation-name') {
        font-size: 13px;
        color: var(--neutral-1000);
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 80%;
      }

      @include c('seeAll-label') {
        font-size: 11px;
        color: var(--primary-700);
        cursor: pointer;
      }
    }
  }

  @include c('results-loader') {
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
  }

  @include c('conversation-result-seeAll') {
    margin-top: 15px;
  }

  @include c('message-container') {
    margin-bottom: 8px;
    display: flex;
    cursor: pointer;

    @include c('avatar-container') {
      flex: 0 0 34px;
    }

    @include c('message-bubble') {
      background-color: var(--neutral-200);
      margin: 0 4px;
      padding: 8px;
      border-radius: 0 12px 12px;
      flex: 1 1;

      em {
        font-weight: bold;
      }

      &--isCurrentUser {
        background-color: var(--cool-neutral-300);
        border-radius: 12px 0 12px 12px;
      }

      @include c('user-name') {
        font-size: 13px;
        color: var(--cool-neutral-900);
        margin-bottom: 4px;
      }

      @include c('message') {
        font-size: 14px;
        color: var(--neutral-1000);
      }

      @include c('attachment-container') {
        border: 1px solid var(--neutral-400);
        padding: 8px;
        display: flex;
        align-items: center;
        margin: 4px 0;
        font-size: 12px;
        border-radius: 6px;

        @include c('attachment-name') {
          margin-left: 8px;
        }
      }

      @include c('message-created-time') {
        font-size: 11px;
        color: var(--neutral-700);
        margin-top: 4px;
      }
    }

    @include c('arrow') {
      flex: 0 0 30px;
      align-self: center;
    }
  }
}
