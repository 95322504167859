$accent-color-1: #ec1722 !default;
$active-color: #74ce4a !default;
$alert-text-color: #3e3e3e !default;
$alert-bg-button: #f6f6f6 !default;
$alert-bg-body: #fbfbfb !default;
$border-color: #dadada !default;
$card-hover-color: #e4f6fe !default;
$disabled-color: #d4d4d4 !default;
$line-color: #e0e0e0 !default;
$link-color: $accent-color-1 !default;
$text-color: #333 !default;
$auto-forward-message-incoming-bg-color: #949ca1 !default;
$auto-forward-message-outgoing-bg-color: #4ba9c8 !default;
$dimmed-accent-color-1: lighten($accent-color-1, 0%) !default;
$dimmed-line-color: lighten($line-color, 3%) !default;
$dimmed-text-color: lighten($text-color, 25%) !default;
$escalation-color: #00918a !default;
$inactive-color: #df655f !default;
$incoming-message-color: #f4f4f4 !default;
$incoming-message-hover-color: #e8e8e8 !default;
$incoming-message-link-color: #35333c !default;
$link-color-active: darken($link-color, 20%) !default;
$outgoing-message-color: #dff7ff !default;
$outgoing-message-hover-color: #a7e9ff !default;
$outgoing-message-link-color: #35333c !default;
$patient-facing-message: #dfefeb !default;
$patient-color: #4a657b !default;
$patient-color-hover: #759cbb !default;
$primary-brand: #db524b !default;
$priority-message-bg-color: $primary-brand !default;
$priority-message-bubble-color: #fff8f0 !default;
$priority-message-hover-color: #f6e5d2 !default;
$priority-message-text-color: #d51609 !default;
$provider-broadcast-list: #3582dc !default;
$role-automated-color: #61798d !default;
$schedule-message-color: #307fdf !default;
$sms-opted-out-disabled-color: #adacb0;
$sms-opted-out-primary-color: #c17d62;
$strong-accent-color-1: darken($accent-color-1, 5%) !default;
$strong-line-color: darken($line-color, 3%) !default;
$strong-primary-brand: darken($primary-brand, 10%) !default;
$strong-text-color: darken($text-color, 25%) !default;
$surface-color: lighten($line-color, 8%) !default;
$tertiary: #eff3f6 !default;
$white: #fff !default;
$x-dimmed-accent-color-1: lighten($accent-color-1, 25%) !default;
$x-dimmed-line-color: lighten($line-color, 5%) !default;
$x-dimmed-surface-color: lighten($line-color, 9%) !default;
$x-dimmed-text-color: lighten($text-color, 40%) !default;
$x-strong-accent-color-1: darken($accent-color-1, 8%) !default;
$x-strong-line-color: darken($line-color, 5%) !default;
$x-strong-primary-brand: darken($primary-brand, 15%) !default;
$x-strong-text-color: darken($text-color, 40%) !default;
$xx-dimmed-accent-color-1: lighten($accent-color-1, 45%) !default;
$xx-dimmed-line-color: lighten($line-color, 8%) !default;
$xx-dimmed-surface-color: lighten($line-color, 10%) !default;
$xx-dimmed-text-color: lighten($text-color, 50%) !default;
$xx-strong-accent-color-1: darken($accent-color-1, 11%) !default;
$xx-strong-line-color: darken($line-color, 8%) !default;
$xx-strong-primary-brand: darken($primary-brand, 20%) !default;
$xx-strong-text-color: darken($text-color, 50%) !default;
$z-index: (
  popup: 1,
  modal: 100,
  overlay: 1000,
);
