@import '../../base';

@include b('MessageButton') {
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  width: 78px;
  height: 20px;
  border-radius: 10px;
  background-color: #eff5f6;
  font-size: 12px;
  color: #6a9fbf;
  font-weight: 500;

  @include if-ie {
    justify-content: space-around;
    padding: 0 5px;
  }
}
