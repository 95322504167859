@import '../base';
@import '../_vars';

@include b('OptInBanner') {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  gap: 10px;
  position: absolute;
  width: 267px;
  height: 44px;
  background: $card-hover-color;
  border-radius: 0 0 8px 8px;
  font-size: 12px;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;

  @include c('opt-in-text') {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: $patient-color;
    width: 126px;
  }

  @include c('hide-button') {
    padding: 5px 10px;
    cursor: pointer;
    color: $patient-color;
    font-weight: 500;
  }
}
