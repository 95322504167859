@import '../../base';

@include b('AlertCard') {
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid var(--neutral-300);
  border-radius: 10px;
  padding: 20px;

  @include c('text-view') {
    margin-bottom: 8px;
    white-space: pre-line;
    word-break: break-word;
    word-wrap: break-word;
  }

  @include c('alerts-list-buttons') {
    display: flex;
    justify-content: flex-end;
  }
}
