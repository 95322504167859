@import '../base';

@include b('BasicImage') {
  border-radius: inherit;
  height: 100%;
  width: 100%;

  @include c('container') {
    // background: hsl(0deg, 0%, 93%);
    background: var(--neutral-300);
    border-radius: inherit;
    height: 100%;
    width: 100%;
  }

  @include c('image') {
    height: 100%;
    width: 100%;
    object-fit: cover;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;

    &--classModifier--roles-transition {
      border-radius: 8px;
    }
  }
}
