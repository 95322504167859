@import '../base';

@include b('BuildInfo') {
  @include center-items-both;

  margin-bottom: 20px;

  &::before,
  &::after {
    content: '';
    display: block;
    flex: 1;
  }

  @include c('header-line') {
    margin: 10px 0;
    font-size: 16px;
    font-weight: 600;
  }

  @include c('body-line') {
    font-size: 14px;
  }

  @include c('body-line-title') {
    display: inline-block;
    width: 70px;
    font-weight: 500;
  }
}
