@import '../../base';
@import './PatientSettingMixins';

@include b('PatientBroadcastRow') {
  @include list-row;

  &:focus-visible {
    border: 3px solid var(--secondary-500);
    padding: 0;
  }

  @include c('list-item') {
    @include ellipsis;
    @include list-item-row;

    flex: 1;
    align-items: center;

    span {
      @include ellipsis;
    }

    @include c('broadcast-list-svg') {
      margin-right: 10px;
      vertical-align: middle;
      flex: 0 0 22px;
    }

    @include c('list-item-updated-by') {
      font-size: 11px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    @include c('list-item-display-name') {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      vertical-align: middle;
      flex: 1 1 auto;
    }
  }
}
