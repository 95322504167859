body .dropdownRoot {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  color: var(--neutral-1000);
  font-size: 13px;
  line-height: 20px;
  margin: 0 0 10px;
  padding: 0 31px 0 10px;
  border: solid 1px var(--neutral-400);
  cursor: pointer;
  overflow: hidden !important;
  width: 220px;
  height: 30px;
  border-radius: 0;
  background: url('../../images/sprite_icons.png') no-repeat top left;
  background-color: var(--neutral-100);
  background-position: 185px -1648px;
  appearance: none;
}

body:global(.theme-dark) .dropdownRoot {
  background: url('../../images/sprite_icons_dark.png') no-repeat top left;
  background-position: 185px -1648px;
}

.dropdownRoot:focus-visible {
  outline: none;
}

.dropdownRoot:disabled {
  background-color: var(--neutral-400);
  cursor: not-allowed;
  opacity: 0.7;
}
