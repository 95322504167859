@import '../../base';

@include b('RosterFilterBarButton') {
  position: relative;
  display: flex;
  justify-content: center;
  flex: 0 0 33.33%;
  color: var(--neutral-600);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  user-select: none;

  svg {
    stroke: var(--neutral-600);
  }

  &:hover {
    color: var(--neutral-800);

    svg {
      stroke: var(--neutral-800);
    }
  }

  @include m('isSelected') {
    color: var(--primary-700);

    svg {
      stroke: var(--primary-700);
      stroke-width: 1.5px;
    }

    &:hover {
      color: var(--primary-900);

      svg {
        stroke: var(--primary-900);
      }
    }
  }

  @include m('useSelectedLine') {
    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      border-style: solid;
      border-width: 1px;
      display: block;
      width: 99%;
      bottom: -16px;
      background-color: var(--primary-300);
      left: 0;

      @include if-ie {
        z-index: 0;
      }
    }
  }

  @include m('showUnreadCount') {
    @include b('UnreadBadgeCount-Text') {
      background-color: var(--primary-300);
    }

    @include b('UnreadBadgeCount-Button') {
      @include c('priority-button') {
        @include svg-background-and-icon(var(--primary-300), var(--neutral-100));
      }
    }

    &:hover {
      color: var(--primary-900);
    }
  }
}
