@import '../base';

@include b('MessageGroupEscalationChangeLog') {
  margin-top: 18px;
  text-align: center;
  font-size: 13px;
  color: #6e6e6e;

  @include m('precededByDateSeparator') {
    margin-top: 9px;
  }

  &:focus-visible {
    outline: 3px solid var(--secondary-500) !important;
  }
}
