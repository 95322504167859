@import '../../base';

@include b('DropdownList') {
  position: relative;

  @include c('dropdown-button') {
    font-family: inherit;
    font-size: 18px;
    font-weight: 400;
    color: var(--neutral-950);
    display: flex;
    flex-direction: column;

    &[disabled] {
      cursor: default;
    }
  }

  @include c('dropdown-container') {
    position: absolute;
    z-index: 2;
    left: 0;
    border-radius: 6px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.24);
    background-color: var(--neutral-100);
    padding: 10px;
    width: 100%;
  }

  @include c('dropdown-option-container') {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
      outline-offset: -3px;
    }

    padding: 10px;
    border-radius: 6px;
    width: 100%;
    height: 39px;

    &:hover {
      background-color: var(--patient-network-100);
    }

    &--isSelected,
    &--isOptionBolded {
      font-weight: 600;
    }

    @include m('isNoResultOption') {
      &:hover {
        background-color: var(--neutral-100);
      }
    }

    @include c('dropdown-option-item') {
      @include ellipsis;

      @include m('isNoResultOption') {
        color: var(--neutral-700);
      }

      text-align: left;
      flex: 1;
      font-size: 14px;
      line-height: 20px;
      padding-left: 3px;
    }
  }

  @include c('search-container') {
    width: 100%;
    display: flex;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 10px;

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
    }
  }

  @include c('search') {
    border-radius: 18px;
    border: solid 1px var(--neutral-300);
    background-color: var(--neutral-150);
    font-size: 14px;
    padding: 3px 10px;
    outline: none;
    width: 100%;
  }

  @include c('search-icon-container') {
    border-radius: 100%;
    background: var(--cool-neutral-100);
    cursor: pointer;
    height: 27px;
    width: 27px;
    right: 11px;
    top: 15px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
    }
  }

  @include c('search-icon') {
    width: 8px;
    height: 8px;
    color: var(--cool-neutral-700);
  }

  @include c('checkmark-svg') {
    padding-left: 5px;
  }

  @include c('mini-badge') {
    width: 12px;
    height: 12px;
    background-color: var(--secondary-500);
    border: 1px solid var(--neutral-100);
    border-radius: 50%;
    position: absolute;
    top: 6px;
    left: 0;
  }
}
