@import '../base';

@include b('ConversationMessages') {
  flex: 1;
  display: flex;
  flex-direction: column;

  @include c('loading-overlay') {
    position: relative;
    width: 100%;
    height: 100%;
    background: var(--neutral-100);
  }

  @include c('dots-container') {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @include c('dots-text') {
    padding-top: 10px;
    color: var(--neutral-700);
  }

  /* Dark Mode TODO: */
  @include c('alert-card-wrapper') {
    min-width: 320px;
    padding: 15px 15px 2px;
    background: linear-gradient(to bottom, var(--neutral-100), var(--neutral-100) 80%, rgba(255, 255, 255, 0.001));
  }

  @include c('message-list-wrapper') {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;

    @include b('ScrollerStickToBottom') {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }
}
