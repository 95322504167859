@import '../base';

@include b('CustomResponseList') {
  @include c('edit-container') {
    text-align: left;

    @include c('title') {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      color: var(--neutral-700);
      margin-bottom: 5px;
    }

    @include c('response-list-container') {
      padding: 4px;
      overflow-y: auto;

      @include c('dots-container') {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
      }

      @include c('message') {
        padding: 10px;
        background-color: var(--cool-neutral-300);
        margin-bottom: 5px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        border: 2px solid transparent;
        cursor: grab;
        color: var(--neutral-900);
        font-size: 14px;
        text-align: left;
        width: 100%;

        &:last-child {
          margin-bottom: 0;
        }

        textarea {
          resize: vertical;
          background-color: transparent;
          width: 100%;
          border-radius: 6px;
          height: auto;
        }

        @include c('text') {
          padding-left: 20px;
          padding-right: 10px;
          flex: 1;
          display: flex;
          align-items: center;

          @include c('clamp') {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            hyphens: auto;

            &:focus-visible {
              outline: 3px solid var(--secondary-500);
            }
          }

          &--blank {
            color: var(--neutral-700);
          }
        }

        @include c('action') {
          display: flex;
          align-items: center;

          svg {
            path {
              @include themify {
                fill: themed('status-text');
              }
            }
          }
        }

        &--dragHover {
          border: 2px dashed var(--cool-neutral-400);
        }

        @include c('delete-icon-holder') {
          box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0);
          width: 25px;
          height: 25px;
          border-radius: 13px;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.4s;
          cursor: pointer;
          padding-left: 1px;

          &:hover {
            box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.18);
            background-color: var(--neutral-100);
          }

          &:focus-visible {
            outline: 3px solid var(--secondary-500);
          }
        }
      }
    }

    @include c('add-message') {
      padding: 10px;
      background-color: var(--neutral-200);
      font-size: 13px;
      color: var(--secondary-500);
      font-weight: 600;
      justify-content: center;
      cursor: pointer;
      text-align: center;
      border-radius: 6px;
      margin-top: 5px;
      width: 100%;

      &:hover {
        background-color: var(--neutral-300);
      }
    }
  }

  @include c('view-container') {
    @include c('dots-container') {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
    }

    @include c('empty-container') {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      text-align: center;

      @include c('empty-message') {
        padding: 10px;
        font-size: 15px;
        margin-bottom: 5px;
        color: var(--cool-neutral-900);
      }

      @include c('empty-action') {
        padding: 10px;
        color: var(--secondary-700);
        background-color: var(--neutral-200);
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        font-size: 13px;
        font-weight: 600;

        &:hover {
          background-color: var(--neutral-300);
        }
      }
    }

    @include c('message-container') {
      flex: 1;
      overflow-y: auto;
      padding: 4px;

      @include c('message') {
        border-radius: 6px;
        background-color: var(--cool-neutral-300);
        padding: 10px;
        margin-bottom: 5px;
        font-size: 14px;
        color: var(--neutral-900);
        cursor: pointer;
        text-align: left;
        width: 100%;

        &:hover {
          background-color: var(--cool-neutral-400);
        }

        @include c('clamp') {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          hyphens: auto;
        }

        @include c('response-dots-container') {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 7px;
        }
      }
    }

    @include c('message-edit') {
      background-color: var(--neutral-200);
      border-radius: 6px;
      color: var(--secondary-500);
      cursor: pointer;
      font-size: 13px;
      font-weight: 600;
      margin-bottom: 0;
      padding: 10px;
      text-align: center;
      width: 100%;

      &:hover {
        background-color: var(--neutral-300);
      }
    }
  }
}
