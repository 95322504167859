@import '../../base';

@include b('AlertNurseHeader') {
  display: flex;
  align-items: center;
  margin-bottom: 14px;

  @include c('alert-severity') {
    border-radius: 17px;
    color: #fff;
    display: flex;
    justify-content: center;
    margin-right: 19px;
    width: 87px;
  }

  @include c('alert-time') {
    color: #8e8e8e;
    font-size: 14px;
  }
}
