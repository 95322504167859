@import '../base';

@include b('AddCareTeamModal') {
  @include c('header') {
    @include common-modal-header;

    color: var(--neutral-1000);

    &:first-child {
      font-size: 16px;
      font-weight: 400;
    }
  }

  @include c('body') {
    @include common-modal-body;

    padding: 27px 75px 56px;

    &--membersSelected {
      padding-bottom: 0;
    }

    @include c('row-header') {
      font-size: 12px;
      color: $xx-dimmed-text-color;
    }

    @include c('member-list') {
      position: relative;
      padding-bottom: 30px;

      &--overflow {
        height: 165px;
      }

      @include c('member-card') {
        display: flex;
        align-items: center;
        height: 60px;
        margin: 5px 0;
        border: solid 1px var(--neutral-300);
        border-radius: 6px;
        padding: 14px 15px 14px 10px;

        &:hover {
          background-color: var(--cool-neutral-100);

          @include c('remove-button') {
            display: block;
          }
        }

        @include c('member-details') {
          margin-left: 10px;

          @include c('member-name') {
            @include ellipsis;

            max-width: 250px;
            font-size: 15px;
            color: var(--neutral-900);
          }

          @include c('member-info') {
            @include ellipsis;

            display: block;
            max-width: 250px;
            font-size: 11px;
            color: var(--neutral-700);
          }
        }

        @include c('remove-button') {
          display: none;
          margin-left: auto;
          cursor: pointer;
          fill: var(--cool-neutral-700);
        }
      }
    }
  }

  @include c('footer') {
    @include common-modal-footer;

    display: flex;
    justify-content: flex-end;
    padding: 24px 30px;
    font-weight: 500;

    @include c('add-button') {
      @include common-button(var(--secondary-500), 74px, 32px, 11px);
    }

    @include c('cancel-button') {
      @include common-button(var(--neutral-800), 74px, 32px, 11px);

      margin-right: 10px;
    }
  }
}
