@import '../../base';

@include b('PatientContactInfo') {
  @include c('section') {
    padding: 12px 17px;
    color: var(--neutral-950);
    font-size: 12px;
    font-weight: 400;

    @include c('section-header') {
      margin-bottom: 3px;
      color: var(--neutral-600);
      font-size: 10px;
      text-transform: uppercase;
    }

    @include c('relation') {
      &--smsOptedOut {
        color: var(--neutral-600);
      }
    }

    @include c('phone-holder') {
      display: flex;

      @include c('phone-number') {
        flex: 1;

        @include c('sms-opted-out-info') {
          margin-top: 3px;
          color: var(--opt-out-100);
        }
      }

      @include c('phone-actions') {
        display: flex;
        flex-direction: row;

        @include c('phone-action') {
          padding-left: 5px;
        }
      }
    }
  }
}
