@import '../base';

@include b('ConversationOptionMenu') {
  margin: 0 4px;
  width: 28px;
  height: 20px;

  svg {
    width: 20px;
    height: 20px;
    color: var(--neutral-300);

    @include m('isSelected') {
      color: var(--cool-neutral-300);
    }

    &:hover {
      color: var(--neutral-500);
    }

    g {
      circle[fill='#4d4d4d'] {
        fill: var(--neutral-950);
      }
    }
  }

  @include c('option-button') {
    display: none;
    fill: var(--neutral-900);
  }
}
