@import '../../base';

@include b('VWRVisitReasonsModal') {
  @include c('header') {
    @include common-modal-header;

    text-align: center;
    font-size: 1.1em;
  }

  @include c('close-button') {
    @include common-modal-close-button;
  }

  @include c('modal') {
    display: flex;
    height: 100%;
    flex-direction: column;
  }

  @include c('inner-body') {
    @include common-modal-body;

    overflow-y: scroll;
    overflow-x: hidden;
    padding: 20px 33px;
  }

  @include c('footer') {
    padding: 20px;
    display: flex;
    justify-content: flex-end;
  }

  @include c('title') {
    font-size: 12px;
    color: #808080;
    margin-bottom: 5px;
  }

  @include c('message') {
    padding: 10px;
    background-color: #dff7ff;
    margin-bottom: 5px;
    border-radius: 6px;
    display: flex;
    border: 2px solid transparent;
    cursor: pointer;

    input {
      background-color: rgba(255, 255, 255, 0.5);
      width: 100%;
      padding: 6px 12px;
    }

    @include c('text') {
      padding-left: 20px;
      padding-right: 10px;
      flex: 1;
      white-space: pre-wrap;
      word-break: break-word;

      @include if-ie {
        word-break: break-all;
      }

      &--blank {
        color: #9b9b9b;
      }
    }

    @include c('action') {
      display: flex;
      align-items: center;
    }

    &--dragTarget {
      border: 2px dashed #91c4d4;
    }
  }

  @include c('add-message') {
    background-color: #fafafa;
    font-size: 13px;
    color: #3080df;
    font-weight: 600;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: #f2f2f2;
    }
  }

  @include c('delete-tooltip') {
    text-align: center;
  }

  @include c('delete-icon-holder') {
    background-color: rgba(255, 255, 255, 0);
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0);
    width: 25px;
    height: 25px;
    border-radius: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s;
    cursor: pointer;

    @include c('delete-icon') {
      width: 12px;
      height: 13px;
      overflow: visible;
    }

    &:hover {
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.18);
      background-color: rgba(255, 255, 255, 1);
    }
  }

  @include c('save-btn') {
    @include common-button(#3080df, 74px, 32px, 11px);

    &--isSaving {
      cursor: default;
      pointer-events: none;
    }
  }

  @include c('cancel-btn') {
    @include common-button($mid-grey, 74px, 32px, 11px);

    margin-right: 10px;
  }

  @include c('no-reasons-message') {
    margin: 10px;
    justify-content: center;
    display: flex;
  }
}
