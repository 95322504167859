@import '../../base';

@include b('AppointmentList') {
  @include c('appointment-container') {
    background-color: var(--neutral-100);
    border: solid 1px var(--neutral-300);
    cursor: pointer;
    display: flex;
    height: 67px;
    padding: 14px 22px;
    margin: 1px 3px;

    &--isChecked {
      background-color: var(--cool-neutral-300);
    }

    &:hover {
      cursor: pointer;
      background-color: var(--cool-neutral-300);
    }

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
    }
  }

  @include c('appointment-details') {
    display: flex;
  }

  @include c('appointment-visit-number') {
    color: var(--neutral-500);
    font-size: 12px;
    font-weight: 600;
    height: 16px;
    margin: 11px 0 12px;
    max-width: 220px;
    overflow: hidden;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 220px;
  }

  @include c('check-mark') {
    background-color: var(--neutral-100);
    border-radius: 2px;
    border: solid 1px var(--neutral-400);
    height: 14px;
    margin: 12px 12px 12px 0;
    padding: 1px;
    width: 14px;

    &--isChecked {
      background-color: var(--success-500);
      border: solid 1px var(--success-500);
    }
  }

  @include c('check-mark-svg') {
    margin-bottom: 4px;
  }

  @include c('appointment-provider-location') {
    color: var(--neutral-1000);
    display: flex;
    font-size: 14px;
    font-weight: 400;
    height: 19px;
    margin: 1px 0 0;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 700px;
  }

  @include c('appointment-provider') {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: auto;
  }

  @include c('appointment-location') {
    margin-left: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @include c('appointment-date-time') {
    color: var(--neutral-1000);
    font-size: 14px;
    font-weight: 800;
    height: 19px;
    margin: 0 9px 1px 0;
    text-align: left;
  }
}
