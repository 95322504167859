@import '../../base';

@include b('NewMessageSearchResult') {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  @include b('AvatarImage') {
    margin-right: 8px;
    flex: 42px 0 0;
    height: 42px;
    width: 42px;
  }
}
