@import '../../base';

@include b('MessageFormIndicator') {
  @include c('feature') {
    display: none;
    background-color: var(--neutral-100);
    border-radius: 30px;
    padding: 10px 30px;
    font-size: 13px;
    font-weight: 500;
    border: solid 1px var(--neutral-300);
    bottom: calc(100% + 10px);
    margin-left: calc(50% - 100px);
    width: 200px;
    text-align: center;
    position: absolute;

    &--isPriority {
      display: block;
      color: var(--primary-700);
    }

    // todo what colors should those be?
    &--isEscalated {
      display: block;
      color: var(--escalation-100);
    }

    &--alwaysEscalate {
      width: 256px;
      display: block;
      color: var(--escalation-100);
      padding: 10px;
    }
  }
}
