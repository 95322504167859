@import '../base';

@include b('JoinForumModal') {
  @include c('body') {
    @include common-modal-body;

    text-align: center;
    padding: 45px 50px 100px;
  }

  @include c('join-btn') {
    @include common-button(var(--secondary-500), 74px, 32px, 11px);
  }

  @include c('cancel-btn') {
    @include common-button(var(--neutral-800), 74px, 32px, 11px);

    margin-right: 10px;
  }

  @include c('forum-container') {
    display: flex;
    position: relative;
    margin: 0 31px;
    height: 156px;

    @include c('image-container') {
      position: relative;
      flex: 0 0 auto;

      @include c('image') {
        width: 156px;
        height: 156px;
        border-radius: 6px;
        background: var(--neutral-100);

        &--default {
          padding: 30px;
          background: var(--secondary-700);
          border-radius: 50%;
        }
      }

      @include c('overlay') {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 33px;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.5);
      }

      @include c('banner') {
        position: absolute;
        left: 9px;
        bottom: 8px;
      }
    }

    @include c('forum-details') {
      display: flex;
      flex-direction: column;
      max-width: 300px;
      padding-left: 31px;
      text-align: left;
      font-size: 12px;
      color: var(--neutral-800);

      span {
        font-weight: 500;
      }

      div:nth-child(2) {
        margin-top: auto;

        span {
          margin: 0 7px 4px 0;
        }
      }

      @include c('description') {
        max-width: 300px;
        font-size: 14px;

        span {
          display: block;
          font-size: 12px;
        }
      }
    }
  }
}
