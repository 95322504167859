@import '../../../base';

@include b('CSVSubmittedModal') {
  @include c('header') {
    @include common-modal-header;

    color: $display-text;
  }

  @include c('close-button') {
    @include common-modal-close-button;

    color: $interaction-signifier;
  }

  @include c('img') {
    width: 68px;
    height: 68px;
  }

  @include c('container') {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #4d4d4d;

    @include c('text') {
      font-size: 14px;
      padding: 0 50px 20px;
      word-break: break-word;

      @include if-ie {
        width: 100%;
        word-wrap: break-word;
      }
    }
  }

  @include c('footer') {
    @include common-modal-footer;

    display: flex;
    justify-content: flex-end;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    align-items: center;
    padding: 24px 28px;
    background-clip: padding-box;
  }

  @include c('ok-btn') {
    @include button-with-disable($interaction-signifier, 95px, 35px, 11px);

    padding-top: 2px;
  }
}
