@import '../../base';

@include b('About') {
  @include profile-detail-container;

  @include c('header') {
    @include profile-header;
  }

  @include c('info') {
    @include profile-detail-info;
  }

  @include c('info-header') {
    @include profile-sub-header;

    flex: 1;
  }

  @include c('version') {
    font-size: 12px;
    font-weight: 500;
    color: #9b9b9b;
  }

  @include c('autoupdate') {
    font-size: 12px;
    font-weight: 500;
    color: #9b9b9b;
  }
}
