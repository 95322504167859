@import '../../../styles/base';

@include b('GiphySelector') {
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
  flex-direction: column;
  position: relative;

  @include c('title') {
    padding-bottom: 12px;
  }

  @include c('gif') {
    height: 350px;
  }

  @include c('loading') {
    height: 350px;
    display: flex;
    align-items: center;
  }

  @include c('no-results') {
    display: flex;
    height: 350px;
    justify-content: center;
    align-items: center;
  }

  @include c('action-bar') {
    display: flex;
    height: 60px;
    margin: 10px;
  }

  @include c('action-button') {
    height: 39px;
    margin: auto 20px;
    border-radius: 4px;
    background-color: $structure-grey-lines;
    color: $white;
    padding: 11px;
    vertical-align: middle;

    &--send {
      background-color: $white;
      border: solid 1px $interaction-signifier;
      color: $interaction-signifier;
      cursor: pointer;

      &:hover {
        background-color: $interaction-signifier;
        color: $white;
      }

      &--isDisabled {
        cursor: default;
        background-color: $structure-grey-lines;
        color: $white;
        border: solid 1px $structure-grey-lines;

        &:hover {
          background-color: $structure-grey-lines;
          color: $white;
        }
      }
    }

    &--close {
      background-color: $primary-brand;
      cursor: pointer;

      &:hover {
        background-color: $primary-brand-hover;
      }
    }

    &--next {
      color: #9eb2bd;
      background-color: $white;
      border: solid 1px #9eb2bd;
      cursor: pointer;

      &:hover {
        background-color: #9eb2bd;
        color: $white;
      }
    }

    &--isDisabled {
      cursor: default;
      background-color: $structure-grey-lines;
      color: $white;
      border: solid 1px $structure-grey-lines;

      &:hover {
        background-color: $structure-grey-lines;
        color: $white;
      }
    }
  }

  @include c('container') {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 150px;
  }

  @include c('text') {
    color: #969696;
    align-self: center;
    font-weight: lighter;
    font-size: 14px;
  }

  @include c('preview-container') {
    margin-top: 10px;
    height: 120px;
  }

  @include c('preview-overflow-container') {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 120px;
    overflow: scroll hidden;
  }

  @include c('powered-by') {
    position: absolute;
    right: 0;
    bottom: 0;

    &--isDarkMode {
      filter: invert(1);
    }
  }
}
