@import '../../base';

@include b('SmsAutomatedOptHeader') {
  border-top-right-radius: 12px;
  background-color: $sms-opted-out-primary-color;
  box-shadow: 0 0 0 1px $sms-opted-out-primary-color;
  padding: 5px;
  color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;

  @include c('text') {
    padding: 0 10px 0 6px;
    font-size: 11px;
    font-weight: 900;
    margin-top: 2.5px;
  }
}
