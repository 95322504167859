@import '../base';

@include b('AlertDetailsModal') {
  @include c('body') {
    @include common-modal-body;

    position: relative;
    width: 616px;

    @include if-ie {
      & > div > div:first-child {
        max-height: 100vh;
      }
    }
  }

  @include c('message-container') {
    width: 616px;
    padding: 20px;
  }

  @include c('recipients-outer') {
    margin-bottom: 15px;

    @include c('recipients-inner') {
      padding: 5px 20px 20px;
    }
  }
}
