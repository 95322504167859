@import '../base';

@include b('CallChangeLog') {
  display: flex;
  justify-content: center;
  min-width: 250px;

  @include c('missed-call') {
    display: flex;
    flex-direction: column;
    background-color: var(--neutral-100);
    border-radius: 8px;
    border: 1px solid var(--neutral-300);
    margin: 20px;
    padding: 12px 14px 0;
    min-width: 300px;
    max-width: 450px;

    @include c('header') {
      display: flex;
      flex-direction: row;
      font-weight: 500;

      @include c('icon') {
        margin-right: 8px;
        width: 16px;
        height: 16px;
      }
    }

    @include c('supporting-text') {
      margin-left: 25px;
    }

    @include c('bang-text') {
      font-size: 13px;
      color: var(--neutral-800);
      flex: 0 1 auto;
    }

    @include c('red-text') {
      color: var(--errors-700);
    }

    @include c('call-again-container') {
      border-top: 1px solid var(--neutral-300);
      display: flex;
      justify-content: center;
      margin-top: 10px;
      padding: 10px 15px;
      width: 100%;

      @include c('call-again') {
        text-align: center;
        font-weight: 500;
        color: var(--secondary-500);
        cursor: pointer;
        white-space: nowrap;

        &:focus-visible {
          outline: 2px solid var(--secondary-500);
        }
      }

      @include c('permissions-text') {
        color: var(--neutral-800);
        font-size: 14px;
        font-weight: 500;
        text-align: center;
      }
    }

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
    }
  }
}
