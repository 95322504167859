@import '../base';

@include b('PatientForwardSuccessModal') {
  height: auto;
  width: 315px;
  padding: 24px 35px 45px;
  background-color: var(--neutral-950);
  opacity: 0.8;
  display: flex;
  color: var(--neutral-100);

  @include c('forwardSuccessHeader') {
    margin: 0;
  }

  @include c('body') {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include c('check-mark') {
      margin-bottom: 20px;
    }

    @include c('message-container') {
      margin-bottom: 8px;
      font-size: 14px;
      text-align: center;
      width: 100%;
    }
  }
}
