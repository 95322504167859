@import '../base';

@mixin input-error {
  border: 1px solid red;
}

@include b('Form') {
  @include m('theme', 'columns') {
    @include m('columns', '2') {
      @include b('FormFieldGroup') {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
      }

      @include b('FormField') {
        flex: 49% 0 0;

        &:nth-last-child(-n+2) {
          margin-bottom: 0;
        }
      }
    }

    @include b('FormField') {
      margin-bottom: 15px;

      @include m('type', 'checkbox') {
        @include c('checkbox-input') {
          margin-left: 4px;
        }
      }

      @include m('type', 'range') {
        @include c('range') {
          margin-left: 4px;
        }
      }

      @include c('label') {
        display: block;
      }

      @include c('label-text') {
        margin-left: 4px;
        margin-bottom: 5px;
        display: block;
      }

      @include b('FormInput') {
        @include m('type', 'textual-input') {
          width: 100%;
        }

        @include m('type', 'range-input') {
          width: 100%;
        }
      }

      @include c('error-message') {
        display: block;
        margin-top: 5px;
        font-size: 90%;
      }
    }
  }

  @include m('theme', 'rows') {
    @include b('FormField') {
      @include m('type', 'checkbox') {
        @include c('checkbox-input') {
          margin-left: 4px;
        }
      }

      @include m('type', 'range') {
        @include c('range') {
          margin-left: 4px;
        }
      }

      @include c('label') {
        display: block;
      }

      @include c('label-text') {
        margin-left: 4px;
        margin-bottom: 5px;
        display: block;
      }

      @include b('FormInput') {
        @include m('type', 'textual-input') {
          width: 100%;
        }

        @include m('type', 'range-input') {
          width: 100%;
        }
      }

      @include c('error-message') {
        display: block;
        margin-top: 5px;
        font-size: 90%;
      }
    }
  }

  @include b('FormFieldGroupSeparator') {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid $dimmed-line-color;
  }
}

@include b('FormField') {
  @include m('hidden') {
    display: none;
  }

  @include m('invisible') {
    visibility: hidden;
  }

  @include c('label-text') {
    font-size: 90%;
    color: $dimmed-text-color;
  }

  @include c('caption') {
    padding-left: 7px;
  }

  @include m('required') {
    @include c('required-indicator') {
      color: red;
    }
  }

  @include c('error-message') {
    color: red;
  }

  @include m('type', 'range') {
    @include c('label-caption') {
      color: $xx-strong-text-color;
      margin-left: 10px;
    }
  }
}

@include b('FormInput') {
  @include m('type', 'textual-input') {
    border: 1px solid $line-color;

    &:focus {
      border-color: $x-strong-line-color;
    }

    padding: 7px;

    // be explicit with error if form was submitted or input was changed
    @include m('form-submitted') {
      @include m('invalid') {
        @include input-error;
      }
    }

    @include m('dirty') {
      @include m('invalid') {
        @include input-error;
      }
    }
  }
}

@include b('FormButton') {
  @include m('type', 'submit') {
    @include primary-button;
  }

  @include c('content') {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;

    @include if-ie {
      padding-top: 0;
    }
  }

  @include c('loader') {
    width: 16px;
    height: 16px;
    color: $white;
    margin-left: 8px;
  }
}
