@import '../base';

@include b('NetworkStatusBar') {
  @include center-items-both;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 400px;
  height: 22px;
  margin: 0 auto;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 0.5px 1.5px -0.5px rgba(0, 0, 0, 0.44);
  color: #35333c;

  @include c('message') {
    text-align: center;
    font-size: 13px;
    font-weight: 500;

    @include if-ie {
      margin-bottom: 2px;
    }
  }

  @include m('status', 'ONLINE') {
    background-color: #bddbaf;
  }

  @include m('status', 'UNREACHABLE') {
    background-color: #efd95b;
  }

  @include m('status', 'CONNECTING') {
    background-color: #d3d3d3;
  }

  @include m('status', 'OFFLINE') {
    background-color: #ffbba2;
  }
}
