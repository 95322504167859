@import '../base';

@include b('PreserveConversation') {
  align-items: center;
  color: var(--neutral-800);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 10px 8px 20px;

  &:focus-visible {
    outline: 3px solid var(--secondary-500);
  }
}
