@import '../base';

@include b('ExistingConversation') {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  @include b('MessageForm') {
    min-height: 60px;
  }

  @include c('header-fade-element') {
    background:
      linear-gradient(
        180deg,
        var(--neutral-100),
        var(--neutral-100) 80%,
        hsla(0deg, 0%, 100%, 0.001)
      );
    height: 75px;
    position: absolute;
    top: -55px;
    left: 0;
    right: 0;
    z-index: 0;
  }

  @include c('typers') {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding-left: 15px;
    width: calc(100% - 8px);
    height: 35px;
    background-color: var(--neutral-100);
    font-size: 12px;
    color: var(--neutral-700);

    &--hasAttachments {
      bottom: 159px;
    }

    .tc-UserName {
      color: var(--neutral-700);
    }
  }
}
