@import '../../base';

$header-height: 123px;

@include b('PatientAdminList') {
  height: 100%;
  width: 100%;

  @include c('patient-admin-list-container') {
    height: calc(100% - #{$header-height});
    margin-top: 32px;
    width: 100%;

    &--topAlignment--search {
      height: calc(100% - #{$header-height} - 30px);
    }

    &--topAlignment--banner {
      height: calc(100% - #{$header-height} - 15px);
    }

    &--topAlignment--searchAndBanner {
      height: calc(100% - #{$header-height} - 55px);
    }
  }

  @include c('patient-admin-list') {
    position: relative;
    width: 100%;
    height: 100%;
  }

  @include c('patient-card') {
    position: relative;
  }

  @include c('text-container') {
    margin-top: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--neutral-800);
    font-style: italic;
  }
}
