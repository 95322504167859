@import '../../base';

@include b('AcknowledgeButton') {
  @include c('container') {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    width: 100%;
    padding: 0 11px 11px;
  }

  @include c('button') {
    margin: auto;
    padding: 8px;
    max-height: 34px;
    background-color: var(--cool-neutral-200);
    border-radius: 17px;
    font-size: 14px;
    font-weight: 400;
    color: var(--neutral-600);
    justify-content: center;
    width: 244px;
    text-align: center;
  }

  @include m('status', 'IN_PROGRESS') {
    color: var(--secondary-500);
    font-weight: 600;

    &,
    * {
      cursor: pointer;
      user-select: none;
    }
  }

  @include m('status', 'ACKNOWLEDGED') {
    display: inline-block;
    justify-content: center;
    width: 20px;
  }
}
