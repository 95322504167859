@import '../base';

@include b('CopyToClipboardButton') {
  display: flex;
  align-items: center;
  height: 22px;
  margin-right: 12px;
  border-radius: 4px;
  font-size: 12px;
  padding: 0 5px;
  color: #3080df;
  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
    background-color: #fff;
  }

  @include m('wasCopied') {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
    background-color: #3080df;
    color: #fff;
    cursor: default;
  }
}

.CopyToClipboardButton-tooltip {
  opacity: 0.7;

  .rc-tooltip-content {
    .rc-tooltip-arrow {
      background-color: #000 !important;
      border-color: transparent !important;
    }
  }
}
