@import '../../base';
@import './PatientSettingMixins';

@include b('TemplatesView') {
  @include patient-settings-view;

  @include c('header') {
    margin-bottom: 24px;

    @include flex-align-center;
    @include flex-justify-between;

    @include c('title') {
      @include header-text;
    }
  }

  @include c('actions') {
    margin-bottom: 24px;

    @include flex-justify-between;

    @include c('actions-left') {
      @include flex-align-center;

      @include c('create') {
        margin-right: 20px;

        @include pa-primary-button;
      }

      @include c('filters') {
        display: inline-block;
      }
    }

    @include c('actions-right') {
      @include c('actions-right-button') {
        @include bulk-delete;
      }
    }
  }

  @include c('loading-more') {
    @include loading-more;
  }
}
