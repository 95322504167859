@import '../../base';

@include b('PatientAdminCardContactDetails') {
  color: var(--neutral-1000);

  @include m('smsOptedOut') {
    color: var(--opt-out-100);
  }

  @include c('option-button-container') {
    display: flex;
    width: 100%;
    margin-left: -15px;
    position: absolute;
    justify-content: center;
    height: 37px;
    align-items: center;

    @include if-ie {
      margin: 0;
      display: flex;
      justify-content: center;
      height: inherit;
      align-items: center;
      width: 100%;
    }

    @include c('button-label') {
      font-size: 14px;
      text-align: left;
    }
  }

  @include c('option-button') {
    align-items: center;
    background-color: var(--patient-network-600);
    color: var(--neutral-100);
    display: flex;
    flex-direction: row;
    font-size: 14px;
    height: 33px;
    justify-content: space-around;
    margin-top: 3px;
    padding-right: 10px;
    width: 77px;

    &--isEditing {
      border-left: 1px solid var(--scheduling-primary-700);
      border-bottom: 1px solid var(--scheduling-primary-700);
      border-top: 1px solid var(--scheduling-primary-700);
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    &--isDeleting {
      border: 1px solid var(--scheduling-primary-700);
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }

    @include c('edit-button-icon') {
      display: flex;
      font-style: normal;
      height: 19px;
      margin-left: 2px;
      margin-right: -15px;
      margin-top: 7px;
      width: 18px;

      path {
        fill: var(--neutral-100);
      }
    }

    @include c('delete-button-icon') {
      display: flex;
      font-style: normal;
      height: 13px;
      margin-left: 6px;
      width: 13px;
      margin-bottom: 3px;

      path {
        stroke: var(--neutral-100);
      }
    }

    &:hover {
      @include c('edit-button-icon') {
        path {
          fill: var(--patient-network-600);
        }
      }

      @include c('delete-button-icon') {
        path {
          stroke: var(--patient-network-600);
        }
      }

      background-color: var(--neutral-100);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      color: var(--patient-network-600);
      cursor: pointer;
      font-style: normal;
    }

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
      z-index: 1;
    }
  }

  @include c('contact-row') {
    display: flex;
    height: 37px;
    margin: 5px 0;
    border-top: solid 1px var(--neutral-300);
    position: relative;

    &--smsOptedOut {
      height: 47px;
      padding-bottom: 5px;
    }
  }

  @include c('contact-row-left') {
    display: flex;
    flex: 1;
    align-items: flex-end;
  }

  @include c('contact-row-right') {
    display: flex;
    text-align: right;
    margin: 5px 12px 0 0;
  }

  @include c('title') {
    color: var(--neutral-700);
    font-size: 10px;
  }

  @include c('name') {
    display: block;
    font-size: 16px;
    font-weight: 500;
    max-width: 132px;
    min-width: 132px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media only screen and (max-width: 900px) {
      max-width: 70px;
      min-width: 70px;
    }

    @media only screen and (max-width: 1050px) and (-ms-high-contrast: none),
      screen and (max-width: 1050px) and (-ms-high-contrast: active) {
      max-width: 70px;
      min-width: 70px;
    }
  }

  @include c('detail-phone') {
    @include patient-admin-detail;

    min-width: 126px;
  }

  @include c('sms-opted-out-info') {
    color: var(--opt-out-100);
    font-size: 10px;
  }

  @include c('detail-relation') {
    @include patient-admin-detail;

    min-width: 162px;
  }

  &:focus-visible {
    outline: 3px solid var(--secondary-500);
  }
}
