@import '../../base';

@include b('PatientProfileModal') {
  @include c('header') {
    @include common-modal-header;

    color: var(--patient-network-700);
  }

  @include c('body') {
    @include common-modal-body;

    height: 700px;
    padding-top: 25px;
  }

  @include c('container') {
    position: relative;
    height: 100%;
    background-color: var(--cool-neutral-200);

    @include c('info-container') {
      padding: 0 34px 20px;
      background-color: var(--neutral-100);

      @include c('patient-header') {
        align-items: center;
        display: flex;
        flex-direction: column;
      }

      @include c('patient-actions') {
        display: flex;
        margin: 23px;
        justify-content: center;

        @include c('patient-action') {
          padding: 0 15px;
        }
      }

      @include c('patient-info') {
        display: flex;
        flex-direction: column;
        font-weight: 500;
        margin-top: 22px;
        text-align: center;

        @include c('patient-details') {
          display: flex;
          flex-direction: row;
          height: 18px;
          justify-content: center;
          margin-bottom: 2px;

          @include c('patient-name') {
            align-self: center;
            display: block;
            justify-content: center;
            max-width: 190px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &--smsOptedOut {
              color: var(--neutral-600);
            }
          }

          @include c('pill') {
            @include ellipsis;

            background-color: var(--patient-network-200);
            border-radius: 8px;
            border: solid 1px var(--patient-network-400);
            color: var(--patient-network-700);
            display: block;
            flex: 0 0 44px;
            font-size: 11px;
            font-stretch: normal;
            font-style: normal;
            font-weight: normal;
            letter-spacing: normal;
            margin-left: 4px;
            max-width: 135px;
            padding: 0 4px;

            @include if-ie {
              flex: 0 0 auto;
              width: 44px;
            }
          }
        }
      }

      @include c('section') {
        margin-bottom: 30px;
        font-weight: 400;

        @include c('section-header') {
          border-radius: 3px;
          background-color: var(--neutral-200);
          padding-left: 17px;
          font-size: 10px;
          color: var(--neutral-1000);
          text-transform: uppercase;
        }

        @include c('phone-number') {
          margin-top: 10px;
          margin-left: 18px;
          color: var(--neutral-1000);
          font-size: 12px;
        }

        @include c('patient-contact') {
          &:not(:last-child) {
            border-bottom: solid 1px var(--neutral-200);
          }
        }

        @include c('sms-opted-out-info') {
          margin-top: 3px;
          margin-bottom: -20px;
          margin-left: 18px;
          color: $sms-opted-out-primary-color;
          font-size: 12px;
        }
      }

      @include c('message-button') {
        display: flex;
        margin: 20px auto 40px;
        font-weight: 400;
      }
    }

    @include c('conversations-container') {
      padding: 28px 50px;
      border-top: 1px solid var(--patient-network-300);
      max-width: 498px;

      @include c('conversations-header') {
        margin-bottom: 8px;
        font-size: 10px;
        font-weight: 400;
        color: var(--neutral-1000);
      }

      @include c('conversation-item-container') {
        margin-bottom: 7px;
        cursor: pointer;

        &:focus-visible {
          outline: 3px solid var(--secondary-500);
        }
      }
    }
  }
}
