@import '../../base';

@include b('ProfileInfo') {
  @include profile-detail-container;

  margin-top: 30px;

  @include c('header') {
    @include profile-header;
  }

  @include c('dots-container') {
    @include center-items-both;

    height: 55px;
  }

  @include c('info') {
    @include profile-detail-info;

    &:hover {
      @include c('name-input') {
        border-bottom: dotted 1px var(--secondary-500);
      }
    }
  }

  @include c('info-header') {
    @include profile-sub-header;

    padding-bottom: 3px;
  }

  @include c('info-name') {
    flex: 1;
    font-size: 14px;
    color: var(--neutral-900);
  }

  @include c('name-input') {
    @include ellipsis;

    padding-bottom: 3px;
    border-bottom: dotted 1px var(--neutral-100);
    width: 95%;
    cursor: pointer;

    &:focus {
      background-color: var(--neutral-100);
      border-color: var(--secondary-500);
      outline: none;
      border-radius: 0;
    }

    &--isDirty {
      border-color: var(--secondary-500);
      outline: none;
    }
  }

  @include c('buttons') {
    display: flex;
  }

  @include c('remove-changes') {
    width: 32px;
    height: 32px;
    color: var(--cool-neutral-700);
    margin-right: 5px;
    cursor: pointer;
  }
}
