@import '../../base';

@include b('RoleSelector') {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  position: relative;
  height: 28px;

  @include c('menu') {
    display: none;

    &--isOpen {
      display: flex;
      flex-direction: column;
      position: absolute;
      background-color: var(--neutral-100);
      border-radius: 6px;
      border: solid 1px var(--neutral-300);
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
      bottom: calc(100% + 5px);
      left: 0;
      width: 250px;

      &:focus-visible {
        outline: 3px solid var(--seconday-500);
      }
    }

    @include c('menu-title') {
      display: flex;
      align-items: center;
      height: 28px;
      padding: 7px 0 0 9px;
      font-size: 10px;
      font-weight: 500;
      color: var(--neutral-500);
    }

    @include c('menu-contents') {
      width: 100%;
    }

    @include c('menu-contents-line') {
      display: flex;
      align-items: center;
      height: 28px;
      padding-left: 9px;
      cursor: pointer;
      user-select: none;
      width: 100%;

      &:hover {
        background-color: var(--cool-neutral-30);

        &:last-child {
          border-radius: 0 0 6px 6px;
        }

        &:focus-visible {
          outline: 3px solid var(--secondary-500);
        }
      }

      &--is-current {
        background-color: var(--neutral-200);

        &:last-child {
          border-radius: 0 0 6px 6px;
        }

        &:focus-visible {
          outline: 3px solid var(--secondary-500);
        }
      }

      &--is-disabled {
        cursor: not-allowed;

        &:hover {
          // background-color: #e1e5e6;
          background-color: var(--neutral-300);

          &:last-child {
            border-radius: 0 0 6px 6px;
          }
        }
      }

      &:focus-visible {
        outline: 3px solid var(--secondary-500);
      }
    }

    @include c('role-dot') {
      @include circle(17px, inherit);

      flex: 0 0 17px;
    }

    @include c('menu-name') {
      @include ellipsis;

      margin-left: 4px;
      font-size: 12px;
      font-weight: 500;
      color: var(--neutral-800);
      flex: 1 1 auto;
    }
  }

  @include c('send-as-container') {
    display: flex;
    align-self: flex-end;
    align-items: center;
    flex: 0 0 76px;
    height: 28px;
  }

  @include c('send-as') {
    font-size: 12px;
    font-weight: 400;
    color: var(--neutral-600);
  }

  @include c('selected-tile-container') {
    display: flex;
    flex: 1;
    flex-shrink: 0;
    height: 28px;
  }

  @include c('selected-tile') {
    @include center-items-both;

    flex-shrink: 0;
    height: 28px;
    margin: auto 0;
    padding: 0 19px;
    border-radius: 6px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
    background-color: var(--neutral-100);
    font-size: 14px;
    font-weight: 400;
    white-space: nowrap;
    color: var(--neutral-1000);

    &--is-clickable {
      cursor: pointer;
      user-select: none;
    }

    &--is-role-light {
      color: var(--neutral-100);
    }

    &--is-role-dark {
      color: var(--neutral-600);
    }
  }
}
