@import '../../base';

@include b('PatientReferenceCard') {
  @include m('detail') {
    @include c('container') {
      width: 100%;

      @include c('patient-detail-container') {
        display: flex;
        border: 1px solid var(--neutral-600);
        background: var(--neutral-150);
        border-radius: 6px;
        align-items: center;
        padding-right: 15px;
        width: inherit;

        &:hover {
          background: var(--neutral-300);
        }

        @include c('patient-detail') {
          padding: 6px 0;
          flex: 1 1 auto;
        }

        @include c('cancel-icon-container') {
          cursor: pointer;
        }

        @include c('loading-container') {
          margin-top: 12px;
          margin-bottom: 18px;
          text-align: center;
          flex: 1 1 auto;
        }
      }
    }
  }

  @include m('editor') {
    @include c('container') {
      margin: 10px 8px;

      @include c('header') {
        background: var(--neutral-100);
        padding: 6px 12px;
        font-size: 12px;
        border: 1px solid var(--neutral-300);
        border-bottom: none;
        color: var(--neutral-900);
        text-transform: uppercase;
        font-weight: 600;
        border-radius: 12px 12px 0 0;
      }

      @include c('patient-detail-container') {
        border: 1px solid var(--neutral-300);
        background: var(--neutral-150);
        border-radius: 0 0 12px 12px;

        &:hover {
          background: var(--neutral-300);
        }

        @include c('patient-detail') {
          padding: 6px 0;
        }

        @include c('loading-container') {
          margin-top: 12px;
          margin-bottom: 18px;
          text-align: center;
        }
      }
    }
  }

  @include m('failed') {
    @include c('container') {
      @include c('patient-detail-container') {
        border: 1px solid var(--errors-500);
        background: var(--errors-100);
        padding: 9px 8px;
        display: flex;
        align-items: center;

        &:hover {
          background: var(--errors-100);
        }

        @include c('error-text') {
          padding-left: 6px;
          flex: 1 1 auto;
          color: var(--neutral-1000);

          @include c('error-header') {
            font-weight: 500;
            font-size: 14px;
          }

          @include c('error-subline') {
            font-size: 12px;
          }
        }

        @include c('retry-button-container') {
          @include c('retry-button') {
            background: var(--errors-500);
            border-radius: 200px;
            padding: 9px 35px;
            color: var(--neutral-100);
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
