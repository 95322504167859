@import '../../base';

@include b('PresenceIndicatorUnavailable') {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  border: 2px solid var(--neutral-100);
  background-color: var(--neutral-100);

  @include m('size', 'LARGE') {
    @include circle(20px);
  }

  @include m('size', 'MEDIUM') {
    @include circle(16px);
  }

  @include m('size', 'SMALL') {
    @include circle(10px);

    border: 1px solid var(--neutral-100);
  }

  @include m('size', 'TINY') {
    @include circle(7px);

    border: 1px solid var(--neutral-100);
  }
}
