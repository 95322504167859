@import '../../base';

@include b('PatientConversationItem') {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  position: relative;
  height: 100%;
  width: 100%;
  background: var(--neutral-100);
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.29);
  text-align: left;

  &:focus-visible {
    outline: 3px solid var(--secondary-500);
  }

  &:hover {
    background: var(--patient-network-300);

    @include avatar-indicator('PresenceIndicatorAvailable', var(--patient-network-300));
    @include avatar-indicator('PresenceIndicatorDnd', var(--patient-network-300));
    @include avatar-indicator('RoleIndicator', var(--patient-network-300));
  }

  @include m('isMainRosterSelected') {
    background: var(--cool-neutral-300);

    @include avatar-indicator('PresenceIndicatorAvailable', var(--cool-neutral-300));
    @include avatar-indicator('PresenceIndicatorDnd', var(--cool-neutral-300));
    @include avatar-indicator('RoleIndicator', var(--cool-neutral-300));

    &:hover {
      background: var(--cool-neutral-300);

      @include avatar-indicator('PresenceIndicatorAvailable', var(--cool-neutral-300));
      @include avatar-indicator('PresenceIndicatorDnd', var(--cool-neutral-300));
      @include avatar-indicator('RoleIndicator', var(--cool-neutral-300));
    }
  }

  @include c('contents') {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    position: relative;
    height: 100%;
    width: 100%;
  }

  @include c('contents-patient') {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    position: relative;
    height: 100%;
    width: 100%;
  }

  @include c('left-pane') {
    align-items: top;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    position: relative;
  }

  @include c('avatar') {
    $avatar-size: 42px;

    margin: 11px 7px 0 14px;
    position: relative;
    flex: $avatar-size 0 0;

    @include b('AvatarImage') {
      width: $avatar-size;
      height: $avatar-size;
      border-radius: $avatar-size;
    }
  }

  &--group {
    @include c('avatar') {
      @include b('AvatarImage') {
        color: $avatar-foreground-group;
      }
    }
  }
}
