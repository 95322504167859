@import '../../base';
@import './PatientSettingMixins';

@include b('AccessPicker') {
  @include c('staff-list') {
    width: 500px;

    @include c('staff') {
      display: flex;
      margin-bottom: 10px;

      @include c('name') {
        font-size: 13px;
        font-weight: 500;
        color: var(--neutral-1000);
        flex: 2;
        display: flex;

        @include c('name-text') {
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      @include c('remove-icon') {
        cursor: pointer;
        width: 18px;
        height: 18px;
        margin-right: 6px;

        circle {
          fill: var(--cool-neutral-100);
        }

        path {
          fill: var(--cool-neutral-700);
        }
      }
    }
  }

  @include c('switch-container') {
    font-size: 12px;
    color: var(--neutral-950);
    display: flex;
    flex: 1;
    justify-content: flex-end;

    @include c('notification') {
      margin-right: 20px;
    }

    .switch {
      overflow: hidden;
    }

    :focus-visible {
      outline: 3px solid var(--secondary-500);
    }
  }
}
