@import '../../base';

@include b('SearchSidebar') {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-right: 1px solid var(--neutral-300);
  background-color: var(--neutral-150);
  font-size: 15px;
  z-index: 2;
  overflow: hidden;

  @include c('flex-container') {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;

    &--isPatientSearch {
      background-color: var(--neutral-200);
    }

    &--loadingDotsInTheBottomOfList {
      background-color: var(--neutral-100) !important;
    }
  }

  @include c('fade-enter') {
    opacity: 0.01;

    @include c('fade-enter-active') {
      opacity: 1;
      transition: opacity 150ms cubic-bezier(0.22, 1, 0.36, 1);
    }
  }

  @include c('fade-leave') {
    opacity: 1;

    @include c('fade-leave-active') {
      opacity: 0.01;
      transition: opacity 50ms cubic-bezier(0.22, 1, 0.36, 1);
    }
  }

  @include c('flex-absolute-center') {
    @include center-items-both;

    flex: 1 0 auto;
    width: 100%;
    height: 100%;
    position: absolute;
    padding-bottom: 150px;

    @include c('blank-text') {
      color: var(--neutral-700);
      font-size: 19px;
      text-align: center;
    }
  }

  @include c('wrapper') {
    flex: auto;
    display: flex;
  }

  @include c('dots') {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex !important;
    justify-content: center;
  }
}
