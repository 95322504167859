@import '../../base';

@include b('PatientReferenceSearchResults') {
  display: flex;
  flex-direction: column;

  @include c('search-results-container') {
    flex: 1 1 auto;

    @include c('search-results-page-loader') {
      text-align: center;
      font-size: 18px;
      color: var(--neutral-800);
      margin: 20px 0;
    }

    @include c('search-results-text-response') {
      color: var(--neutral-800);
      display: flex;
      font-size: 18px;
      justify-content: center;
      margin-top: 20%;
      text-align: center;
    }
  }
}
