@import '../base';

@include b('AvatarDropzone') {
  cursor: pointer;

  &:hover {
    @include c('overlay') {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @include c('avatar-upload-container') {
    position: relative;
    width: 100%;
    height: 100%;
  }

  @include c('overlay') {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.3);
    z-index: 1;
    border-radius: 50%;

    @include c('edit-avatar-text') {
      z-index: 3;
      background-color: $white;
      color: $interaction-signifier;
      padding: 2px 9px;
      font-size: 11px;
      border-radius: 12px;
    }
  }
}
