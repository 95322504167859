@import '../../base';
@import '../../DayPickerGradients';

@include b('ScheduledMessagesDateRangePicker') {
  @import '~react-dates/lib/css/datepicker';

  .PresetDateRangePicker_button {
    border: 2px solid var(--patient-network-700);
    color: var(--patient-network-700);
  }

  .CalendarMonth {
    background: var(--neutral-100);
  }

  .DayPicker {
    background: var(--neutral-100);
  }

  .DayPickerNavigation_button__default {
    &:active {
      background: var(--neutral-200);
    }

    &:focus {
      border: 1px solid var(--neutral-400);
    }

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
    }
  }

  .CalendarMonthGrid {
    background: var(--neutral-100);
  }

  .CalendarDay__default {
    border: 1px solid var(--neutral-200);
    color: var(--neutral-950);
  }

  .CalendarDay__blocked_out_of_range {
    background: var(--neutral-100);
    border: 1px solid var(--neutral-200);
    color: var(--neutral-400);
  }

  .CalendarDay {
    background: var(--neutral-100);
    display: table-cell;
    vertical-align: middle;

    &:hover {
      background: var(--cool-neutral-700);
      border: 1px solid var(--cool-neutral-700);
    }

    &__selected_span {
      background: var(--cool-neutral-300);
      color: var(--neutral-1000);
      border: 1px solid var(--neutral-200);
    }

    &__selected,
    &__selected:active {
      color: var(--neutral-100);
      border: 1px solid var(--neutral-200);

      @include gradient-selected-day-hover;

      &:hover {
        border: 1px solid var(--neutral-200);

        @include gradient-selected-day-hover;
      }
    }

    &__hovered_span,
    &__hovered_span:hover {
      background: var(--cool-neutral-300);
      border: 1px solid var(--neutral-200);
      color: var(--patient-network-700);
    }

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
    }
  }

  @include c('start-date-selected') {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    @include gradient-selected-day-hover-to-left;
  }

  @include c('end-date-selected') {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    @include gradient-selected-day-hover-to-right;
  }

  @include c('header') {
    font-size: 16px;
    padding: 20px;
    color: var(--neutral-1000);
    font-weight: bold;
  }

  @include c('inputs-container') {
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;

    @include c('input-container') {
      flex-direction: column;

      @include c('input') {
        width: 96%;
        background-color: var(--neutral-100);
        border-radius: 6px;
        border: solid 1px var(--neutral-400);
        color: var(--neutral-1000);
        font-size: 13px;
        line-height: 28px;
        height: 38px;
        padding: 3px 15px;

        &:focus-visible {
          outline: 3px solid var(--secondary-500);
        }
      }
    }
  }

  @include c('button-container') {
    text-align: right;
    padding: 10px 20px;

    @include c('button-clear') {
      background-color: var(--neutral-100);
      border-radius: 6px;
      border: solid 1px var(--neutral-400);
      color: var(--patient-network-700);
      font-size: 13px;
      line-height: 28px;
      padding: 0 15px;
      margin-right: 10px;
    }

    @include c('button-apply') {
      background-color: var(--patient-network-700);
      border: solid 1px var(--patient-network-700);
      border-radius: 6px;
      color: var(--neutral-100);
      font-size: 13px;
      line-height: 28px;
      padding: 0 15px;
    }
  }
}
