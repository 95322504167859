@import '../../base';

@include b('MentionsButton') {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  cursor: pointer;

  @include c('image') {
    path {
      fill: var(--neutral-700);
    }

    &:hover {
      path {
        fill: var(--secondary-500);
      }
    }
  }
}
