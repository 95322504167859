@import '../../../base';

@include b('ChangePassword') {
  padding-left: 20px;
  width: 100%;

  &:focus {
    outline: none;
  }

  @include c('container') {
    display: flex;
    align-items: center;
    padding: 15px 0 5px;
  }

  @include c('header') {
    @include profile-sub-header;

    width: 160px;
  }

  @include c('form-current') {
    flex: 1;
    font-size: 14px;

    input {
      color: $display-text;
    }
  }

  @include c('form') {
    flex: 1;
    font-size: 14px;

    input {
      color: $primary-brand;
    }

    &--new {
      input {
        color: #04a74d;
      }
    }

    &--reEnterNew {
      input {
        color: #04a74d;
      }
    }
  }

  @include c('input') {
    @include ellipsis;

    padding-bottom: 3px;
    border-bottom: dotted 1px $white;
    width: 95%;
    cursor: pointer;

    &:focus {
      background-color: $white;
      border-color: #3080df;
      outline: none;
      border-radius: 0;
    }
  }

  @include c('buttons-container') {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;
  }

  @include c('remove-changes') {
    width: 32px;
    height: 32px;
    color: #9eb2bd;
    margin-right: 5px;
    cursor: pointer;
  }
}
