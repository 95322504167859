@import '../base';

@include b('AppSelectorButtons') {
  @include center-items-both;

  flex: 0 0 auto;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  @include c('button') {
    @include center-items-both;

    flex: 0 0 auto;
    flex-direction: column;
    cursor: pointer;
    margin-top: 15px;
    position: relative;
    border-radius: 10px;
  }

  @include c('icon') {
    fill: #9baab6;
  }

  @include c('bg-icon') {
    @include center-items-both;

    width: 40px;
    height: 40px;
    background-color: var(--cool-neutral-200);
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.22);

    &:hover {
      box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.315);
    }
  }

  @include m('button', 'Messenger') {
    @include center-items-both;

    @include c('bg-icon') {
      @include center-items-both;

      &--selected {
        background-color: var(--neutral-100);
      }

      @include c('icon') {
        width: 40px;
        height: 40px;
      }
    }
  }

  @include m('button', 'PatientSettings') {
    @include center-items-both;

    @include c('bg-icon') {
      @include center-items-both;

      &--selected {
        background-color: var(--patient-network-700);

        @include c('icon') {
          fill: var(--neutral-100);
        }
      }

      @include c('icon') {
        height: 30px;
        padding-bottom: 2px;
        width: 30px;
      }
    }
  }

  @include m('button', 'Roles') {
    @include center-items-both;

    @include c('bg-icon') {
      @include center-items-both;

      &--selected {
        background-color: #6ccab8;

        @include c('icon') {
          fill: white;
        }
      }

      @include c('icon') {
        width: 20px;
        height: 20px;
      }
    }
  }

  @include m('button', 'Collaboration') {
    @include center-items-both;

    @include c('bg-icon') {
      @include center-items-both;

      @include c('icon') {
        width: 20px;
        height: 20px;

        &--teamsEnabled {
          path {
            stroke: #9baab6;
          }
        }

        path {
          fill: #9baab6;
        }
      }

      &--selected {
        background-color: #82c8b8;

        @include c('circle') {
          background: #fff;
        }

        @include c('icon') {
          &--teamsEnabled {
            path {
              stroke: #82c8b8;
            }
          }

          path {
            fill: white;
          }
        }

        @include c('small-icon') {
          fill: #82c8b8;
        }
      }
    }
  }

  @include m('button', 'Analytics') {
    @include center-items-both;

    @include c('bg-icon') {
      &--selected {
        background-color: #42a6c7;

        @include c('icon') {
          fill: white;
        }
      }

      @include c('icon') {
        width: 23px;
        height: 23px;
      }
    }
  }

  @include m('button', 'Settings') {
    @include center-items-both;

    @include c('bg-icon') {
      &--selected {
        background-color: #d52331;

        @include c('icon') {
          fill: white;
        }
      }

      @include c('icon') {
        width: 23px;
        height: 23px;
      }
    }
  }

  @include m('button', 'VirtualWaiting') {
    @include center-items-both;

    @include c('bg-icon') {
      @include center-items-both;

      &--selected {
        background-color: #4a657b;

        @include c('icon') {
          fill: white;
        }
      }

      @include c('icon') {
        width: 21px;
        height: 18px;
      }
    }
  }

  @include m('button', 'PatientDirectory') {
    @include center-items-both;

    @include c('bg-icon') {
      @include center-items-both;

      &--selected {
        background-color: var(--secondary-500);

        @include c('icon') {
          fill: var(--neutral-100);
        }
      }

      @include c('icon') {
        height: 30px;
        padding-bottom: 2px;
        width: 30px;
      }
    }
  }

  @include m('button', 'BroadcastLists') {
    @include center-items-both;

    @include c('bg-icon') {
      @include center-items-both;

      @include c('icon') {
        height: 50px;

        path {
          fill: #9baab6;
          stroke: #9baab6;
        }
      }

      &--selected {
        background-color: #4a657b;

        @include c('icon') {
          path {
            fill: #fff;
            stroke: #fff;
          }
        }
      }
    }
  }

  @include c('mini-badge') {
    width: 12px;
    height: 12px;
    background-color: #007aff;
    border: 1px solid #fff;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 9px;
  }

  @include c('text') {
    color: var(--neutral-800);
    font-size: 10px;
    letter-spacing: 0.6px;
    margin-top: 7px;
    width: 71px;
    word-wrap: break-word;
  }

  @include c('location') {
    position: absolute;
    margin-right: 1px;

    @include c('container') {
      position: relative;
    }

    @include c('circle') {
      @include circle(18px);
      @include center-items-both;

      background-color: var(--cool-neutral-100);
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 2px;
      left: 2px;

      @include if-ie {
        left: -8px;
      }
    }

    @include c('small-icon') {
      fill: #9baab6;
      width: 14px;
      height: 14px;
    }
  }

  @include c('unread-count') {
    position: absolute;
    border: 1px solid white;
    border-radius: 20px;
    top: 20px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
    margin-left: 15px;

    @include if-ie {
      right: 8px;
    }
  }

  @include c('unread-text') {
    @include if-ie {
      padding: 0 6px 1px;
    }
  }

  @-moz-document url-prefix('') {
    @include c('unread-count') {
      margin-left: 30px;
    }
  }
}
