@import '../../base';

@include b('DummyPatientConversationItem') {
  height: 48px;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.29);
  background-color: var(--cool-neutral-300);
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  position: relative;
  width: 100%;

  @include c('left-pane') {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    position: relative;
    height: 48px;
    padding-left: 11px;
  }

  @include c('avatar-image-container') {
    @include center-items-both;

    $avatar-size: 40px;

    margin: 0 8px 0 4px;
    position: relative;

    &--entity {
      flex: $avatar-size 0 0;

      @include b('AvatarImage') {
        width: $avatar-size;
        height: $avatar-size;
        border-radius: 50%;
      }
    }

    @include c('avatar-image') {
      width: $avatar-size;
      height: $avatar-size;
      cursor: pointer;
      background-color: var(--patient-network-700);
      padding: 5px;
      border-radius: 50%;

      path {
        fill: var(--neutral-100);
      }
    }

    @include c('blank-avatar') {
      @include center-items-both;
      @include circle;

      width: $avatar-size;
      height: $avatar-size;
      background-color: var(--patient-network-700);
      padding-top: 1px;
    }
  }

  @include c('details') {
    display: flex;
    height: 100%;
    width: 100%;
    padding: 16px 9px 5px 0;
  }

  @include c('row') {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 19px;
  }

  @include c('text') {
    @include ellipsis;

    flex: 1;
    line-height: 1.3;
    font-size: 14px;
    font-weight: 600;
    color: var(--neutral-950);
  }

  @include c('close-button-container') {
    @include center-items-both;

    width: 20px;
    height: 20px;
    background-color: #8bd9f3;
    border-radius: 50%;
  }

  @include c('close-button') {
    width: 10px;
    fill: var(--cool-neutral-300);
    cursor: pointer;
  }
}
