@import '../../base';

@include b('AlertEscalateHeader') {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  padding: 10px;

  svg {
    width: 17px;
    height: 15px;
    margin-right: 10px;
  }
}
