@import '../../base';

@include b('ConversationPane') {
  display: flex;
  flex: 1;

  @include c('loading') {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  @include c('contents') {
    position: relative;
    display: flex;
    flex: 1;
  }

  @include c('container') {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: var(--neutral-100);
  }

  @include c('layout') {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: var(--neutral-100);
  }

  @include c('header') {
    align-items: center;
    width: 100%;
    padding: 15px 15px 16px;
    position: relative;
    color: var(--neutral-1000);

    &--isInfoPaneOpen {
      padding: 15px 15px 16px;
    }
  }

  @include c('placeholder-container') {
    @include center-items-both;

    flex: 1;
  }

  @include c('placeholder-text') {
    flex: 1;
    text-align: center;
    font-size: 19px;
    color: var(--neutral-700);
  }
}
