@import '../base';

@include b('AlwaysEscalateModal') {
  @include c('header') {
    background-color: #00918a;
    text-align: center;
    color: white;
    border-radius: 5px 5px 0 0;
    height: 122px;
    font-size: 18px;
    font-weight: 600;
    width: 100%;
  }

  @include c('img') {
    display: block;
    margin: 35px auto 10px;
    border-radius: 0;
    box-shadow: none;
    height: 30px;
    width: 35px;
  }

  @include c('body') {
    background-color: $alert-bg-body;
  }

  @include c('body-text') {
    border-bottom: solid 1px $dimmed-line-color;
    line-height: 1.6em;
    vertical-align: middle;
    font-weight: 500;
    padding: 20px 20px 55px;
    color: $alert-text-color;
  }

  @include c('button') {
    text-align: center;
    background-color: $alert-bg-button;
    color: #058cff;
    font-weight: 500;
    padding: 20px 0;
    cursor: pointer;
    border-radius: 0 0 5px 5px;

    &:hover {
      background-color: #eee;
    }
  }
}
