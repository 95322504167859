@import '../base';

@include b('AssigneeSearchBox') {
  background-color: #f3f6f7;
  border-radius: 3px;
  border: solid 1px #e4e7ea;
  height: auto;
  position: absolute;
  right: 15px;
  top: 10px;
  width: 353px;
  z-index: 2;

  @include c('search-results') {
    overflow-y: auto;
    max-height: 275px;
  }

  @include c('individuals-tab') {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3px 10px 4px;
    gap: 10px;
    height: 26px;
    mix-blend-mode: multiply;
    order: 0;
    align-self: stretch;
  }

  @include c('limited-results-tab') {
    align-content: center;
    align-items: center;
    align-self: stretch;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    font-weight: 550;
    gap: 10px;
    height: 36px;
    justify-content: center;
    padding: 3px 10px 4px;
  }

  @include c('entity-info') {
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    flex-direction: column;
    padding-bottom: 12px;
    padding-top: 15px;
    width: 100%;
  }

  @include c('entity-details') {
    @include ellipsis;

    color: #898989;
    display: flex;
    font-size: 10px;
    height: 14px;
    height: auto;
    width: auto;

    @include c('entity-title') {
      @include ellipsis;

      margin-right: 3px;
      max-width: 130px;
    }
  }

  @include c('no-search-results') {
    align-items: center;
    color: #898989;
    display: flex;
    font-style: italic;
    justify-content: center;
    margin: 40px;
  }

  @include c('search-outer') {
    align-items: center;
    align-self: stretch;
    background-color: #fff;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: 46px;
    order: 0;
    padding: 8px 0 8px 10px;

    &:hover {
      background-color: #e4f6fe;
    }
  }

  @include c('search-container') {
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 18px;
    border: solid 1px #eaeaea;
    padding: 0 3px 0 12px;
    height: 28px;
    width: 320px;
    margin: 15px auto;

    @include c('search-text') {
      color: #8698a5;
      font-size: 14px;
      height: 19px;
      width: 100%;

      input {
        background: #fbfcfc;
        width: 100%;
      }

      input::placeholder {
        color: #8698a5;
      }
    }

    @include c('close-container') {
      background-color: #eff3f6;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      height: 22px;
      justify-content: center;
      align-items: center;
      width: 22px;

      svg {
        height: 8px;
        width: 8px;
        fill: #a2b0bc;
      }

      &--hide {
        visibility: hidden;
      }
    }
  }
}
