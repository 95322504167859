@mixin gradient-day-hover {
  background:
    radial-gradient(
      ellipse at center,
      var(--neutral-200) 0%,
      var(--neutral-200) 60%,
      var(--neutral-100) 63%,
      var(--neutral-100) 100%
    );
}

@mixin gradient-selected-day-hover {
  background:
    radial-gradient(
      ellipse at center,
      var(--patient-network-700) 0%,
      var(--patient-network-700) 60%,
      rgba(255, 255, 255, 0) 63%,
      rgba(255, 255, 255, 0) 100%
    );
}

@mixin gradient-selected-day-hover-to-right {
  background:
    radial-gradient(
      ellipse at center,
      var(--patient-network-700) 0%,
      var(--patient-network-700) 60%,
      rgba(255, 255, 255, 0) 63%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(
      to right,
      var(--cool-neutral-300) 0%,
      var(--cool-neutral-300) 50%,
      rgba(255, 255, 255, 0) 53%,
      rgba(255, 255, 255, 0) 100%
    );
}

@mixin gradient-selected-day-hover-to-left {
  background:
    radial-gradient(
      ellipse at center,
      var(--patient-network-700) 0%,
      var(--patient-network-700) 60%,
      rgba(255, 255, 255, 0) 63%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(
      to left,
      var(--cool-neutral-300) 0%,
      var(--cool-neutral-300) 50%,
      rgba(255, 255, 255, 0) 53%,
      rgba(255, 255, 255, 0) 100%
    );
}
