body .tabButton {
  font-size: 12px;
  color: var(--neutral-100);
  cursor: pointer;
  border: none;
  height: 45px;
  padding: 0;
  margin-right: 5px;
  background-color: var(--neutral-100);
}

.tabButton .button {
  border: none;
  padding: 25px 6px 12px;
}

.tabButton:hover {
  background-color: var(--primary-500);
}

.button .isSelected,
.tabButton .isSelected {
  background-color: var(--primary-500);
}
