@import '../../base';

@include b('NewItemButton') {
  @include center-items-both;

  cursor: pointer;
  font-size: 12px;
  height: 50px;
  position: relative;
  text-transform: uppercase;
  width: 100%;

  @include themify {
    box-shadow: themed('box-shadow-color');
  }

  &::after {
    background-image: linear-gradient(to bottom, transparent, var(--neutral-150));
    content: '';
    height: 5px;
    position: absolute;
    top: -5px;
    width: 100%;
  }

  &:hover {
    background-color: var(--neutral-200);
  }

  @include c('text') {
    color: $primary-brand-hover;
  }
}
