@import '../base';

@include b('MessageForwardModal') {
  @include c('body') {
    min-width: 640px;
  }

  @include c('forwardHeader') {
    @include common-modal-header;

    &:first-child {
      font-weight: 400;
    }
  }

  @include c('sending') {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
  }

  @include c('Message-forward-modal') {
    // color: #4a90e2;
    color: var(--secondary-700);
  }

  @include c('recipients-container') {
    display: flex;
    width: 100%;
  }

  @include c('priority-button-container') {
    flex: 0 0 45px;
    align-self: center;
    justify-self: center;
  }

  @include c('priority-indicator-container') {
    position: absolute;
    top: -45px;
    left: 0;
    right: 0;
    text-align: center;
  }

  @include c('priority-on-indicator') {
    display: inline-block;
    color: var(--primary-700);
    border: solid 1px var(--neutral-300);
    background-color: var(--neutral-100);
    border-radius: 30px;
    padding: 4px 15px;
    font-size: 13px;
    font-weight: 500;
    margin: 0 auto;
  }

  @include c('recipients') {
    flex: 1 0 auto;
    display: flex;
    flex-direction: row;
    background-color: var(--neutral-100);
    border: 1px solid var(--secondary-500);
    border-radius: 7px;
    padding: 5px 10px;
    align-items: center;

    @include c('recipients-label') {
      flex: 0 0 auto;
      padding-right: 5px;
      color: var(--neutral-800);
      font-size: 16px;
      font-weight: 500;
    }

    @include c('autosizer') {
      flex: 1 0 auto;
      margin-right: 10px;
    }

    @include c('autosizer-contents') {
      flex: 1 0 auto;
    }
  }

  @include c('preview-messages-container') {
    height: 280px;
    width: 500px;
    margin: 0 auto;
    margin-top: 10px;
  }

  @include c('information-container') {
    background-color: var(--neutral-200);
    padding: 15px 40px 10px;
    position: relative;
  }

  @include c('patient-information-container') {
    background-color: var(--neutral-100);
    padding-bottom: 8px;
    border-bottom: solid 1px var(--neutral-300);
    margin: 0 31px;
    margin-bottom: 40px;
  }

  @include c('patient-autosizer') {
    font-size: 16px;
    color: var(--neutral-700);
    display: inline;
    flex: 1 0 auto;
  }

  @include c('patient-autosizer-contents') {
    flex: 1 0 auto;
    padding-left: 30px;
  }

  @include c('patient-recipients') {
    display: flex;
    flex-direction: row;
    background-color: var(--neutral-100);
    border: none;

    @include c('patient-recipients-label') {
      flex: 0 0 auto;
      padding-top: 7px;
      color: var(--neutral-700);
    }
  }

  @include c('sender') {
    text-align: center;
    flex-shrink: 0;
  }

  @include c('sender-avatar') {
    margin-bottom: 8px;
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
  }

  @include c('sender-name') {
    font-weight: bold;
    font-size: 90%;
  }

  @include c('message') {
    @include b('MessageAttachment') {
      @include m('type', 'image') {
        img {
          max-height: 130px;
        }
      }
    }
  }

  @include c('image') {
    @include svg-background-and-icon(var(--neutral-400), transparent);

    width: 25px;
    height: 25px;
    display: flex;
    margin: auto;

    &,
    * {
      cursor: pointer;
      user-select: none;
    }

    &:hover {
      @include svg-background-and-icon(var(--primary-300), transparent);
    }

    &--isPriority {
      @include svg-background-and-icon(var(--primary-300), transparent);
    }

    &--alwaysEscalate {
      @include svg-background-and-icon(#999, $white);

      &:hover {
        @include svg-background-and-icon(#999, $white);
      }
    }
  }

  @include c('forward-btn') {
    @include button-with-disable(var(--secondary-500), 74px, 32px, 11px);
  }

  @include c('cancel-btn') {
    @include common-button(var(--neutral-800), 74px, 32px, 11px);

    margin-right: 8px;
  }

  @include c('forwardFooter') {
    position: relative;
    padding: 0 40px 40px 15px;
    display: flex;
    justify-content: flex-end;
    font-weight: 500;
    background-color: var(--neutral-200);
    border: 1px solid var(--neutral-200);
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;

    // for IE, left side bug caused by border-bottom
    background-clip: padding-box;

    &--patientFooter {
      padding: 24px 15px;
    }
  }
}
