@import '../../base';

$card-height: 68px;

@include b('VisitorCard') {
  width: 410px;
  height: $card-height;
  border-radius: 8px;
  border: solid 0.5px #c7c7c7;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 6px 14px 8px 16px;
  min-height: $card-height;

  &:hover {
    cursor: pointer;
  }

  @include m('isSelected') {
    background-color: #dff7ff;
  }

  @include m('isCompletedStatusStyle') {
    opacity: 0.45;
  }

  @include c('line') {
    display: flex;
    align-items: flex-start;
    margin-top: 3px;
  }

  @include c('status') {
    color: $patient-color;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    flex: 0 0 100px;

    &--isNewStatus {
      color: #007aff;
    }
  }

  @include c('time') {
    font-size: 10px;
    font-weight: 500;
    color: #979797;
    flex: 0 0 100px;
  }

  @include c('name') {
    @include ellipsis;

    font-size: 14px;
    font-weight: 500;
    color: #35333c;
    flex: 1;
  }

  @include c('visit-type-container') {
    display: flex;
    align-items: center;
    flex: 1;
    margin-top: 2px;
  }

  @include c('visit-type') {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 8px;
    font-size: 10px;
    font-weight: 500;
    color: #979797;
    padding-left: 6px;
  }

  @include c('badge-count') {
    height: 19px;
    align-self: flex-end;
  }

  @include c('assignment') {
    @include ellipsis;

    padding-right: 2px;
    font-size: 10px;
    font-weight: 500;
    text-align: right;
    text-transform: uppercase;
    color: #979797;
    width: 150px;

    &--isUnassigned {
      color: #007aff;
    }
  }

  @include c('last-message-timestamp') {
    margin-left: 6px;
    font-weight: 300;
    font-size: 10px;
    white-space: nowrap;
    color: $message-timestamp;
  }

  @include c('reason-dot') {
    &--1 {
      g {
        fill: #14dab1;
      }
    }

    &--2 {
      g {
        fill: #dd98fa;
      }
    }

    &--3 {
      g {
        fill: #c7c7c7;
      }
    }

    &--4 {
      g {
        fill: #fcb040;
      }
    }

    &--5 {
      g {
        fill: #53a7c7;
      }
    }

    &--6 {
      g {
        fill: #000;
      }
    }

    &--7 {
      g {
        fill: #ff7a00;
      }
    }

    &--8 {
      g {
        fill: #fac925;
      }
    }

    &--9 {
      g {
        fill: #6cb331;
      }
    }

    &--10 {
      g {
        fill: #fa6f68;
      }
    }
  }

  &:focus-visible {
    outline: 3px solid var(--secondary-500);
    z-index: 1;
  }
}
