@import '../base';

@include b('VirtualWaitingRoomCallCard') {
  @include center-items-both;

  flex-direction: column;
  width: 500px;
  height: 310px;
  border-radius: 12px;
  border: solid 1px #c7c7c7;
  margin: 10px auto;
  font-weight: 500;

  @include c('header-container') {
    flex: 1;
  }

  @include c('header-icon') {
    @include center-items-both;

    width: 65px;
    height: 65px;
    background-color: $patient-color;
    border-radius: 50%;
    margin-top: 40px;

    svg {
      width: 34px;
      height: 28px;
      fill: white;
    }
  }

  @include c('header-text') {
    @include ellipsis;

    width: 450px;
    font-size: 15px;
    margin-bottom: 20px;
    color: #45434d;
    text-align: center;
  }

  @include c('call-buttons') {
    @include center-items-both;

    justify-content: space-between;
  }

  @include c('call-button') {
    flex-direction: row;
    width: 240px;
    height: 45px;
    border-radius: 22px;
    background-color: #ecf1f3;
    color: $patient-color;
    font-size: 14px;
    margin: 0 20px;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;

    &:hover {
      background-color: #e9e9e9;
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;

      &:hover {
        background-color: #ecf1f3;
      }
    }
  }

  @include c('video-icon') {
    svg {
      width: 20px;
      height: 15px;
    }

    fill: $patient-color;
    margin-right: 10px;
  }

  @include c('phone-icon') {
    fill: $patient-color;
    margin: 0 10px 0 18px;
  }

  @include c('end-button') {
    @include center-items-both;

    width: 178px;
    height: 45px;
    border-radius: 22px;
    background-color: $patient-color;
    color: #fff;
    font-size: 14px;
    margin-top: 20px;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: $patient-color-hover;
      border-color: $patient-color-hover;
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;

      &:hover {
        background-color: $patient-color;
      }
    }

    @include m('status', 'complete') {
      background-color: #f4f4f4;
      color: #979797;
      cursor: default;

      &:hover {
        background-color: #f4f4f4;
      }
    }
  }

  @include c('time') {
    align-self: flex-start;
    color: $patient-color;
    font-size: 11px;
    margin: 10px;
  }
}
