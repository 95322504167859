@import '../../base';

@include b('AutoForwardMessageHeader') {
  padding: 5px;
  color: var(--neutral-100);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  @include c('auto-forward-text') {
    color: var(--neutral-100);
    padding: 0 10px 0 7px;
    font-size: 14px;
    font-weight: 100;
  }

  @include m('direction', 'INCOMING') {
    border-radius: 0 10px 0 0;
    background-color: var(--cool-neutral-800);
  }

  @include m('direction', 'OUTGOING') {
    border-radius: 10px 0 0;
    background-color: var(--message-status-sent);
  }
}
