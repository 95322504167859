@import '../base';

@include b('MessageGroupMembershipChangeLog') {
  margin-top: 23px;
  text-align: center;
  font-size: 13px;
  min-height: 18px;

  @include m('precededByDateSeparator') {
    margin-top: 18px;
  }

  @include m('isMultiSelect') {
    margin-top: 14px;
  }

  // Dark Mode TODO: */
  @include m('action', 'OPT_IN') {
    color: #30a0c4;
  }

  @include m('action', 'OPT_OUT') {
    color: var(--neutral-600);
  }

  @include m(
    (
      ('action', 'ADD'),
      ('action', 'JOIN'),
      ('action', 'LEAVE'),
      ('action', 'REMOVE'),
      ('action', 'DENY')
    )
  ) {
    color: var(--neutral-800);
  }

  &:focus-visible {
    outline: 3px solid var(--secondary-500);
  }
}
