@import '../base';

@include b('GroupOptions') {
  flex: 0 0 185px;
  background-color: var(--neutral-100);
  border: 1px solid var(--neutral-300);
  border-radius: 12px;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-left: 8px;
  margin-right: 8px;
  color: var(--neutral-900);
  font-size: 12px;

  @include c('title') {
    font-weight: bold;
    padding: 0 0 12px 20px;
  }

  @include c('team-save-toggle') {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
  }

  @include c('option-row') {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 10px 12px 20px;
    cursor: pointer;
    width: 100%;

    &:hover {
      background-color: var(--cool-neutral-300);
      border-radius: 5px;
    }

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
    }

    &--disabled {
      pointer-events: none;
      cursor: default;

      &:hover {
        background-color: var(--neutral-200);
      }
    }

    @include c('option-button') {
      margin-right: 10px;
      display: flex;
    }
  }

  @include c('option-header') {
    color: var(--neutral-800);

    &--disabled {
      color: var(--neutral-500);
    }
  }

  @include c('export-container') {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  @include c('side-arrow') {
    width: 8px;
    height: 12px;
  }
}
