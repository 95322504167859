@import '../../base';

@include b('EscalationMessageHeader') {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 5px;
  background-color: #67bdb9;
  box-shadow: 0 0 0 1px #67bdb9;
  color: white;

  @include c('escalation-header') {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  @include c('escalation-text') {
    padding-left: 6px;
    font-size: 11px;
    font-weight: 900;
    margin-top: 2.5px;
  }

  @include c('escalation-text-fade') {
    width: 120px;
    height: 16px;
    border-radius: 8px;
    margin-left: 6px;
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0), #fff);
    opacity: 0.5;
  }

  @include c('banner') {
    padding: 0 5px;
    margin-left: auto;
  }

  @include c('banner-fade') {
    width: 71px;
    height: 16px;
    object-fit: contain;
    border-radius: 8.5px;
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0), #f6eddd);
    margin-left: auto;
    opacity: 0.5;
  }

  @include m('senderStatus', 'FAILED') {
    background-color: $priority-message-bg-color;
    box-shadow: 0 0 0 1px $primary-brand;
  }

  @include m('direction', 'INCOMING') {
    border-radius: 0 10px 0 0;
  }

  @include m('direction', 'OUTGOING') {
    border-radius: 10px 0 0;
  }
}
