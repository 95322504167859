@import '../../base';

@include b('NewProfileStatus') {
  @include profile-detail-container;

  @include c('dots-container-text') {
    @include center-items-both;

    height: 55px;
  }

  @include c('dnd-blocked-container') {
    @include c('dnd-blocked-bg') {
      position: absolute;
      top: 30px;
      right: 0;
      left: 0;
      bottom: 0;
      background: var(--neutral-800);
      opacity: 0.4;
    }

    @include c('dnd-blocked-content') {
      position: absolute;
      top: 30px;
      right: 0;
      left: 0;
      bottom: 0;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      @include c('dnd-blocked-text') {
        background: var(--neutral-100);
        font-size: 12px;
        color: var(--neutral-900);
        line-height: 35px;
        width: 330px;
        border-radius: 30px;
        box-shadow: 0 3px 4px 0 var(--neutral-600);
      }
    }
  }

  @include c('dnd-timer-info') {
    padding: 0 15px;

    &--isTimerSet {
      background: var(--cool-neutral-200);
      padding-top: 15px;
    }
  }

  @include c('dnd-timer-container') {
    display: flex;
    flex-direction: column;
  }

  @include c('dnd-container') {
    margin: -8px -7px;
  }

  @include c('timers') {
    display: flex;
    justify-content: space-between;
    margin: 7px 0 5px;
  }

  @include c('timer-option') {
    background: var(--cool-neutral-200);
    border-radius: 6px;
    border: 1px solid var(--neutral-400);
    color: var(--neutral-900);
    display: flex;
    gap: 10px;
    height: 37px;
    justify-content: center;
    padding: 10px;
    width: 107px;

    &:hover {
      border: 1px solid var(--secondary-500);
      cursor: pointer;
    }
  }

  @include c('dnd-status-message-container') {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  @include c('dnd-status-message-icon') {
    margin: 14px 6px;
  }

  @include c('dnd-until-container') {
    display: flex;
    justify-content: space-between;
  }

  @include c('dnd-until-time') {
    font-size: 14px;
    line-height: 16px;
    margin-top: 7px;
    text-align: left;
  }

  @include c('dnd-end-time') {
    font-weight: 500;
  }

  @include c('dnd-cancel') {
    border-radius: 6px;
    border: 1px solid var(--errors-500);
    color: var(--errors-500);
    display: flex;
    font-size: 11px;
    justify-content: center;
    height: 31px;
    margin-right: 7px;
    padding: 8px;
    width: 74px;

    &:hover {
      background: var(--errors-500);
      border: 1px solid var(--neutral-100);
      color: var(--neutral-100);
      cursor: pointer;
    }
  }

  @include c('dnd-status-message') {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 5px;
    margin-top: 13px;
  }

  @include c('dnd-status-svg') {
    margin-right: 6px;
  }

  @include c('dnd-banner') {
    align-items: center;
    background: var(--cool-neutral-700);
    color: var(--neutral-100);
    display: flex;
    font-size: 15px;
    justify-content: center;
    padding: 5px 20px;

    @include c('dnd-svg') {
      margin-right: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 20px;
    }

    @include c('dnd-text') {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      hyphens: auto;
      font-weight: 400;
    }
  }

  @include c('auto-forward-info') {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 15px 15px;
    background: var(--cool-neutral-200);
  }

  @include c('auto-forward-header') {
    font-size: 14px;
    font-weight: 500;
    color: var(--neutral-900);
    padding: 10px 0;
  }

  @include c('dots-container') {
    @include center-items-both;

    height: 100%;
  }

  @include c('list') {
    display: flex;
    flex-direction: column;
  }
}
