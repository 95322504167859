@import '../../base';

@mixin standard-spacing {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

@include b('CollaborationTags') {
  background: var(--neutral-100);
  border-right: 3px solid var(--neutral-400);
  display: flex;
  flex: 0 0 349px;
  flex-flow: column nowrap;
  height: 100%;
  position: relative;
  font-size: 14px;

  @include c('loading-holder') {
    display: flex;
    justify-content: center;
  }

  @include c('header') {
    @include standard-spacing;

    background-color: var(--neutral-100);
    font-size: 18px;
    font-weight: bold;
    padding: 16px 16px 6px;

    &--searchOpen {
      padding-bottom: 0;
    }
  }

  @include c('batch-actions') {
    padding-left: 15px !important;
  }

  @include c('heading') {
    @include standard-spacing;

    background-color: var(--neutral-100);
  }

  @include c('actions') {
    display: flex;

    @include c('action') {
      margin: 0 3px;
      position: relative;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  @include c('tags-list-container') {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  @include c('batch-actions-container') {
    height: 30px;

    &--searchOpen {
      margin-top: 5px;
    }
  }

  @include c('batch-actions') {
    padding-left: 12px;

    input {
      cursor: pointer;
      margin: 2px 6px;
      height: 11px;

      &:hover {
        box-shadow: 0 3px 4px rgba(0, 0, 0, 0.2);
      }
    }

    label {
      color: var(--neutral-900);
      cursor: pointer;
      font-weight: 300;
      font-size: 16px;
      padding-left: 4px;
      line-height: 22px;
    }
  }

  @include c('tag-color') {
    border-radius: 20px;
    display: inline-block;
    height: 20px;
    margin: 0 8px;
    vertical-align: middle;
    width: 20px;
  }

  @include c('tag-filter-color-pills-container') {
    align-items: center;
    background-color: var(--neutral-100);
    display: inline-flex;
    flex-flow: row wrap;
    padding: 5px 8px 0;
    width: 100%;
  }

  @include c('close-all-pills-button') {
    background-color: var(--cool-neutral-200);
    border-radius: 100%;
    cursor: pointer;
    height: 28px;
    margin-right: 2px;
    text-align: center;
    width: 28px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: var(--cool-neutral-700);

      path {
        fill: var(--neutral-100);
      }
    }
  }

  @include c('icon') {
    @include icon-default;

    circle[fill='#fff'] {
      fill: var(--neutral-100);
    }
  }

  @include c('bookmark-icon') {
    height: 13px;
    margin-right: 5px;
    width: 12px;
  }

  @include c('not-tagged-icon') {
    margin-right: 5px;

    &--noPath {
      path {
        display: none;
      }
    }
  }

  @include c('role-icon') {
    background-color: var(--cool-neutral-200);
    border-radius: 100%;
    fill: var(--cool-neutral-700);
    height: 21px;
    padding: 5px;
    width: 21px;
  }

  @include c('deselect') {
    background-color: var(--neutral-150);
    border-radius: 3px;
    border: 1px solid var(neutral-300);
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    height: 24px;
    width: 104px;
  }

  @include c('batch-tag-icon') {
    border-radius: 14px;
    padding-left: 5px;
    width: 42px;

    svg {
      height: 28px;
      width: 16px;
    }

    &:hover {
      .caretDown {
        border-top: 4px solid var(--neutral-100);
      }
    }
  }

  @include c('no-results') {
    color: var(--cool-neutral-700);
    font-size: 12px;
    font-weight: 500;
    line-height: 1.3;
    margin-top: 15px;
    text-align: center;
  }

  @include c('container') {
    align-items: center;
    background-color: var(--cool-neutral-200);
    border-radius: 14px;
    display: inline-flex;
    flex-flow: row wrap;
    margin: 2px 1px;
    padding: 7.3px 6px;
  }

  @include c('color-symbol') {
    border: 1px solid var(--neutral-100);
    border-radius: 100%;
    height: 14px;
    width: 14px;
  }

  @include c('content') {
    font-size: 12px;
    margin-right: 2px;
    padding: 0 4px;
  }

  @include c('close-button') {
    border-radius: 100%;
    color: var(--cool-neutral-800);
    cursor: pointer;
    height: 14px;
    text-align: center;
    width: 14px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: var(--cool-neutral-700);

      @include c('close-pill-icon') {
        path {
          fill: var(--neutral-100);
        }
      }
    }
  }

  @include c('close-pill-icon') {
    height: 10px;
    width: 10px;
  }

  @include c('dropdown-container') {
    background-color: var(--neutral-100);
    border-radius: 6px;
    border: 1px solid var(--cool-neutral-700);
    box-shadow: 0 2px 1px 1px rgba(0, 0, 0, 0.15);
    left: -120px;
    position: absolute;
    text-align: center;
    top: 35px;
    width: 280px;
    z-index: 1;
  }

  @include c('dropdown-title') {
    color: var(--neutral-700);
    font-size: 12px;
    font-weight: normal;
    padding: 10px;
    text-align: left;
  }

  @include c('tag-colors-container') {
    display: inline-block;
    margin: 7px 17.5px;
  }

  @include c('tag-colors-option') {
    border-radius: 100%;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.21);
    cursor: pointer;
    height: 20px;
    width: 20px;

    &--selected {
      border: 3px solid var(--neutral-100);
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.42);
    }
  }

  @include c('filter-actions') {
    padding: 20px 15px 25px;
  }

  @include c('filter-button') {
    background-color: var(--secondary-500);
    color: var(--neutral-100);
    cursor: pointer;
    font-weight: normal;
    line-height: 40px;
    text-align: center;
    border-radius: 6px;
    width: 100%;
    font-size: 14px;
  }

  @include c('tooltip') {
    margin-top: -14px;
  }

  /****** TagListItem *****/

  @include c('item') {
    align-items: center;
    background-color: var(--neutral-100);
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: flex-start;

    &--itemSelected {
      background-color: var(--cool-neutral-300);
    }

    &:hover {
      background-color: var(--neutral-300);
    }

    &--dropdown {
      background-color: var(--neutral-200);

      &:hover {
        background-color: var(--neutral-200);
      }
    }
  }

  @include c('left-side-container') {
    flex-grow: 1;
    flex-shrink: 0;
    height: 100%;
    min-width: 35px;
  }

  @include c('check-container') {
    align-items: center;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    margin-left: 2px;
    width: 100%;
  }

  @include c('check-container-circle') {
    align-items: center;
    display: flex;
    height: 32px;
    justify-content: center;
    transition: width 100ms, height 100ms;
    width: 32px;

    input {
      cursor: pointer;

      &:hover {
        box-shadow: 0 3px 4px rgba(0, 0, 0, 0.2);
      }
    }
  }

  @include c('tag-list-item-container') {
    align-items: center;
    border-bottom: solid 1px var(--neutral-100);
    border: solid 1px transparent;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    height: 100%;
    padding-left: 10px;
    padding-right: 12px;
    width: 100%;
  }

  @include c('tag-item-count') {
    color: var(--cool-neutral-800);
    flex: 0 0 auto;
    float: right;
    font-size: 12px;
    margin-right: 5px;
  }

  @include c('tags-list-modal-container') {
    flex: 1;
  }

  @include c('empty-bg') {
    background: url('../../../images/pattern-clip@2x.png');

    @include themify {
      background-blend-mode: color-burn;
      background-color: var(--neutral-100);
    }
  }

  @include c('accordion-row') {
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    cursor: pointer;
    flex: 1;
    color: var(--neutral-900);
  }

  @include c('chevron') {
    &--isOpen {
      transform: rotate(180deg);
    }
  }
}
