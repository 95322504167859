@import '../../base';

@include b('AlertViewConversation') {
  display: flex;
  align-items: center;
  border-top: 1px solid var(--neutral-500);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 12px 14px;
  background-color: var(--neutral-200);

  @include c('container') {
    font-weight: 500;
    color: var(--secondary-500);
    cursor: pointer;

    &:hover {
      color: #175296;
    }

    @include c('text') {
      margin-left: 5px;
    }
  }
}
