@import '../../base';

@include b('DummyConversationAvatar') {
  @include c('avatar-image-container') {
    @include center-items-both;

    $avatar-size: 52px;

    &--entity {
      flex: $avatar-size 0 0;

      @include b('AvatarImage') {
        width: $avatar-size;
        height: $avatar-size;
        border-radius: $avatar-size;
      }
    }

    @include c('avatar-image') {
      width: $avatar-size;
      height: $avatar-size;
    }

    @include c('blank-avatar') {
      @include center-items-both;
      @include circle;

      width: $avatar-size;
      height: $avatar-size;
      background-color: var(--neutral-100);
      color: var(--neutral-1000);
      padding-top: 1px;
    }

    ellipse {
      fill: var(--neutral-100);
      stroke: var(--cool-neutral-300);
    }
  }
}
