@import '../../base';

@include b('CollaborationEntityEditorActionButtons') {
  display: flex;
  flex-direction: column;
  background-color: var(--neutral-100);
  width: 100%;
  min-height: auto;
  position: relative;

  @include c('loading-overlay-container') {
    background-color: var(--neutral-150);
    bottom: 0;
    left: 0;
    opacity: 0.92;
    position: absolute;
    right: 0;
    top: 0;
  }

  @include c('button') {
    align-items: center;
    display: flex;
    justify-content: center;
    border-top: 1px solid var(--neutral-300);
    background-color: var(--neutral-150);
    color: var(--secondary-500);
    cursor: pointer;
    height: 41px;

    &:hover {
      background-color: var(--neutral-200);
    }

    &--disabled {
      cursor: default;
      color: var(--neutral-900);

      &:hover {
        background-color: var(--neutral-150);
      }
    }
  }
}
