@import '../../base';

@include b('VirtualWaitingRoomOptionsMenu') {
  flex-grow: 1;
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include c('form-submission-error-notice') {
    font-family: inherit;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: red;
    border: 1px solid red;
    border-radius: 8px;
    padding: 7px 10px 22px;
    margin-bottom: 10px;
  }

  @include c('form-items') {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
    padding-top: 4px;
    padding-right: 4px;
  }

  @include c('status-section') {
    display: flex;
  }

  @include c('status-text') {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }

  @include c('status-text-main') {
    display: flex;
    align-items: center;
    flex-grow: 1;
    font-family: inherit;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.43;
    color: #35333c;

    :focus-visible {
      outline: 3px solid var(--secondary-500);
    }
  }

  @include c('status-text-main-text') {
    flex-grow: 1;
    padding-left: 11px;
  }

  @include c('status-text-secondary') {
    font-family: inherit;
    font-size: 12px;
    color: #979797;
    padding-top: 10px;
  }

  @include c('status-text-secondary-closing') {
    font-family: inherit;
    font-size: 12px;
    color: $primary-brand;
    padding-top: 10px;
  }

  @include c('access-picker') {
    .Select {
      width: auto;
    }

    .Select-menu-outer {
      width: 100%;
    }

    ul[class$='staff-list'] {
      width: auto;
    }
  }

  @include c('staff-error-message') {
    font-size: 12px;
    color: $primary-brand;
  }

  @include c('access-text') {
    font-family: inherit;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.43;
    color: #35333c;
    padding-top: 20px;
  }

  @include c('access-description') {
    font-family: inherit;
    font-size: 12px;
    color: #979797;
    padding-top: 8px;
    padding-bottom: 10px;
  }

  @include c('buttons-container') {
    display: flex;
    justify-content: flex-end;
  }

  @include c('cancel-button') {
    font-family: inherit;
    width: 142px;
    height: 41px;
    color: #979797;
    border-radius: 6px;
    border: solid 1px #979797;
    font-size: 18px;
    font-weight: 600;
    text-align: center;

    &:hover {
      background-color: #f3f1f1;
    }
  }

  @include c('save-button') {
    font-family: inherit;
    width: 142px;
    height: 41px;
    color: white;
    border-radius: 6px;
    background-color: $patient-color;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin-left: 10px;

    &:hover {
      background-color: $patient-color-hover;
    }

    &:disabled {
      background-color: #979797;
      cursor: default;

      &:hover {
        background-color: #979797;
      }
    }
  }

  @include c('modal-text') {
    font-weight: 400;
  }
}
