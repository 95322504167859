@import '../../base';

@include b('EmojiPickerPortal') {
  position: relative;
  bottom: 20px;

  @import '~emoji-mart/css/emoji-mart';

  .emoji-mart {
    font-family: inherit;
    color: var(--neutral-1000);
    background: var(--neutral-100);
    border: 1px solid var(--neutral-400);
  }

  .emoji-mart-emoji-native {
    font-family: inherit;
  }

  .emoji-mart-category {
    .emoji-mart-emoji {
      &:hover {
        &::before {
          background: var(--neutral-200);
        }
      }
    }
  }

  .emoji-mart-category-label span {
    background-color: var(--neutral-100);
  }

  input {
    border: 1px solid var(--neutral-400);
  }

  .emoji-mart-skin-swatches {
    background-color: var(--neutral-100);
    border: 1px solid var(--neutral-400);
  }

  .emoji-mart-bar:last-child {
    border-top: 1px solid var(--neutral-400);
    border-bottom: none;
  }

  .emoji-mart-search input {
    border: 1px solid var(--neutral-400);
  }

  .emoji-mart-search-icon path {
    fill: var(--neutral-1000);
  }

  .emoji-mart-bar {
    border-bottom: 1px solid var(--neutral-400);
  }
}
