@import '../base';

@include b('ExistingPatientConversationCard') {
  width: 215px;
  height: 60px;
  border-radius: 6px;
  background-color: var(--neutral-150);
  margin: 5px;
  padding: 8px;
  display: flex;
  cursor: pointer;

  &:hover {
    background-color: var(--cool-neutral-300);
  }

  &:focus-visible {
    outline: 3px solid var(--secondary-500);
  }

  @include c('avatar') {
    margin: 2px 8px 2px 2px;

    @include b('AvatarImage') {
      height: 23px;
      width: 23px;
      background-color: var(--neutral-150);
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.13);

      &--singleProvider,
      &--singleProviderSmsOptedOut {
        border-radius: 50%;
      }

      &--singleProviderSmsOptedOut,
      &--multipleProvidersSmsOptedOut {
        box-shadow: none;
        background: transparent;
      }

      @include c('image-container-square') {
        border-radius: 3px;

        &--entityType--multipleProvidersSmsOptedOut {
          border-radius: 6px;
        }
      }

      @include c('image') {
        padding: 3px;

        &--entityType--singleProviderSmsOptedOut,
        &--entityType--multipleProvidersSmsOptedOut {
          padding: 0;
        }
      }
    }
  }

  @include c('conversation-information') {
    width: 160px;

    @include c('display-name') {
      @include ellipsis;

      font-size: 15px;
      font-weight: bold;
      line-height: 17px;
    }

    @include c('sub-text') {
      display: flex;
      font-size: 10px;
      font-weight: 600;
      justify-content: space-between;

      @include c('members-text') {
        width: 49px;
      }

      @include c('members-list') {
        @include ellipsis;

        margin-left: 8px;
        font-style: italic;
        font-weight: 400;
        width: 107px;
      }

      @include c('created-text') {
        width: 56px;
        white-space: nowrap;
      }

      @include c('created-list') {
        @include ellipsis;

        margin-left: 8px;
        font-style: italic;
        font-weight: 400;
        width: 100px;
      }
    }
  }
}
