@import '../../base';

@include b('ScheduleMessageModal') {
  @import '~react-dates/lib/css/datepicker';

  height: 700px;

  svg {
    [fill='#4A657B'] {
      fill: var(--patient-network-700);
    }

    [stroke='#4A657B'] {
      stroke: var(--patient-network-700);
    }

    [fill='#ECF1F3'] {
      fill: var(--neutral-200);
    }
  }

  .SingleDatePicker {
    background-color: var(--neutral-100);

    .DateInput_input {
      background-color: var(--neutral-100);
    }
  }

  .SingleDatePickerInput {
    background-color: var(--neutral-100);
    color: var(--neutral-1000);
  }

  .DayPicker {
    background-color: var(--neutral-100);
  }

  .DayPicker_weekHeader {
    color: var(--neutral-1000);
  }

  .DayPickerNavigation_button__default {
    border: 1px solid var(--patient-network-300);
    background-color: var(--neutral-100);
    color: var(--neutral-800);

    &:active {
      background: var(--neutral-200);
    }

    &:focus {
      border: 1px solid var(--neutral-400);
    }

    &:hover {
      border: 1px solid var(--neutral-400);
    }

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
    }
  }

  .CalendarMonth {
    background-color: var(--neutral-100);
  }

  .CalendarMonth_caption {
    color: var(--neutral-1000);
  }

  .CalendarMonthGrid {
    background-color: var(--neutral-100);
  }

  .CalendarDay {
    background-color: var(--neutral-100);
    border: 1px solid var(--neutral-200);
    color: var(--neutral-100);

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
    }
  }

  .CalendarDay__blocked_out_of_range {
    background: var(--neutral-100);
    border: 1px solid var(--neutral-200);
    color: var(--neutral-400);

    &:hover {
      border: 1px solid var(--neutral-100);
    }
  }

  .DateInput {
    background-color: var(--neutral-100);
  }

  @include c('header') {
    @include common-modal-header;

    color: var(--neutral-1000);
    font-size: 16px;
    font-weight: normal;
  }

  @include c('header-content') {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;

    p {
      margin-left: 9px;
    }
  }

  @include c('delivery-method') {
    background-color: var(--neutral-100);
    border-radius: 6px;
    border: solid 1px var(--neutral-400);
    height: 45px;
    width: 447px;
  }

  @include c('delivery-label') {
    color: var(--patient-network-700);
    font-size: 14px;
    font-weight: 600;
    margin-left: 25px;
    min-height: 25px;
  }

  @include c('message-type-dropdown') {
    background-color: var(--neutral-100);
    border-radius: 6px;
    border: solid 1px var(--neutral-400);
    cursor: pointer;
    height: 38px;
    margin: -8px 0 0 56px;
    padding: 0 19px 0 11px;
    width: 446px;

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
    }
  }

  @include c('message-kind-dropdown') {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
  }

  @include c('sections-container') {
    min-height: 600px;
  }

  @include c('close-btn') {
    @include common-modal-close-button;

    color: var(--patient-network-700);
  }

  @include c('body') {
    @include common-modal-body;

    height: 600px;
    padding: 30px 60px 0;
  }

  @include c('primary-btn') {
    @include button-with-disable(var(--patient-network-700), 74px, 32px, 11px);

    user-select: none;
  }

  @include c('secondary-btn') {
    @include common-button(var(--neutral-800), 74px, 32px, 11px);

    margin-right: 8px;
    user-select: none;
  }

  @include c('delivery-method-icon') {
    display: flex;
  }

  @include c('form-container') {
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow-y: auto;
    position: relative;
    flex: 1 1 0;

    @include c('section') {
      display: flex;
      margin-bottom: 32px;
      padding-right: 3px;

      &--deliveryMethodSection {
        flex-direction: column;
        margin-bottom: 25px;
      }

      @include c('recipient-svg') {
        margin-top: 5px;
      }

      & > svg {
        flex: 0 0 31px;
      }

      @include c('section-title') {
        color: var(--patient-network-700);
        font-size: 14px;
        font-weight: 600;
        margin-left: 20px;
        min-height: 25px;
      }

      @include c('section-container') {
        width: 100%;
        margin-left: 20px;

        &--recipientPicker {
          min-height: 68px;
        }

        &--noReply {
          color: var(--neutral-950);
          display: flex;
          font-size: 12px;
        }

        &--isDeliverySection {
          color: var(--neutral-1000);
          font-size: 13px;
          font-weight: 600;
          text-align: left;

          > div {
            align-items: flex-start;
            flex-direction: column;

            span {
              margin: 0;
            }
          }
        }

        @include c('section-header') {
          min-height: 25px;
          color: var(--patient-network-700);
          font-size: 14px;
          font-weight: 600;

          &--noReply {
            color: var(--neutral-950);
            display: flex;
            font-size: 13px;
            font-weight: 300;
            height: 16px;
            margin-right: -5px;
            width: 70px;
          }
        }
      }
    }
  }

  @include c('schedule-header') {
    display: flex;
    justify-content: space-between;
  }

  @include c('switch-container') {
    align-items: center;
    background-color: var(--neutral-200);
    border-radius: 15px;
    color: var(--neutral-950);
    display: flex;
    font-size: 12px;
    font-weight: normal;
    justify-content: space-around;
    padding: 4px 0;
    width: 130px;

    @include c('repeat') {
      margin-left: 5px;
    }

    @include c('switch') {
      margin-right: 2px;
      overflow: hidden;

      & > div {
        display: flex;

        :focus-visible {
          border: 3px solid var(--secondary-500);
        }
      }
    }
  }

  @include c('footer') {
    position: relative;
    padding: 30px 32px 28px;
    display: flex;
    justify-content: flex-end;
    font-weight: 500;
    background-color: var(--neutral-150);
  }

  @include c('delete-btn') {
    @include common-button(var(--neutral-800), 74px, 32px, 11px);

    &:hover {
      background-color: var(--primary-700);
      color: var(--neutral-300);
      border: none;
    }
  }
}
