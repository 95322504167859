@import '../base';

@include b('MessageInfoModal') {
  @include c('body') {
    @include common-modal-body;

    flex: 1 0 281px;
    display: flex;
    padding: 30px 40px 10px 70px;
  }

  @include c('content-outer') {
    flex: 1 1 auto;
    position: relative;
    margin-bottom: 20px;

    @include c('content-inner') {
      padding: 5px 30px 0 0;
    }
  }

  @include c('line') {
    line-height: 1.5;
    font-size: 18px;
    color: var(--neutral-1000);

    @include c('title') {
      font-weight: 600;
    }
  }

  @include c('done-btn') {
    @include common-button(var(--secondary-500), 74px, 32px, 11px);
  }
}
