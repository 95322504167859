@import '../../base';

@include b('MobileLogin') {
  padding: 16px 16px 0 0;

  @include c('button') {
    display: flex;
    position: relative;
    align-self: flex-end;
    align-items: center;
    padding: 10px;
    font-size: 12px;
    font-weight: 600;
    color: var(--secondary-900);
    border-radius: 6px;
    border: solid 1px var(--secondary-700);
    background-color: var(--cool-neutral-300);
    cursor: pointer;
    user-select: none;

    &:focus {
      outline: none;
    }

    &:hover {
      @include c('hover-circle') {
        background-color: var(--secondary-700);
        border-radius: 50%;

        @include c('down-arrow') {
          fill: var(--neutral-100);
        }
      }
    }

    @include c('phone-icon') {
      margin-right: 7px;
      width: 11px;
    }

    @include c('hover-circle') {
      margin-left: 33px;
      width: 12px;
      height: 12px;

      @include c('down-arrow') {
        margin: 3px;

        /* fill: #2b72c6; */
        fill: var(--secondary-900);
        width: 6px;
      }
    }
  }

  @include c('mobile-qr-login') {
    position: absolute;
    top: 60px;
    right: 68px;
    width: 360px;
    height: auto;
    border-radius: 6px;
    background-color: var(--neutral-100);
    border: solid 1px var(--neutral-300);
    box-shadow: 0 3px 8px 0 var(--neutral-300);

    &:focus {
      outline: none;
    }

    @include c('qr-image') {
      text-align: center;
      padding: 10px 20px;
      padding-top: 15px;
      position: relative;
      width: 100%;
    }

    @include c('mobile-text') {
      background-color: var(--neutral-200);
      padding: 15px;
      border-radius: 0 0 6px 6px;

      @include c('mobile-header') {
        font-weight: 600;
        display: flex;

        @include c('circle') {
          @include circle(24px);
          @include center-items-both;

          background-color: var(--neutral-100);
          box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
          margin: 0 6px 6px 0;

          @include if-ie {
            left: -8px;
          }

          @include c('tc-icon') {
            fill: var(--cool-neutral-700);
            width: 18px;
            height: 18px;
          }
        }
      }

      @include c('login-text') {
        color: var(--secondary-700);
      }
    }
  }
}
