@import '../../../base';

@include b('AutoForwardModal') {
  @include c('body') {
    @include common-modal-body;

    padding: 30px 30px 0;

    .Select-control {
      .Select-placeholder {
        padding-left: 19px;
      }
    }
  }

  @include c('extended-auto-forward-options') {
    .Select-control {
      max-height: 100px;
      overflow-y: auto;
    }
  }

  @include c('body-container') {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 225px;

    @include c('description-container') {
      width: 100%;
      display: flex;
      color: var(--neutral-700);
      line-height: 26px;
    }

    @include c('error-message-container') {
      display: block;
      height: 30px;
      width: 100%;
      color: var(--errors-300);
      padding-top: 6px;
    }

    @include c('members-container') {
      width: 100%;
      padding: 10px 0 30px;
      height: 115px;
      display: flex;
    }
  }

  @include c('save-btn') {
    @include button-with-disable(var(--secondary-500), 74px, 32px, 11px);
  }

  @include c('cancel-btn') {
    @include common-button(var(--cool-neutral-700), 74px, 32px, 11px);

    margin-right: 8px;
  }
}
