@import '../../base';

@include b('CollaborationEntitiesItem') {
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-flow: row nowrap;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @include c('role-details-container') {
    display: flex;
    flex: 1;
    flex-direction: row;
    overflow-x: auto;
  }

  @include c('role-icon-container') {
    flex-shrink: 0;
    height: 32px;
    margin: 17px 0;
    min-width: 21px;
    text-align: center;

    @include b('AvatarImage') {
      @include c('image-container-square') {
        background-color: var(--cool-neutral-700) !important;
        border-width: 0 !important;

        @include c('smallIcon') {
          padding-bottom: 1px;
        }
      }

      svg {
        width: 18px;
        height: 14px;

        path {
          fill: var(--neutral-100);
        }
      }
    }
  }

  @include c('role-icon') {
    background-color: var(--cool-neutral-200);
    border-radius: 100%;
    fill: var(--cool-neutral-700);
    height: 21px;
    padding: 5px;
    width: 21px;
  }

  @include c('team-icon') {
    background-color: var(--cool-neutral-700);
    border-radius: 100%;
    height: 32px;
    padding: 5px;
    width: 32px;

    g {
      fill: var(--neutral-100) !important;
    }
  }

  @include c('role-info') {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 13px;
    overflow-x: auto;
  }

  @include c('no-owner') {
    color: var(--primary-300);
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 14px;
  }

  @include c('role-name') {
    align-items: center;
    display: flex;
    font-size: 16px;
    line-height: 22px;
    width: 100%;
  }

  @include c('role-name-text') {
    margin-right: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @include c('bookmark-icon') {
    height: 13px;
    margin-right: 5px;
    width: 12px;

    path {
      fill: #f0c908;
    }
  }

  @include c('role-tag') {
    color: #9ca7a8;
    font-size: 13px;
    width: 100%;

    span {
      vertical-align: middle;
      font-weight: normal;
    }
  }

  @include c('message-entities-button') {
    align-self: center;
    background: var(--neutral-100);
    border: 0.5px solid var(--secondary-900);
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.21);
    box-sizing: border-box;
    color: var(--secondary-900);
    font-weight: 400;
    font-size: 14px;
    flex: 0 1 auto;
    line-height: 28px;
    margin-left: 18px;
    text-align: center;
    width: 132px;
  }
}
