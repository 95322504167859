@import '../base';

@include b('MessengerFrame') {
  display: flex;
  flex-direction: column;

  @include c('header-row') {
    display: flex;
    flex-direction: row;
    border-bottom: solid 1px var(--neutral-400);
    flex: 0 0 72px;
    position: relative;
    background-color: var(--neutral-100);

    &--isPrintModeOpen {
      @media print {
        display: none;
      }
    }

    @include c('sidebar-header') {
      flex: 450px 0 0;
      display: flex;
      flex-direction: row;
      min-width: 350px;
    }

    @include c('middle-header') {
      display: flex;
      flex-direction: row;
    }
  }

  @include c('role-banner') {
    visibility: hidden;

    @media print {
      display: none;
    }

    &--enable {
      visibility: visible;
      flex: 0 0 32px;
      border-bottom: solid 1px var(--neutral-400);
    }
  }
}
