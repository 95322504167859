@import '../../base';

@include b('RoleInfoPane') {
  @include c('main-container') {
    flex: 1 0 120px;
    color: var(--neutral-900);
    position: relative;
    padding: 0 8px;

    @include c('role-name-avatar') {
      margin: 50px 0 25px;
      text-align: center;

      @include c('role-avatar') {
        position: relative;
        height: 80px;
        width: 80px;
        margin: auto;
      }

      @include c('role-names') {
        padding-top: 10px;
        font-size: 18px;
        font-weight: 500;
      }

      @include c('tag-name') {
        color: var(--neutral-700);
        font-size: 14px;
        font-weight: normal;
      }

      @include c('tag-indicator') {
        display: inline-block;
        flex-shrink: 0;
        width: 6px;
        height: 6px;
        margin: 3px 5px;
        border-radius: 6.4px;

        @include if-ie {
          margin-top: 4px;
        }
      }

      @include b('UserName') {
        font-size: 14px;
        font-weight: 500;
      }

      @include c('on-duty') {
        color: var(--success-500);
        font-size: 12px;
        margin-top: 5px;
        white-space: nowrap;
      }

      @include c('no-on-duty') {
        font-size: 12px;
        color: var(--primary-300);
        white-space: nowrap;
        font-weight: 600;
      }
    }

    @include c('role-titles') {
      line-height: 20px;

      @include c('role-name') {
        padding-bottom: 10px;
      }
    }

    @include c('user-details') {
      line-height: 14px;
      display: block;
    }

    @include c('options') {
      flex: 0 0 185px;
      background-color: var(--neutral-100);
      border: 1px solid var(--neutral-300);
      border-radius: 6px;
      padding: 20px 10px;
      display: flex;
      flex-direction: column;
      margin: 20px 8px 0;
      color: var(--neutral-900);
      font-size: 12px;
    }

    @include c('title') {
      font-weight: bold;
      padding: 0 0 12px 20px;
    }

    @include c('option-header') {
      color: var(--neutral-800);

      &--disabled {
        color: var(--neutral-500);
      }
    }

    @include c('option-row') {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 12px 10px 12px 20px;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background-color: var(--cool-neutral-300);
      }

      &--disabled {
        cursor: auto;

        &:hover {
          background-color: var(--neutral-200);
        }
      }
    }

    @include c('side-arrow') {
      width: 8px;
      height: 12px;
      margin-right: 0.75vw;
    }

    @include c('RoleSaveButton') {
      display: flex;
      align-items: center;
      justify-content: center;

      @include c('image') {
        width: 14px;
        height: 14px;
        margin-right: 8px;
        cursor: pointer;

        &--disabled {
          cursor: auto;
        }
      }
    }
  }
}
