@import '../../base';

@include b('PrintMessageItem') {
  margin: 3px 0;
  border-radius: 6px;
  padding: 10px 10px 8px 7px;
  color: var(--neutral-800);
  font-size: 16px;

  @media print {
    -webkit-print-color-adjust: exact;
  }

  @include m('direction', 'INCOMING') {
    background-color: var(--neutral-200);
  }

  @include c('user-name') {
    color: var(--neutral-800);
    font-size: 12px;
    font-weight: bold;
  }

  @include c('forwarded') {
    font-size: 14px;
  }

  @include c('forwarded-body') {
    margin: 3px 0;
    border-left: solid 1.5px var(--neutral-900);
    padding-left: 9px;
  }

  @include c('body-forward-text') {
    font-size: 16px;
  }

  @include c('body') {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 2px 0 8px;

    @include c('text-container') {
      width: 100%;
      word-wrap: break-word;
    }

    a {
      color: var(--secondary-500);
    }

    img {
      border-radius: 8px;
    }

    .Linkify {
      img {
        max-width: $message-body-max-width;
        max-height: $message-image-max-height;
        padding-top: 5px;

        @include medium-messages {
          max-width: $message-body-width-medium;
        }
      }
    }
  }

  @media print {
    page-break-inside: avoid;
  }
}
