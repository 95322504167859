@import '../../base';

@include b('MessageDropdown') {
  align-self: center;

  @include c('option-button') {
    height: 20px;
    width: 20px;
  }

  @include c('show-onhover-actions-button') {
    display: none;
    position: relative;

    &:hover {
      cursor: pointer;
    }

    circle {
      fill: var(--neutral-1000);

      &:first-child {
        fill: currentcolor;
      }
    }

    @include m('priority', 'NORMAL') {
      @include m('direction', 'INCOMING') {
        left: 15px;

        svg {
          color: var(--neutral-200);
        }

        &:hover {
          svg {
            color: var(--neutral-300);
          }
        }
      }

      @include m('direction', 'OUTGOING') {
        right: 15px;

        svg {
          color: var(--cool-neutral-300);
        }

        &:hover {
          svg {
            color: var(--cool-neutral-400);
          }
        }
      }

      @include m('isEscalated') {
        svg {
          color: var(--escalation-200);
        }

        &:hover {
          svg {
            color: var(--escalation-200);
          }
        }
      }

      @include m('isRoleAutomatedMessage') {
        svg {
          color: var(--cool-neutral-900);
        }

        &:hover {
          svg {
            color: var(--patient-network-700);
          }
        }
      }

      @include m('isForwardedFromPatientNetwork') {
        svg {
          color: var(--patient-network-700);
        }

        &:hover {
          svg {
            color: var(--patient-network-700);
          }
        }
      }

      @include m('isPatientOrContactMessage') {
        svg {
          color: var(--patient-network-500);
        }

        &:hover {
          svg {
            color: var(--patient-network-400);
          }
        }
      }

      @include m((('senderStatus', 'FAILED'), ('senderStatus', 'RETRYING'))) {
        margin-bottom: 28px;

        svg {
          color: var(--errors-50);
        }

        &:hover {
          svg {
            color: var(--errors-100);
          }
        }
      }
    }

    @include m('priority', 'HIGH') {
      svg {
        color: var(--primary-300);
      }

      &:hover {
        svg {
          color: var(--primary-700);
        }
      }

      circle {
        fill: var(--neutral-100);

        &:first-child {
          fill: currentcolor;
        }
      }

      @include m('direction', 'INCOMING') {
        left: 15px;
      }

      @include m('direction', 'OUTGOING') {
        right: 15px;
      }

      @include m((('senderStatus', 'FAILED'), ('senderStatus', 'RETRYING'))) {
        margin-bottom: 28px;

        svg {
          color: var(--errors-50);
        }

        &:hover {
          svg {
            color: var(--errors-100);
          }
        }
      }
    }
  }

  @include c('hide-actions-button') {
    display: none;
  }

  &:focus-visible {
    box-shadow: 0 0 0 3px var(--secondary-500);
    border-radius: 10px;
  }
}
