:global(.tc-AppSelector) :global(.tc-MessengerFrame) .multiSelect {
  background-color: var(--neutral-100);
  border: 1px solid var(--neutral-300);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  width: 350px;
  border-radius: 4px;
  position: relative;
}

:global(.tc-AppSelector) :global(.tc-MessengerFrame) .multiSelect :global(.Select-control) {
  position: relative;
  cursor: text;
}

:global(.tc-AppSelector)
  :global(.tc-MessengerFrame)
  .multiSelect.disabled
  :global(.Select-control) {
  cursor: default;
  background-color: var(--neutral-400);
}

:global(.tc-AppSelector) :global(.tc-MessengerFrame) .multiSelect :global(.Select-aria-only) {
  position: absolute;
  display: inline-block;
  height: 1px;
  width: 1px;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  float: left;
}

:global(.tc-AppSelector) :global(.tc-MessengerFrame) .multiSelect :global(.Select-placeholder) {
  bottom: 0;
  color: var(--neutral-500);
  left: 0px;
  position: absolute;
  right: 0;
  top: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

:global(.tc-AppSelector) :global(.tc-MessengerFrame) .multiSelect :global(.Select-placeholder),
:global(.tc-AppSelector) :global(.tc-MessengerFrame) .multiSelect :global(.Select-input) {
  line-height: 30px;
  padding-left: 5px;
}

.multiSelect :global(.Select-clear-zone) {
  display: none;
}

.multiSelect :global(.Select-menu-outer) {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top-color: #e6e6e6;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1;
  -webkit-overflow-scrolling: touch;
}

:global(.tc-AppSelector) :global(.tc-MessengerFrame) .multiSelectOption:global(.Select-value) {
  background-color: var(--neutral-300);
  display: inline-flex;
  align-items: center;
  margin: 3px;
  border-radius: 15px;
  cursor: pointer;
  padding: 5px 8px;
  font-size: 12px;
}

:global(.tc-AppSelector)
  :global(.tc-MessengerFrame)
  .disabled
  .multiSelectOption:global(.Select-value),
:global(.tc-AppSelector)
  :global(.tc-MessengerFrame)
  .disabled
  .multiSelectOption:global(.Select-value):hover {
  color: var(--neutral-700);
  background-color: var(--neutral-300);
  cursor: default;
}

:global(.tc-AppSelector)
  :global(.tc-MessengerFrame)
  .multiSelectOption:global(.Select-value):hover {
  background-color: var(--neutral-400);
}

:global(.tc-AppSelector) :global(.tc-MessengerFrame) .multiSelectOption:global(.Select-option),
:global(.tc-AppSelector) :global(.tc-MessengerFrame) .multiSelect :global(.Select-noresults) {
  padding: 10px;
  border-bottom: 1px solid var(--neutral-300);
}

:global(.tc-AppSelector)
  :global(.tc-MessengerFrame)
  .multiSelectOption:global(.Select-option):last-child,
:global(.tc-AppSelector)
  :global(.tc-MessengerFrame)
  .multiSelect
  :global(.Select-noresults):last-child {
  border-bottom: none;
}

:global(.tc-AppSelector)
  :global(.tc-MessengerFrame)
  .multiSelectOption:global(.Select-option):global(.is-focused) {
  background-color: var(--cool-neutral-300);
  cursor: pointer;
}

.multiSelectOption :global(.Select-value-icon) {
  margin-right: 3px;
}
