@import '../base';

@include b('VideoCallMember') {
  text-align: center;
  height: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: var(--neutral-200);
  padding: 5px 0;
  align-items: center;

  @include c('icon') {
    margin-left: 10px;
  }

  &:nth-child(even) {
    background-color: var(--neutral-100);
  }

  @include c('invite-button') {
    display: flex;
    height: 22px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: var(--secondary-500);
    color: var(--neutral-100);
    font-size: 12px;
    width: 65px;
    margin-right: 4px;

    &[disabled] {
      background-color: var(--neutral-300);
      color: var(--patient-network-700);
      cursor: default;
    }
  }

  @include c('name-status-container') {
    margin-left: 10px;
    flex: 1;

    @include c('name-container') {
      font-size: 12px;
      font-weight: 400;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      color: var(--neutral-1000);
    }

    @include c('status') {
      color: var(--neutral-900);
      font-size: 9px;
      text-align: left;
      font-weight: 100;
      line-height: 1em;

      &--connected {
        color: var(--success-500);
      }
    }
  }
}
