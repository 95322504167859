@import '../../base';

@include b('RetryAttachment') {
  @include center-items-both;

  flex-direction: column;
  background-color: #fff;
  line-height: 1;
  font-size: 12px;
  width: $message-body-max-width;
  height: $message-image-max-height;
  color: #333;
  border-radius: 8px;
  border: 1px solid #e4e9ed;

  @include c('retry') {
    color: $interaction-signifier !important;
    cursor: pointer;
    padding: 10px;

    &:hover {
      color: #2b70c4 !important;
      text-decoration: underline;
    }
  }

  @include c('cannot') {
    padding-bottom: 10px;

    &--noPadding {
      padding-bottom: 0;
      padding-top: 10px;
    }
  }

  @include m('small') {
    width: 300px;
    height: 70px;
  }
}
