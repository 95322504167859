@import '../../base';

$header-height: 64px;

@include b('PrintMode') {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--neutral-200);

  @include c('header') {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: $header-height;
    padding: 13px 22px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--neutral-100);

    @include c('party-container') {
      display: flex;
      align-items: center;

      @include c('display-name') {
        margin-left: 14px;
        color: var(--neutral-1000);
        font-weight: 500;
        font-size: 18px;
      }
    }

    @include c('buttons-container') {
      display: flex;
      align-items: center;

      @include c('print-button') {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 115px;
        height: 30px;
        margin-right: 18px;
        border-radius: 6px;
        background-color: var(--secondary-500);
        color: var(--neutral-100);
        cursor: pointer;

        span {
          font-size: 12px;
          font-style: normal;
        }

        &--disabled {
          background-color: var(--neutral-400);
          cursor: not-allowed;
        }
      }

      @include c('close-button') {
        width: 25px;
        height: 25px;
        color: var(--secondary-500);
        cursor: pointer;
      }
    }

    @media print {
      display: none;
    }
  }

  @include c('pages') {
    overflow: auto;
    margin-top: $header-height;

    @media print {
      overflow: visible;
    }
  }

  @include c('dots-container') {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-top: 64px;
  }

  @include c('dots-text') {
    padding-top: 10px;
    color: var(--neutral-900);
  }

  @media print {
    display: block;
    position: relative;
    width: 100%;
  }
}

@media print {
  @page {
    margin: 0.5cm;
  }
}
