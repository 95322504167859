@import '../../base';

@include b('RoleAutomatedMessageHeader') {
  background-color: $role-automated-color;
  box-shadow: 0 0 0 1px $role-automated-color;
  padding: 5px;
  color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  @include c('text') {
    padding: 0 10px 0 6px;
    font-size: 11px;
    font-weight: 900;
    margin-top: 2.5px;
  }

  @include m('direction', 'INCOMING') {
    border-radius: 0 10px 0 0;
  }

  @include m('direction', 'OUTGOING') {
    border-radius: 10px 0 0;
  }
}
