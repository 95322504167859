@import '../../base';

@include b('PriorityMessageHeader') {
  background-color: var(--errors-500);
  box-shadow: 0 0 0 1px var(--errors-500);
  padding: 5px;
  color: var(--neutral-100);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  @include m('is-auto-forwarded') {
    align-items: flex-start;
  }

  @include c('priority-text') {
    padding: 0 10px 0 6px;
    font-size: 11px;
    font-weight: 900;
    margin-top: 2.5px;
  }

  @include c('auto-forward') {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  @include c('auto-forward-text') {
    padding: 0 10px 0 0;
    font-size: 12px;
    font-weight: 100;
  }

  @include m('direction', 'INCOMING') {
    border-radius: 0 10px 0 0;
  }

  @include m('direction', 'OUTGOING') {
    border-radius: 10px 0 0;
  }
}
