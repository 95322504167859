@import '../../../base';

@include b('AutomatedMessages') {
  display: flex;
  height: 100%;
  width: 100%;

  @include c('wrapper') {
    position: relative;
    margin: 16px 16px 0 20px;
    flex: 1;
    overflow: auto;
  }

  @include c('sidebar-wrapper') {
    background: var(--cool-neutral-200);
    border-left: 1px solid var(--neutral-400);
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    width: 350px;
  }

  @include c('sidebar-workflow-name') {
    background-color: var(--neutral-100);
    border: solid 1px var(--cool-neutral-700);
    box-shadow: inset 0 4px 2px 0 var(--neutral-300);
    display: flex;
    height: 30px;
    margin: 0 25px 0 0;
    padding: 4px 10px;
    width: 295px;
  }

  @include c('workflow-name') {
    background-color: var(--cool-neutral-300);
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    opacity: 0.7;
    padding: 10px 5px;
    width: 515px;
  }

  @include c('workflow-information') {
    background: var(--cool-neutral-200);
    opacity: 0.7;
    padding-bottom: 20px;
    pointer-events: none;
    width: 338px;

    &--workflowNameSet {
      opacity: 1;
      pointer-events: auto;
    }
  }

  @include c('workflow-status') {
    background-color: var(--neutral-100);
    border-radius: 6px;
    border: solid 1px var(--neutral-400);
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
    height: 110px;
    margin: 10px 0 8px 5px;
    padding: 5px 0 16px;
  }

  @include c('workflow-event-information') {
    background-color: var(--neutral-100);
    border-top: 1px solid var(--neutral-400);
    height: 56px;
    padding: 6px 8px 0;
    width: 331px;
    margin: 2px;

    &:hover {
      background-color: var(--cool-neutral-300);
      cursor: pointer;
    }

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
    }
  }

  @include c('workflow-event-offset') {
    color: var(--neutral-1000);
    font-size: 14px;
    font-weight: 600;
    height: 19px;
    margin: 0 59px 0 0;
    text-align: left;
    width: 130px;
  }

  @include c('workflow-event-name') {
    color: var(--neutral-1000);
    font-size: 14px;
    height: 18px;
    margin: 1px 0 0;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 320px;
  }

  @include c('workflow-status-button') {
    background-color: var(--neutral-100);
    border-radius: 50%;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.15);
    color: var(--neutral-950);
    cursor: pointer;
    font-size: 11px;
    font-weight: 700;
    height: 67px;
    margin: 5px 10px 1px 0;
    padding: 2px;
    width: 67px;

    &--hover {
      color: var(--neutral-100);
      background-color: var(--patient-network-700);
      border-radius: 50%;
    }
  }

  @include c('workflow-status-message-button') {
    border-top: 1px solid var(--neutral-400);
    display: flex;
    margin-top: -21px;

    &--showProgress {
      margin-top: -21px;
    }
  }

  @include c('activate-button') {
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 24px 0;

    &--isDisabled {
      cursor: not-allowed;
    }

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
      border-radius: 31px;
    }
  }

  @include c('clipboard-button') {
    align-content: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-left: 5px;
    margin-top: 3px;
  }

  @include c('clipboard-check') {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 23px;
    width: 22px;
    margin-left: 7px;
    background-color: var(--success-500);
    padding: 3px;
  }

  @include c('workflow-status-title') {
    width: 40px;
    height: 15px;
    margin: 0 233px 4px 7px;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: -0.01px;
    text-align: left;
    color: var(--neutral-950);
  }

  @include c('workflow-status-message') {
    width: 241px;
    height: 60px;
    margin: 10px 2px 0 7px;
    font-size: 12px;
    line-height: 1.25;
    text-align: left;
    color: var(--neutral-950);
  }

  @include c('workflow-event') {
    height: auto;
    margin: 10px 0 8px 5px;
    padding: 5px 0;
    border-radius: 6px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
    border: solid 1px var(--neutral-400);
    background-color: var(--neutral-100);
    max-height: 313px;
  }

  @include c('workflow-id') {
    background-color: var(--neutral-100);
    border-radius: 6px;
    border: solid 1px var(--neutral-400);
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
    margin: 6px;
    padding: 5px 1px 1px;
    width: 333px;
  }

  @include c('workflow-id-title') {
    color: var(--neutral-950);
    font-size: 11px;
    font-weight: 600;
    height: 15px;
    margin: 0 0 10px 5px;
    text-align: left;
    width: 81px;
  }

  @include c('workflow-id-icon') {
    display: flex;
    border-top: 1px solid var(--neutral-400);
    margin-top: -10px;
    padding-top: 5px;

    &--isSelected {
      background: var(--cool-neutral-300);
    }

    &:hover {
      background: var(--cool-neutral-300);
    }

    &:focus-visible {
      background: var(--cool-neutral-300);
    }
  }

  @include c('workflow-id-number') {
    color: var(--neutral-800);
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    height: 25px;
    margin: 2px 0 0 4px;
    text-align: left;
    width: 300px;
  }

  @include c('workflow-event-title') {
    color: var(--neutral-950);
    font-size: 11px;
    font-weight: 600;
    height: 15px;
    letter-spacing: -0.01px;
    margin: 0 322px 4px 6px;
    text-align: left;
    width: 36px;
  }

  @include c('workflow-event-add') {
    display: flex;
    border-top: 1px solid var(--neutral-400);
  }

  @include c('progress-bar') {
    height: 2px;
    margin-bottom: 13px;
    margin-top: -20px;
    color: var(--success-50);
    appearance: none;
    background: none;
    border: none;
    border-radius: 0 5px 5px 0;
    width: 100%;

    &::-webkit-progress-bar {
      background: none;
    }

    &::-webkit-progress-value {
      background: var(--success-50);
      transition: width 60ms ease-in-out;
    }

    &::-moz-progress-bar {
      background: var(--success-50);
    }

    &::-ms-fill {
      background: var(--success-50);
      border: none;
    }
  }

  @include c('workflow-new-event') {
    border-radius: 6px;
    border: dotted 1px var(--neutral-500);
    cursor: pointer;
    display: flex;
    height: 30px;
    margin: 7px 6px 2px;
    padding: 5px 59px 5px 85px;
    width: 352px;

    &:hover:enabled {
      background-color: var(--patient-network-700);
      color: var(--neutral-100);

      @include c('add-event-button') {
        background: var(--neutral-100);
        border-radius: 50%;
        padding: 3px;

        path {
          fill: var(--patient-network-700);
          stroke: var(--patient-network-700);
        }
      }

      @include c('add-new-event') {
        background-color: var(--patient-network-700);
        color: var(--neutral-100);
      }
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }
  }

  @include c('sidebar-workflow-close') {
    background: var(--cool-neutral-100);
    border-radius: 50%;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-right: 17px;
    margin-top: 17px;

    &--workflowEdit {
      margin-right: 6px;
      margin-top: 6px;
    }

    path {
      fill: var(--secondary-500);
    }

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
    }
  }

  @include c('sidebar-workflow-close-button') {
    padding: 2px;

    &--workflowEdit {
      margin-left: 4px;
      margin-bottom: 1px;
    }
  }

  @include c('workflows-list') {
    height: auto;
    overflow: auto;
  }

  @include c('selected-workflow') {
    margin: 3px;

    &--isSelected {
      background: var(--cool-neutral-300);
    }

    &:focus-visible {
      border-radius: 8px;
      outline: 3px solid var(--secondary-500);
    }
  }

  @include c('events-list') {
    height: auto;
    max-height: 224px;
    overflow: hidden auto;

    @include if-ie {
      overflow: auto;
    }
  }

  @include c('success-checkmark') {
    height: 16px;
    width: 10px;
  }

  @include c('add-new-event') {
    width: 102px;
    height: 19px;
    margin: 3px 0 0 10px;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    color: var(--patient-network-700);
  }

  @include c('add-event-button') {
    background: var(--patient-network-700);
    border-radius: 50%;
    height: 18px;
    padding: 3px;

    path {
      fill: var(--neutral-100);
      stroke: var(--neutral-100);
    }
  }

  @include c('delete-workflow') {
    background-color: var(--cool-neutral-200);
    border-radius: 3px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
    color: var(--errors-100);
    cursor: pointer;
    margin: 10px 78px 0 75px;
    padding: 7px 31px 6px;

    &--isEnabled {
      background-color: var(--neutral-100);
      color: var(--primary-300);

      &:hover {
        background-color: var(--primary-300);
        color: var(--neutral-100);
      }
    }
  }

  @include c('delete-tooltip') {
    text-align: center;
    position: relative;
    margin-left: 125px;
    margin-top: -14px;
    width: 60px;
    height: 10px;
  }

  @include c('workflow-details') {
    background-color: var(--neutral-100);
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    width: 350px;
  }

  @include c('workflow-name-existing') {
    display: flex;
    flex: 1;
    justify-content: space-between;
    max-height: 95px;
    overflow: hidden;
    margin: 17px 15px 17px 18.9px;
    white-space: pre-wrap;
    word-break: break-word;

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
    }
  }

  @include c('copied-text') {
    background-color: var(--patient-network-700);
    border-radius: 4px;
    color: var(--neutral-100);
    display: flex;
    font-size: 12px;
    height: 22px;
    justify-content: space-between;
    margin: 26px 121px 68px 150px;
    padding: 3px 14px 3px 6px;
    width: 75px;
  }

  @include c('header') {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    @include c('title') {
      font-size: 22px;
      color: var(--neutral-900);
    }

    @include c('add-button') {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      cursor: pointer;
      margin: 3px;

      &:hover {
        background-color: var(--patient-network-700);

        path {
          stroke: var(--neutral-100);
        }
      }

      &--disabled {
        path {
          stroke: var(--neutral-400);
        }

        &:hover {
          background-color: inherit;

          path {
            stroke: var(--neutral-400);
          }
        }
      }

      @include c('add-button-svg') {
        padding: 0 1px 3px 2px;
        width: 20px;

        g {
          stroke: var(--cool-neutral-700);
        }
      }
    }
  }

  @include c('input-container') {
    display: flex;
  }

  @include c('inline-editor') {
    color: var(--neutral-950);
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-weight: 600;
    height: 27px;
    width: auto;

    @include c('submit-input') {
      color: var(--cool-neutral-700);
      flex: 1 0 auto;
      font-size: 10px;
      font-weight: normal;
      margin-bottom: 3px;
      margin-left: 5px;
    }

    @include c('input-editor') {
      &--isAutomated {
        font-size: 14px;
        height: 23px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 270px;
      }

      &::-ms-clear {
        display: none;
        width: 0;
        height: 0;
      }
    }

    @include c('edit-icon') {
      margin-left: 5px;
      display: none;
    }

    &:hover {
      border-radius: 18px;

      @include c('edit-icon') {
        display: inline-block;
      }
    }

    &--editing {
      cursor: initial;
    }
  }

  @include c('loading') {
    position: absolute;
    visibility: hidden;
    bottom: 10px;
    width: 100%;
    text-align: center;

    &--isLoading {
      visibility: visible;
    }
  }
}
