@import '../base';

@include b('MuteButton') {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 10px 8px 20px;

  &:focus-visible {
    outline: 3px solid var(--secondary-500);
  }

  @include m('disabled') {
    &:hover {
      background-color: $disabled-hover;
      border-radius: 5px;
    }
  }

  @include c('option-header') {
    color: #686868;

    &--disabled {
      color: $label-text;
    }
  }

  @include c('option-button') {
    display: flex;

    :focus-visible {
      outline: 3px solid var(--secondary-500);
    }
  }
}
