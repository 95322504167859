@import '../base';

@include b('PatientDeleteModal') {
  @include c('body') {
    min-width: 504px;
  }

  @include c('message-container') {
    @include center-items-both;

    padding: 30px 0 55px;
  }

  @include c('deleteHeader') {
    @include common-modal-header;

    &:first-child {
      color: var(--primary-300);
      font-weight: 500;
    }
  }

  @include c('message') {
    font-size: 16px;
    max-width: 376px;
    margin-left: 55px;
    margin-right: 55px;
    text-align: center;
    word-break: break-word;

    @include c('patient-name') {
      font-weight: 600;
    }
  }

  @include c('cancel-btn') {
    @include common-button(var(--cool-neutral-700), 74px, 32px, 11px);

    &--progress {
      &:hover {
        background-color: var(--neutral-100);
        color: var(--cool-neutral-700);
        cursor: default;
        border-color: var(--cool-neutral-700);
      }
    }

    margin-right: 8px;
  }

  @include c('closeButton') {
    @include common-modal-close-button;

    color: var(--primary-300);
  }

  @include c('ok-btn') {
    @include common-button(var(--primary-300), 74px, 32px, 11px);

    &--progress {
      &:hover {
        background-color: var(--neutral-100);
        border-color: var(--primary-300);
        color: var(--primary-300);
        cursor: default;
      }
    }
  }

  @include c('forwardFooter') {
    background-color: var(--neutral-150);
    border: 1px solid var(--neutral-150);
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    display: flex;
    font-weight: 500;
    justify-content: flex-end;
    position: relative;
    padding: 30px 40px;

    // for IE, left side bug caused by border-bottom
    background-clip: padding-box;
  }

  @include c('dots-container') {
    @include center-items-both;

    height: 75%;
  }
}
