@import '../base';

@include b('MessageSenderStatus') {
  @include m('FAILED') {
    font-weight: 400;
    color: var(--errors-500);
  }

  @include m('SENT') {
    color: var(--message-status-sent);
  }
}

@include b('MessageRecipientStatus') {
  @include m('READ') {
    color: var(--message-status-read);
  }

  @include m('DELIVERED') {
    color: var(--message-status-delivered);
  }

  @include m('NEW') {
    color: var(--message-status-sent);
  }
}
