@import '../../base';

@include b('VirtualWaitingRoomPage') {
  display: flex;
  height: 100%;
  width: 100%;

  @include c('empty-room') {
    background-color: var(--neutral-150);
    flex-grow: 1;
  }

  @include b('ConversationMessages') {
    margin-top: 0;
  }
}
