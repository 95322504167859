@import '../base';

@include b('PatientSearchResultDetails') {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  width: 100%;
  border-top: solid 1px var(--neutral-400);
  background-color: var(--neutral-100);

  @include c('wrapper') {
    display: flex;
    height: 49px;
    align-items: center;
    cursor: pointer;

    &--smsOptedOut {
      height: 62px;
    }

    &--isAddFamilyAndContacts {
      margin-top: 5px;
      height: 37px;
      border-radius: 5px;
    }
  }

  @include c('wrapper-inside') {
    display: flex;
    align-items: center;
    border-radius: 6px;
    width: 100%;
    height: 39px;
    margin: 6px;

    &:hover {
      background-color: var(--cool-neutral-300);
    }

    &--smsOptedOut {
      height: 52px;
    }

    &--disableSelection {
      cursor: default;

      &:hover {
        background-color: transparent;
      }
    }
  }

  @include c('avatar') {
    margin: 0 8px 0 26px;

    &--isAddFamilyAndContacts {
      margin: 0 0 0 4px;
    }
  }

  @include c('details') {
    display: flex;
    flex-direction: column;
    flex: 1;

    &--isAddFamilyAndContacts {
      display: flex;
      flex-direction: inherit;
      justify-content: space-between;
    }

    @include c('opt-out-container') {
      &--isAddFamilyAndContacts {
        display: flex;
        margin-right: 5px;
      }
    }
  }

  @include c('call') {
    padding-right: 5px;
  }

  @include c('name') {
    font-size: 15px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--neutral-1000);

    &--smsOptedOut {
      color: var(--neutral-600);
      max-width: 110px;
    }

    &--isAddFamilyAndContacts {
      flex-direction: column;
      max-width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  @include c('patient-details') {
    font-size: 10px;
    color: var(--neutral-700);

    &--smsOptedOut {
      opacity: 0.5;
    }

    @include c('patient-mrn') {
      margin-left: 5px;
    }
  }

  @include c('highlighted') {
    color: inherit;
    background-color: var(--cool-neutral-300);
  }

  @include c('avatar-class') {
    background-color: var(--patient-network-700);
    border-radius: 50%;
    display: flex;
    align-content: center;
    justify-content: center;

    @include if-ie {
      padding-top: 2px;
    }

    &--smsOptedOut {
      background-color: inherit;
    }
  }
}
