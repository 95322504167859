@import '../../base';

@include b('CollaborationSelector') {
  background-color: var(--neutral-100);
  border: solid 1px var(--neutral-300);
  border-radius: 6px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.09);
  color: var(--neutral-950);
  max-height: 303px;
  overflow-y: auto;
  position: absolute;
  width: 353px;
  z-index: 1;
  display: flex;
  flex-direction: column;

  @include m('isMentionMemberFloatingModalOpen') {
    max-height: 210px;
    width: 400px;
  }

  @include m('large') {
    width: 100%;
  }

  @include m('coverInput') {
    top: 20px;
  }

  @include c('search-container') {
    margin: 15px auto;
    width: 260px;
  }

  @include c('sp-header') {
    text-align: left;
  }

  @include c('list-option') {
    align-items: center;
    border: 1px solid var(--neutral-100);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    height: inherit;
    text-align: left;

    &--selected {
      background-color: var(--cool-neutral-300);
      border: 1px solid var(--cool-neutral-300);
    }

    &:hover {
      background-color: var(--cool-neutral-300);
      border: 1px solid var(--cool-neutral-300);
    }

    &--default {
      background-color: var(--cool-neutral-300);
      border: 1px solid var(--cool-neutral-300);
    }

    &--disabled {
      cursor: not-allowed;

      @include if-ie {
        padding-right: 10px;
      }

      &:hover {
        background-color: var(--neutral-150);
        border: 1px solid var(--neutral-150);
        color: var(--cool-neutral-700);
      }
    }
  }

  @include c('no-search-results-found') {
    align-items: center;
    display: flex;
    height: 210px;
    justify-content: center;
    line-height: 32px;
  }

  @include c('loading') {
    text-align: center;
    height: 50px;
    cursor: default;

    &--isMentionMemberFloatingModalOpen {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
