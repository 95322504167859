@import '../../base';

@include b('InformProviderModal') {
  @include c('header') {
    @include common-modal-header;

    color: #3080df;
  }

  @include c('body') {
    @include common-modal-body;

    height: 200px;
    padding: 30px 60px 0;
  }

  @include c('footer') {
    height: 80px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #fafafa;
    border: 1px solid #fafafa;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-right: 20px;
  }

  @include c('invite-btn') {
    @include common-button($mid-grey, 74px, 32px, 11px);

    color: #fff;
    background-color: $patient-color;

    &:hover {
      background-color: $patient-color-hover;
      border-color: $patient-color-hover;
    }

    &--isSubmitting {
      cursor: default;
      pointer-events: none;
    }

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
    }
  }

  @include c('cancel-btn') {
    @include common-button($mid-grey, 74px, 32px, 11px);

    color: #9eb2bd;
    border: solid 0.9px #9eb2bd;
    background-color: #fff;
    margin-right: 10px;

    &:hover {
      background-color: #9eb2bd;
      color: $white;
      border-color: #9eb2bd;
    }

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
    }
  }
}
