@import '../base';

@include b('PatientSaveSuccessModal') {
  border-radius: 10px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
  background-color: var(--neutral-950);
  height: 119px;
  opacity: 0.8;
  width: 190px;

  @include c('saveSuccessHeader') {
    margin: 0;
  }

  @include c('body') {
    align-items: center;
    display: flex;
    flex-direction: column;

    @include c('check-mark') {
      margin-top: 20px;
      margin-bottom: 1px;
    }

    @include c('message-container') {
      color: var(--neutral-100);
      font-size: 20px;
      font-weight: 500;
      margin-top: 20px;
      text-align: center;
    }
  }
}
