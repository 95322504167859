@import '../../../base';

@include b('ScheduleMessageStatus') {
  @include c('optedOutContainer') {
    color: #8e4a2f;
    font-size: 10px;

    b {
      font-size: 12px;
      font-weight: 500;
    }
  }

  @include c('statusPill') {
    background-color: black;
    border-radius: 4px;
    color: white;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    padding: 1px 5px;
    text-transform: capitalize;

    &--scheduled {
      background-color: #3080df;
    }

    &--send-failed {
      background-color: #d52331;
    }

    &--delivered {
      background-color: #489eb4;
    }

    &--sent {
      background-color: #489eb4;
    }

    &--read {
      background-color: #04a74d;
    }

    &--isAutomatedMessage {
      background-color: #8bcb5d;
    }
  }
}
