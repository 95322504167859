@import '../../base';

@include b('VisitorCardList') {
  overflow-y: auto;
  height: calc(100% - 125px);

  @include c('AccessibleList') {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 3px;
    padding-bottom: 3px;

    & > * {
      margin-top: 10px;
    }

    & > *:first-child {
      margin-top: 0;
    }
  }
}
