@import '../../base';

@include b('ShareBroadcastListSearchBox') {
  background: var(--neutral-100);
  width: 353px;
  min-height: 80px;
  padding: 16px 12px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.09);
  border: solid 1px var(--neutral-300);

  @include c('sbl-header') {
    display: flex;
    flex-direction: row;
    font-weight: bold;
    align-content: center;
    margin-bottom: 5px;

    @include c('sbl-share-count') {
      flex: 1 0 auto;
    }

    @include c('sbl-add-button') {
      cursor: pointer;
    }
  }

  @include c('sbl-max-limit-text') {
    color: var(--errors-500);
    font-size: 12px;
    padding: 8px 12px;
  }

  @include c('sbl-results-dropdown') {
    position: absolute;
    background: var(--neutral-100);
    width: 327px;
    z-index: 2;
    border-radius: 6px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.09);
    border: solid 1px var(--neutral-300);

    @include c('sbl-search') {
      margin: 10px 12px;

      input {
        height: 16px;
        width: 100%;
      }

      input::placeholder {
        color: var(--neutral-400);
      }
    }

    @include c('sbl-results') {
      max-height: 175px;
      overflow-y: scroll;
      padding: 5px;

      @include c('sbl-result-item') {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1 1 auto;
        padding: 6px 8px;
        cursor: pointer;
        font-weight: bold;

        &:hover {
          border-radius: 6px;
          background-color: var(--cool-neutral-300);
        }
      }
    }
  }

  @include c('sbl-result-name') {
    @include ellipsis;

    font-size: 14px;
    margin: 0 10px;
    font-weight: 500;
    flex: 1 1 auto;
  }

  @include c('sbl-empty-list-text') {
    font-style: italic;
    text-align: center;
  }

  @include c('sbl-shared-members-list') {
    max-height: 250px;
    overflow-y: auto;

    @include c('sbl-pending-results') {
      padding: 5px 0;

      @include c('sbl-result-item') {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1 1 auto;
        padding: 6px 8px;
        margin: 2px 0;
        font-weight: bold;
        background-color: var(--secondary-700);
        border-radius: 6px;
        color: var(--neutral-100);
      }
    }

    @include c('sbl-current-results') {
      padding: 5px 0;

      @include c('sbl-result-item') {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1 1 auto;
        padding: 6px 8px;
        margin: 2px 0;
        font-weight: bold;
        border-radius: 6px;

        &:hover {
          background-color: var(--cool-neutral-300);
        }
      }
    }

    @include c('sbl-pending-results-divider') {
      margin: 5px 0;
      border: 1px solid var(--neutral-300);
    }
  }

  @include c('sbl-result-avatar') {
    flex: 0 0 auto;
    cursor: default;
  }

  @include c('sbl-result-remove-button') {
    cursor: pointer;
    height: 16px;
    width: 16px;
    flex: 0 0 16px;
  }

  @include c('sbl-actions') {
    display: flex;
    flex-direction: row;
    font-size: 15px;
    font-weight: bold;
    line-height: 46px;
    text-align: center;
    margin-top: 10px;

    @include c('sbl-cancel-button') {
      flex: 1 1 50%;
      height: 46px;
      margin-right: 5px;
      border-radius: 6px;
      border: solid 1px var(--neutral-400);
      color: var(--neutral-700);
      background-color: var(--neutral-100);
      cursor: pointer;
    }

    @include c('sbl-save-button') {
      flex: 1 1 50%;
      margin-left: 5px;
      border-radius: 6px;
      height: 46px;
      background: var(--secondary-700);
      color: var(--neutral-100);
      cursor: pointer;
    }
  }
}
