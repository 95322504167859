@import '../base';

@include b('GroupedMessagesList') {
  padding: 16px 0 35px;
  height: inherit;

  @include m('hasMessageFormIndicator') {
    padding-bottom: 60px;
  }

  &::before {
    content: '';
    display: block;
    flex: 1;
  }

  @include c('message-loader-top') {
    position: absolute;
    vertical-align: middle;
    color: var(--neutral-700);
    width: 200px;
    padding: 0 13px 0 8px;
  }

  @include c('message-loader-bottom') {
    position: absolute;
    vertical-align: middle;
    color: var(--neutral-700);
    width: 200px;
    padding: 6px 13px 0 8px;
  }

  @include c('spinner') {
    vertical-align: middle;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-left: 8px;

    > * {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }

  @include c('list') {
    @include clearfix;

    list-style: none;
    padding: 0;
    margin: 0;
  }

  @include c('date-separator') {
    text-align: center;
    font-size: 13px;
    color: var(--neutral-800);

    &--showMargin {
      margin-top: 23px;
    }

    &--isMultiSelect {
      margin-top: 8px;
    }

    &:focus-visible {
      outline: 3px solid var(--secondary-500) !important;
    }
  }

  @include c('bang-text') {
    text-align: center;
    font-size: 13px;
    color: var(--neutral-800);

    &--showMargin {
      margin-top: 14px;
    }
  }

  @include c('new-messages') {
    display: flex;
    flex-direction: row;
    height: 20px;
    user-select: none;
    margin-top: 17px;

    &--precededByDateSeparator {
      margin-top: 7px;
    }

    @include c('new-messages-box') {
      flex: 0 0 auto;
      height: 20px;

      // border: 1px solid #4570a9;
      border: 1px solid var(--secondary-900);
      border-radius: 8.5px;
      padding: 2px 6px 1px;
      white-space: nowrap;
      text-align: center;
      font-size: 11px;

      // color: #4570a9;
      color: var(--secondary-900);

      &:focus-visible {
        outline: 3px solid var(--secondary-500);
      }
    }

    &::before,
    &::after {
      content: '';
      display: block;
      flex: 1 0 auto;
      height: 10px;
      margin: 0 1px;

      // border-bottom: 1px solid #4570a9;
      border-bottom: 1px solid var(--secondary-900);
    }
  }

  @include c('last-item') {
    height: 25px;
  }
}
