@import '../../base';

@include b('YoureAllCaughtUp') {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  width: 100%;

  @include c('all-caught-up-text') {
    margin-top: 26px;
    font-size: 16px;
    color: var(--neutral-1000);
  }

  @include c('view-history') {
    margin-top: 5px;
    font-size: 15px;
    color: #1e90ff;
    cursor: pointer;
  }

  @include c('all-caught-up-image') {
    border-radius: 50%;
    border: 1px solid var(--neutral-300);
    overflow: hidden;
    box-shadow: 0 3px 8px 0 var(--neutral-300);

    svg {
      height: 150px;
      width: 150px;

      g {
        background-color: var(--neutral-100);
      }

      circle[fill='#ffffff'],
      path[fill='#ffffff'] {
        fill: var(--neutral-100);
      }
    }
  }
}
