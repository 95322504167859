@import '../../base';

@include b('PatientReferenceDetailsModal') {
  font-size: 14px;

  @include c('patient-image-header') {
    height: 75px;
    width: auto;
    margin-top: 35px;
    margin-bottom: 30px;

    svg {
      height: 100%;
      width: 100%;
    }
  }

  @include c('patient-ref-name') {
    font-size: 24px;
    font-weight: 700;
    padding: 0 40px;
    word-break: break-word;
  }

  @include c('patient-ref-details') {
    color: var(--neutral-700);
    margin-top: 2px;

    @include c('discharged-text') {
      color: var(--primary-300);
    }
  }

  @include c('patient-ref-location') {
    color: var(--neutral-900);
    margin: 3px 10px 0;
    flex-wrap: wrap;
  }

  @include c('patient-ref-create-conv-button') {
    margin-top: 30px;
    margin-bottom: 20px;

    button {
      background: var(--cool-neutral-200);
      border-radius: 200px;
      color: var(--secondary-500);
      padding: 14px 16px;
      font-weight: 300;

      &:hover {
        background: var(--cool-neutral-300);
      }
    }
  }

  @include c('patient-ref-headers-container') {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    height: 29px;
    justify-content: space-around;
    order: 0;
    width: auto;

    @include c('patient-ref-header') {
      color: var(--neutral-1000);
      font-size: 14px;
      font-weight: 500;
      opacity: 0.7;
      order: 0;
      width: 200px;

      &--selected {
        border-bottom: solid 2px var(--patient-network-700);
      }
    }
  }

  @include c('patient-ref-conversations') {
    background: var(--neutral-200);
    border-radius: 0 0 5px 5px;
    box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.2);
    max-height: 300px;
    overflow-y: scroll;
    padding: 10px;
    text-align: left;

    @include c('empty-care-team') {
      background: var(--neutral-200);
      color: var(--neutral-900);
      display: flex;
      flex-direction: column;
      font-size: 16px;
      font-weight: 400;
      gap: 10px;
      height: auto;
      justify-content: center;
      line-height: 19px;
      padding: 60px 60px 70px;
      text-align: center;
      width: auto;
    }

    @include c('search-results-page-loader') {
      color: var(--neutral-800);
      font-size: 18px;
      height: 100px;
      margin: 50px 0;
      text-align: center;
    }

    @include c('conversation-card-container') {
      display: flex;
      background: var(--neutral-100);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      margin: 5px 0;
      padding: 11px 8px;
      align-items: center;
      cursor: pointer;

      @include c('avatar') {
        flex: 0 0 auto;
        padding-right: 8px;
      }

      @include c('avatar-care-team') {
        flex: 0 0 auto;
        height: 45px;
        padding-right: 8px;
        width: 45px;

        @include c('avatar-image') {
          border-radius: 50%;
          height: 45px;
          margin-left: 10px;
          width: 45px;
        }

        @include c('avatar-image-initials') {
          align-items: center;
          border-radius: 50%;
          border: 1px solid var(--neutral-600);
          display: flex;
          font-size: 16px;
          height: 45px;
          justify-content: center;
          margin-left: 10px;
          width: 45px;
        }
      }

      @include c('details') {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;

        &--careTeamTabSelected {
          margin-left: 20px;
        }

        @include c('top-row') {
          display: flex;

          @include c('name') {
            @include ellipsis;

            flex: 1 1 auto;
            color: var(--neutral-950);
            font-weight: 500;
            padding-right: 10px;
          }

          @include c('last-message') {
            flex: 0 0 auto;

            @include c('last-message-timestamp') {
              font-size: 10px;
              font-weight: 400;
              color: var(--neutral-700);
            }
          }
        }

        @include c('mid-row') {
          display: flex;
          height: 20px;
          max-width: 310px;

          @include c('last-message') {
            @include ellipsis;

            max-width: 32em;
            font-weight: 400;
            color: var(--neutral-700);
          }
        }

        @include c('bot-row') {
          font-size: 11px;
          height: 14px;
          display: flex;
          align-items: baseline;

          @include c('sender-role') {
            flex: 1 1 auto;
            text-align: right;
            color: var(--neutral-700);
          }

          @include c('last-message-status') {
            flex: 1 1 auto;
          }
        }
      }
    }
  }
}
