@import '../../base';

@include b('CollaborationEntityEditorCreateButtons') {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px;
  min-height: auto;

  .tc-ToolTip {
    flex: 1;
  }

  .tc-ToolTip:first-child {
    margin-right: 10px;
  }

  @include c('actions-button') {
    height: 38px;
    border: 1px solid var(--neutral-300);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2036);
    border-radius: 3px;
    width: 100%;

    &--isCreate {
      background-color: var(--secondary-700);
      border: 1px solid var(--secondary-700);
      color: var(--neutral-100);
    }

    &[disabled] {
      background: var(--cool-neutral-200);
      border: 1px solid var(--neutral-300);
      box-sizing: border-box;
      border-radius: 3px;
      color: rgba(127, 127, 127, 0.3);
      cursor: default;
    }
  }
}
