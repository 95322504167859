@import '../base';

@include b('EscalationBanner') {
  display: flex;
  align-items: flex-start;

  @include c('escalation-banner') {
    padding: 2px 6px 1px;
    border-radius: 8.5px;
    font-size: 11px;
    font-weight: 500;
    color: var(--neutral-100);
  }

  @include m('status', 'IN_PROGRESS') {
    background-color: var(--orange-100);
    color: var(--orange-500);
  }

  @include m('status', 'CANCELLED') {
    background-color: var(--errors-50);
    color: var(--errors-900);
  }

  @include m('status', 'ACKNOWLEDGED') {
    background-color: var(--escalation-50);
    color: var(--escalation-100);
  }

  @include m('status', 'NO_RESPONSE') {
    background-color: var(--neutral-200);
    color: var(--neutral-900);
  }
}
