@import '../base';

@include b('RemoveRoleOrUserLineModal') {
  @include c('header') {
    @include common-modal-header;

    color: var(--primary-300);
  }

  @include c('footer') {
    background: var(--neutral-150);
    display: flex;
    justify-content: flex-end;
    padding: 20px 30px 30px;
  }

  @include c('body') {
    @include common-modal-body;

    text-align: center;
    padding: 45px 50px 75px;
  }

  @include c('unassign-btn') {
    @include common-button(var(--primary-300), 74px, 32px, 11px);
  }

  @include c('cancel-btn') {
    @include common-button(var(--neutral-800), 74px, 32px, 11px);

    margin-right: 10px;
  }
}
