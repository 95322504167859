@import '../base';

@include b('TypingStatusIndicator') {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include c('dots') {
    align-self: flex-end;
    line-height: inherit;
    margin-bottom: 0.25em;
  }

  @include c('name-indicator') {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
}
