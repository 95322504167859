@import '../../base';

@include b('DndBanner') {
  @include c('dnd-status') {
    align-items: center;
    background: var(--cool-neutral-700);
    color: var(--neutral-100);
    display: flex;
    flex-direction: column;
    font-size: 15px;
    justify-content: center;
    margin-top: 17px;
    padding: 5px 0;

    @include c('dnd-status-information') {
      display: flex;
      padding: 0 12px;
      align-items: center;
      justify-content: center;
      width: 100%;

      @include c('dnd-status-svg') {
        margin-right: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 20px;
      }

      @include c('dnd-text') {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        hyphens: auto;
        font-weight: 400;

        &--isOverflowing {
          cursor: pointer;
        }

        &--isExpanded {
          overflow: visible;
          display: block;
          -webkit-line-clamp: unset;
          -webkit-box-orient: unset;
        }
      }
    }

    @include c('dnd-auto-forward') {
      font-weight: 100;
      padding-top: 3px;
      font-size: 13px;

      @include c('dnd-auto-forward-name') {
        margin-left: 5px;
        font-weight: 500;
      }
    }
  }
}
