@import '../../base';

@include b('CollaborationTeamEditorMembers') {
  @include c('members-row') {
    display: flex;
    flex-direction: row;
    width: 500px;

    @include c('members') {
      cursor: text;
      flex: 1 1 auto;
      outline: none;
    }
  }

  @include c('container') {
    &--editing {
      padding-top: 0;
    }
  }

  @include c('require-members-text-row') {
    color: var(--cool-neutral-700);
    font-weight: 400;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
  }

  @include c('selected-members-row') {
    padding-bottom: 2px;

    &--showViewAll {
      padding-bottom: 0;
    }
  }

  @include c('header') {
    padding: 5px 9px 13px;

    &--isNewTeam {
      padding-bottom: 7px;
    }
  }

  @include c('team-description-text') {
    font-weight: 600;
    font-size: 11px;
    display: flex;
    align-items: center;

    &--canEdit {
      padding-bottom: 12px;
    }

    @include c('text') {
      flex: 1 1 auto;

      &--count {
        display: flex;
        font-size: 12px;
        justify-content: flex-end;
        align-items: center;
        font-weight: normal;
        color: var(--neutral-900);
      }

      &--overLimit {
        color: var(--primary-300);
        font-weight: bold;
      }
    }
  }

  @include c('text') {
    flex: 1 1 auto;

    &--count {
      display: flex;
      font-size: 12px;
      justify-content: flex-end;
      align-items: center;
      font-weight: normal;
      color: var(--neutral-900);
    }

    &--overLimit {
      color: var(--primary-300);
      font-weight: bold;
    }
  }

  @include c('info-icon') {
    background-color: var(--neutral-700);
    color: var(--neutral-100);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 14px;
    width: 18px;
    height: 18px;
    border-radius: 9px;
    cursor: pointer;
    margin-left: 5px;

    &:hover {
      background-color: var(--neutral-1000);
    }

    &--active {
      background-color: var(--neutral-1000);
    }
  }

  @include c('member-list-row ') {
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: auto;
  }

  @include c('team-editor-button') {
    color: #4580d8;
    background-color: var(--neutral-150);
    font-size: 14px;
    padding: 6px;
    text-align: center;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
      background-color: var(--neutral-200);
    }
  }

  @include c('form-row') {
    border: 1px solid var(--neutral-500);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    padding: 0 8px 0 5px;

    .add-team-members {
      color: var(--neutral-1000);
      font-weight: 400;

      .Select-placeholder {
        font-size: 14px;
        margin-left: 0;
        display: flex;
        align-items: center;
      }

      .Select-menu-outer {
        margin-left: 0 !important;
        width: 338px !important;
      }

      .Select-input {
        height: 30px !important;
        margin: 2px 0 !important;

        input {
          height: 18px;
        }
      }
    }
  }

  @include c('members-wrapper') {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

    &--isNewTeamOrEditorSection {
      height: 370px;
      max-height: none;
    }

    &--viewAllMembers {
      margin-bottom: 10px;
    }

    @include c('view-all-members') {
      box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.2);
      background-color: var(--neutral-100);
      color: var(--secondary-500);
      font-weight: 500;
      font-size: 12px;
      padding: 12px;
      text-align: center;

      &:hover {
        background-color: var(--cool-neutral-200);
      }
    }
  }

  @include c('actions') {
    height: 27px !important;
    width: 27px !important;

    &--isLoading {
      visibility: hidden;
    }
  }

  @include c('actions-remove') {
    cursor: pointer;
    fill: var(--neutral-100);
    height: 27px !important;
    width: 27px !important;
  }

  @include c('list-item') {
    @include center-items-both;

    border-radius: 6px;
    padding: 5px;
    margin: 0 9px;

    &--isNewTeamOrEditorSection {
      cursor: pointer;

      &:hover {
        background-color: var(--cool-neutral-300);
      }
    }

    &:hover {
      @include c('actions-remove') {
        path {
          fill: #6edbff;
        }
      }
    }

    circle {
      fill: var(--neutral-100);
      stroke: var(--neutral-100);
    }

    @include c('entity-svg') {
      flex-shrink: 0;
      margin-left: 5px;

      &--not-tagged-role {
        @include b('AvatarImage') {
          .tc-AvatarImage__image--entityType--user {
            background-color: var(--neutral-100);
            stroke: black;
            border: 1px solid var(--neutral-700);

            path {
              fill: black;
            }
          }

          @include c('image-container-square') {
            background-color: var(--cool-neutral-700) !important;
            border-width: 0 !important;
          }

          path {
            fill: var(--neutral-100);
          }
        }
      }

      .tc-AvatarImage__smallIcon {
        width: 14px;
        height: 14px;
      }
    }

    @include c('entity-info') {
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      margin-left: 15px;

      @include c('entity-name') {
        color: var(--neutral-1000);
        font-size: 14px;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 244px;
      }

      @include c('entity-sub-info') {
        font-size: 12px;
        max-width: 250px;

        &--small {
          font-size: 10px;
        }

        @include c('entity-tag') {
          @include ellipsis;

          max-width: 250px;
        }

        @include c('entity-details') {
          @include ellipsis;

          &--red {
            color: var(--primary-300);
          }

          color: var(--neutral-700);
          width: auto;
        }
      }
    }
  }

  @include c('member-spaces-outer-container') {
    position: relative;
  }

  @include c('member-spaces-inner-container') {
    position: absolute;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    width: 184px;
    background-color: var(--neutral-1000);
    color: var(--neutral-100);
    top: 20px;
    right: 0;
    z-index: 200;
    border-radius: 5px;
    font-size: 12px;

    @include c('header') {
      background-color: var(--neutral-1000);
      padding: 5px 15px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      font-weight: 600;
    }

    @include c('content') {
      div {
        margin: 10px 15px;
        font-weight: normal;
      }
    }
  }
}
