@import '../../base';

@include b('CallButton') {
  @include c('button') {
    background-color: var(--neutral-400);
    border-radius: 50%;
    cursor: pointer;
    height: 26px;
    width: 26px;

    @include c('phone-icon') {
      height: 13px;
      width: 13px;
      padding-top: 1px;
    }

    & path[fill-rule='evenodd'] {
      fill: var(--neutral-700);
    }

    &:hover {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    }

    &:disabled {
      cursor: not-allowed;

      &:hover {
        box-shadow: none;
      }

      & path[fill-rule='evenodd'] {
        fill: var(--neutral-500);
      }
    }

    &--detailsPane {
      background-color: var(--neutral-100);
      height: 32px;
      width: 32px;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);

      & path[fill-rule='evenodd'] {
        fill: var(--cool-neutral-700);
      }

      @include c('phone-icon') {
        height: 14px;
        width: 14px;
        padding: 0;
      }
    }
  }
}
