@import '../../base';

@include b('MobileQRLogin') {
  position: absolute;
  top: 60px;
  right: 68px;
  width: 360px;
  height: auto;
  border-radius: 6px;
  background-color: var(--neutral-100);
  border: solid 1px var(--neutral-300);
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
  z-index: 1;

  &:focus {
    outline: none;
  }

  @include c('qr-container') {
    text-align: center;
    padding: 10px 20px;
    padding-top: 15px;
    position: relative;
    width: 100%;
    height: 250px;

    @include c('qr-image') {
      width: 150px;
      padding: 40px 0;
    }

    @include c('new-qr-code') {
      padding: 70px 20px;

      @include c('expired-text') {
        font-size: 12px;
        padding-bottom: 10px;
      }

      @include c('new-code-button') {
        margin: auto;
        padding: 4px 2px 4px 12px;
        width: 124px;
        height: 32px;
        border-radius: 15px;
        background-color: rgba(4, 167, 77, 0.49);
        display: flex;
        user-select: none;

        &:hover {
          background-color: var(--success-500);
        }

        svg {
          color: var(--neutral-100);
        }

        @include c('new-code-text') {
          padding: 3px 10px 0 5px;
          font-size: 14px;
          font-weight: 600;
          color: var(--neutral-100);
        }

        @include c('arrow-circle') {
          @include circle(24px);
          @include center-items-both;

          width: 24px;
          height: 24px;
          background-color: var(--success-500);
        }
      }
    }
  }

  @include c('mobile-text') {
    background-color: var(--neutral-150);
    padding: 15px;
    border-radius: 0 0 6px 6px;
    border-top: solid 1px var(--neutral-300);

    @include c('mobile-header') {
      font-weight: 600;
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      user-select: none;

      @include c('circle') {
        @include circle(24px);
        @include center-items-both;

        background-color: var(--neutral-100);
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
        margin-right: 6px;

        @include if-ie {
          left: -8px;
        }

        @include c('tc-icon') {
          fill: var(--cool-neutral-700);
          width: 18px;
          height: 18px;
        }
      }
    }

    @include c('login-text') {
      color: var(--secondary-500);
    }
  }
}
