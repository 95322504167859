@import '../base';

@include b('EscalationPolicyInfoPane') {
  flex: 1 0 auto;
  position: relative;
  font-size: 14px;

  @include c('name-and-avatar') {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 0 120px;
    margin: 50px 8px 30px;
    color: $display-text;
  }

  @include c('avatar-container') {
    height: 80px;
    width: 80px;
  }

  @include c('name-container') {
    border-radius: 29px;
    color: var(--neutral-1000);
    font-size: 18px;
    font-weight: 500;
    margin: 3px 0;
    padding: 4px 15px;
    text-align: center;
  }

  @include c('section') {
    border-radius: 8px;
    margin-left: 8px;
    margin-right: 8px;
    color: var(--neutral-900);
    font-size: 12px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    @include c('title') {
      font-weight: bold;
    }
  }

  @include c('escalation-status') {
    padding: 15px 14px;

    @include m('status', 'ACKNOWLEDGED') {
      background-color: var(--escalation-50);
      color: var(--escalation-100);
    }

    @include m('status', 'CANCELLED') {
      background-color: var(--errors-50);
      color: var(--errors-900);
    }

    @include m('status', 'IN_PROGRESS') {
      background-color: var(--orange-100);
      color: var(--orange-500);
      text-align: center;
    }

    @include m('status', 'NO_RESPONSE') {
      background-color: var(--neutral-300);
      color: var(--neutral-900);
    }
  }

  @include c('sender') {
    color: var(--neutral-700);
    font-size: 13px;
  }

  @include c('message-time') {
    color: var(--neutral-700);
    font-size: 13px;
  }

  @include c('message-body') {
    margin: 3px 0 5px;
    color: var(--neutral-900);
    font-size: 14px;
  }

  @include c('target') {
    display: flex;
    flex: 1 0 auto;
    padding: 10px 0;

    &:not(:last-child) {
      border-bottom: 1px solid var(--neutral-300);
    }

    @include c('target-info') {
      display: flex;
      flex: 1 0 auto;
      flex-direction: column;

      @include c('recipient-type') {
        margin-bottom: 3px;
        color: var(--neutral-700);
        font-size: 10px;
        font-weight: 600;
      }

      @include c('name') {
        margin: 0 0 3px;
        color: var(--neutral-900);
        font-size: 14px;
        font-weight: 500;
      }

      @include c('tag') {
        margin: 0 0 3px;
        color: var(--neutral-700);
        font-size: 12px;
      }

      @include c('duration') {
        color: var(--cool-neutral-800);
        font-size: 12px;
      }
    }

    @include c('target-status') {
      display: flex;
      flex: 1 0 auto;
      flex-direction: column;
      align-self: center;
      align-items: flex-end;
      line-height: 1.2;
      font-size: 13px;
      color: var(--neutral-700);

      @include c('status') {
        font-weight: 500;
        color: var(--scheduling-primary-100);

        @include m((('status', 'READ'), ('status', 'ACKNOWLEDGED'))) {
          color: var(--success-500);
        }
      }
    }
  }
}
