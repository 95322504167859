@import '../base';

@include b('SupportedFileTypesModal') {
  font-size: 18px;

  @include c('header') {
    @include common-modal-header;

    font-weight: bold;
    color: var(--primary-300);
  }

  @include c('body') {
    @include rejected-attachments-modal-body;

    padding: 35px;
  }

  @include c('close-button') {
    @include common-modal-close-button;

    color: var(--primary-300);
  }

  @include c('title') {
    font-weight: bold;
    margin-bottom: 2vh;
  }

  @include c('formats-box') {
    background-color: var(--neutral-150);
    border: 1px solid var(--neutral-700);
    border-radius: 6px;
    padding: 10px 20px;
    color: var(--neutral-700);

    @include c('formats-list') {
      columns: 3 10px;
      column-width: 30px;
      text-align: left;
      width: 260px;

      li {
        margin-top: 0.5vh;

        &:first-of-type {
          margin-top: 0;
        }
      }
    }
  }

  @include c('footer') {
    @include rejected-attachments-footer;
  }
}
