@import '../../base';

@include b('ProviderBroadcastListRow') {
  align-items: center;
  position: relative;
  color: var(--neutral-1000);
  display: flex;
  font-size: 14px;
  line-height: 50px;
  background-color: var(--neutral-100);

  @include m('selectable') {
    cursor: pointer;

    &:hover {
      background-color: var(--cool-neutral-100);

      @include c('list-item-checkbox') {
        background-color: var(--cool-neutral-100);
      }
    }
  }

  @include m('isSelected') {
    background-color: var(--cool-neutral-300);

    &:hover {
      background-color: var(--cool-neutral-300);

      @include c('list-item-checkbox') {
        background-color: var(--cool-neutral-300);
      }
    }
  }

  > * {
    flex: 1;
    padding-left: 15px;
    vertical-align: middle;
  }

  @include c('list-item-checkbox') {
    cursor: default;
    flex: none;
    padding: 0;
    text-align: center;
    width: 58px;

    @include c('list-item-checkbox-default') {
      border-radius: 2px;
      border: solid 1px var(--neutral-400);
      cursor: pointer;
      height: 14px;
      width: 14px;
      position: relative;
      margin: 0 auto;

      &--isSelected {
        border: none;

        svg {
          position: absolute;
          left: 0;
          top: 0;
        }
      }

      &:focus-visible {
        outline: 3px solid ar(--secondary-500);
      }
    }
  }

  @include c('member-details') {
    flex: 1.5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 0;

    &--selected {
      background-color: var(--cool-neutral-100);
    }

    & > div {
      line-height: initial;
    }

    @include c('member-name') {
      @include ellipsis;

      font-weight: 600;
      display: flex;
      align-items: center;

      &--hideBorder {
        border-top: none;
      }

      @include c('entity-avatar') {
        flex: 0 0 auto;
      }

      @include c('name') {
        @include ellipsis;

        margin-left: 12px;
      }

      @include c('grouped-contact-relation') {
        font-weight: normal;
      }
    }
  }

  @include c('title') {
    @include ellipsis;

    flex: 1;
    border-top: solid 1px var(--neutral-300);

    &--hideBorder {
      border-top: none;
    }

    &--selected {
      background-color: var(--cool-neutral-100);
    }

    &::after {
      content: '\200b';
    }
  }

  @include c('department') {
    @include ellipsis;

    border-top: solid 1px var(--neutral-300);

    &--hideBorder {
      border-top: none;
    }

    &--selected {
      background-color: var(--cool-neutral-100);
    }

    &::after {
      content: '\200b';
    }
  }

  @include c('added-by') {
    @include ellipsis;

    border-top: solid 1px var(--neutral-300);

    &--hideBorder {
      border-top: none;
    }

    &--selected {
      background-color: var(--cool-neutral-100);
    }

    &::after {
      content: '\200b';
    }
  }

  @include c('added-on') {
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex: 1;
    border-top: solid 1px var(--neutral-300);

    &--hideBorder {
      border-top: none;
    }

    @include c('added-on-text') {
      @include ellipsis;
    }

    &--selected {
      background-color: var(--cool-neutral-100);
    }
  }

  @include c('close-button-container') {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--secondary-700);
    border-radius: 50%;
    min-height: 20px;
    height: 20px;
    min-width: 20px;
    margin: 0 10px;
    padding: 0;

    &:hover {
      background-color: var(--secondary-900);
    }

    @include c('close-icon-white') {
      fill: var(--neutral-100);
    }
  }
}
