@import '../base';

@include b('GroupEditor') {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  position: relative;
  font-size: 14px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100%;

  @include c('expiration-detail') {
    font-size: 12px;
    font-weight: 600;
    color: #30a0c4;
    text-align: center;
    width: 100%;
    padding-bottom: 70px;
  }

  @include c('bottom-div') {
    height: 1px;
  }
}
