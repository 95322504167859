@import '../../base';
@import './PatientSettingMixins';

@include b('ScheduledMessagesRow') {
  @include list-row;

  &:focus-visible {
    border: 3px solid var(--secondary-500);
    padding: 0;
  }

  height: 68px;

  @include c('list-item') {
    @include list-item-row;

    &--updateDetails {
      @include c('list-item-updated-on') {
        @include c('list-item-updated-by') {
          color: var(--neutral-950);
          font-size: 11px;
        }
      }
    }

    @include c('template-label') {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: auto;
    }

    @include c('repeating') {
      color: var(--neutral-700);
      display: flex;
      font-size: 11px;
      text-transform: capitalize;

      @include c('repeating-svg') {
        margin-right: 3px;
        margin-top: 1px;
      }
    }
  }
}
