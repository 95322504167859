@import '../../base';

@include b('VirtualWaitingRoomSideBarHeader') {
  height: 68px;
  background-color: white;
  border-bottom: solid 0.5px #c7c7c7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px 16px 44px;
  position: relative;
  min-width: 228px;

  @include c('loading-container') {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @include c('room-info') {
    display: flex;
    flex-direction: column;
    align-items: center;

    > div {
      width: auto;
    }
  }

  @include c('no-rooms-title') {
    font-family: inherit;
    color: #c7c7c7;
    font-size: 18px;
  }

  @include c('no-rooms-subtext') {
    font-family: inherit;
    color: #c7c7c7;
    font-size: 10px;
  }

  @include c('selected-room') {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include c('selected-room-text') {
    @include ellipsis;

    padding-right: 5px;
    max-width: 250px;
  }

  @include c('dropdown-button') {
    font-family: inherit;
    font-size: 18px;
    font-weight: 400;
    color: #4d4d4d;
    display: flex;
    align-items: center;

    > p {
      padding-right: 6px;
    }
  }

  @include c('room-status-text-open') {
    font-family: inherit;
    font-size: 10px;
    font-weight: 600;
    margin-top: 5px;
    color: #007aff;
    align-self: flex-start;
  }

  @include c('room-status-text-closed') {
    font-family: inherit;
    font-size: 10px;
    font-weight: 600;
    margin-top: 5px;
    color: red;
    align-self: flex-start;
  }

  @include c('ellipse-button') {
    padding: 8px 10px;
  }
}
