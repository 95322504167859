@import '../../../base';

@include b('PatientBroadcastCsvFailModal') {
  @include c('header') {
    @include common-modal-header;

    color: $display-text;
  }

  @include c('close-button') {
    @include common-modal-close-button;

    color: $primary-brand;
  }

  @include c('img') {
    width: 68px;
    height: 68px;
  }

  @include c('text') {
    color: $display-text;
    font-weight: 600;
    text-align: center;
    padding: 10px 20px 20px;
  }
}
