@import '../base';

@include b('GroupForm') {
  margin: 50px 8px 30px;
  flex-direction: column;
  color: var(--neutral-900);

  @include c('name-and-avatar') {
    display: flex;
    align-items: center;
    flex-direction: column;

    &--isPatientCare {
      display: block;
      text-align: center;
    }
  }

  @include c('avatar-container') {
    height: 64px;
    width: 64px;
    position: relative;
    cursor: pointer;

    &--isPatientMessaging {
      cursor: default;
    }

    &:hover {
      @include c('overlay') {
        display: block;
      }

      @include c('edit-avatar-text') {
        display: block;
      }

      @include c('edit-avatar-icon-container') {
        display: block;
      }
    }
  }

  @include c('avatar-container-team') {
    height: 80px;
    width: 80px;
    position: relative;
  }

  @include c('avatar-upload-container') {
    width: 100%;
    height: 100%;
    position: relative;
  }

  /* Dark Mode TODO: */
  @include c('detail-card-container') {
    border-radius: 6px;
    background-color: #f0fbff;
    border: solid 1px #cdd9de;
    padding: 2px 16px;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    max-width: 340px;
  }

  @include c('patient-detail-card-container') {
    padding-top: 12px;
    width: 221px;
    height: 100%;
  }

  @include c('patient-actions-container') {
    display: flex;
    justify-content: center;
    margin: 10px 0 -15px;
  }

  @include c('group-actions-container') {
    display: flex;
    justify-content: center;
    margin: 10px 0 -15px;
  }

  @include c('overlay') {
    display: none;
    position: absolute;
    background-color: rgba(#000, 0.3);
    z-index: 1;
    height: 64px;
    width: 64px;
    border-radius: 50%;
  }

  @include c('edit-avatar-text') {
    display: none;
    position: absolute;
    z-index: 3;
    margin: 42px 0 0 14px;
    background-color: var(--neutral-100);
    color: var(--secondary-500);
    padding: 2px 9px;
    font-size: 11px;
    border-radius: 12px;
  }

  @include c('edit-avatar-icon-container') {
    display: none;
    z-index: 3;
    margin: 5px 0 0 18px;
    position: absolute;
    background-color: var(--neutral-100);
    padding: 6px 7px 5px;
    border-radius: 50%;

    @include c('edit-avatar-icon') {
      fill: var(--secondary-500);
      width: 15px;
      height: 15px;
      padding-top: 3px;
    }
  }

  @include c('avatar-container-patient-care') {
    display: inline-block;
    height: 64px;
    width: 64px;
  }

  @include c('avatar-mute-container') {
    width: 100%;
    height: 100%;
    position: relative;
  }

  @include c('name-container') {
    margin: 3px 0;
    padding: 4px 15px;
    border-radius: 29px;
    font-weight: 500;

    &:hover {
      background-color: var(--cool-neutral-300);
    }
  }

  @include c('name-container-team') {
    margin: 3px 0;
    padding: 4px 15px;
    border-radius: 29px;
    font-weight: 500;
    font-size: 18px;
  }

  @include c('name-input') {
    @include ellipsis;

    background-color: transparent;
    font-size: 18px;
    text-align: center;
    padding-bottom: 3px;
    color: var(--neutral-900);

    &:focus {
      border-bottom: dotted 1px var(--neutral-700);
      outline: none;
    }
  }

  @include c('group-members') {
    font-size: 12px;
  }

  @include c('patient-name') {
    border-radius: 29px;
    font-weight: 500;
    font-size: 18px;
    padding: 4px 15px 0;
    text-align: center;
  }

  @include c('contact-name') {
    @include ellipsis;

    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--neutral-900);
    margin-top: 7px;
  }

  @include c('relation-name') {
    @include ellipsis;

    display: inline;
    padding: 0 4px;
    font-size: 18px;
    font-weight: normal;
  }

  /* Dark Mode TODO: */
  @include c('pill') {
    border-radius: 8px;
    border: solid 1px #cdd9de;
    padding: 0 4px;
    margin-left: 4px;
    background-color: #f0fbff;
    font-size: 11px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #536676;
  }

  @include c('patient-details') {
    color: var(--neutral-900);
    font-size: 11px;
    font-weight: 200;
  }

  @include c('submit-container') {
    margin-left: 10px;
    cursor: pointer;
  }

  @include b('MenuItemList') {
    padding: 0;
    border-radius: 12px;

    @include c('list-item') {
      &:hover {
        border-radius: 12px;
        background-color: var(--neutral-100);
      }
    }

    @include c('menu-item') {
      height: 18px;
      color: var(--secondary-500);
    }
  }

  @include c('tag-details') {
    margin-bottom: 8px;
    text-align: center;

    @include if-ie {
      display: table;
    }

    @include c('tag-indicator') {
      display: inline-block;
      flex-shrink: 0;
      width: 6px;
      height: 6px;
      border-radius: 3px;
      margin: 7px 10px 0 0;
    }

    @include c('tag-name') {
      display: inline;
      color: var(--neutral-700);
      font-size: 14px;
      max-width: 300px;
    }
  }
}
