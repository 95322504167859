@import '../../base';

@include b('AudioAttachment') {
  @include m('type', 'audio') {
    @include center-items-both;

    position: relative;

    @include c('loading') {
      @include center-items-both;

      background-color: #fff;
      line-height: 1;
      font-size: 12px;
      width: $message-body-width-medium;
      height: 80px;
      color: #333;
      border-radius: 8px;
      border: 1px solid #e4e9ed;

      @include c('downloading-indicator') {
        width: 20px;
        height: 20px;
      }
    }

    @include c('attachment-container') {
      position: relative;
      border: 1px solid #e4e9ed;
      padding: 13px;
      border-radius: 8px;
      background: #fff;

      @include c('attachment-metadata') {
        display: flex;
        align-items: center;
        padding-bottom: 8px;
        cursor: pointer;

        &--unselectable {
          cursor: default;
        }
      }

      @include c('audio') {
        max-width: $message-body-max-width;
        max-height: $message-image-max-height;
        user-select: none;
        outline: none;
        vertical-align: top;

        @include medium-messages {
          max-width: $message-body-width-medium;
        }

        &--isDownloading {
          display: none;
        }
      }
    }
  }
}
