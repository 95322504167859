@import '../../base';

@include b('SidebarOrganizationSelector') {
  display: flex;
  align-items: center;
  height: 46px;
  width: auto;
  margin: auto auto auto 0;
  border-radius: 100px;
  color: var(--neutral-1000);
  user-select: none;

  // path {
  //   fill: var(--neutral-1000);
  // }

  @include c('selector-container') {
    width: 350px;
    padding: 28px;
  }

  @include c('selected-org') {
    position: relative;
    float: left;
    width: 288px;
    border-radius: 20px;
    border: 1px solid var(--neutral-400);
    background-color: var(--neutral-150);
    display: flex;
    height: 39px;
    padding: 0 5px;
  }

  @include c('selected-hover') {
    border-radius: 20px;
    display: flex;
    padding: 3px 10px;
    flex: 1;
    cursor: pointer;
    align-self: center;
    text-align: left;

    &:hover {
      background-color: var(--neutral-300);
    }

    &--priority {
      background-color: var(--primary-300);
      color: var(--neutral-100);

      &:hover {
        background-color: var(--primary-700);
      }
    }
  }

  @include c('patient-button') {
    display: flex;
    height: 28px;
    border-radius: 14px;
    border: solid 1px var(--neutral-400);
    background-color: var(--patient-network-700);
    font-size: 14px;
    margin: 4px 0 0 3px;
    text-align: center;
    padding: 3px;
    cursor: pointer;
    align-items: center;

    circle {
      fill: var(--patient-network-700);
    }

    @include c('button-arrows') {
      path[stroke='#FFF'] {
        stroke: var(--neutral-100);
      }

      path[fill='#FFF'] {
        fill: var(--neutral-100);
      }
    }
  }

  @include c('patient-button-text') {
    text-align: center;
    padding-left: 7px;
    color: var(--neutral-100);
  }

  @include c('provider-button') {
    display: flex;
    height: 28px;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    border: solid 1px var(--neutral-400);
    background-color: var(--cool-neutral-300);
    font-size: 14px;
    margin: 4px 0 0 3px;
    padding: 3px;
    cursor: pointer;

    circle {
      fill: var(--cool-neutral-300);
    }

    @include c('button-arrows') {
      path[stroke='#35333C'] {
        stroke: var(--neutral-1000);
      }

      path[fill='#35333C'] {
        fill: var(--neutral-1000);
      }
    }
  }

  @include c('button-arrows') {
    margin-left: 8px;
  }

  @include c('unread-badge') {
    margin-left: 8px;
  }

  @include c('provider-button-text') {
    text-align: center;
    padding-left: 7px;
  }

  @include c('name') {
    @include ellipsis;

    padding-bottom: 2px;
    font-size: 16px;
    flex: 1;
  }

  @include c('image-container') {
    margin-top: -2px;
  }

  @include c('image') {
    fill: var(--neutral-900);
    width: 9px;
    height: 5px;
  }
}
