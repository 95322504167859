@import '../base';

@include b('CreateSuccessModal') {
  width: 277px;
  min-height: 285px;
  padding: 35px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.09);
  background-color: var(--neutral-100);
  display: flex;
  color: var(--neutral-1000);
  justify-content: center;

  @include c('createSuccessHeader') {
    margin: 0;
  }

  @include c('body') {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include c('check-mark') {
      border-radius: 50%;
      margin-bottom: 20px;
      width: 68px;
      height: 68px;
      background-color: var(--success-500);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @include c('message-container') {
      margin-bottom: 8px;
      font-size: 14px;
      text-align: center;
      width: 100%;
      font-weight: 600;
      color: var(--neutral-1000);
    }
  }
}
