@import '../../base';

@include b('PatientDirectory') {
  display: flex;
  background: var(--neutral-100);
  flex-direction: column;

  @include c('header-container') {
    flex: 0 0 auto;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    margin: 26px 22px 17px;
    display: flex;
    align-items: center;

    svg {
      path {
        fill: var(--neutral-1000);
      }
    }

    @include c('header-text') {
      color: var(--neutral-1000);
      margin-left: 10px;
    }
  }
}
