@import '../../base';

@include b('CollaborationModal') {
  font-size: 14px;
  font-weight: 100;
  line-height: 16px;
  margin: auto;
  overflow-y: visible;
  width: 438px;

  @include c('error-message-container') {
    height: 37px;
    margin-top: 8px;
    margin-bottom: 10px;
  }

  @include c('error-message') {
    background-color: var(--errors-300);
    border: solid 1px var(--errors-500);
    border-radius: 6px;
    color: var(--neutral-100);
    font-size: 12px;
    margin: 0 auto;
    padding: 7px;
    text-align: center;
    width: 353px;
  }

  @include c('input-group') {
    margin: 0 auto;
    margin-bottom: 35px;
    width: 353px;
  }

  @include c('label') {
    color: var(--neutral-700);
    display: block;
    font-size: 12px;
    margin-bottom: 7px;
    text-align: left;
  }

  @include c('input') {
    span {
      font-weight: normal !important;
    }

    border: solid 1px var(--neutral-300);
    border-radius: 6px;
    font-size: 16px;
    font-weight: 100;
    outline: none;
    padding: 7px;
    width: 100%;
    flex: 1 0 auto;

    .Select-menu-outer {
      width: 340px;
      max-height: 540px;
      margin: 0;
      text-align: left;
    }

    .Select-multi-value-wrapper {
      text-align: left;
      flex: 1 1 auto;
      min-height: 36px;
      max-height: 200px;
      overflow-y: auto;
      padding: 0;

      .Select-placeholder {
        font-weight: 400;
        text-align: left;
      }
    }
  }

  @include c('selector') {
    color: var(--neutral-950);
    cursor: pointer;
    position: relative;

    span {
      font-weight: normal;
    }
  }

  @include c('tag-color-button') {
    background-color: var(--neutral-150);
    border: solid 1px var(--neutral-300);
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 100;
    height: 32px;
    outline: none;
    padding: 5px;
    width: 100%;
  }

  @include c('selected-preview-container') {
    margin: 20px 0;
    text-align: left;
  }

  @include c('selected-preview-label') {
    color: var(--neutral-700);
    font-size: 12px;
    padding: 8px 10px;
  }

  @include c('selected-preview') {
    border: solid 1px var(--neutral-300);
    border-radius: 6px;
    height: 100px;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  @include c('selected-preview-item') {
    display: flex;
    flex-direction: row;
    padding: 4px 10px;
  }

  @include c('selected-preview-tag') {
    flex: 1 0 auto;
    font-size: 16px !important;
  }

  @include c('selected-preview-role-count') {
    align-self: center;
    color: var(--neutral-700);
    font-size: 12px;
  }

  @include c('selector-container') {
    margin: auto;
    padding-top: 30px;
    width: 353px;
  }

  @include c('body') {
    &--warning {
      color: var(--primary-300);
    }
  }

  @include c('clear-button') {
    background-color: var(--cool-neutral-100);
    border-radius: 100%;
    color: var(--cool-neutral-800);
    cursor: pointer;
    height: 22px;
    position: absolute;
    right: 20px;
    text-align: center;
    top: 9px;
    width: 22px;

    &:hover {
      path {
        fill: var(--neutral-100);
      }
    }
  }

  @include c('icon-secondary') {
    @include close-icon;

    background-color: var(--cool-neutral-200);

    circle {
      stroke: var(--cool-neutral-200);
    }

    &:hover {
      background-color: var(--cool-neutral-200);

      path {
        fill: var(--secondary-500);
      }
    }

    &--active {
      circle {
        stroke: var(--secondary-500);
        fill: var(--secondary-500);
      }

      background-color: var(--secondary-500);

      path {
        fill: var(--neutral-100);
      }

      &:hover {
        path {
          fill: var(--neutral-100);
        }
      }
    }
  }

  @include c('selector-display-item-component') {
    border: solid 1px var(--neutral-300);
    border-radius: 6px;
    height: 60px;
    padding: 0 10px;

    &:hover {
      background-color: var(--cool-neutral-200);
    }
  }

  @include c('selector-display-item') {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: flex-start;

    &--empty {
      color: var(--neutral-400);
    }

    .tc-CollaborationUserItem {
      flex: 1;
    }
  }

  @include c('pager-container') {
    border: solid 1px var(--neutral-400);
    border-radius: 6px;
    margin: 0 auto 25px;
    width: 350px;
  }

  @include c('table-header') {
    align-items: flex-start;
    background-color: var(--cool-neutral-200);
    border-radius: 6px 6px 0 0;
    display: flex;
    flex-direction: row;
    height: 22px;

    span {
      padding: 5px 0 0 10px;
      font-weight: normal;
    }
  }

  @include c('input-container') {
    align-items: center;
    background-color: var(--neutral-100);
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    height: 42px;
    padding: 5px 10px;

    /* border-bottom: solid 1px var(--neutral-400); */
  }

  @include c('number') {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: row;
  }

  @include c('end-number') {
    color: var(--neutral-400);
    padding-left: 3px;
  }

  @include c('selected-number-preview') {
    color: var(--secondary-500);
  }

  @include c('input-number') {
    background: var(--neutral-100);
    border: solid 1px var(--cool-neutral-700);
    border-radius: 3px;
    box-shadow: inset 0 2px 1px 1px rgba(0, 0, 0, 0.25);
    font-size: 12px;
    height: 24px;
    margin-left: 3px;
    text-align: center;
    width: 70px;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: var(--neutral-400);
    }

    &--error {
      background-color: var(--errors-50);
      border: solid 1px var(--errors-500);
      box-shadow: none;
    }
  }

  @include c('find-button') {
    cursor: pointer;
    font-size: 11px;
    font-weight: 500;
    margin-left: 8px;
    text-align: center;
    background-color: var(--secondary-500);
    border-radius: 20px;
    color: var(--neutral-100);
    height: 20px;
    line-height: 20px;
    text-transform: none;
    width: 45px;
  }

  @include c('numbers-container') {
    background-color: var(--neutral-150);
    border-radius: 0 0 6px 6px;
    height: 110px;
    overflow-y: scroll;
  }

  @include c('selected-preview-item-number') {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding: 4px 10px;

    &:hover {
      background-color: var(--cool-neutral-300);
    }

    &--altBGNumbers {
      background-color: var(--neutral-100);
    }

    &--selectedNumber {
      background-color: var(--cool-neutral-300);
    }
  }

  @include c('selected-preview-number') {
    align-items: center;
    display: flex;
    flex: 1 0 auto;
    font-size: 12px;
    justify-content: center;
  }

  @include c('inline-container') {
    width: 100%;
  }

  @include c('inline-modal') {
    // background-color: $defaultBG;
    background-color: var(--neutral-100);
    border-radius: 4px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    text-align: center;
  }

  @include c('inline-header') {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 6px;
  }

  @include c('body-text') {
    font-size: 14px;
    line-height: 1.4;
  }

  @include c('button') {
    cursor: pointer;
    font-weight: normal;
    line-height: 40px;
    text-align: center;
    background-color: var(--secondary-500);
    color: var(--neutral-100);

    // width: 100%;
    border-radius: 20px;
    display: inline-block;
    margin-top: 25px;
    padding: 0 36px;
    width: initial;
  }

  @include c('button-dismiss-container') {
    display: block;
    height: 28px;
    text-align: right;
  }

  @include c('dismiss-button') {
    cursor: pointer;
    display: inline-block;
    height: 28px;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 28px;
  }

  @include c('modal-role-body') {
    border: solid 1px var(--neutral-300);
    border-radius: 6px;
    margin: 0 auto;
    padding: 0 10px;
    width: 353px;
  }

  @include c('body-center') {
    padding: 0 36px;
    padding-bottom: 32px;
    padding-top: 40px;
  }

  @include c('org-table') {
    background: var(--neutral-150);
    border: solid 1px var(--neutral-300);
    border-radius: 6px;
    color: var(--neutral-950);
    margin-top: 10px;
  }

  @include c('org-list-item') {
    align-items: center;
    border-bottom: solid 1px var(--neutral-300);
    cursor: pointer;
    display: flex;
    line-height: 33px;

    &:last-child {
      border: none;
    }

    &:hover {
      background-color: var(--cool-neutral-300);
    }
  }

  @include c('org-table-header') {
    align-items: center;
    border-bottom: solid 1px var(--neutral-300);
    display: flex;
    line-height: 33px;
    background: var(--neutral-200);
    border-radius: 6px 6px 0 0;
    cursor: initial;
    font-weight: bold;
  }

  @include c('org-table-list') {
    height: 300px;
  }

  @include c('org-table-cell') {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @include c('org-name') {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-right: solid 1px var(--neutral-300);
    padding-left: 24px;
    width: 390px;
    text-align: left;
  }

  @include c('org-token') {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-right: solid 1px var(--neutral-300);
    text-align: center;
    width: 300px;
  }

  @include c('org-user-count') {
    border-right: solid 1px var(--neutral-300);
    text-align: center;
    width: 90px;
  }

  @include c('org-status') {
    border-right: solid 1px var(--neutral-300);
    text-align: center;
    width: 70px;
  }

  @include c('org-domain') {
    text-align: center;
    width: 200px;
  }

  @include c('org-modal') {
    width: 1200px !important;
  }

  @include c('org-modal-body') {
    padding: 0 36px;
    padding-bottom: 32px;
    padding-top: 40px;
  }

  @include c('header-normal') {
    @include common-modal-header;

    line-height: 1.5em;
  }

  @include c('close-button-normal') {
    @include common-modal-close-button;
  }
}
