@import '../../base';

@include b('MessageDetails') {
  margin-top: 8px;
  display: flex;
  min-width: 205px;
  flex: 1 0 auto;
  width: 100%;

  @include c('message-time') {
    font-size: 10px;
    position: relative;
    float: left;
    color: var(--neutral-700);
    white-space: nowrap;

    &--time-left {
      margin-left: 1em;
      margin-right: 1em;
    }

    &--isPatientMessageForward {
      font-weight: 500;
      color: var(--cool-neutral-900);
    }
  }

  @include c('message-status') {
    font-size: 10px;
    position: relative;
    margin-left: auto;
    color: var(--neutral-700);
    white-space: nowrap;

    @include b('MessageRecipientStatus') {
      @include m('group') {
        @include m('isClickable') {
          cursor: pointer;
        }
      }
    }
  }
}
