@import '../../base';

@include b('TagItem') {
  display: flex;

  @include c('image') {
    width: 21px;
    height: 21px;
    margin: 0 15px 0 30px;
  }
}
