@import '../base';

@include b('Banner') {
  display: flex;
  align-items: flex-start;

  @include c('banner') {
    padding: 2px 6px 1px;
    border-radius: 8.5px;
    box-shadow: 0 0.5px 1.5px -0.5px rgba(0, 0, 0, 0.44);
    font-size: 10px;
    font-weight: 500;
    color: $white;

    @include if-ie {
      padding-bottom: 2px;
    }
  }

  @include m('type', 'mentioned') {
    background-color: var(--secondary-300);
    letter-spacing: 0.5px;
    font-weight: 400;
    text-transform: uppercase;
  }

  @include m('type', 'priority') {
    background-color: $primary-brand;
    letter-spacing: 0.5px;
    font-weight: 400;
    text-transform: uppercase;
  }

  @include m('type', 'pinned') {
    background-color: green;
    letter-spacing: 0.5px;
    font-weight: 400;
    text-transform: uppercase;
  }

  @include m('type', 'forum') {
    background-color: #1a82d3;
    letter-spacing: 0.5px;
    font-weight: 400;
    padding: 1px 6px;
    font-size: 10px;
    line-height: 1.36;
    height: 13px;
    margin-bottom: 4px;
    text-transform: uppercase;
  }

  @include m('type', 'Low') {
    background-color: #29dbcb;
    font-weight: bold;
  }

  @include m('type', 'Medium') {
    background-color: #e8c200;
    font-weight: bold;
  }

  @include m('type', 'High') {
    background-color: #f00;
    font-weight: bold;
  }

  @include m('type', 'Alarm') {
    background-color: #f00;
    font-weight: bold;
  }

  @include m('type', 'Escalated') {
    background-color: #00918a;
    letter-spacing: 0.8px;
    font-weight: 400;
  }

  @include m('type', 'IN_PROGRESS') {
    background-color: #f6edde;
    color: #ca9647;
  }

  @include m('type', 'CANCELLED') {
    background-color: #ead4d3;
    color: #962b28;
  }

  @include m('type', 'ACKNOWLEDGED') {
    background-color: #d6e9e4;
    color: #00918a;
  }

  @include m('type', 'Connected') {
    background-color: var(--success-70);
    color: var(--cool-neutral-100);
  }

  @include m('type', 'Disconnected') {
    background-color: var(--secondary-900);
    color: var(--cool-neutral-100);
  }

  @include m('type', 'Pending') {
    background-color: var(--neutral-950);
    color: var(--cool-neutral-100);
  }

  @include m('type', 'Fail') {
    background-color: var(--primary-300);
    color: var(--cool-neutral-100);
  }

  @include m('type', 'NO_RESPONSE') {
    background-color: #e2e2e2;
    color: #565559;
  }
}
