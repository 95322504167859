@import '../../styles/base';

@include b('UserInfoHeader') {
  height: 70px;

  @include c('user-details-wrapper') {
    display: flex;
    align-items: center;
    position: absolute;
    height: 100%;
    width: auto;
    right: 0;
    user-select: none;
  }

  /* Dark Mode TODO */
  @include c('open-org-selector') {
    background-color: #4a90e2;
    width: 54px;
    line-height: 20px;
    border-radius: 10px;
    font-size: 10px;
    margin-right: 25px;
    color: var(--neutral-100);
    text-align: center;
    cursor: pointer;
    letter-spacing: 0.5px;

    &:hover {
      background-color: var(--secondary-500);
    }
  }

  @include c('user-details') {
    @include center-items-both;

    height: 46px;
    width: auto;
    padding-left: 20px;
    margin-right: 5px;
    cursor: pointer;

    &:hover {
      border-radius: 100px;
      background-color: var(--neutral-200);
    }
  }

  @include c('avatar') {
    float: right;
    margin: 0 10px;
  }

  @include c('icon-container') {
    margin-right: 5px;
    margin-left: 10px;
    padding-left: 8.4px;
    padding-top: 8.4px;
    width: 36px;
    height: 36px;
    background-color: var(--patient-network-700);
    border-radius: 100%;
    display: flex;

    svg {
      width: 20px;
      height: 20px;

      path {
        fill: var(--neutral-100);
      }
    }
  }

  @include c('user-settings-container') {
    height: 46px;
    width: auto;
    padding-left: 20px;
    margin-right: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
      border-radius: 100px;
      background-color: var(--neutral-200);
    }

    &:focus-visible {
      border-radius: 25px;
    }
  }

  @include c('user-name') {
    font-size: 1.1em;
    font-weight: 500;
    color: var(--neutral-900);
    line-height: 1.5em;
  }

  @include c('user-status') {
    font-size: 0.8em;
    font-weight: 400;
    color: var(--neutral-800);
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 220px;
    white-space: nowrap;
  }
}
