@import '../../base';

@include b('AddContactsFromList') {
  padding: 0 10px 10px 43px;
  cursor: pointer;

  @include m('disabled') {
    cursor: auto;
    opacity: 0.5;

    &:hover {
      background-color: var(--neutral-100);
    }
  }

  &:focus-visible {
    outline: 3px solid var(--secondary-500);
    outline-offset: -3px;
  }

  @include c('wrapper') {
    display: flex;
    align-items: center;
    border-top: 1px solid var(--neutral-400);
    padding-top: 10px;

    @include c('contact-info') {
      @include c('contact-name') {
        color: var(--neutral-1000);
        font-size: 14px;
        font-weight: 600;
        height: 19px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 244px;
      }

      @include c('contact-details') {
        color: var(--neutral-700);
        display: flex;
        font-size: 10px;
        height: 14px;
        height: auto;
        width: 189px;
        width: auto;

        @include c('contact-mrn') {
          margin-right: 5px;
        }

        @include c('contact-dob') {
          margin-right: 5px;
        }

        @include c('contact-gender') {
          color: var(--neutral-500);
          font-size: 10px;
          height: 14px;
          width: 189px;
        }
      }
    }

    @include c('check-mark') {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 18px;
      width: 18px;
      margin-left: 25px;
      border-radius: 50%;
      position: absolute;
      right: 10px;

      &--isSelected {
        background-color: var(--success-500);
      }
    }
  }

  &:hover {
    background-color: var(--cool-neutral-300);
  }
}
