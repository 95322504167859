@import '../../base';

@include b('RoleItem') {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  font-size: 14px;
  text-align: left;

  @include c('role-tag-details') {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  @include c('user-details') {
    align-items: flex-start;
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    margin: 0 10px;

    &--isSelected {
      flex-direction: column;
    }
  }

  @include c('user-name') {
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @include c('tag-indicator') {
    flex-shrink: 0;
    width: 4px;
    height: 4px;
    margin: 3px 5px;
    border-radius: 6px;
  }

  @include c('tag-name') {
    @include ellipsis;

    color: var(--neutral-700);
    font-size: 12px;
    flex: 1 1 auto;

    @include c('highlighted') {
      background-color: transparent;
      font-weight: normal;
      color: var(--neutral-1000);
    }
  }
}
