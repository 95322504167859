@import '../../base';

@include b('PatientInfo') {
  display: inline-block;
  font-size: 11px;
  font-weight: normal;
  color: var(--patient-network-700);

  @include m('card-style') {
    border-radius: 6px;
    background-color: var(--patient-network-200);
    border: solid 1px var(--patient-network-400);
    padding: 4px 8px 2px;
    width: 100%;
  }

  @include m('is-hovered') {
    background-color: transparent;
    color: var(--neutral-100);
    border: solid 1px transparent;
  }

  @include c('patient-details') {
    &--isCenterName {
      text-align: center;
    }
  }

  @include c('patient-details-one-line') {
    line-height: 13px;
  }

  @include c('patient-name') {
    font-size: 13px;
    font-weight: bold;
    line-height: 13px;
    padding-bottom: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 250px;
    display: flex;

    &--isCenterName {
      justify-content: center;
    }
  }
}
