@import '../base';

@include b('PatientSettingsSearchBar') {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 960px;
  margin: auto;
  min-width: 630px;

  @include c('search-container') {
    display: flex;
    width: 275px;
    height: 30px;
    border-radius: 20px;
    border: solid 1px var(--neutral-400);
    background-color: var(--neutral-100);

    @include c('input') {
      margin: 4px 2px 3px 18px;
      outline: none;
      width: 250px;

      &::-ms-clear {
        display: none;
      }

      &::placeholder {
        color: var(--neutral-600);
      }
    }

    @include c('search-icon-container') {
      cursor: pointer;
      user-select: none;
      margin: 3px 3px 0 0;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      @include c('button-icon-cancel') {
        width: 9px;
        height: 9px;

        rect {
          fill: var(--patient-network-700);
        }
      }

      &:hover {
        background-color: var(--neutral-500);

        @include c('button-icon-cancel') {
          rect {
            fill: var(--neutral-100);
          }
        }
      }

      &:focus-visible {
        outline: 3px solid var(--secondary-500);
      }
    }
  }
}
