@import '../base';

@include b('PriorityMessageModal') {
  @include c('header') {
    background-color: var(--primary-300);
    text-align: center;
    color: var(--neutral-100);
    border-radius: 5px 5px 0 0;
    height: 118px;
    font-size: 18px;
    width: 100%;
  }

  @include c('img') {
    display: block;
    margin: 20px auto;
    border-radius: 0;
    box-shadow: none;
    height: 32px;
    width: 32px;
  }

  @include c('priority-message-body') {
    padding: 20px 30px 15px;
    height: 120px;
    background-color: var(--neutral-150);
    border-bottom: solid 1px $dimmed-line-color;
    line-height: 1.6em;
    vertical-align: middle;
    color: var(--neutral-1000);
  }

  @include c('priority-message-close-btn') {
    text-align: center;
    background-color: var(--neutral-150);
    color: var(--primary-700);
    padding: 20px 0;
    cursor: pointer;
    border-radius: 0 0 5px 5px;

    &:hover {
      background-color: var(--neutral-300);
    }
  }
}
