@import '../../base';

@include b('DeliveryMethodScheduledMessageMenu') {
  background-color: var(--neutral-100);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.14);
  padding: 3px;
  width: 446px;

  > button {
    border-radius: 6px;
    padding: 4px 7px;

    &:hover {
      background-color: var(--neutral-200);
    }
  }
}

@include b('DeliveryMethodScheduledMessageMenuItem') {
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 400;

  @include m('clickable') {
    cursor: pointer;

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
    }
  }

  svg {
    margin-right: 9px;

    [fill='#4A657B'] {
      fill: var(--patient-network-700);
    }
  }

  @include c('arrows') {
    color: var(--neutral-700);
    font-weight: 400;
    margin: 0 10px 0 8px;
  }
}
