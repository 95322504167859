@import '../../base';

@include b('PatientsList') {
  height: 100%;
  width: 100%;

  @include c('patient-admin-wrapper') {
    height: 100%;
    margin: 20px 0 0;
    width: 100%;
  }

  @include c('patient-details-wrapper') {
    height: 100%;
    overflow: auto;
    width: 100%;
    padding: 0 30px;

    @include c('patient-details-holder') {
      max-width: 950px;
      margin: 0 auto;
    }

    @include c('appointments-info') {
      color: var(--neutral-900);
      font-size: 22px;
      font-weight: 500;
      height: 30px;
      margin-top: 20px;
      width: 151px;
    }
  }

  @include c('appointment-month-container') {
    background-color: var(--neutral-150);
    color: var(--neutral-1000);
    font-size: 14px;
    font-weight: 500;
    height: 35px;
    margin: 0 3px;
    padding: 8px 0 8px 22px;
    text-align: left;
  }

  @include c('patient-details') {
    display: flex;
    margin-bottom: 40px;
    margin-top: 25px;
  }

  @include c('patient-detailed-information') {
    margin-left: 3px;

    &--isDob {
      flex-grow: 2;
    }

    &--isGender {
      flex-grow: 1;
    }
  }

  @include c('patient-name') {
    color: var(--neutral-1000);
    font-size: 15px;
    font-weight: 600;
    margin-left: 8px;
    margin-top: -4px;
  }

  @include c('patient-name-info') {
    font-weight: 600;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
    width: 400px;
  }

  @include c('patient-display-name-container') {
    font-weight: 600;
    height: 20px;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @include c('patient-display-name') {
    max-width: 100px;
    margin-right: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: auto;
  }

  @include c('appointments-available-container') {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  @include c('appointments-table') {
    margin-top: -40px;
  }

  @include c('upcoming-past-appointments') {
    display: flex;
  }

  @include c('appointment-list') {
    margin-bottom: 50px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  @include c('empty-message') {
    color: var(--neutral-800);
    font-size: 15px;
    font-style: italic;
    height: 20px;
    margin: 10% auto 0;
    text-align: center;
  }

  @include c('appt-section') {
    color: var(--neutral-900);
    font-size: 15px;
    font-weight: 500;
    height: 20px;
    margin: 10px 10px 0 0;
    text-align: left;
    padding-bottom: 24px;

    &--active {
      border-bottom: solid 1px var(--patient-network-700);
    }

    &:hover {
      cursor: pointer;
      border-bottom: solid 1px var(--patient-network-700);
    }
  }

  @include c('delete-appointment-button') {
    align-items: center;
    background-color: var(--primary-700);
    border-radius: 3px;
    color: var(--neutral-100);
    display: flex;
    font-size: 13px;
    font-weight: 600;
    height: 28px;
    justify-content: center;
    margin-right: 10px;
    width: 78px;

    &:hover {
      @include c('delete-icon') {
        background-color: var(--errors-50);

        path {
          stroke: var(--primary-700);
        }
      }

      cursor: pointer;
      color: var(--primary-700);
      background-color: var(--errors-50);
    }
  }

  @include c('create-appointment-button') {
    align-items: center;
    background-color: var(--patient-network-700);
    border-radius: 4px;
    color: var(--neutral-100);
    display: flex;
    font-size: 13px;
    font-weight: 600;
    height: 28px;
    justify-content: center;
    width: 148px;
    cursor: pointer;
    border: 1px solid var(--patient-network-700);

    &:hover {
      color: var(--patient-network-700);
      background-color: var(--neutral-100);
    }
  }

  @include c('delete-icon') {
    background-color: var(--primary-300);
    margin-bottom: 2px;
    margin-right: 5px;

    path {
      stroke: var(--neutral-100);
    }
  }

  @include c('patient-information') {
    align-items: center;
    background-color: var(--cool-neutral-100);
    border-radius: 6px;
    border: solid 1px var(--cool-neutral-500);
    display: flex;
    font-size: 11px;
    font-weight: 400;
    height: 22px;
    padding: 5px;

    @include c('patient-mrn') {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex-grow: 3;
    }
  }

  @include c('patient-admin-header') {
    display: flex;
    margin: 0 31px;
  }

  @include c('patient-list-title') {
    cursor: pointer;
    height: 20px;
    width: 80px;
    padding-bottom: 5px;

    &:hover {
      background-color: var(--cool-neutral-300);
    }
  }

  @include c('patient-details-header') {
    display: flex;
  }

  @include c('patient-admin-navigator') {
    display: flex;
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
    height: 27px;
    font-size: 12px;
    color: var(--neutral-900);
    border-bottom: solid 1px var(--neutral-400);

    @include c('patient-admin-navigator-data') {
      display: flex;
      margin-bottom: 15px;
      margin-left: 15px;
    }

    @include c('patient-admin-navigator-breadcrumb') {
      width: 6px;
      height: 15px;
      color: var(--neutral-900);
      margin-left: 5px;
      margin-right: 8px;
    }
  }

  @include c('patient-admin-title') {
    color: var(--neutral-900);
    cursor: default;
    display: flex;
    flex: 1;
    align-items: center;
    font-size: 12px;
  }

  @include c('patient-admin-data') {
    height: 31px;
    font-size: 23px;
    color: var(--neutral-900);
  }

  @include c('patient-admin-icons') {
    display: flex;
  }

  @include c('bg-icon') {
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 31px;
    justify-content: center;
    margin: 0 4px;
    width: 31px;

    @include c('button-icon-path') {
      fill: var(--patient-network-700);
      width: 17px;
      height: 17px;

      g {
        stroke: var(--patient-network-700);
      }
    }

    @include c('button-icon-fill') {
      fill: var(--patient-network-700);
      width: 17px;
      height: 17px;
    }

    @include c('button-icon-refresh') {
      fill: var(--patient-network-700);
      height: 17px;
      width: 17px;
    }

    @include c('button-icon-cancel') {
      width: 13px;
      height: 13px;

      rect {
        fill: var(--patient-network-700);
      }
    }

    &--open {
      background-color: var(--patient-network-700);

      @include c('button-icon-path') {
        path {
          stroke: var(--neutral-100);
        }
      }
    }

    &:hover {
      background-color: var(--patient-network-700);

      @include c('button-icon-fill') {
        fill: var(--neutral-100);
      }

      @include c('button-icon-refresh') {
        path {
          fill: var(--neutral-100);
          stroke: var(--neutral-100);
        }
      }

      @include c('button-icon-cancel') {
        rect {
          fill: var(--neutral-100);
        }
      }

      @include c('button-icon-path') {
        path {
          stroke: var(--neutral-100);
        }
      }
    }
  }

  @include c('tooltip') {
    background-color: var(--patient-network-700) !important;
  }

  @include c('patient-count-container') {
    display: flex;
    margin: 0 31px;
  }

  @include c('patient-count-info') {
    margin-left: 10px;
  }

  @include c('patient-count-header') {
    font-size: 16px;
    font-weight: 500;
  }

  @include c('patient-count') {
    font-size: 12px;
  }

  @include c('patient-count-info') {
    margin-left: 10px;
  }

  @include c('avatar-container') {
    background-color: var(--patient-network-700);
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @include c('patient-icon') {
    width: 40px;
    height: 40px;

    g {
      fill: var(--neutral-100);
    }
  }

  @include c('patient-avatar') {
    background: var(--patient-network-700);
    border-radius: 50%;
    width: 35px;
    height: 35px;

    g {
      fill: var(--neutral-100);
    }
  }

  @include b('PatientSearchBar') {
    @include center-items-both;

    background-color: var(--neutral-150);
  }

  @include c('refresh-message') {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-left: 142px;
    margin-top: 7px;
    position: absolute;

    @include if-ie {
      margin-left: -142px;
    }
  }

  @include c('arrow') {
    border-radius: 50%;
    background-color: var(--patient-network-700);
    height: 8px;
    margin-top: 23px;
    position: absolute;
    width: 8px;

    @include if-ie {
      margin-left: 118px;
    }
  }

  @include c('refresh-content') {
    align-items: center;
    background-color: var(--patient-network-700);
    border-radius: 3px;
    color: var(--neutral-100);
    display: flex;
    font-size: 12px;
    height: 33px;
    justify-content: center;
    margin-top: 27px;
    padding: 0 15px;
    position: absolute;
    width: 288px;
    z-index: 100;

    @include c('content') {
      flex: 1;
    }

    @include c('close-button') {
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
    }
  }

  @include c('upload-menu-outer-container') {
    position: relative;
  }

  @include c('upload-menu-inner-container') {
    position: absolute;
    width: 139px;
    font-size: 12px;
    background-color: var(--neutral-100);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    top: 34px;
    right: -50px;
    padding: 7px 0;

    @include c('upload-menu-option') {
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--patient-network-700);
      width: 100%;

      &:hover {
        background-color: var(--cool-neutral-300);
      }
    }
  }
}
