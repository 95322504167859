@import '../../base';

@include b('ProcessingIndicator') {
  position: fixed;
  bottom: 15px;
  right: 15px;
  color: white;
  padding: 10px 20px;
  background: $patient-color;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  display: flex;
  align-items: center;
  height: 38px;

  @include c('text') {
    margin-right: 10px;
    font-size: 12px;
  }
}
