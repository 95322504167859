@import '../../../base';

@include b('ContactDeleteModal') {
  @include c('body') {
    min-width: 530px;
  }

  @include c('message-container') {
    @include center-items-both;

    padding: 30px 0 55px;
  }

  @include c('deleteHeader') {
    @include common-modal-header;

    &:first-child {
      color: #db524b;
      font-weight: 500;
      font-size: 16px;
    }
  }

  @include c('message') {
    font-size: 16px;
    max-width: 376px;
    margin-left: 55px;
    margin-right: 55px;
    text-align: center;
    word-break: break-word;

    @include c('patient-name') {
      font-weight: 600;
    }
  }

  @include c('cancel-btn') {
    @include common-button(#9eb2bd, 74px, 32px, 11px);

    &--progress {
      &:hover {
        background-color: white;
        color: #9eb2bd;
        border-color: #9eb2bd;
        cursor: default;
      }
    }

    margin-right: 8px;
  }

  @include c('closeButton') {
    @include common-modal-close-button;

    color: #db534c;
  }

  @include c('ok-btn') {
    @include common-button($primary-brand, 74px, 32px, 11px);

    &--progress {
      &:hover {
        background-color: white;
        border-color: $primary-brand;
        color: $primary-brand;
        cursor: default;
      }
    }
  }

  @include c('forwardFooter') {
    background-color: #fafafa;
    border: 1px solid #fafafa;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    display: flex;
    font-weight: 500;
    justify-content: flex-end;
    position: relative;
    padding: 30px 40px;
    background-clip: padding-box;
  }

  @include c('dots-container') {
    @include center-items-both;

    height: 75%;
  }
}
