@import '../../base';

@include b('ScheduleMessageHeader') {
  background-color: #cbeef9;
  padding: 5px;
  color: $patient-color;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  @include c('scheduled-text') {
    padding: 0 10px 0 6px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 2.5px;
    text-transform: uppercase;
  }

  @include m('direction', 'INCOMING') {
    border-radius: 0 10px 0 0;
  }

  @include m('direction', 'OUTGOING') {
    border-radius: 10px 0 0;
  }
}
