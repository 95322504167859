@import '../../base';

@include b('PatientBroadcastFormHeader') {
  flex-shrink: 0;
  color: var(--neutral-950);
  font-size: 20px;
  margin-bottom: 10px;

  @include c('list-error') {
    color: var(--errors-500);
    font-size: 12px;
    margin: -24px 0 8px;
  }

  @include c('header-wrapper') {
    display: flex;
    flex-direction: column;

    @include c('sync-status') {
      color: var(--secondary-700);
      font-size: 12px;
      height: 16px;
      margin: -20px 3px 16px 6px;
      text-align: left;
      width: 350px;

      &--syncFailed {
        color: var(--errors-500);
      }
    }

    @include c('sync-status-icon') {
      margin-right: 10px;
    }

    @include c('left-wrapper') {
      display: flex;
      flex-direction: column;

      @include c('header-content-list-information') {
        display: flex;
        flex-direction: column;

        @include c('header') {
          display: flex;
        }

        @include c('header-content') {
          display: flex;
        }

        @include c('group-main-details') {
          display: flex;
          flex-direction: column;
          margin-left: 15px;
          justify-content: center;

          @include c('broadcast-list-name') {
            color: var(--neutral-950);
            display: flex;
            font-size: 16px;
            font-weight: 600;
            height: 27px;
            width: auto;
          }

          @include c('inline-editor') {
            color: var(--neutral-950);
            cursor: pointer;
            display: flex;
            font-size: 16px;
            font-weight: 600;
            height: 25px;
            width: auto;

            @include c('submit-input') {
              color: var(--cool-neutral-800);
              font-weight: normal;
              font-size: 10px;
              margin-left: 5px;
              flex: 1 0 auto;
            }

            @include c('edit-icon') {
              margin-left: 5px;
              display: none;
            }

            &:hover {
              border-radius: 18px;

              @include c('edit-icon') {
                display: inline-block;
              }
            }

            &:focus-visible {
              @include c('edit-icon') {
                display: inline-block;
                outline: 3px solid var(--secondary-500);
                border-radius: 18px;
              }
            }

            &--editing {
              cursor: initial;
            }

            @include c('input-container') {
              background-color: var(--neutral-100);
              border: solid 1px var(--cool-neutral-700);
              border-radius: 2px;
              box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.25);
              color: var(--neutral-600);
              display: flex;
              flex-direction: row;
              font-size: 14px;
              height: 25px;
              padding: 4px 7px;
              width: auto;

              input {
                border: none;
                flex: 1 1 auto;
                background: none;
                line-height: 1px;

                &:focus {
                  outline: none;
                }
              }

              input::-ms-clear {
                display: none;
              }
            }
          }

          @include c('group-people-count') {
            color: var(--neutral-950);
            font-size: 13px;
            font-weight: 500;
            height: 18px;
            margin-top: 1px;
            width: auto;
          }
        }
      }

      @include c('permission-details') {
        color: var(--neutral-950);
        display: flex;
        font-size: 12px;
        height: 35px;
        align-items: center;

        &--isADSync {
          cursor: auto;
        }

        @include c('submit-input') {
          color: var(--cool-neutral-800);
          font-weight: normal;
          font-size: 10px;
          margin-left: 5px;
          flex: 1 0 auto;

          &--isADSync {
            display: none;
            margin-left: 15px;
          }
        }

        @include c('edit-icon') {
          margin-left: 5px;
          display: none;
        }

        @include c('admin-only') {
          margin-right: 10px;
        }

        @include c('admin-only-button') {
          margin-top: 3px;

          &:focus-within {
            outline: 3px solid var(--secondary-500);
            border-radius: 14px;
          }
        }

        @include c('share-button') {
          text-transform: uppercase;
          font-weight: bold;
          font-size: 10px;
          cursor: pointer;
          margin-left: 10px;

          @include c('share-button-arrow') {
            display: inline-block;
            width: 0;
            height: 0;
            border: 3px solid transparent;
            border-top: 3px solid;
            border-right: 3px solid;
            color: var(--neutral-700);
            transform: rotate(135deg);
            margin-bottom: 3px;
            margin-left: 2px;
          }
        }
      }

      @include c('share-dropdown-container') {
        position: absolute;
        top: 182px;
        left: 30px;
        z-index: 2;
      }

      @include c('group-creator') {
        color: var(--neutral-700);
        font-size: 12px;
        height: 16px;
        margin-top: 10px;
        width: auto;
      }
    }

    @include c('right-wrapper') {
      display: flex;
    }
  }
}
