@import '../../base';

@include b('MessageBubble') {
  @include c('content-status') {
    @include m('direction', 'OUTGOING') {
      border-radius: 12px 0 12px 12px;
      background-color: var(--cool-neutral-300);
      border: solid 1px var(--cool-neutral-300);

      &,
      a {
        color: var(--neutral-1000);
      }

      @include m('isEscalated') {
        background-color: var(--neutral-100);
        box-sizing: border-box;
        border: solid 1px var(--escalation-200);
      }

      @include m('isRoleAutomatedMessage') {
        background-color: var(--neutral-100);
        border: solid 1px var(--cool-neutral-900);
      }

      @include m('isForwardedFromPatientNetwork') {
        background-color: var(--neutral-100);
        border: solid 1px var(--cool-neutral-900);
      }

      @include m('isPatientOrContactMessage') {
        background-color: var(--patient-network-500);
        border: solid 1px var(--patient-network-500);
      }

      @include m('isAutoForwardMessage') {
        background-color: var(--neutral-100);
        border: solid 1px var(--message-status-sent);
      }

      @include m((('senderStatus', 'FAILED'), ('senderStatus', 'RETRYING'))) {
        border-radius: 12px;

        /* background-color: #ffebea; */
        background-color: var(--errors-50);
        border: solid 1px var(--errors-500);
      }

      @include m('isDropdownActive') {
        background-color: var(--cool-neutral-400);
        border: solid 1px var(--cool-neutral-400);

        @include m('priority', 'HIGH') {
          background-color: var(--neutral-100);
          border: solid 1px var(--primary-300);
        }

        @include m('isEscalated') {
          background-color: var(--neutral-100);
          border: solid 1px var(--escalation-200);
        }

        @include m('isRoleAutomatedMessage') {
          background-color: var(--neutral-100);
          border: solid 1px var(--cool-neutral-900);
        }

        @include m('isForwardedFromPatientNetwork') {
          background-color: var(--neutral-100);
          border: solid 1px var(--patient-network-700);
        }

        @include m('isPatientOrContactMessage') {
          background-color: var(--patient-network-500);
          border: solid 1px var(--patient-network-500);
        }

        @include m((('senderStatus', 'FAILED'), ('senderStatus', 'RETRYING'))) {
          /* background-color: #f7dad8;
           border: solid 1px #db524b; */
          background-color: var(--errors-100);
          border: solid 1px var(--errors-500);
        }
      }

      @include m('priority', 'HIGH') {
        background-color: var(--neutral-100);
        border: solid 1px var(--primary-300);

        @include m('senderStatus', 'FAILED') {
          border-radius: 12px;

          /* background-color: #ffebea;
           border: solid 1px #db524b; */
          background-color: var(--errors-50);
          border: solid 1px var(--errors-500);
        }

        @include m('senderStatus', 'RETRYING') {
          border-radius: 12px;

          /* background-color: #ffebea;
           border: solid 1px #db524b; */
          background-color: var(--errors-50);
          border: solid 1px var(--errors-500);
        }
      }
    }

    @include m('direction', 'INCOMING') {
      border-radius: 0 12px 12px;
      background-color: var(--neutral-200);
      border: solid 1px var(--neutral-200);

      a {
        color: var(--neutral-1000);
      }

      @include m('isEscalated') {
        background-color: var(--neutral-100);
        border: solid 1px var(--escalation-200);
      }

      @include m('isAutoForwardMessage') {
        background-color: var(--neutral-100);
        border: solid 1px var(--cool-neutral-800);
      }

      @include m('isRoleAutomatedMessage') {
        background-color: var(--neutral-100);
        border: solid 1px var(--cool-neutral-900);
      }

      @include m('isForwardedFromPatientNetwork') {
        background-color: var(--neutral-100);
        border: solid 1px var(--cool-neutral-900);
      }

      @include m('isPatientOrContactMessage') {
        background-color: var(--patient-network-500);
        border: solid 1px var(--patient-network-500);
      }

      @include m('isSmsOptMessage') {
        background-color: var(--neutral-100);
        border: solid 1px var(--opt-out-100);
      }

      @include m('isDropdownActive') {
        background-color: var(--neutral-300);
        border: solid 1px var(--neutral-300);

        @include m('priority', 'HIGH') {
          background-color: var(--neutral-100);
          border: solid 1px var(--primary-300);
        }

        @include m('isEscalated') {
          background-color: var(--neutral-100);
          border: solid 1px var(--escalation-200);
        }

        @include m('isRoleAutomatedMessage') {
          background-color: var(--neutral-100);
          border: solid 1px var(--cool-neutral-900);
        }

        @include m('isForwardedFromPatientNetwork') {
          background-color: var(--neutral-100);
          border: solid 1px var(--patient-network-700);
        }

        @include m('isPatientOrContactMessage') {
          background-color: var(--patient-network-400);
          border: solid 1px var(--patient-network-400);
        }
      }

      @include m('priority', 'HIGH') {
        background-color: var(--neutral-100);
        border: solid 1px var(--primary-300);
      }
    }

    &:focus-visible {
      outline: 3px solid var(--secondary-500) !important;
    }
  }
}
