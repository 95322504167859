@import '../base';

@include b('RoundButton') {
  @include c('button') {
    width: 45px;
    height: 45px;
    border-radius: 23px;
    background-color: var(--patient-network-300);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    transition: box-shadow 0.25s, background-color 0.25s;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &:hover {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    }

    &--isInverseHover {
      &:hover {
        background-color: var(--patient-network-700);

        svg {
          fill: var(--neutral-100);
        }
      }
    }

    svg {
      fill: var(--patient-network-700);
    }

    &--isWhite {
      background-color: var(--neutral-100);
    }

    &--isSmall {
      width: 32px;
      height: 32px;
      border-radius: 16px;
    }

    &:disabled {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
      cursor: default;
    }
  }

  @include c('overlay') {
    background-color: rgba(255, 255, 255, 0);
    border-radius: 23px;
    transition: background-color 0.5s;

    &--isSmall {
      border-radius: 16px;
    }

    &--disabled {
      background-color: rgba(255, 255, 255, 0.4);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}
