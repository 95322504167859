@import '../../base';

@include b('CalleeCapabilitiesModal') {
  @include c('header') {
    @include common-modal-header;

    color: var(--primary-300);
  }

  @include c('close-button') {
    @include common-modal-close-button;

    color: var(--primary-300);
  }

  @include c('body') {
    @include common-modal-body;

    padding: 30px 30px 50px;
  }

  @include c('body-container') {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include c('description-container') {
      display: flex;
      color: var(--neutral-700);
      line-height: 26px;
    }
  }

  @include c('ok-btn') {
    @include common-button(var(--primary-300), 74px, 32px, 11px);

    margin-right: 15px;
  }
}
