@import '../../base';

@include b('CollapsingSearchBar') {
  border-radius: 20px;
  font-size: 16px;
  line-height: 23px;
  transition: width 1s cubic-bezier(0.22, 1, 0.36, 1);

  @include c('close-button-container') {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3px;
    width: 20px;
    border-radius: 50%;
    background-color: var(--cool-neutral-200);
    height: 20px;

    @include c('close-icon-white') {
      fill: var(--cool-neutral-700);
    }

    &:hover {
      background-color: var(--patient-network-700);

      @include c('close-icon-white') {
        fill: var(--neutral-100);
      }
    }
  }

  @include m('theme', 'general') {
    @include c('wrapper') {
      display: flex;
      align-items: center;
      border: 2px solid transparent;
      height: 28px;

      @include c('search-icon-container') {
        align-items: center;
        display: flex;
        height: 28px;
        justify-content: center;
        margin-left: -2px;
        width: 28px;
      }

      @include c('input-container') {
        flex: 1;
        margin: 0 8px;
        font-size: 13px;
        border: none;
        padding: 0;

        &::-ms-clear {
          display: none;
        }
      }

      &--currentAppSelected--BroadcastLists {
        @include c('search-icon-container') {
          @include c('search-icon') {
            fill: var(--secondary-700);
          }

          &:hover {
            @include c('search-icon') {
              fill: var(--neutral-100);
            }
          }
        }

        @include c('close-button-container') {
          &:hover {
            background-color: var(--secondary-700);
          }
        }
      }

      &--active {
        background-color: var(--neutral-100);
        border: solid 2px rgba(5, 140, 255, 0.25);
        width: 260px;
        border-radius: 15.6px;
      }

      &--hideFocus {
        background-color: var(--neutral-150);
        border: solid 2px var(--neutral-400);
        width: 260px;
        border-radius: 15.6px;

        @include c('search-icon-container') {
          background-color: var(--patient-network-700);
          cursor: default;

          @include c('search-icon') {
            fill: var(--neutral-100);
          }

          &--currentAppSelected--BroadcastLists {
            background-color: var(--secondary-700);
            cursor: default;

            @include c('search-icon') {
              fill: var(--neutral-100);
            }
          }
        }
      }

      &--disabled {
        @include c('search-icon-container') {
          cursor: not-allowed;

          @include c('search-icon') {
            fill: var(--neutral-400);
          }

          &:hover {
            background: initial;

            @include c('search-icon') {
              fill: var(--neutral-400);
            }
          }
        }
      }
    }
  }

  @include m('theme', 'dynamic-templates') {
    @include c('wrapper') {
      display: flex;
      align-items: center;
      border: 2px solid transparent;
      height: 28px;

      @include c('search-icon-container') {
        align-items: center;
        border-radius: 100%;
        cursor: pointer;
        display: flex;
        height: 28px;
        justify-content: center;
        margin-left: 3px;
        margin-right: 2px;
        width: 28px;

        @include c('search-icon') {
          path {
            fill: var(--neutral-500);
          }
        }
      }

      @include c('input-container') {
        flex: 1;
        margin-right: 8px;
        font-size: 13px;
        border: none;
        padding: 0;

        &--searchIconHidden {
          margin-left: 10px;
        }

        &::-ms-clear {
          display: none;
        }

        &::placeholder {
          color: var(--neutral-500);
          font-weight: 400;
        }
      }

      &--active {
        background-color: var(--neutral-150);
        border: solid 2px rgba(5, 140, 255, 0.25);
        width: 260px;
        border-radius: 15.6px;
      }

      &--hideFocus {
        background-color: var(--neutral-150);
        border: solid 2px var(--neutral-400);
        width: 260px;
        border-radius: 15.6px;
      }
    }
  }
}
