@import '../../base';

@include b('CalleeBusyModal') {
  height: 230px;
  color: var(--neutral-1000);

  @include c('header') {
    @include common-modal-header;

    margin: 35px 0 4px;
  }

  @include c('oops-text') {
    font-size: 22px;
    font-weight: 600;
    color: var(--neutral-1000);
    margin-bottom: 14px;
  }

  @include c('body') {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
}
