@import '../base';

@include b('PatientSearchResultHeader') {
  display: flex;
  font-size: 15px;
  max-width: 340px;

  @include c('patient-avatar') {
    margin-right: 10px;
  }

  @include c('name-container') {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    min-height: 0;
    min-width: 0;

    &--isCentered {
      max-width: 180px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  @include c('name') {
    align-items: center;
    color: var(--neutral-950);
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &--isCentered {
      margin-top: 5px;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  @include c('patient-detail-container') {
    background-color: var(--patient-network-200);
    border-radius: 6px;
    color: var(--neutral-1000);
    display: inline-block;
    font-size: 11px;
    margin-top: 2px;
    padding: 2px 4px;

    @include c('patient-name') {
      color: var(--patient-network-700);
      font-size: 13px;
      font-weight: 600;
      height: 18px;
      margin-left: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 179px;
    }
  }

  @include c('relation') {
    color: var(--neutral-950);
    font-weight: 600;
    margin-left: 6px;
  }
}
