@import '../../base';

@include b('AlertCompletedItem') {
  margin-bottom: 8px;
  padding: 10px;
  font-size: 15px;

  @include m('isV2') {
    margin-bottom: 0;
    max-width: 100%;
    border: 1px solid #f4e4c4;
    border-radius: 6px;
    padding: 6px 10px;
    line-height: 1.4;
    font-size: 16px;
  }

  @include m('isExpanded') {
    margin-bottom: 6px;
  }

  @include m('shouldClip') {
    @include ellipsis;
  }
}
