@import '../base';

@include b('ToolTip') {
  cursor: pointer;
  width: inherit;
  display: initial;

  @include c('toolTipContainer') {
    position: absolute;
    z-index: 100;

    &--bottom {
      left: 50%;
      margin-top: 11px;
      transform: translateX(-50%);
    }

    &--left {
      top: 0%;
      transform: translateX(-105%);
    }

    &--top {
      bottom: 125%;
      left: 50%;
      transform: translateX(-50%);
    }

    &--right {
      margin-left: 25px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  @include c('bottom-left') {
    margin-top: 8px;
    padding-right: 60px;
  }

  @include c('toolTip') {
    background-color: var(--neutral-1000);
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    position: relative;

    &--allowMultiline {
      width: 250px;
    }

    &--patient {
      background-color: var(--patient-network-700);
      text-align: center;
    }

    &--broadcastList {
      background-color: $provider-broadcast-list;
      text-align: center;
    }
  }

  @include c('arrow') {
    background-color: var(--neutral-1000);
    border-radius: 100%;
    height: 10px;
    position: absolute;
    width: 10px;
    z-index: 1;

    &--arrow-bottom {
      left: 50%;
      top: -3px;
      transform: translateX(-50%);
    }

    &--arrow-top {
      bottom: -3px;
      left: 50%;
      transform: translateX(-50%);
    }

    &--arrow-right {
      top: 50%;
      transform: translateY(-50%);
      left: -3px;
    }

    &--arrow-left {
      top: 25%;
      transform: translateY(50%);
      transform: translateX(100%);
      right: 6px;
    }

    &--patient {
      background-color: var(--patient-network-700);
    }

    &--broadcastList {
      background-color: $provider-broadcast-list;
    }
  }

  @include c('text') {
    color: var(--neutral-100);
    font-size: 12px;
    font-weight: 100;
    line-height: 24px;
    padding: 0 15px;
    white-space: nowrap;

    &--allowMultiline {
      padding: 15px;
      line-height: normal;
      white-space: normal;
    }
  }
}
