@import '../../base';

@include b('TagFilter') {
  position: relative;

  @include c('inner-container') {
    position: absolute;
    width: 280px;
    top: 10px;
    border: 1px solid var(--neutral-300);
    border-radius: 6px;
    right: 0;
    z-index: 99;
    background-color: var(--neutral-100);
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
  }

  @include c('title') {
    color: var(--neutral-700);
    padding: 4px 10px;
    font-size: 12px;
    display: flex;
    height: 38px;
    align-items: center;

    @include c('text') {
      flex: 1 1 auto;
    }

    button {
      background-color: var(--cool-neutral-100);
      border-radius: 50%;
      width: 22px;
      height: 22px;

      path {
        fill: var(--cool-neutral-700);
      }

      &:hover {
        path {
          fill: var(--secondary-500);
        }
      }
    }
  }

  @include c('section-header') {
    font-size: 13px;
    font-weight: 600;
    display: flex;
    align-items: center;
    color: var(--neutral-900);
    height: 32px;
    padding: 0 10px;
    cursor: default;
  }

  @include c('loading') {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tc-CollaborationTags__item {
    height: 32px;
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    background-color: var(--neutral-150);
  }

  .tc-CollaborationTags__item--itemSelected {
    background-color: var(--cool-neutral-300);
  }

  @include c('not-tagged-icon') {
    margin-right: 5px;

    &--noPath {
      path {
        display: none;
      }
    }
  }
}
