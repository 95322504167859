@import '../../../base';

@include b('CreatePatientModal') {
  width: 960px;
  height: 475px;

  @include c('small-patient-admin-modal') {
    height: 260px;
  }

  @include c('header') {
    @include common-modal-header;
  }

  @include c('body') {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin-top: 65px;
  }

  @include c('errors') {
    height: 22px;
    margin-bottom: 15px;
    padding: 2px;

    &--hasError {
      align-items: center;
      background-color: var(--primary-300);
      border: solid 1px var(--primary-300);
      border-radius: 6px;
      color: var(--neutral-100);
      display: flex;
      font-size: 12px;
      font-weight: 500;
      padding: 0 50px;
    }
  }

  @include c('footer') {
    @include common-modal-footer;

    display: flex;
    justify-content: flex-end;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    align-items: center;
    padding: 15px 10px;

    // for IE, left side bug caused by border-bottom
    background-clip: padding-box;
  }

  @include c('save-btn') {
    @include button-with-disable(var(--secondary-500), 75px, 32px, 11px);

    &--progress {
      cursor: default;
    }

    &--isSendDisabled {
      &:hover {
        background-color: var(--neutral-100);
        border-color: var(--cool-neutral-700);
        color: var(--cool-neutral-700);
        cursor: default;
      }
    }

    padding-top: 2px;
  }

  @include c('cancel-btn') {
    @include common-button(var(--cool-neutral-700), 75px, 32px, 11px);

    &--progress {
      &:hover {
        background-color: var(--neutral-100);
        border-color: var(--cool-neutral-700);
        color: var(--cool-neutral-700);
        cursor: default;
      }
    }

    margin-right: 8px;
    padding-top: 2px;
  }

  @include c('patient-admin-container') {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: var(--neutral-100);
    width: 860px;
    margin: 0 auto;
    border: solid 1px var(--neutral-300);
    border-radius: 3px;
    padding: 0 10px;

    &:last-child {
      padding-bottom: 6px;
    }
  }

  @include c('option-button-container') {
    display: flex;
    margin-left: -15px;
    margin-top: 4px;
    position: absolute;
    border-radius: 6px;
  }

  @include c('option-button') {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 87px;
    height: 29px;
    border-radius: 15px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: var(--neutral-100);
    color: var(--patient-network-700);
    font-family: HelveticaNeue, sans-serif;
    margin-right: 17px;
    padding-right: 10px;

    @include c('edit-button-icon') {
      display: flex;
      font-style: normal;
      width: 18px;
      height: 19px;
      margin-top: 10px;
      margin-left: 2px;
      margin-right: -15px;
    }

    @include c('delete-button-icon') {
      display: flex;
      font-style: normal;
      width: 13px;
      height: 13px;
      margin-left: 6px;
    }

    &:hover {
      @include c('edit-button-icon') {
        path {
          fill: var(--neutral-100);
        }
      }

      @include c('delete-button-icon') {
        path {
          stroke: var(--neutral-100);
        }
      }

      background-color: var(--patient-network-700);
      font-style: normal;
      color: var(--neutral-100);
      cursor: pointer;
    }
  }

  @include c('add-patient-button') {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 289px;
    height: 24px;
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.21);
    border: solid 1px var(--patient-network-700);
    color: var(--patient-network-700);
    padding: 10px;
    font-size: 12px;
    font-weight: 500;
    margin-top: 15px;
    cursor: pointer;

    &--progress {
      cursor: default;
    }
  }

  @include c('dots-container') {
    @include center-items-both;

    height: 75%;
  }
}
