@import '../base';

@include b('FailureModal') {
  @include c('header') {
    @include common-modal-header;

    color: var(--neutral-900);
  }

  @include c('close-button') {
    @include common-modal-close-button;

    color: var(--primary-300);
  }

  @include c('img') {
    width: 68px;
    height: 68px;
    margin-bottom: 15px;
  }

  @include c('phoneNumberText') {
    text-align: left;
    padding: 0 0 10px 30px;
  }

  @include c('text') {
    color: var(--neutral-900);
    text-align: center;
    padding: 10px 20px 20px;
  }

  @include c('ok-btn-container') {
    display: flex;
    justify-content: center;
    border-top: solid 1px var(--neutral-200);
  }

  @include c('ok-btn') {
    text-align: center;
    color: var(--primary-300);
    border-radius: 0 0 5px 5px;
    padding: 1.3em 0;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    width: 100%;
  }
}
