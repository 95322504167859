@include b('CallLogViewDetails') {
  border-radius: 12px;
  border: 1px solid var(--neutral-300);
  background-color: var(--neutral-100);
  padding: 20px;
  margin: 30px 0;

  @include c('member-item') {
    display: flex;
    padding: 10px;
    border-radius: 6px;
    font-size: 12px;

    @include c('opt-out') {
      flex: 1;
      text-align: right;
      color: $sms-opted-out-primary-color;
      font-size: 12px;
    }

    @include c('member-name') {
      &--userOptedOut {
        opacity: 0.5;
      }
    }

    @include c('icon') {
      margin-right: 15px;
      display: flex;
      align-items: center;
    }

    &:nth-child(odd) {
      background-color: var(--neutral-150);
    }
  }

  @include c('details') {
    margin: 8px 0;

    div {
      font-size: 13px;
      line-height: 1.62;
      color: var(--neutral-950);

      span {
        color: var(--patient-network-700);
        margin-right: 5px;
      }
    }
  }

  @include c('title') {
    padding: 7px 0;
    border-bottom: 1px solid var(--neutral-300);
    color: var(--neutral-950);
    font-size: 16px;
    font-weight: 600;
  }

  @include c('title-sm') {
    padding: 7px 0;
    color: var(--neutral-950);
    font-size: 12px;
    font-weight: 600;
  }
}
