@import '../../../base';

@include b('UploadPBXLinesModal') {
  width: 960px;
  color: var(--neutral-950);

  @include c('header') {
    @include common-modal-header;

    color: var(--neutral-950);
  }

  @include c('body') {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin: 25px 32px 100px;

    &--appointmentReminders {
      margin: 25px 32px;
    }
  }

  @include c('pbx-system-id-container') {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    margin-top: 25px;
  }

  @include c('pbx-system-id-label') {
    display: flex;
    align-items: center;
    font-weight: 600;
    color: var(--patient-network-700);
  }

  @include c('upload-button-container') {
    border-radius: 3px;
    border: solid 1px var(--neutral-300);
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.09);
    cursor: pointer;
    display: flex;
    margin: auto;
    margin-top: 40px;
    padding: 20px 30px 20px 20px;
    width: 550px;

    &:hover {
      background-color: var(--neutral-150);
    }
  }

  @include c('upload-button-input') {
    display: none;
  }

  @include c('column-one') {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  @include c('column-two') {
    align-self: center;
  }

  @include c('first-row') {
    font-weight: 600;
    color: var(--patient-network-700);
  }

  @include c('second-row') {
    padding-top: 10px;
  }

  @include c('upload-text') {
    padding-top: 10px;
    display: inline-block;
  }

  @include c('button-icon-path') {
    fill: var(--patient-network-700);
    width: 22px;
    height: 22px;
  }

  @include c('error') {
    height: 30px;
    margin-bottom: 10px;
  }

  @include c('error-message') {
    background-color: var(--errors-300);
    border: solid 1px var(--errors-500);
    border-radius: 6px;
    color: var(--neutral-100);
    margin: auto;
    padding: 3px;
    text-align: center;
  }
}
