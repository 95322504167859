@import '../../../base';

@include b('EulaModal') {
  @include c('header') {
    @include common-modal-header;

    color: var(--neutral-1000);
  }

  @include c('body') {
    @include common-modal-body;

    height: 50vh;
    padding: 30px;
  }

  @include c('content-container') {
    position: relative;
    height: 100%;
  }

  @include c('dots-container') {
    @include center-items-both;

    height: 100%;
  }

  @include c('eula-content') {
    padding: 15px;

    p:not([class]) {
      margin: 1em 0;
    }
  }

  @include c('accept-btn') {
    @include common-button(var(--secondary-500), 74px, 32px, 11px);
  }

  @include c('logout-btn') {
    @include common-button(var(--cool-neutral-700), 74px, 32px, 11px);

    margin-right: 8px;
  }
}
