@import '../base';

@include b('PatientContactSearchResultDetails') {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  width: 100%;
  background-color: var(--netrual-100);

  @include c('divider') {
    margin-left: 66px;
    width: auto;
    height: 1px;
    border-top: 1px solid var(--neutral-300);

    &--isAddFamilyAndContacts {
      border-top: none;
    }
  }

  @include c('wrapper') {
    display: flex;
    align-items: center;
    border-radius: 6px;
    height: 39px;
    margin: 6px;

    &:hover {
      background-color: var(--cool-neutral-300);
    }

    &--smsOptedOut {
      height: 52px;
    }

    &--isAddFamilyAndContacts {
      margin: 0 6px;
      margin-top: 5px;
      height: 39px;
    }

    &--disableSelection {
      &:hover {
        background-color: initial;
      }
    }
  }

  @include c('patient-space-box') {
    flex: 0 0 60px;

    &--isAddFamilyAndContacts {
      flex: 0 0 5px;
    }
  }

  @include c('call') {
    padding-right: 10px;
    display: flex;
    align-items: center;
  }

  @include c('patient-contact-box') {
    display: flex;
    cursor: pointer;
    flex: 1;

    &--disableSelection {
      cursor: default;
    }
  }

  @include c('patient-contact-box-hover') {
    border-radius: 0 6px 6px 0;
    width: 100%;
    height: 39px;
    margin: 6px 6px 6px 0;

    &--smsOptedOut {
      height: 52px;
    }

    &--isAddFamilyAndContacts {
      display: flex;
      justify-content: space-between;
    }
  }

  @include c('avatar-class') {
    background-color: var(--patient-network-700);
    border-radius: 50%;
    display: flex;
    align-content: center;
    justify-content: center;

    @include if-ie {
      padding-top: 2px;
    }

    &--smsOptedOut {
      background-color: inherit;
    }
  }

  @include c('patient-relation') {
    color: var(--neutral-700);
    font-size: 10px;
    padding-top: 4px;

    &--smsOptedOut {
      opacity: 0.5;
      padding-top: 0;
    }
  }

  @include c('contact-name') {
    color: var(--neutral-1000);
    font-size: 15px;
    font-weight: bold;
    overflow: hidden;
    padding-bottom: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;

    &--smsOptedOut {
      color: var(--opt-out-100);
      padding-bottom: 0;
    }
  }

  @include c('contact-information') {
    display: flex;

    @include c('avatar') {
      display: flex;
      align-items: center;

      @include c('avatar-class-information') {
        background-color: var(--patient-network-700);
        border-radius: 50%;
        display: flex;
        align-content: center;
        justify-content: center;

        @include if-ie {
          padding-top: 2px;
        }

        &--smsOptedOut {
          background-color: inherit;
        }
      }
    }

    @include c('contact-details') {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &--isAddFamilyAndContacts {
        max-width: 250px;
        justify-content: center;
      }

      &--smsOptedOut {
        max-width: 110px;
      }

      @include c('patient-relation-information') {
        color: var(--neutral-700);
        font-size: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &--smsOptedOut {
          opacity: 0.5;
          padding-top: 0;
        }
      }

      @include c('contact-name-information') {
        color: var(--neutral-950);
        font-size: 15px;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &--smsOptedOut {
          color: var(--neutral-600);
          padding-bottom: 0;
        }
      }
    }
  }
}
