@import '../../base';

@include b('MessageContent') {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 100%;
  padding: 5px 11px;

  @include c('user-name') {
    @include ellipsis;

    display: flex;
    max-width: $message-body-max-width;
    align-items: flex-start;
    color: var(--cool-neutral-900);
    font-size: 14px;
    margin-right: 4px;

    &--isPatientMessaging {
      align-items: center;
      color: var(—-patient-network-700);
      font-weight: 500;
    }

    &--isPatientMessageForward {
      font-weight: 600;
    }

    @include c('patient-pill') {
      flex: 0 0 44px;
      border-radius: 8px;
      background-color: var(--neutral-100);
      font-size: 11px;
      color: var(--patient-network-700);
      text-align: center;
      font-weight: 400;

      &--isPatientMessageForward {
        margin-left: 5px;
        margin-top: 2px;
      }

      &--isForwardedFromPatientNetwork {
        width: 44px;
        height: 17px;
        border: solid 1px var(--patient-network-400);
        background-color: var(--patient-network-200);
        font-weight: normal;
        margin: 1px 0 0 4px;
      }
    }
  }

  @include c('patient-name') {
    @include ellipsis;

    font-size: 14px;
    font-weight: 600;
    color: var(--neutral-1000);

    &--isForwardedFromPatientNetwork {
      color: var(--neutral-1000);
    }
  }

  @include c('patient-contact-relation') {
    @include ellipsis;

    font-size: 14px;
    font-weight: 600;

    &--isForwardedFromPatientNetwork {
      color: var(--neutral-1000);
    }
  }

  @include c('user-name-link') {
    @include ellipsis;

    margin-right: 4px;

    &:hover {
      cursor: pointer;
      color: var(--secondary-500);
    }

    &:focus-visible {
      box-shadow: inset 0 0 0 3px var(--secondary-500);
    }
  }

  @include c('user-name-removed') {
    @include ellipsis;

    color: var(--neutral-700);
  }

  @include c('away') {
    font-weight: 500;
    margin-top: 3px;

    svg {
      margin-right: 5px;
    }

    circle {
      fill: var(--neutral-1000);
    }
  }

  @include c('user-name-patient') {
    @include ellipsis;

    color: var(--neutral-1000);
    font-weight: 600;
    margin-right: 4px;
    max-width: $message-body-width-medium;
  }

  @include c('automated-message') {
    display: flex;
    align-items: flex-start;
    color: var(--neutral-1000);
    font-size: 13px;
    text-decoration: underline;
    padding-bottom: 3px;
  }

  @include c('role-tag') {
    display: flex;
    align-items: flex-start;
    color: var(--neutral-800);
    font-size: 13px;
  }

  @include c('forwarded-from') {
    display: inline;
    padding: 5px 0 0;
    color: var(--neutral-1000);
    font-size: 14px;
    font-weight: 500;

    @include m('isForwardedFromPatientNetwork') {
      font-size: 13px;
      color: var(--cool-neutral-900);
      padding: 0 0 10px;
    }
  }

  @include c('link') {
    cursor: pointer;

    &:hover {
      color: var(--secondary-500);
    }
  }

  @include c('body') {
    overflow: hidden;
    line-height: 1.4;
    font-size: 16px;
    word-wrap: break-word;
    word-break: break-word;
    white-space: pre-line;
    text-align: left;
    color: var(--neutral-900);

    @include if-ie {
      padding-bottom: 1px;
      line-height: normal;
    }

    @include m('body-type', 'forwarded') {
      display: flex;
      flex-direction: row;
      padding: 3px 2px 4px;
    }

    @include c('emoji-text') {
      max-width: $message-body-max-width;
      font-size: 48px;
      line-height: 1.2;
      padding-top: 8px;
    }

    @include c('text-container') {
      max-width: $message-body-max-width;

      @include medium-messages {
        max-width: $message-body-width-medium;
      }

      .Linkify {
        img {
          max-width: $message-body-max-width;
          max-height: $message-image-max-height;
          padding-top: 5px;

          @include medium-messages {
            max-width: $message-body-width-medium;
          }
        }
      }
    }

    @include c('code-container') {
      @include markdown;

      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      max-width: $message-code-max-width;
    }

    @include c('phoneNumber') {
      cursor: pointer;
      text-decoration: underline;
      color: var(--secondary-500);

      &:hover {
        color: var(--secondary-500);
        text-decoration: underline;
      }
    }
  }

  @include c('body-forward-border') {
    margin: 3px 0;
    border: solid 1.5px var(--neutral-900);
    border-radius: 1em;
    background-color: var(--neutral-900);
  }

  @include c('body-forward-details') {
    flex: 1 0 auto;
    padding: 0 0.6em;
  }

  @include c('body-forward-name') {
    font-weight: 600;
    color: var(--patient-network-800);
  }

  @include c('body-forward-name-title') {
    display: flex;

    @include m('isForwardedFromPatientNetwork') {
      color: var(--neutral-950);
    }
  }

  @include c('body-forward-text') {
    padding: 1px 0 4px;
  }

  @include m('sender-status', 'SENDING') {
    opacity: 0.7;
  }

  @include m('sender-status', 'FAILED') {
    opacity: 0.8;
  }

  @include c('retry-button-wrapper') {
    display: inline-block;
    margin: auto 4px;
  }

  @include c('retry-button') {
    color: var(--primary-900);
    font-size: 80%;
    font-weight: bold;
    padding: 5px;

    &[disabled] {
      color: $dimmed-text-color;
      cursor: wait;
    }
  }

  @include c('patient-conversation-container') {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    background-color: var(--neutral-200);
    margin: 2px 0 15px;
    padding: 6px;
  }

  @include c('from-conversation-header') {
    font-size: 10px;
    color: var(--neutral-950);
  }

  @include c('patient-conversation-name') {
    border-radius: 8px;
    background-color: var(--neutral-200);
    margin: 11px;
  }

  @include c('patient-details') {
    border-radius: 6px;
    border: solid 1px var(--patient-network-400);
    background-color: var(--cool-neutral-100);
    margin-top: 3px;
    padding: 3px 8px;
    width: 100%;
  }

  @include c('patient-mrn') {
    font-size: 13px;
    color: var(--patient-network-700);
  }

  @include c('mention') {
    color: var(--secondary-600);
    cursor: pointer;
    text-decoration: underline;
    user-select: none;
    white-space: nowrap;

    &--isMentionedCurrentUser {
      pointer-events: none;
    }
  }
}
