@import '../base';

@include b('SessionExpired') {
  @include center-items-both;

  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: #f9f9f9;
  padding-top: 40px;

  @include c('container') {
    flex: 0 0 auto;
    position: relative;
    width: 100%;
    margin: auto;
    max-width: 418px;
    background-color: $white;
    box-shadow: 0 4px 10px 0 rgba(95, 45, 45, 0.37);
    border: solid 1px #e7e7e7;
    border-radius: 6px;
  }

  @include c('content') {
    @include center-items-both;

    flex-direction: column;
    padding: 38px 52px 28px;
  }

  @include c('title-text') {
    margin-top: 34px;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    text-align: center;
    color: $display-text;
  }

  @include c('title-subtext') {
    margin-top: 12px;
    width: 100%;
    font-size: 12px;
    text-align: center;
    color: #808080;
  }

  @include c('error-container') {
    margin-top: 12px;
    height: 32px;
    width: 100%;
  }

  @include c('offline-error') {
    font-size: 12px;
    text-align: center;
    color: $priority-message-bg-color;
  }

  @include c('refresh-btn') {
    width: 100%;
    border-top: solid 1px #f2f2f2;
    border-radius: 0 0 5px 5px;
    padding: 1.3em 0;
    background-color: #fafafa;
    text-align: center;
    color: #d65b55;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      background-color: #f2f2f2;
    }
  }

  &::after {
    content: '';
    display: block;
    flex: 0 1 50%;
  }
}
