@import '../../base';

@include b('NewMessageHeader') {
  width: 100%;
  padding: 10px 23px 0 20px;

  @include c('NewMessageHeader-container') {
    display: flex;
    flex-direction: row;

    @include c('input-holder') {
      width: 100%;
    }

    @include c('input-container') {
      display: flex;
      flex-direction: row;
    }

    @include c('avatar-container') {
      display: flex;
      align-items: center;

      @include c('avatar-preview') {
        color: var(--secondary-500);
        font-size: 12px;
        display: flex;
        align-items: center;
        background: var(--cool-neutral-200);
        border-radius: 200px;

        img {
          width: 30px;
          object-fit: cover;
          height: 30px;
          border-radius: 100%;
          margin-right: 8px;
        }

        @include c('avatar-preview-cancel') {
          margin-left: 10px;
          margin-right: 6px;
          cursor: pointer;
          height: 20px;
          width: 20px;
          border-radius: 100%;
          text-align: center;
          padding-top: 2px;

          svg {
            path {
              fill: var(--secondary-500);
            }
          }

          &:hover {
            background: var(--secondary-300);

            svg {
              path {
                fill: var(--cool-neutral-200);
              }
            }
          }
        }
      }
    }

    @include c('label') {
      height: 36px;
      line-height: 36px;
      padding: 0 10px 0 0;
      text-align: right;
      color: var(--neutral-700);
      vertical-align: middle;
    }

    @include c('input') {
      width: 100%;
      outline: none;
      background-color: var(--neutral-100);

      &::placeholder {
        color: var(--neutral-500);
      }

      &--isPatientOrContact {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      @include if-ie {
        &::-ms-clear {
          display: none;
        }
      }
    }

    @include c('header-action-button') {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      margin-left: 10px;
      padding: 0 10px;
      height: 36px;
      font-size: 15px;
      color: var(--secondary-500);
      cursor: pointer;
      user-select: none;
      background-color: var(--cool-neutral-200);
      border-radius: 200px;
      font-weight: 300;

      @include c('header-action-button-icon') {
        margin-right: 5px;
        height: 16px;

        svg {
          height: 100%;
          width: 100%;
        }
      }

      &:hover {
        background-color: var(--cool-neutral-300);
      }
    }

    @include c('add-group-image-input') {
      display: none;
    }

    @include c('new-group') {
      flex: 0 0 auto;
      height: 36px;
      line-height: 36px;
      padding: 0 0 0 30px;
      font-size: 15px;
      color: var(--secondary-500);
      cursor: pointer;
      user-select: none;

      &:hover {
        color: var(--secondary-900);
      }
    }
  }

  @include c('patient-reference-card-container') {
    margin-top: 22px;
  }
}
