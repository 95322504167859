@import '../../base';

@include b('PatientReferenceSearch') {
  @include m('mode', 'Dropdown') {
    @include c('search-input-container') {
      background: var(--cool-neutral-200);
      padding: 10px;
      border-radius: 6px 6px 0 0;

      @include c('search-input') {
        background: var(--neutral-100);
        border-radius: 110px;
        border: 1px solid var(--neutral-300);
        display: flex;
        font-size: 15px;
        justify-content: space-between;
        padding: 5px 5px 5px 10px;
        width: 100%;

        @include c('search-input-value') {
          width: 340px;
        }

        &::placeholder {
          color: var(--cool-neutral-800);
        }

        @include c('search-cancel') {
          cursor: pointer;
        }
      }
    }

    @include c('search-results-category') {
      background: var(--neutral-200);
      display: flex;
      padding-left: 20px;

      @include c('search-category') {
        color: var(--neutral-700);
        cursor: pointer;
        display: flex;
        font-size: 14px;
        font-weight: 600;
        height: 22px;
        justify-content: center;
        margin-right: 35px;
        min-width: 60px;
        opacity: 0.7;
        padding-bottom: 27px;
        text-align: center;
        width: auto;

        &--selected {
          border-bottom: 2px solid var(--secondary-500);
          color: var(--secondary-500);
        }
      }
    }

    @include c('search-results') {
      background: var(--neutral-150);
      max-height: 400px;
      overflow-y: auto;
      min-height: 226px;

      &--isEmpty {
        background: var(--cool-neutral-200);
        display: flex;
        justify-content: center;
        min-height: 95px;
        padding-top: 33px;
      }

      @include c('search-start-container') {
        align-items: center;
        color: var(--neutral-700);
        display: flex;
        font-size: 14px;
        font-weight: 500;
        height: 17px;
        justify-content: center;
        line-height: 17px;
        width: 326px;
        margin: 0 auto;

        &--isMyPatientsTab {
          padding-top: 42px;
        }

        @include c('search-start-icon') {
          margin-right: 5px;

          svg {
            display: block;

            path {
              fill: var(--cool-neutral-800);
            }
          }
        }
      }

      @include c('search-result-row') {
        border-bottom: solid 1px var(--neutral-300);
        cursor: pointer;
        padding: 7px 4px 7px 10px;

        &:hover {
          background: var(--cool-neutral-300);
        }
      }

      @include c('search-results-page-loader') {
        text-align: center;
        font-size: 18px;
        color: var(--neutral-800);
        margin: 10px 0;
      }

      @include c('no-search-results-text-response') {
        text-align: center;
        margin-top: 20%;
        font-size: 14px;
        color: var(--neutral-800);
      }

      @include c('search-results-text-response') {
        text-align: center;
        margin-top: 20%;
        font-size: 18px;
        color: var(--neutral-800);
      }
    }
  }

  @include m('mode', 'Directory') {
    display: flex;
    background: var(--neutral-100);
    flex-direction: column;

    @include c('search-input-container') {
      flex: 0 0 auto;
      position: relative;
      display: flex;
      align-items: center;
      margin: 0 22px 15px;
      z-index: 1;

      @include c('search-input-icon') {
        position: absolute;
        left: 12px;

        svg {
          display: block;

          path {
            fill: var(--cool-neutral-800);
          }
        }
      }

      @include c('search-input') {
        display: flex;
        font-size: 15px;
        padding: 5px 5px 5px 37px;
        background: var(--neutral-100);
        justify-content: space-between;
        border-radius: 110px;
        border: 1px solid var(--neutral-300);
        width: 446px;
        height: 33px;

        @include c('search-input-value') {
          width: 340px;
        }

        &::placeholder {
          color: var(--cool-neutral-800);
        }

        @include c('search-cancel') {
          cursor: pointer;
        }
      }
    }

    @include c('search-results-category') {
      display: flex;
      padding-left: 20px;
      margin: 10px 0;

      @include c('search-category') {
        color: var(--neutral-500);
        cursor: pointer;
        display: flex;
        font-size: 16px;
        font-weight: 600;
        height: 22px;
        justify-content: center;
        margin-right: 35px;
        min-width: 60px;
        opacity: 0.7;
        padding-bottom: 27px;
        text-align: center;
        width: auto;

        &--selected {
          border-bottom: 2px solid var(--secondary-500);
          color: var(--secondary-500);
        }
      }
    }

    @include c('search-results') {
      flex: 1 1 auto;
      overflow-y: auto;

      @include c('search-start-container') {
        background: var(--cool-neutral-200);
        border-radius: 6px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
        color: var(--neutral-700);
        display: flex;
        gap: 10px;
        height: 119px;
        justify-content: center;
        left: 23px;
        padding: 10px;
        position: absolute;
        top: 163px;
        width: 445px;
        align-items: center;

        &--allowCareTeamAssignments {
          position: initial;
          margin-left: 20px;
          margin-bottom: 5px;
        }

        @include c('search-start-icon') {
          svg {
            display: block;

            path {
              fill: var(--cool-neutral-800);
            }
          }
        }
      }

      @include c('search-result-row') {
        border-bottom: solid 1px var(--neutral-300);
        cursor: pointer;
        padding: 7px 4px 7px 3px;
        margin: 0 22px;
        padding-top: 10px;

        &:hover {
          background: var(--cool-neutral-300);
        }
      }
    }
  }
}
