@import '../../base';

@include b('VirtualWaitingRoomEmptyRoom') {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0eff4;

  @include c('content') {
    display: flex;
    flex-direction: column;
    align-items: center;

    > p {
      color: #979797;
      font-size: 14px;
    }
  }

  @include c('primary') {
    display: flex;
    align-items: center;
    padding-bottom: 18px;
  }

  @include c('primary-text') {
    font-family: inherit;
    font-size: 30px;
    color: #4d4d4d;
    padding-left: 13px;
  }
}
