@import '../../base';

@include b('AccountSettings') {
  @include profile-detail-container;

  &:focus {
    outline: none;
  }

  @include c('header') {
    @include profile-header;
  }

  @include c('info') {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
  }

  @include c('info-static') {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    border-bottom: solid 1px var(--neutral-300);
    margin-right: 25px;
  }

  @include c('info-header-no-edit') {
    @include profile-detail-info;

    border: none;
  }

  @include c('info-header-edit') {
    padding: 20px 25px 0;
    width: 100%;
  }

  @include c('info-header') {
    @include profile-sub-header;

    flex: 1;
  }

  @include c('info-body') {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 70px;
  }

  @include c('body-text') {
    font-size: 12px;
    padding: 5px 0 20px 20px;
    color: var(--neutral-700);
  }

  @include c('info-header-static') {
    @include profile-sub-header;

    flex: 1;
    padding: 20px 20px 0;
    width: 100%;
  }

  @include c('info-header-otp') {
    color: var(--neutral-700);
    flex: 1 1;
    font-size: 10px;
    margin-top: 7px;
    padding: 0 21px;
    width: 100%;
  }

  @include c('settings-container') {
    @include profile-detail-info;

    font-size: 10px;
    color: var(--neutral-700);

    @include c('settings-content') {
      flex: 1 1 auto;
      cursor: pointer;
    }
  }
}
