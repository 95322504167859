@import '../base';

@include b('PatientAdminFailureModal') {
  @include c('header') {
    @include common-modal-header;

    color: var(--neutral-950);
  }

  @include c('close-button') {
    @include common-modal-close-button;

    color: var(--primary-300);
  }

  @include c('img') {
    width: 68px;
    height: 68px;
    margin-bottom: 15px;
  }

  @include c('text') {
    color: var(--neutral-1000);
    color: var(--neutral-950);
    font-size: 14px;
    height: 42px;
    line-height: 1.5;
    padding: 10px 20px 20px;
    text-align: center;
    width: 220px;
  }

  @include c('body') {
    color: var(--neutral-950);
    display: flex;
    justify-content: center;
    margin-bottom: 55px;
  }

  @include c('ok-btn') {
    text-align: center;
    color: var(--primary-300);
    border-top: solid 1px var(--neutral-200);
    border-radius: 0 0 5px 5px;
    padding: 1.3em 0;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    margin-top: 35px;
    background-color: var(--neutral-150);
  }
}
