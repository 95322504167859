@import '../../base';

@include b('VisitorCallModal') {
  @include c('header') {
    @include common-modal-header;

    flex-direction: row;
    justify-content: flex-end;
  }

  @include c('close-button') {
    cursor: pointer;
    margin-right: 5px;

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
    }
  }

  @include c('body') {
    @include common-modal-body;

    height: 200px;
    padding: 30px 60px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  @include c('footer') {
    height: 100px;
    background-color: #fff;
  }

  @include c('patient-container') {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  @include c('avatar-container') {
    width: 64px;
    height: 64px;
    margin-bottom: 10px;
  }

  @include c('call-text') {
    font-size: 15px;
    font-weight: 600;
  }

  @include c('call-container') {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  @include c('call-button') {
    display: flex;
    width: 280px;
    height: 45px;
    align-items: center;
    justify-content: center;
    border-radius: 22.5px;
    background-color: #ecf1f3;
  }

  @include c('video-icon') {
    height: 14px;
    margin-right: 10px;

    svg {
      width: 21px;
      height: 14px;
      fill: $patient-color;
    }
  }

  @include c('call-button-text') {
    color: $patient-color;
    font-size: 14px;
    font-weight: 600;
  }
}
