@import '../base';

@include b('GroupMembers') {
  flex: 0 0 185px;
  background-color: var(--neutral-100);
  border: 1px solid var(--neutral-300);
  border-radius: 12px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-left: 8px;
  margin-right: 8px;
  color: var(--neutral-900);

  @include c('header') {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 30px 10px;
    font-size: 12px;
    font-weight: bold;
  }

  @include c('footer') {
    display: flex;
    height: 15px;
  }

  @include c('add-button') {
    svg {
      height: 18px;
      width: 18px;
      fill: var(--cool-neutral-700);

      &:hover {
        fill: var(--secondary-500);
      }
    }

    &--active {
      svg {
        fill: var(--secondary-500);
      }
    }
  }

  @include c('recipient-picker-container') {
    margin-left: 10px;
    margin-right: 30px;
  }

  @include c('list-container') {
    position: relative;
    padding-left: 10px;
    margin: 10px 10px 0 0;
    transition: opacity 200ms cubic-bezier(0.22, 1, 0.36, 1);
    display: flex;
    flex-direction: column;
  }

  @include c('user-name') {
    flex: 1;
  }

  @include c('list-item') {
    @include center-items-both;

    margin: 1px 10px 2px 0;
    padding: 4px 20px;
    border-radius: 5px;
    cursor: pointer;
    height: 45px;

    @include medium {
      margin: 1px 0 0;
    }

    @include b('AvatarImage') {
      margin-right: 10px;
    }

    &:hover {
      background-color: var(--cool-neutral-300);

      @include c('actions') {
        border-left: solid 2px var(--cool-neutral-500);
      }

      @include c('actions-remove') {
        fill: var(--cool-neutral-700);
        padding-left: 0;

        &:hover {
          fill: var(--secondary-500);
        }
      }
    }

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
      padding-left: 0;

      @include c('actions-remove') {
        fill: var(--cool-neutral-700);

        &:hover {
          fill: var(--secondary-500);
        }
      }
    }

    &--highlight {
      background-color: var(--secondary-500);
      color: var(--neutral-100);

      @include c('user-title') {
        color: var(--neutral-100);
      }

      &:hover {
        background-color: var(--secondary-500);

        @include c('actions-remove') {
          fill: var(--neutral-100);

          &:hover {
            fill: var(--neutral-100);
          }
        }
      }
    }
  }

  @include c('details') {
    display: flex;
    align-items: center;
    flex: 1;
    line-height: normal;
    height: inherit;

    @include c('user-details') {
      display: flex;
      flex-direction: column;
      flex: 1;
      text-align: left;

      @include c('user-name') {
        @include ellipsis;

        color: var(--neutral-900);
        font-weight: bold;
        max-width: 190px;

        &--underLine {
          text-decoration: underline;
        }

        &--smsOptedOut {
          color: $sms-opted-out-disabled-color;
        }
      }

      @include c('user-title') {
        @include ellipsis;

        max-width: 190px;
      }
    }

    &:focus-visible {
      outline: 3px solid var(--secondary-500);

      @include c('actions-remove') {
        fill: var(--cool-neutral-700);
        padding-left: 0;

        &:hover {
          fill: var(--secondary-500);
        }
      }
    }

    &--highlight {
      &:focus-visible {
        outline: 3px solid var(--neutral-100);
      }
    }
  }

  @include c('user-title') {
    color: var(--neutral-700);
  }

  @include c('patient-title') {
    font-size: 14px;
    color: var(--neutral-700);
  }

  @include c('actions') {
    flex: 0 0 40px;
    padding-left: 20px;
    height: 75%;

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
      padding-left: 0;

      @include c('actions-remove') {
        fill: var(--cool-neutral-700);

        &:hover {
          fill: var(--secondary-500);
        }
      }
    }

    &--highlight {
      &:focus-visible {
        outline: 3px solid var(--neutral-100);
      }
    }
  }

  @include c('actions-remove') {
    cursor: pointer;
    fill: var(--neutral-100);
    height: 100%;
  }

  @include c('buttons-container') {
    @include medium {
      width: 100%;
    }

    &--fixedButtons {
      position: fixed;
      bottom: 0;
      left: 0;
      display: flex;
      width: 100%;
      pointer-events: none;

      @include c('padding') {
        flex: 1;
        width: auto;

        @include medium {
          width: 432px;
          flex: none;
        }
      }
    }
  }

  @include c('buttons') {
    pointer-events: all;
    display: flex;
    height: 70px;
    padding: 20px 10px 0;
    border-radius: 0 0 6px 6px;
    background:
      linear-gradient(
        to top,
        var(--neutral-100),
        var(--neutral-100) 70%,
        rgba(255, 255, 255, 0.001)
      );

    &--fixedButtons {
      width: 330px;
      flex: none;
      margin-right: 10px;

      @include medium {
        width: auto;
        flex: 1;
      }
    }

    &--scrollBarOffset {
      width: 315px;
      margin-right: 25px;

      @include medium {
        width: auto;
      }
    }
  }

  @include medium {
    width: auto;
    position: relative;
  }

  @include c('cancel-button') {
    @include center-items-both;

    width: 150px;
    height: 32px;
    border-radius: 6px;
    border: solid 1px var(--neutral-300);
    color: var(--secondary-500);

    @include medium {
      flex: 1;
    }
  }

  @include c('save-button') {
    @include center-items-both;

    width: 150px;
    height: 32px;
    border-radius: 6px;
    background-color: var(--secondary-500);
    color: $white;
    margin-left: 8px;

    @include medium {
      flex: 1;
    }
  }

  @include c('dots-container') {
    @include center-items-both;

    height: 75%;
  }

  @include c('loading') {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
}
