@import '../base';

@include b('UserConversationInfoPane') {
  flex: 1 0 120px;
  color: var(--neutral-900);
  position: relative;

  @include c('name-and-avatar') {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    text-align: center;
  }

  .tc-DndBanner {
    margin: 10px 9px 0;
  }

  @include c('avatar-container') {
    position: relative;
  }

  @include c('name') {
    display: block;
    margin-top: 7px;
    padding: 0 15px;
    text-align: center;
    font-weight: 500;
  }

  @include c('title-department') {
    font-size: 14px;
    color: var(--neutral-700);
  }

  @include c('call-container') {
    display: flex;
    justify-content: center;
    margin: 10px 0 -4px;
  }

  @include c('options') {
    flex: 0 0 185px;
    background-color: var(--neutral-100);
    border: 1px solid var(--neutral-300);
    border-radius: 6px;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    margin: 20px 8px 0;
    color: var(--neutral-900);
    font-size: 12px;
  }

  @include c('title') {
    font-weight: bold;
    padding: 0 0 12px 20px;
  }

  @include c('option-header') {
    color: var(--neutral-800);

    &--disabled {
      color: var(--neutral-500);
    }
  }

  @include c('option-row') {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 10px 12px 20px;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background-color: var(--cool-neutral-300);
    }

    &--disabled {
      cursor: auto;

      &:hover {
        background-color: var(--neutral-200);
      }
    }
  }

  @include c('option-row-slider') {
    display: flex;
    flex-direction: column;
    padding: 12px 10px 12px 20px;
    color: var(--neutral-500);

    &:hover {
      background-color: var(--neutral-200);
      border-radius: 5px;
    }

    @include c('option-header-slider') {
      font-size: 14px;
      margin-bottom: 5px;
    }

    @include c('option-slider') {
      width: 95%;
      padding-top: 10px;
    }
  }

  @include c('option-slider') {
    width: 200px;
  }

  @include c('side-arrow') {
    width: 8px;
    height: 12px;
    margin-right: 0.75vw;
  }
}
