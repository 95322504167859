@import '../../base';

@include b('DesktopAppSettings') {
  @include profile-detail-container;

  @include c('header') {
    @include profile-header;
  }

  @include c('da-lead') {
    color: #9b9b9b;
    font-size: 10px;
    font-weight: 400;
    margin-left: 5px;
  }

  @include c('info') {
    @include profile-detail-info;
  }

  @include c('info-header-edit') {
    padding: 20px 25px 0;
    width: 100%;
  }

  @include c('info-header') {
    @include profile-sub-header;

    flex: 1;
  }

  @include c('sound-select') {
    width: 110px;
    border-radius: 3.5px;
    background-color: $white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.18);
    line-height: 25px;
    cursor: pointer;
    color: #484848;

    &:hover {
      @include c('svg-box') {
        background-color: #f4f4f4;
      }
    }

    @include c('svg-box') {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      padding: 0 10px;

      &--isActive {
        background-color: #f4f4f4;
      }
    }

    @include c('svg') {
      width: 5px;
      margin-left: 10px;

      svg {
        width: 5px;
      }
    }
  }
}
