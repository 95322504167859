@import '../../base';

@include b('PatientBroadcastListRowDetails') {
  align-items: center;
  position: relative;
  color: var(--neutral-950);
  display: flex;
  font-size: 14px;
  line-height: 50px;
  background-color: var(--neutral-100);

  @include m('selectable') {
    cursor: pointer;

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
    }

    &:hover {
      background-color: var(--cool-neutral-100);

      @include c('list-item-checkbox') {
        background-color: var(--cool-neutral-100);
      }
    }
  }

  @include m('smsOptedOut') {
    &::after {
      background: rgba(var(--neutral-100-rgb), 0.5);
      bottom: 0;
      content: '';
      height: 100%;
      position: absolute;
      width: 100%;
    }
  }

  @include m('isSelected') {
    background-color: var(--cool-neutral-300);

    &:hover {
      background-color: var(--cool-neutral-300);

      @include c('list-item-checkbox') {
        background-color: var(--cool-neutral-300);
      }
    }
  }

  > * {
    flex: 1;
    padding-left: 15px;
    vertical-align: middle;
  }

  @include c('list-item-checkbox') {
    cursor: default;
    flex: none;
    padding: 0;
    text-align: center;
    width: 58px;

    @include c('list-item-checkbox-default') {
      border-radius: 2px;
      border: solid 1px var(--neutral-400);
      cursor: pointer;
      height: 14px;
      width: 14px;
      margin: 0 auto;
      position: relative;

      &--isSelected {
        border: none;

        svg {
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }

  @include c('svg-container') {
    flex: 0.2;

    @include c('patient-svg') {
      border-radius: 50%;
      margin-right: 10px;
      vertical-align: middle;

      svg {
        padding: 2px;
      }

      &--smsOptedOut {
        svg {
          padding: 0;
        }
      }
    }
  }

  @include c('member-details') {
    flex: 1.5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 15px;
    padding-left: 0;
    min-height: 50px;

    &--selected {
      background-color: var(--cool-neutral-100);
    }

    &--isSingle {
      padding: 15px 0;
    }

    & > div {
      line-height: initial;
    }

    @include c('member-name') {
      @include ellipsis;

      font-weight: 600;

      &--hideBorder {
        border-top: none;
      }

      @include c('grouped-contact-relation') {
        font-weight: normal;
      }
    }

    @include c('patient-name') {
      color: var(--neutral-700);
      font-size: 10px;
    }

    @include c('patient-details') {
      @include ellipsis;

      color: var(--neutral-700);
      font-size: 10px;
    }
  }

  @include c('patient-mrn') {
    @include ellipsis;

    flex: 1;
    border-top: solid 1px var(--neutral-300);

    &--hideBorder {
      border-top: none;
    }

    &--selected {
      background-color: var(--cool-neutral-100);
    }

    &::after {
      content: '\200b';
    }
  }

  @include c('patient-gender') {
    border-top: solid 1px var(--neutral-300);

    &--hideBorder {
      border-top: none;
    }

    &--selected {
      background-color: var(--cool-neutral-100);
    }

    &::after {
      content: '\200b';
    }
  }

  @include c('patient-dob') {
    @include ellipsis;

    border-top: solid 1px var(--neutral-300);

    &--hideBorder {
      border-top: none;
    }

    &--selected {
      background-color: var(--cool-neutral-100);
    }

    &::after {
      content: '\200b';
    }
  }

  @include c('added-by') {
    @include ellipsis;

    border-top: solid 1px var(--neutral-300);

    &--hideBorder {
      border-top: none;
    }

    &--selected {
      background-color: var(--cool-neutral-100);
    }

    &::after {
      content: '\200b';
    }
  }

  @include c('added-on') {
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex: 1;
    border-top: solid 1px var(--neutral-300);

    &--hideBorder {
      border-top: none;
    }

    @include c('added-on-text') {
      @include ellipsis;
    }

    &--selected {
      background-color: var(--cool-neutral-100);
    }
  }

  @include c('close-button-container') {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--patient-network-700);
    border-radius: 50%;
    min-height: 20px;
    height: 20px;
    min-width: 20px;
    margin: 0 10px;
    padding: 0;

    &:hover {
      background-color: var(--patient-network-600);
    }

    @include c('close-icon-white') {
      fill: var(--neutral-100);
    }
  }
}
