@import '../../base';

@include b('PatientSearchBox') {
  background-color: var(--neutral-100);
  border-radius: 6px;
  border: solid 1px var(--neutral-300);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.09);
  height: calc(100vh - 300px);
  max-height: 437px;
  min-height: 250px;
  overflow-y: auto;
  position: absolute;
  top: 207px;
  width: 353px;
  z-index: 2;

  @include c('search-results') {
    overflow-y: auto;
  }

  @include c('search-results-loader') {
    width: 100%;
    text-align: center;
    margin: 10px 0;
  }

  @include c('no-search-results') {
    align-items: center;
    color: var(--neutral-700);
    display: flex;
    font-style: italic;
    justify-content: center;
    margin-top: 40px;
  }

  @include c('search-container') {
    display: flex;
    align-items: center;
    background-color: var(--neutral-150);
    border-radius: 18px;
    border: solid 1px var(--neutral-300);
    padding: 0 3px 0 12px;
    height: 28px;
    width: 262px;
    margin: 20px auto;

    @include c('search-text') {
      font-size: 14px;
      height: 19px;
      width: 100%;
      background-color: var(--neutral-150);

      &:focus-visible {
        outline: none;
      }

      input::placeholder {
        color: var(--cool-neutral-700);
      }
    }

    @include c('close-container') {
      background-color: var(--neutral-400);
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      height: 22px;
      justify-content: center;
      align-items: center;
      width: 22px;

      svg {
        height: 8px;
        width: 8px;
        fill: var(--cool-neutral-700);
      }

      &--hide {
        visibility: hidden;
      }
    }
  }
}
