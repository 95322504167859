@import '../base';

@include b('GroupMessageRecipient') {
  display: flex;
  align-items: center;
  border-bottom: solid 1px var(--neutral-200);
  padding: 2px 0;
  font-size: 14px;

  @include m('isFirst') {
    border-top: solid 1px var(--neutral-200);
  }

  @include c('user-info') {
    display: flex;
    align-items: center;
    margin-right: auto;
    font-weight: bold;
    color: var(--neutral-900);

    @include c('avatar') {
      margin-right: 1vw;
      font-weight: normal;
    }

    @include c('user-name') {
      display: block;

      & + div {
        font-weight: normal;
      }
    }
  }

  @include c('details') {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  /* Dark Mode TODO: */
  @include c('status') {
    color: var(--message-status-sent);
    margin-bottom: 0.25vh;

    &--read {
      color: var(--message-status-read);
    }

    &--isReaction {
      color: #5fb5ff;
    }
  }

  @include c('time') {
    color: var(--neutral-900);
    font-size: 12px;
  }
}
