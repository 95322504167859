@import '../../base';

@include b('RoleStatus') {
  display: flex;
  flex: 1;
  flex-direction: row;

  @include c('role-name') {
    @include ellipsis;

    flex: 1;
    flex-shrink: 0;
    text-align: right;
    font-weight: 300;
    font-size: 11px;
    color: var(--neutral-800);
  }

  @include c('tag-indicator') {
    flex-shrink: 0;
    width: 8px;
    height: 8px;
    margin: 3px 0 0 5px;
    border-radius: 6.4px;

    @include if-ie {
      margin-top: 4px;
    }
  }
}
