@import '../../base';

@include b('ConversationList') {
  flex: 1 1 auto;
  position: relative;
  display: flex;

  @include c('fade') {
    position: absolute;
    left: 0;
    top: 0;
    height: 15px;
    width: 350px;
    background:
      linear-gradient(
        to bottom,
        var(--cool-neutral-200),
        var(--cool-neutral-200) 73%,
        rgba(255, 255, 255, 0.001)
      );
    border-right: 1px solid var(--neutral-300);
  }

  @include c('conversations') {
    flex: 1 1 auto;
    display: flex;
  }

  @include c('placeholder-container') {
    @include center-items-both;

    flex: 1;
  }

  @include c('ie11-list') {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }

  @include c('dots-container') {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }

  @include c('forum-indicator') {
    padding: 7px 25px 7px 36px;

    // color: #8d8c91;
    color: var(--neutral-700);
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0.4px;
  }

  @include c('team-filler') {
    padding: 7px 25px 7px 36px;
    background: var(--cool-neutral-200);
  }

  @include c('list-item') {
    cursor: pointer;
    opacity: 1;
    transition: opacity 40ms;

    @include avatar-indicator('PresenceIndicatorAvailable', var(--cool-neutral-200));
    @include avatar-indicator('PresenceIndicatorDnd', var(--cool-neutral-200));
    @include avatar-indicator('RoleIndicator', var(--cool-neutral-200));

    &--isScrolling {
      pointer-events: none !important;
    }

    &--sectionSeparator {
      border-bottom: 10px solid var(--neutral-100);

      @include b('ConversationItemDetails') {
        border-bottom: none;
      }
    }

    &--sectionSeparatorPatient {
      border-bottom: 10px solid var(--cool-neutral-200);
    }

    &--preview {
      opacity: 0.9;
    }
  }
}
