@import '../../base';
@import './PatientSettingMixins';

@include b('TemplatesRow') {
  @include list-row;

  &:focus-visible {
    border: 3px solid var(--secondary-500);
    padding: 0;
  }

  @include c('list-item') {
    @include ellipsis;
    @include list-item-row;

    @include c('template-title') {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: auto;
    }

    @include c('list-item-updated-by') {
      color: var(--neutral-950);
      font-size: 11px;
    }
  }
}
