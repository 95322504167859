@import '../../base';

@include b('DndBlocker') {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  @include c('blocker-container') {
    position: relative;
    width: 100%;
    height: 100%;
  }

  @include c('bg') {
    width: 100%;
    height: 100%;
    background-color: var(--neutral-300);
    opacity: 0.5;
  }

  @include c('details-container') {
    @include center-items-both;

    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  @include c('details') {
    @include center-items-both;

    position: relative;
    padding: 6px;
    width: 330px;
    height: 32px;
    border-radius: 15px;
    font-size: 12px;
    color: var(--neutral-800);
    box-shadow: 0 2px 4px 0 rgba(var(--neutral-500-rgb), 0.5);
    background-color: var(--neutral-100);
  }
}
