@import '../../base';

@include b('BasicContextMenu') {
  @include c('menu-item') {
    font-size: 14px !important;
    width: 100px !important;
    padding: 0 !important;

    span {
      display: flex;
      justify-content: space-between;
    }
  }
}
