@import '../../base';

@include b('ConversationHeader') {
  width: 100%;

  @include m('distributionList') {
    display: flex;
  }

  @include m('addedMargin') {
    margin-bottom: 10px;
  }

  @include m('escalated') {
    height: 60px;
  }

  @include b('AvatarImage') {
    margin-right: 8px;
    flex: 42px 0 0;
    height: 42px;

    &--escalation {
      flex: 52px 0 0;
      height: 52px;
    }
  }

  @include c('user-details') {
    flex: 1;
    display: flex;
    flex-direction: column;
    font-weight: 500;

    @include c('loader') {
      font-size: 14px;
    }
  }

  @include c('group-details') {
    flex: 1;
    display: flex;
    font-weight: 500;

    &--isEscalated {
      font-size: 14px;
      font-weight: 600;
    }
  }

  @include c('escalation-details') {
    margin-top: 4px;
    font-size: 13px;
    font-weight: 500;

    @include m('status', 'ACKNOWLEDGED') {
      color: var(--escalation-100);
    }

    @include m('status', 'CANCELLED') {
      color: var(--errors-700);
    }

    @include m('status', 'IN_PROGRESS') {
      color: var(--orange-500);
    }

    @include m('status', 'NO_RESPONSE') {
      color: var(--neutral-900);
    }
  }

  @include c('extra-details') {
    display: block;
  }

  @include c('group-name') {
    display: flex;
    color: var(--neutral-900);
    align-items: center;
    overflow: visible;

    @include if-ie {
      .tc-GroupName {
        flex-shrink: 0;
      }
    }

    &--isPatientCare {
      font-size: 18px;
    }

    &--canMakeCallIsDisabled {
      padding-right: 30px;
    }
  }

  @include c('patient-details') {
    color: var(--neutral-700);
    font-size: 11px;
    font-weight: 200;
  }

  @include c('auto-forward-details') {
    margin-top: 10px;
    padding: 6px;
    background-color: var(--cool-neutral-300);
    border: solid 1px var(--cool-neutral-400);
    border-radius: 6px;
    font-size: 14px;
    color: var(--scheduling-primary-100);
    text-align: center;
    letter-spacing: 0.8px;

    @include c('display-name') {
      font-weight: 600;
    }
  }

  @include c('user-status') {
    font-size: 75%;
    color: var(--neutral-400);
    font-weight: normal;
    margin-left: 7px;
    display: inline-block;
    white-space: nowrap;
  }

  @include m('user') {
    display: flex;
    flex-direction: row;
    align-items: center;

    @include c('basic-details') {
      display: flex;
      align-items: center;
    }

    @include c('user-status') {
      font-size: 14px;
      color: var(--neutral-700);
      margin-left: 0;
    }
  }

  @include c('user-username') {
    @include ellipsis;

    display: block;
    font-size: 75%;
    color: $dimmed-text-color;
    font-weight: normal;
    margin-top: 3px;
  }

  @include m('role') {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 30px;

    @include c('role-titles') {
      display: flex;
      align-items: center;
      flex-basis: auto;

      @include c('role-name') {
        margin: 0 5px 0 0;
        font-size: 16px;
        font-weight: 600;

        @include if-ie {
          flex-shrink: 0;
        }
      }

      @include c('tag-indicator') {
        display: inline-block;
        flex-shrink: 0;
        width: 6px;
        height: 6px;
        margin: 12px 10px 9px;
        border-radius: 6.4px;
      }

      @include c('tag-name') {
        @include ellipsis;

        color: var(--neutral-700);
        flex: 1;
        font-size: 14px;
        padding-top: 2px;

        @include if-ie {
          flex: 1 1 auto;
        }
      }
    }

    @include c('basic-details') {
      line-height: 14px;
    }

    @include c('extra-details') {
      line-height: 10px;
      display: flex;
      flex-direction: column;
    }

    @include b('AvatarImage') {
      margin: 5px 17px 0 0;
      flex: 50px 0 0;
      height: 50px;
    }

    @include c('on-duty') {
      color: var(--success-500);
      font-size: 12px;
      line-height: 14px;
      margin-top: 5px;
      white-space: nowrap;
    }

    @include c('no-on-duty') {
      font-size: 12px;
      color: var(--errors-500);
      white-space: nowrap;
      font-weight: 600;
    }

    @include c('user-status') {
      margin-left: 0;
      color: var(--neutral-700);
      font-size: 10px;
      line-height: 12px;
    }

    @include c('user-title') {
      color: var(--neutral-700);
      font-size: 11px;
      margin-top: 0;
      line-height: 12px;
      white-space: nowrap;
      font-weight: normal;
    }

    @include b('UserName') {
      font-size: 14px;
      font-weight: 550;
      display: inline;
    }
  }

  @include m('group') {
    display: flex;
    align-items: center;

    @include c('group-members') {
      margin-top: 3px;
      display: block;

      @include b('AvatarImage') {
        margin-right: 6px;
      }
    }

    @include c('group-members') {
      font-weight: normal;
      cursor: default;
    }

    @include c('basic-details') {
      display: flex;
      align-items: flex-end;
    }

    @include c('tag-details') {
      display: flex;
      align-items: center;
      flex: 1;
      padding-top: 4px;

      @include if-ie {
        flex-basis: auto;
        flex-shrink: 6;
      }

      @include c('tag-indicator') {
        display: inline-block;
        flex-shrink: 0;
        width: 6px;
        height: 6px;
        margin: 0 10px 5px;
        border-radius: 6.4px;
      }

      @include c('tag-name') {
        @include ellipsis;

        color: var(--neutral-700);
        font-size: 14px;
        padding-bottom: 4px;
      }
    }
  }

  @include c('group-patient-reference-card') {
    margin-top: 10px;
  }

  @include b('UserName') {
    @include ellipsis;
  }

  @include c('phone-icon') {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    width: 75px;

    &--isPatientCare {
      margin-bottom: 3px;
    }
  }
}
