@import '../../../base';

@include b('UploadPatientsCsvModal') {
  width: 960px;
  color: var(--neutral-950);

  @include c('header') {
    @include common-modal-header;

    color: var(--neutral-950);
  }

  @include c('body') {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin: 25px 32px 100px;

    &--appointmentReminders {
      margin: 25px 32px;
    }
  }

  @include c('upload-button-container') {
    border-radius: 3px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.09);
    border: solid 1px var(--neutral-300);
    display: flex;
    padding: 20px 30px 20px 20px;
    margin: auto;
    cursor: pointer;

    &:hover {
      background-color: var(--neutral-100);
    }

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
    }
  }

  @include c('upload-button-input') {
    display: none;
  }

  @include c('column-one') {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  @include c('column-two') {
    align-self: center;
  }

  @include c('first-row') {
    font-weight: 600;
    color: var(--patient-network-700);
  }

  @include c('second-row') {
    padding-top: 10px;
  }

  @include c('upload-text') {
    padding-top: 10px;
    display: inline-block;
  }

  @include c('upload-text-link') {
    font-weight: 600;
    color: var(--secondary-500);
    margin: 0 5px;
    text-decoration: none;
    cursor: pointer;

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
    }
  }

  @include c('button-icon-path') {
    fill: var(--patient-network-700);
    width: 22px;
    height: 22px;

    g {
      stroke: var(--patient-network-700);
    }
  }

  @include c('error') {
    height: 30px;
    margin-bottom: 10px;
  }

  @include c('error-message') {
    background-color: var(--errors-300);
    border: solid 1px var(--errors-500);
    border-radius: 6px;
    color: var(--neutral-100);
    margin: auto;
    padding: 3px;
    text-align: center;
  }

  @include c('timezone-settings') {
    border-top: 1px solid var(--neutral-300);
    margin: 40px -42px 0;
    padding: 15px 42px 10px;

    @include c('first-row') {
      margin-bottom: 5px;
    }
  }

  @include c('timezone-menu-btn') {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 28px;
    border: solid 1px var(--cool-neutral-100);
    border-radius: 5px;
    padding: 6px 10px;
    cursor: pointer;
    color: var(--neutral-1000);
    font-size: 14px;
    font-weight: 500;

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
    }
  }
}
