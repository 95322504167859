@import '../../base';

@include b('CallModal') {
  border-radius: 10px !important;
  font-size: 16px;
  text-align: center;

  @include c('header') {
    height: 60px;
  }

  @include c('close-button') {
    @include common-modal-close-button;

    right: 20px;
    top: 20px;
  }

  @include c('body') {
    color: var(--neutral-800);
    margin-bottom: 30px;
  }

  @include c('body-no-margin') {
    color: var(--neutral-800);
  }

  @include c('enable-text') {
    margin-top: 40px;
    font-size: 15px;
  }

  @include c('unsupported-body') {
    color: var(--neutral-800);
    margin: 0 50px 50px;
  }

  @include c('title') {
    font-weight: 600;
    color: var(--neutral-1000);
    padding-bottom: 8px;

    @include c('displayName') {
      color: var(--secondary-500);
    }
  }

  @include c('call-button-text') {
    margin-left: 10px;
  }

  @include c('browser') {
    color: var(--success-500);
    font-weight: 700;
    text-decoration: none;
  }

  @include c('spinner') {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
  }

  @include c('call-button') {
    display: flex;
    width: 319px;
    height: 56px;
    color: var(--patient-network-700);
    background-color: var(--neutral-200);
    box-shadow: 0 0 3 0 rgba(0, 0, 0, 0);
    transition: box-shadow 0.25s;
    margin: 20px auto;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    position: relative;

    &:hover {
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.23);
    }

    &[disabled] {
      box-shadow: none !important;
      cursor: not-allowed;
    }
  }

  @include c('footer') {
    @include common-modal-footer;

    height: 85px;
    position: relative;
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    font-weight: 500;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-clip: padding-box;
    margin-top: 20px;
  }

  @include c('primary-btn') {
    @include common-button(var(--secondary-500), 124px, 38px, 11px);
  }

  @include c('secondary-btn') {
    @include common-button(var(--cool-neutral-700), 124px, 38px, 11px);

    margin-right: 8px;
  }
}
