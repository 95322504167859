@import '../../base';

@include b('TimeInputSelect') {
  @include c('time-menu-btn') {
    @include schedule-message-context-menu-btn(125px);

    margin-right: 12px;

    &--isInvalidTime {
      box-shadow: 0 0 0 3px rgba(255, 5, 5, 0.25);
    }

    input {
      font-size: 13px;
      color: var(--neutral-1000);
      width: 80px;

      &::placeholder {
        color: var(--cool-neutral-800);
        font-weight: normal;
      }

      &:focus-visible {
        outline: 3px solid var(--secondary-500);
      }
    }

    svg {
      width: 13px;
    }
  }

  @include c('time-error') {
    color: var(--primary-700);
    font-size: 12px;
    height: 0;
    left: 10px;
    overflow: visible;
    position: relative;
    top: -21px;
  }
}

@include b('TimeInputSelectDropdown') {
  @include schedule-message-context-menu-dropdown(125px);
}
