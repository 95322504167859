$bem-use-namespace: false !default;
$bem-reset: false !default;
$current-block-stack: () !global;

@for $i from 1 through 1000 {
  $current-block-stack: append($current-block-stack, null) !global;
}

$current-block-stack-index: 0 !global;

@function current-block-config() {
  @return nth($current-block-stack, $current-block-stack-index);
}

@function current-block-name() {
  @return map-get(current-block-config(), 'block-name');
}

@function current-block-promote() {
  @return map-get(current-block-config(), 'promote');
}

@function current-block-namespace() {
  @return map-get(current-block-config(), 'namespace');
}

@mixin block($block-name, $namespace: $bem-use-namespace, $reset: $bem-reset, $promote: true) {
  $current-block-stack-index: $current-block-stack-index + 1 !global;

  @if $current-block-stack-index > 1 {
    $promote: false;
  }

  $config: (
    'block-name': $block-name,
    'promote': $promote,
    'namespace': $namespace
  );
  $current-block-stack: set-nth($list: $current-block-stack, $n: $current-block-stack-index, $value: $config) !global;

  // apply reset only for root level
  @if $current-block-stack-index == 1 and $reset {
    #{block-selector($block-name, $namespace: $namespace, $promote: false)} {
      @include reset;
    }
  }

  #{block-selector($block-name, $namespace: $namespace, $promote: $promote)} {
    @content;
  }

  $current-block-stack-index: $current-block-stack-index - 1 !global;
}

@mixin block-child($child-name, $promote: false, $pseudo: null) {
  @if type-of($child-name) != 'list' {
    $child-name: ($child-name);
  }

  $sel: '';

  @each $child-name-item in $child-name {
    $current-selector: block-child-selector(
      $block-name: current-block-name(),
      $block-namespace: current-block-namespace(),
      $child-name: $child-name-item,
      $block-promote: current-block-promote(),
      $child-promote: $promote,
      $pseudo: $pseudo
    );
    $sel: '#{$sel}#{$current-selector},';
  }

  #{$sel} {
    @content;
  }
}

@mixin block-override($args...) {
  @include block($args...) {
    @content;
  }
}

@mixin blocks-override($block-names, $promote: true) {
  @each $block-name in $block-names {
    @include block($block-name, $promote: $promote) {
      @content;
    }
  }
}

@mixin block-mod($mod-name, $mod-value: null, $promote: false, $pseudo: null, $op: 'or') {
  @if type-of($mod-name) == 'string' {
    $tmp: (null);
    $tmp: set-nth($tmp, 1, ($mod-name, $mod-value));
    $mod-name: $tmp;
  }

  $sel: null;

  @each $mod-item in $mod-name {
    $mod-name-item: nth($mod-item, 1);
    $mod-value-item: null;

    @if length($mod-item) > 1 {
      $mod-value-item: nth($mod-item, 2);
    }

    $current-selector: block-mod-selector(
      $block-name: current-block-name(),
      $block-namespace: current-block-namespace(),
      $mod-name: $mod-name-item,
      $mod-value: $mod-value-item,
      $block-promote: current-block-promote(),
      $mod-promote: $promote,
      $pseudo: $pseudo
    );
    $sel: '#{$sel}#{&}#{$current-selector}';

    @if $op != 'and' {
      $sel: '#{$sel},';
    }
  }

  @at-root #{$sel} {
    @content;
  }
}

@function block-selector($block-name, $namespace: null, $promote: false) {
  $sel: '.';

  @if $namespace {
    $sel: '#{$sel}#{$namespace}-';
  }

  $sel: '#{$sel}#{$block-name}';

  @if $promote {
    $sel: '#{$sel}[class]';
  }

  @return $sel;
}

@function block-child-selector($block-name, $block-namespace, $child-name, $child-promote: false, $block-promote: true, $pseudo: null) {
  $sel: '#{block-selector($block-name, $block-namespace, false)}__#{$child-name}';

  @if $child-promote {
    $sel: '#{$sel}[class]';
  }

  @if $pseudo {
    $sel: '#{$sel}#{$pseudo}';
  }

  @return $sel;
}

@function block-mod-selector($block-name, $block-namespace, $mod-name, $mod-value: null, $mod-promote: false, $block-promote: true, $pseudo: null) {
  $sel: '#{block-selector($block-name, $block-namespace, false)}--#{$mod-name}';

  @if $mod-value {
    $sel: '#{$sel}--#{$mod-value}';
  }

  @if $mod-promote {
    $sel: '#{$sel}[class]';
  }

  @if $pseudo {
    $sel: '#{$sel}#{$pseudo}';
  }

  @return $sel;
}

@function current-block-child-selector($child-name) {
  @return block-child-selector(current-block-name(), current-block-namespace(), $child-name, $block-promote: false);
}

@function current-block-mod-selector($child-name) {
  @return block-mod-selector(current-block-name(), current-block-namespace(), $child-name, $block-promote: false);
}

@mixin b($args...) {
  @include block($args...) {
    @content;
  }
}

@mixin m($args...) {
  @include block-mod($args...) {
    @content;
  }
}

@mixin c($args...) {
  @include block-child($args...) {
    @content;
  }
}
