@import '../../../base';

@include b('AlertsFilterList') {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;

  @include m('showDnd') {
    margin-top: 10px;
  }

  @include c('alert-conversation-items') {
    border-bottom: 1px solid var(--neutral-300);
    flex: 0 0 auto;
    color: var(--neutral-900);
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    height: 52px;
    justify-content: space-between;
    padding: 17px 0 20px 32px;

    &:hover {
      background: var(--cool-neutral-300);
    }

    &--activeItem {
      background: var(--cool-neutral-300);
    }

    @include c('unread-count-wrapper') {
      padding-right: 17px;
    }
  }
}
