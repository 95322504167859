@import '../base';

@include b('PatientUserSettings') {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--neutral-150);

  @include c('header') {
    background-color: var(--neutral-100);
    border-bottom: solid 1px var(--neutral-300);

    @include c('name-container') {
      text-align: center;
      padding: 21px 0;

      @include c('title') {
        font-size: 18px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.02px;
        color: var(--neutral-1000);
      }

      @include c('name') {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.01px;
        color: var(--neutral-700);
      }
    }

    @include c('close-container') {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  @include c('content') {
    display: flex;
    flex-direction: row;
    flex: 1 0 auto;
    justify-content: center;
  }

  @include c('body') {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @include c('items') {
    width: 500px;
  }
}
