@import '../../base';

@include b('RosterFilterMenu') {
  li {
    height: 28px;
  }

  @include c('list-item') {
    > span {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 28px;
      padding-right: 0;

      &--is-priority {
        background-color: $primary-brand;
        color: $white;
        border-radius: 24px;
      }
    }

    @include b('UnreadBadgeCount-Button') {
      height: 16px;

      @include c('priority-button') {
        width: 16px;
        height: 16px;
      }
    }
  }

  @include c('unread-count') {
    top: -2px;
    margin-left: 4px;
    font-weight: 400;
  }
}
