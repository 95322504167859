@import '../../base';

@include b('CollaborationInlineModal') {
  width: 100%;

  @include c('inline-modal') {
    background-color: var(--neutral-100);
    border-radius: 4px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
    position: relative;
    text-align: center;
    margin: 0 auto;
    max-width: 428px;
    padding: 48px;

    &--isTag {
      margin: 15px;
      padding: 20px;
    }
  }

  @include c('inline-header') {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 6px;
  }

  @include c('body-text') {
    font-size: 14px;
    line-height: 1.4;
  }

  @include c('button') {
    cursor: pointer;
    font-weight: normal;
    line-height: 40px;
    text-align: center;
    background-color: var(--secondary-500);
    color: var(--neutral-100);
    border-radius: 20px;
    display: inline-block;
    margin-top: 25px;
    padding: 0 36px;
    width: initial;
  }

  @include c('button-dismiss-container') {
    display: block;
    height: 28px;
    text-align: right;
  }

  @include c('dismiss-button') {
    cursor: pointer;
    display: inline-block;
    height: 28px;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 28px;
  }
}
