@import '../../base';

@include b('CalleePermissionsModal') {
  @include c('header') {
    @include common-modal-header;

    margin-bottom: 30px;
  }

  @include c('close-button') {
    @include common-modal-close-button;
  }

  @include c('body-container') {
    @include common-modal-body;

    display: flex;
    flex-direction: column;
    text-align: center;

    @include c('description-container' s) {
      color: var(--neutral-800);
      margin: 10px 50px 50px;
    }
  }

  @include c('ok-btn') {
    @include common-button(var(--secondary-500), 124px, 38px, 11px);
  }

  @include c('cancel-btn') {
    @include common-button(var(--cool-neutral-700), 124px, 38px, 11px);

    margin-right: 8px;
  }
}
