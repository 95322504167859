body .personaForm {
  display: flex;
  flex-direction: column;
}

.personaTable .createButton {
  margin: 10px 0;
}

.personaForm .personaLabel {
  float: left;
  font-size: 14px;
  font-weight: 550;
  margin: 0;
  padding-top: 14px;
  text-align: left;
  width: 100px;
}

.personaForm .personaRoot {
  display: flex;
  margin: 0 0 5px;
  flex-direction: row;
  align-content: center;
}

.personaForm .personaButtons {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;
  padding: 20px 0 30px;
  text-align: center;
}

.personaForm .personaHeader {
  float: left;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 0;
}

.personaForm .personaHr {
  margin: 15px 0;
  border: 1px solid var(--neutral-300);
  margin-bottom: 20px;
}

.personaForm .personaCreate {
  margin-right: 10px;
}

.personaForm .personaCancel {
  margin-left: 10px;
}

.personaForm .errorLabel {
  color: var(--primary-500);
  font-size: 12px;
  font-weight: 550;
  align-self: center;
  width: 100px;
}

.personaForm .personaChild {
  display: flex;
  margin: 12px;
}

.personaForm .loadingHolder {
  display: flex;
  justify-content: center;
  width: 100%;
}
