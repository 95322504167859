@import '../base';

@include b('TeamProfile') {
  width: 100%;
  height: 100%;
  overflow: auto;

  @include c('team-card') {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 60px auto 0;
    width: 416px;
    height: 490px;
    border-radius: 12px;
    background-color: #f7f7f7;
    margin-bottom: 17px;

    @include c('team-container') {
      position: relative;
      height: 400px;

      @include c('team-details') {
        flex: 1 1 auto;
        padding: 20px;

        @include c('team-header') {
          display: flex;
          align-items: center;
          margin-bottom: 40px;

          @include c('team-avatar') {
            font-size: 9px;
          }

          @include c('team-name') {
            @include ellipsis;

            max-width: 250px;
            margin-left: 7px;
            font-size: 22px;
            font-weight: 500;
            color: #4d4d4d;
          }

          @include c('edit-button') {
            position: absolute;
            top: 20px;
            right: 20px;
            color: #3080df;
            font-size: 14px;
            cursor: pointer;
          }
        }

        @include c('team-description') {
          word-break: break-word;
          font-size: 12px;
          color: #4d4d4d;
          margin-bottom: 30px;

          @include c('description-label') {
            font-weight: 500;
            color: #515151;
            margin-bottom: 5px;
          }
        }

        @include c('team-members') {
          @include c('label-container') {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 13px;

            @include c('members-label') {
              font-size: 12px;
              font-weight: 500;
              color: #515151;
            }

            @include c('team-membership-status') {
              font-weight: 400;
              font-size: 12px;

              &--status--declined {
                color: #f7f7f7;
                border-radius: 8px;
                background-color: #db524b;
                padding: 1px 0;
                padding-left: 8px;
                cursor: pointer;
              }

              &--status--request {
                color: #3080df;
                cursor: pointer;
              }

              &--status--pending {
                color: #787878;
              }

              &--status--member {
                color: #cf706c;
                cursor: pointer;

                &:hover {
                  color: #f68782;
                }
              }

              @include c('reset-decline-container') {
                display: inline-block;
                color: #db524b;
                background: #f7f7f7;
                border-radius: 100%;
                height: 12px;
                width: 12px;
                vertical-align: middle;
                text-align: center;
                margin-left: 5px;
                margin-right: 5px;
                margin-top: -2px;

                @include c('reset-decline-x') {
                  margin-top: -3px;
                  margin-left: 1px;
                }
              }
            }
          }

          @include c('user-container') {
            display: flex;
            align-items: center;
            padding: 7px 0;

            @include c('user-details') {
              display: flex;
              flex-direction: column;
              margin-left: 18px;
              color: #959595;
              font-size: 10px;

              @include c('user-name') {
                font-size: 14px;
                font-weight: 500;
                color: #4d4d4d;
              }

              @include c('user-title') {
                font-size: 12px;
                color: #9b9b9b;
              }
            }
          }
        }
      }
    }

    @include c('button-container') {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 94px;
      width: 100%;
      background: #f7f7f7;
      border-radius: 0 0 13px 13px;

      &--dropShadow {
        box-shadow: 0 -10px 10px -10px rgba(0, 0, 0, 0.16);
      }

      @include c('action-button') {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 147px;
        height: 31px;
        border-radius: 21.5px;
        background-color: #3080df;
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        cursor: pointer;
        user-select: none;

        &--isLoading {
          cursor: default;
          pointer-events: none;
        }

        &:hover {
          background-color: #0075ff;
        }

        @include if-ie {
          padding-bottom: 2.5px;
        }
      }
    }
  }

  @include c('delete-team-container') {
    width: 80px;
    margin: 0 auto;
    margin-bottom: 30px;
    text-align: center;

    @include c('delete-team-text') {
      cursor: pointer;
      font-size: 12px;
      font-weight: 400;
      color: #a0a0a0;

      &:hover {
        color: #db524b;
      }
    }
  }
}
