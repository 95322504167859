@import '../../base';

@include b('RosterFilterBar') {
  width: 100%;
  flex: 0 0 auto;

  @include c('filter') {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 18px 0 2px;
    font-size: 12px;
    font-weight: bold;

    @include c('unread-count') {
      top: -2px;
      margin-left: 5px;
      font-weight: 400;
    }

    @include c('image-container') {
      padding-left: 5px;
      padding-right: 3px;
    }

    @include c('image') {
      width: 7px;
      height: 7px;
    }

    &--spacingBelowFilterBar {
      padding: 18px 0 15px;
    }
  }

  @include c('filter-container') {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
