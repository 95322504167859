@import '../../base';

@include b('AddADSyncModal') {
  @include c('header') {
    @include common-modal-header;

    color: var(--neutral-950);
  }

  @include c('close-button') {
    @include common-modal-close-button;

    color: var(--cool-neutral-800);
  }

  @include c('body') {
    @include common-modal-body;

    padding: 35px 30px 40px;
    text-align: center;
    line-height: 1.4;

    @include c('input-container') {
      text-align: center;

      input {
        width: 215px;
        border: solid 1px var(--cool-neutral-700);
        padding: 4px 6px;
        outline: none;
        border-radius: 2px;
        font-size: 14px;
      }

      input::placeholder {
        color: var(--neutral-600);
      }

      @include c('error-container') {
        color: var(--errors-500);
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        margin-top: 20px;
      }
    }
  }

  @include c('done-btn') {
    @include common-button(var(--neutral-950), 74px, 32px, 11px);

    &--isLoading {
      cursor: default;
      pointer-events: none;
    }

    &--isDisabled {
      cursor: not-allowed;
      opacity: 0.4;
      pointer-events: none;
    }
  }

  @include c('cancel-btn') {
    @include common-button(var(--cool-neutral-700), 74px, 32px, 11px);

    margin-right: 10px;
  }
}
