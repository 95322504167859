@import '../../base';
@import './PatientSettingMixins';

@include b('ScheduledMessagesView') {
  min-width: 900px;

  .DayPickerNavigation_button__default {
    border: 1px solid var(--patient-network-300);
    background-color: var(--neutral-100);
    color: var(--neutral-800);
  }

  .DayPicker_weekHeader {
    color: var(--neutral-1000);
  }

  .CalendarMonth_caption {
    color: var(--neutral-1000);
  }

  @include patient-settings-view;

  @include c('header') {
    margin-bottom: 8px;

    @include flex-align-center;
    @include flex-justify-between;

    @include c('title') {
      @include header-text;
    }

    @include c('show-picker') {
      display: block;
      width: 38px;
      height: 38px;
      border-radius: 50%;
      background-color: var(--patient-network-700);

      @include c('expand-icon-svg') {
        width: 36px;
        height: 36px;
        margin-left: 1px;

        svg {
          g {
            circle {
              fill: var(--neutral-100);
              stroke: var(--patient-network-700);
            }

            path {
              fill: var(--patient-network-700);
            }
          }
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }

    @include c('outer-container') {
      position: relative;

      @include c('inner-container') {
        position: absolute;
        border-radius: 6px;
        background: var(--neutral-100);
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.24);
        display: block;
        right: 0;
        top: 10px;
        height: 540px;
        width: 321px;
        z-index: 1;
      }
    }
  }

  @include c('title-sect') {
    @include c('title-buttons') {
      color: var(--cool-neutral-800);
      font-size: 14px;
      font-weight: 500;
      padding-bottom: 10px;
      padding-right: 10px;

      &--selected {
        border-bottom: solid 1px var(--patient-network-700);
        color: var(--patient-network-700);
      }
    }

    @include c('filler') {
      border-top: solid 1px var(--neutral-300);
      flex: 1;
      margin-top: -1px;
    }
  }

  @include c('actions') {
    margin: 20px 0;

    @include flex-justify-between;

    @include c('actions-left') {
      display: flex;

      @include c('create') {
        @include pa-primary-button;
      }

      @include c('filters') {
        display: flex;
        margin-left: 15px;
      }
    }

    @include c('actions-right') {
      @include c('actions-right-button') {
        @include bulk-delete;
      }
    }
  }

  @include c('filtered-by') {
    color: var(--patient-network-700);
    font-size: 12px;
    line-height: 28px;
    margin: -10px 0 20px;

    @include flex-align-center;

    @include c('filtered-range') {
      background-color: var(--neutral-200);
      border-radius: 6px;
      margin-left: 11px;
      padding: 0 11px;

      button {
        margin-left: 8px;
      }
    }
  }

  @include c('loading-more') {
    @include loading-more;
  }
}
