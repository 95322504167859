@import '../../base';
@import './PatientSettingMixins';

@include b('ScheduledMessagesTable') {
  flex: 1;
  height: 100%;

  @include c('list') {
    @include table-wrap;

    @include c('list-header') {
      @include table-header;

      @include c('list-item') {
        @include list-item-header;

        color: var(--secondary-900);
        display: flex;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        white-space: nowrap;
      }
    }

    @include c('list-body') {
      @include table-body;
    }

    @include c('empty') {
      @include empty;
    }
  }

  @include c('sort-arrow') {
    g[stroke='#4A657B'] {
      stroke: var(--patient-network-700);
    }
  }
}
