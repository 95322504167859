@import '../../base';

@include b('PatientAdminCard') {
  position: absolute;
  left: 28px;
  right: 28px;
  bottom: 11px;
  top: 0;

  @include c('patient-admin-container') {
    background: var(--neutral-100);
    height: 100%;
    max-width: 960px;
    min-width: 630px;
    margin: 4px auto;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
    }

    @include if-ie {
      width: calc(75%);
      align-items: flex-start;
    }
  }

  @include c('patient-contact-container') {
    background: var(--neutral-100);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  @include c('avatar-col') {
    flex: 0 0 64px;
    align-self: baseline;
  }

  @include c('avatar-container') {
    background-color: var(--patient-network-700);
    width: 26px;
    height: 26px;
    border-radius: 100%;
    margin-left: 17px;
    margin-top: 12px;

    path {
      fill: var(--neutral-100);
    }

    &--smsOptedOut {
      background-color: inherit;
    }
  }

  @include c('patient-icon') {
    width: 26px;
    height: 26px;
  }

  @include c('detail-col') {
    flex: 1;
  }

  @include c('add-contact-row') {
    height: 40px;
    width: 100%;
    background-color: var(--neutral-150);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    cursor: pointer;

    @include c('add-contact-container') {
      display: flex;
      flex-direction: row;

      @include c('icon') {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        margin: 2px 5px 0 0;
        background: var(--patient-network-700);

        @include c('button-icon-path') {
          path {
            stroke: var(--neutral-100);
          }

          width: 8px;
          height: 8px;
        }
      }

      @include c('add-contact-text') {
        font-size: 14px;
        color: var(--patient-network-700);
      }
    }

    &:focus-visible {
      outline: 3px solid var(--secondary-500);
    }
  }
}
