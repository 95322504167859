@import '../../base';

@include b('ProviderBroadcastListTable') {
  flex: 1;

  @include c('list') {
    border-collapse: collapse;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    @include c('list-header') {
      flex-shrink: 0;
      margin: 0 6px;
      color: var(--neutral-950);
      display: flex;
      font-size: 12px;
      font-weight: 600;
      padding-bottom: 12px;
      text-transform: uppercase;
      border-bottom: solid 1px var(--neutral-300);

      > * {
        flex: 1;
        padding-left: 15px;
        cursor: pointer;

        &:hover {
          @include c('sort-arrow') {
            visibility: visible;
          }
        }
      }

      @include c('list-item-checkbox') {
        flex: none;
        padding: 0;
        text-align: center;
        width: 58px;
      }

      @include c('list-item-label') {
        flex: 1.5;
        padding-left: 0;
      }

      @include c('sort-arrow') {
        margin-left: 5px;
        margin-bottom: -7px;
        transform: rotate(360deg);
        height: 22px;
        width: 22px;
        visibility: hidden;
        cursor: pointer;

        &--isDesc {
          transform: rotate(180deg);
        }

        &--sortBy {
          visibility: visible;
        }
      }

      &--isPregen {
        > * {
          flex: 1;
          padding-left: 15px;
          cursor: initial;

          &:hover {
            @include c('sort-arrow') {
              visibility: hidden;
            }
          }
        }

        @include c('sort-arrow') {
          &--sortBy {
            visibility: hidden;
          }
        }
      }
    }

    @include c('lists-container') {
      overflow-y: auto;

      @include c('pending-members') {
        border: 1px solid var(--secondary-500);
        padding: 0 6px;
        background-color: var(--cool-neutral-300);
        border-radius: 2px;
        padding-top: 5px;
        margin-bottom: 10px;

        @include c('save-cancel-buttons') {
          display: flex;
          justify-content: flex-end;
          margin-bottom: 5px;

          @include c('save-button') {
            background-color: var(--secondary-500);
            border-radius: 6px;
            color: var(--neutral-100);
            font-size: 12px;
            font-weight: 600;
            height: 30px;
            letter-spacing: -0.01px;
            margin-right: 6px;
            width: 104px;

            &:hover {
              background-color: var(--secondary-900);
            }

            &:disabled {
              background-color: var(--neutral-100);
              color: var(--neutral-300);
              cursor: auto;
            }
          }

          @include c('cancel-button') {
            background-color: var(--neutral-100);
            border-radius: 6px;
            border: solid 0.9px var(--secondary-700);
            color: var(--secondary-700);
            font-size: 12px;
            font-stretch: normal;
            font-style: normal;
            font-weight: 600;
            height: 30px;
            letter-spacing: -0.01px;
            line-height: normal;
            margin-right: 10px;
            text-align: center;
            width: 104px;

            &:hover {
              background-color: var(--primary-300);
              border: solid 1px var(--primary-300);
              color: var(--neutral-100);
            }
          }
        }
      }

      @include c('members') {
        padding: 0 6px;
      }

      @include c('members-group') {
        border: 1px solid var(--neutral-400);

        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }

    @include c('empty-broadcastList') {
      color: var(--neutral-800);
      font-size: 15px;
      font-style: italic;
      height: 20px;
      margin-left: 30%;
      margin-top: 10%;
      text-align: center;
      width: 443px;

      &--isSecurityGroupPresent {
        display: flex;
        justify-content: center;
        margin-left: 20%;
        width: 600px;
      }
    }

    @include c('pregen-broadcastList') {
      width: inherit;
      text-align: center;
      padding: 14px 0;
      border-radius: 2px;
      border: solid 1px var(--neutral-400);
      font-size: 14px;
      color: var(--neutral-1000);

      @include c('pregen-broadcastList-text') {
        width: 530px;
        margin: 0 auto;
      }
    }
  }
}
