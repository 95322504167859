@mixin patient-settings-view {
  background-color: var(--neutral-100);
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px 70px 0 30px;
  position: relative;
  width: 100%;
}

@mixin container {
  padding-left: 20px;
  height: 100%;
  width: 100%;
  overflow: auto;
}

@mixin table-wrap {
  border-collapse: collapse;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

@mixin table-header {
  border-bottom: solid 1px var(--neutral-400);
  color: var(--secondary-900);
  display: flex;
  flex: none;
  font-size: 12px;
  font-weight: 600;
  padding-bottom: 8px;
  text-transform: uppercase;

  &--noCheckbox {
    padding-left: 20px;
  }
}

@mixin table-body {
  overflow-y: auto;
  padding-bottom: 20px;
}

@mixin flex-justify-between {
  display: flex;
  justify-content: space-between;
}

@mixin flex-align-center {
  align-items: center;
  display: flex;
}

@mixin fieldset {
  margin-top: 11px;
  max-width: 460px;
}

@mixin header-text {
  color: var(--neutral-950);
  font-size: 22px;
  text-transform: capitalize;
}

@mixin tab-heading {
  line-height: 65px;

  @include header-text;
}

@mixin pa-primary-button {
  background-color: var(--patient-network-700);
  border-radius: 6px;
  color: var(--neutral-100);
  font-size: 13px;
  line-height: 28px;
  padding: 0 15px;

  &:hover {
    background-color: var(--patient-network-600);
  }
}

@mixin bulk-delete {
  height: 22px;
  width: 68px;
  border-radius: 15px;

  @include flex-align-center;

  @include c('delete-icon') {
    vertical-align: sub;
    width: 20px;
  }

  @include c('delete-icon-text') {
    color: $patient-color;
    font-size: 13px;
    font-weight: 600;
    height: 10px;
    width: 41px;
  }

  &:hover {
    background-color: var(--errors-50);

    @include c('delete-icon') {
      path {
        fill: var(--errors-500);
      }
    }

    @include c('delete-icon-text') {
      color: var(--errors-500);
      fill: var(--errors-500);
    }
  }
}

@mixin list-item {
  cursor: pointer;
  flex: 2;
  padding-right: 15px;
  font-size: 13px;
  display: flex;

  &--checkbox {
    flex: none;
    padding: 0;
    width: 58px;
    text-align: center;
  }

  &--one {
    flex: 1;
  }

  &--two {
    flex: 2;
  }

  &--three {
    flex: 3;
  }

  &--four {
    flex: 4;
  }
}

@mixin list-row {
  align-items: center;
  border: solid 1px var(--neutral-400);
  border-top: none;
  color: var(--neutral-1000);
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 50px;
  position: relative;
  padding: 0 2px;

  &:hover {
    background-color: var(--cool-neutral-100);
  }

  @include m('noCheckbox') {
    padding-left: 22px;

    &:focus-visible {
      padding-left: 20px;
    }
  }

  @include m('selected') {
    background-color: var(--cool-neutral-300);
  }

  @include m('smsOptedOut') {
    &::after {
      background: rgba(var(--neutral-100-rgb), 0.5);
      bottom: 0;
      content: '';
      height: 100%;
      position: absolute;
      width: 100%;
    }

    &:hover {
      background-color: inherit;
    }
  }
}

@mixin list-item-header {
  @include list-item;

  @include c('sort-arrow') {
    margin-left: 5px;
    margin-top: -5px;
    height: 22px;
    width: 22px;
    visibility: hidden;

    &--isDesc {
      transform: rotate(180deg);
    }

    &--sortBy {
      visibility: visible;
    }
  }

  &:hover,
  &:focus-visible {
    @include c('sort-arrow') {
      visibility: visible;
    }
  }
}

@mixin list-item-row {
  @include ellipsis;
  @include list-item;

  &--checkbox {
    align-items: center;
    cursor: default;
    display: flex;
    flex: 0 58px;
    height: 100%;
    justify-content: center;
    padding: 0;

    @include c('list-item-checkbox-default') {
      cursor: pointer;
      width: 14px;
      height: 14px;
      border-radius: 2px;
      border: solid 1px var(--neutral-400);
      position: relative;

      &--selected {
        border: none;

        svg {
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }
}

@mixin input-legend {
  display: block;
  font-weight: 600;
  font-size: 12px;
  color: var(--neutral-1000);
  text-transform: uppercase;
  line-height: 19px;
}

@mixin input-label {
  display: block;
  color: var(--neutral-800);
  font-size: 13px;
  line-height: 18px;
}

@mixin input {
  display: block;
  border: 1px solid var(--neutral-500);
  border-radius: 6px;
  line-height: 32px;
  width: 96%;
  margin-top: 6px;
  margin-bottom: 2px;
  background-color: var(--neutral-150);
  padding: 0 9px;
  height: 32px;
}

@mixin input-placeholder {
  color: var(--cool-neutral-900);
}

@mixin input-error-text {
  display: block;
  color: var(--errors-500);
  font-size: 12px;
}

@mixin button {
  display: inline-block;
  border-radius: 6px;
  cursor: pointer;
}

@mixin loading-more {
  bottom: 10px;
  left: 50%;
  position: absolute;
  visibility: hidden;

  &--isLoadingMore {
    visibility: visible;
  }
}

@mixin empty {
  animation: fadein 0s;
  animation: fadein 1s;
  color: var(--neutral-800);
  font-size: 15px;
  font-style: italic;
  height: 20px;
  margin: 10% auto 0;
  text-align: center;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
