@import '../../base';

@include b('AlertActionButton') {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 120px;
  min-height: 36px;
  border: 1px solid;
  border-radius: 270px;
  margin: 0 0 8px 8px;
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;

  @include m('isGroupAlertsAllowed') {
    @include ellipsis;

    margin: 6px 0 0 6px;
    min-width: 0;
    border-radius: 6px;
    border-color: var(--secondary-500);
    padding: 6px 10px;
    font-size: 16px;
    color: var(--secondary-500);

    &:hover {
      opacity: 1;
      border-color: var(--secondary-600);
      color: var(--secondary-600);
    }
  }

  @include m('shouldFill') {
    background-color: var(--secondary-500);
    color: var(--neutral-100);

    &:hover {
      background-color: var(--secondary-600);
      color: var(--neutral-100);
    }
  }

  &:hover {
    opacity: 0.7;
  }

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.3;
  }
}
