@import '../../base';

@include b('AdminIframe') {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;

  @include b('Admin') {
    height: 100%;
    width: 100%;
  }
}
