@import '../../base';

@include b('PrintMessageDetails') {
  display: flex;
  justify-content: space-between;

  @include c('message-time') {
    color: var(--neutral-700);
    font-size: 12px;

    &--time-left {
      margin-top: -2px;
      color: var(--neutral-700);
    }
  }
}
