@import '../../base';

@include b('Sidebar') {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-right: 1px solid var(--neutral-300);
  background-color: var(--cool-neutral-200);

  @include c('fade') {
    position: absolute;
    left: 0;
    top: 0;
    height: 15px;
    width: 350px;
    background:
      linear-gradient(
        to bottom,
        var(--cool-neutral-200),
        var(--cool-neutral-200) 73%,
        rgba(255, 255, 255, 0.001)
      );
    border-right: 1px solid var(--neutral-300);
  }

  @include c('header') {
    display: block;

    &--flex-layout {
      display: flex;
    }
  }

  @include c('search-container') {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  @include c('roaster-container') {
    width: 100%;

    &--narrow {
      width: 80%;
    }
  }

  @include c('icon-container') {
    position: absolute;
    z-index: 1;
    margin-bottom: 0;

    &--negative-margin {
      margin-bottom: -13px;
    }
  }
}
