@import '../../../base';

@include b('LogoutModal') {
  @include c('header') {
    @include common-modal-header;

    color: var(--primary-300);
  }

  @include c('close-button') {
    @include common-modal-close-button;

    color: var(--primary-300);
  }

  @include c('body') {
    @include common-modal-body;

    padding: 30px 30px 90px;
  }

  @include c('description-container') {
    text-align: center;
    color: var(--neutral-800);
    line-height: 26px;
  }

  @include c('logout-btn') {
    @include common-button(var(--primary-300), 74px, 32px, 11px);
  }

  @include c('cancel-btn') {
    @include common-button(var(--cool-neutral-700), 74px, 32px, 11px);

    margin-right: 8px;
  }
}
