@import '../../base';

@include b('MessageAttachmentList') {
  display: flex;
  flex-direction: column;
  margin: 5px 0;

  @include c('attachment-list-item') {
    display: flex;
    flex-direction: column;
  }

  #{current-block-child-selector('body')} + #{current-block-child-selector('attachment-list')} {
    margin-top: 6px;
  }
}
