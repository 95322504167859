@import '../base';

@include b('PatientBroadcastListSearchResultDetails') {
  @include m('context', 'SEARCH_RESULT') {
    background-color: var(--neutral-100);
    border-top: solid 1px var(--neutral-400);
  }

  @include c('wrapper') {
    display: flex;
    align-items: center;

    &--context--SELECTED_ITEM {
      align-items: flex-start;
    }

    &--context--SEARCH_RESULT {
      margin: 6px;
      border-radius: 6px;
      height: 38px;

      &:hover {
        background-color: var(--cool-neutral-300);
      }

      @include c('broadcast-avatar') {
        margin-left: 26px;
      }
    }
  }

  @include c('broadcast-avatar') {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }

  @include c('info-container') {
    @include c('name') {
      font-size: 15px;
      font-weight: 600;

      @include c('highlighted') {
        background-color: var(--cool-neutral-300);
      }
    }

    @include c('broadcast-list-details') {
      display: flex;
    }

    @include c('container') {
      display: flex;
      flex-direction: column;
    }

    @include c('member-count') {
      font-size: 12px;
    }

    @include c('show-member-list') {
      margin-left: 3px;
      font-size: 12px;
    }

    @include c('view-list-btn') {
      font-size: 11px;
      color: var(--secondary-500);
      cursor: pointer;
    }

    @include c('member-list') {
      background-color: var(--neutral-150);
      border-radius: 10px;
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.09);
      min-height: 100px;
      max-height: calc(100vh - 200px);
      overflow-y: auto;
      width: 280px;

      @include c('members-header') {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 10px;

        @include c('members-name') {
          color: var(--neutral-950);
          display: flex;
          justify-content: center;
        }

        @include c('members-close') {
          display: flex;
          height: 22px;
          margin-left: 240px;
          margin-top: -10px;
          width: 19px;
        }
      }

      @include c('members-container') {
        svg {
          margin-top: 3px;
          opacity: initial;
        }

        @include c('loading') {
          line-height: 70px;
          text-align: center;
        }

        @include c('no-results-container') {
          display: flex;
          font-style: italic;
          justify-content: center;
          padding: 30px;
          text-align: center;
        }

        @include c('member-container') {
          display: flex;
          margin: 10px 5px;
          padding: 0 15px;

          @include c('member-details-list') {
            display: flex;
            flex-direction: column;
            margin-left: 10px;

            @include c('member-display-name') {
              @include ellipsis;

              color: var(--neutral-1000);
              display: flex;
              font-size: 14px;
              line-height: 19px;
              max-height: 20px;
              max-width: 200px;
              width: auto;

              @include c('member-name') {
                @include ellipsis;

                font-weight: 600;
                max-width: 130px;
                white-space: nowrap;
                width: auto;
              }

              @include c('member-relation') {
                @include ellipsis;

                margin-left: 5px;
                max-width: 60px;
                white-space: nowrap;
                width: auto;
              }
            }

            @include c('member-information') {
              color: var(--neutral-700);
              font-size: 10px;
              line-height: 14px;
              text-align: left;

              @include c('patient-name') {
                display: flex;
                max-height: 15px;
                max-width: 150px;
                white-space: nowrap;
                width: auto;

                @include c('patient-member-name') {
                  @include ellipsis;
                }
              }

              @include c('member-details') {
                display: flex;

                @include c('member-mrn') {
                  @include ellipsis;

                  max-width: 80px;
                }
              }
            }
          }
        }
      }
    }
  }
}
