@import '../../base';

@include b('VideoAttachment') {
  @include m('type', 'video') {
    @include center-items-both;

    @include c('loading') {
      @include center-items-both;

      background-color: #fff;
      line-height: 1;
      font-size: 12px;
      width: $message-body-max-width;
      height: $message-video-max-height;
      color: #333;
      border-radius: 8px;
      border: 1px solid #e4e9ed;

      @include c('downloading-indicator') {
        width: 20px;
        height: 20px;
      }
    }

    @include c('attachment-container') {
      position: relative;
      border: 1px solid #e4e9ed;
      padding: 3px;
      border-radius: 8px;
      background: #fff;
      cursor: pointer;

      &--unselectable {
        cursor: default;
      }

      @include c('video') {
        max-width: $message-body-max-width;
        max-height: $message-video-max-height;
        user-select: none;
        border-radius: 5px;
        outline: none;
        vertical-align: top;

        @include medium-messages {
          max-width: $message-body-width-medium;
        }

        &--isDownloading {
          display: none;
        }
      }

      @include c('video-preview-overlay') {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #333;
        opacity: 0.6;
        border-radius: 5px;
        margin: 3px;
      }

      @include c('video-play-icon') {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 50px;
      }
    }
  }
}
