@import '../../base';

@include b('LogoutButton') {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 111px;
  height: 25px;
  border-radius: 3.5px;
  background-color: var(--neutral-100);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.18);
  cursor: pointer;

  &:hover {
    @include c('text') {
      background-color: var(--neutral-200);
    }

    @include c('svg-box') {
      background-color: var(--neutral-100);
    }
  }

  @include c('text') {
    display: flex;
    align-items: center;
    height: 100%;
    flex: 0 0 87px;

    /* color: #484848; */
    color: var(--neutral-900);
    font-size: 12px;
    padding-left: 10px;
    border-radius: 3.5px 0 0 3.5px;
  }

  @include c('svg-box') {
    display: flex;
    justify-content: center;
    flex: 0 0 24px;
    border-left: solid 1px var(--neutral-200);
    height: 100%;
    background-color: var(--neutral-200);
    border-radius: 0 3.5px 3.5px 0;
  }

  svg {
    width: 5px;
  }
}
